import { EuiPanel, useResizeObserver } from "@elastic/eui";
import React, { useEffect, useRef } from "react";
import { RaceEventTimer } from "./RaceEventTimer/RaceEventTimer";
import { Ratings } from "./Ratings/Ratings";
import { RegisteredTo } from "./RegisteredTo/RegisteredTo";

const BottomBar = () => {
  const ref = useRef<HTMLDivElement | null>(null);
  const dimensions = useResizeObserver(ref.current);

  useEffect(() => {
    const toastListContainer = document.querySelector<HTMLElement>(
      ".euiGlobalToastList"
    );
    if (toastListContainer && dimensions.height > 0) {
      toastListContainer.style.marginBottom = `${dimensions.height}px`;
    }

    document.body.style.paddingBottom = `${dimensions.height}px`;

    return () => {
      document.body.style.paddingBottom = "";
    };
  }, [dimensions.height]);

  return (
    <EuiPanel
      panelRef={ref}
      borderRadius="none"
      style={{
        position: "fixed",
        bottom: 0,
        left: 0,
        zIndex: 998,
        width: "100%"
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
          gap: "20px"
        }}
      >
        <RegisteredTo />
        <RaceEventTimer />
        <Ratings />
      </div>
    </EuiPanel>
  );
};

export default BottomBar;
