import { useUIContext } from "contexts/ui";
import { useWebSocketServiceContext } from "contexts/webSocketService/webSocketService";
import React, { createContext, useCallback, useContext, useState } from "react";
import { useQueryClient } from "react-query";
import { Race, RaceSerie } from "types/raceSeries";
import { useRaceSeriesWsEventsHandler } from "./useRaceSeriesWsEventsHandlers";
import { toggleElement } from "../../utils/utils";

type RaceSeries = RaceSerie[];
export type RegisterRaceLoading = {
  raceId: Race["raceId"];
};
type RaceSeriesContextSchema = {
  raceSeries: RaceSeries;
  setRaceSeries: React.Dispatch<React.SetStateAction<RaceSeries>>;
  registerRaceLoading: RegisterRaceLoading[];
  toggleRegisteredRaceLoading: React.Dispatch<
    React.SetStateAction<RegisterRaceLoading>
  >;
};

export const RaceSeriesContext = createContext<RaceSeriesContextSchema>(
  {} as RaceSeriesContextSchema
);
RaceSeriesContext.displayName = "RaceSeriesContext";

export const RaceSeriesContextProvider: React.FC = ({ children }) => {
  const [raceSeries, setRaceSeries] = useState<RaceSeries>([] as RaceSeries);
  const [registerRaceLoading, setRegisterRaceLoading] = useState<
    RegisterRaceLoading[]
  >([]);
  const toggleRegisteredRaceLoading = useCallback(registerToRace => {
    setRegisterRaceLoading(oldValue => toggleElement(oldValue, registerToRace));
  }, []);
  const webSocket = useWebSocketServiceContext();
  const uiContext = useUIContext();
  const queryClient = useQueryClient();
  useRaceSeriesWsEventsHandler(
    webSocket,
    setRaceSeries,
    uiContext,
    queryClient
  );

  return (
    <RaceSeriesContext.Provider
      value={{
        raceSeries,
        setRaceSeries,
        registerRaceLoading,
        toggleRegisteredRaceLoading
      }}
    >
      {children}
    </RaceSeriesContext.Provider>
  );
};

export const useRaceSeriesContext = () => useContext(RaceSeriesContext);
