import React, { FC, ReactNode, useState } from "react";
import {
  EuiButton,
  EuiCallOut,
  EuiEmptyPrompt,
  EuiIcon,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSkeletonRectangle,
  EuiText
} from "@elastic/eui";
import { useQuery } from "react-query";
import axios from "../../../utils/axios";
import { RaceSummaryResponseDto } from "../../../types/profile";
import { Ratings } from "./Ratings";
import { FastestLapTimes } from "./FastestLapTimes";
import { SeriesStats } from "./SeriesStats";
import { RacePlace } from "./RacePlace";
import { SeloResults } from "./SeloResults";
import { format } from "date-fns";
import { MdLocationOn as PinIcon } from "react-icons/md";

interface Props {
  driverId: string | number;
  splitId: string | number;
  opened?: boolean;
  raceSeriesName?: string;
  time?: string;
  track?: string;

  renderTrigger?(setOpened: (opened: boolean) => void): ReactNode;
}

export const DriverPerformanceModal: FC<Props> = props => {
  const [opened, setOpened] = useState(false);

  const calculatedOpened =
    props.opened === true || props.opened === false ? props.opened : opened;

  const raceSummaryQuery = useQuery({
    queryKey: ["raceSummary", props.driverId, props.splitId],
    queryFn: () => {
      return axios.get<RaceSummaryResponseDto>(
        process.env.REACT_APP_ENDPOINT_DRIVER_PROFILE +
          `/driverstats/${props.driverId}/split/${props.splitId}/summary`
      );
    },
    refetchOnWindowFocus: false,
    enabled: calculatedOpened
  });

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {props.renderTrigger ? (
        props.renderTrigger(setOpened)
      ) : (
        <EuiButton onClick={() => setOpened(true)}>
          Driver's performance
        </EuiButton>
      )}

      {calculatedOpened && (
        <EuiModal
          style={{ minWidth: "40vw", maxWidth: "900px" }}
          onClose={() => setOpened(false)}
        >
          <EuiModalHeader
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              gap: "10px"
            }}
          >
            <EuiModalHeaderTitle>
              {props.raceSeriesName ||
                raceSummaryQuery.data?.data?.eventDetails?.raceSeriesName}
            </EuiModalHeaderTitle>
            {props.time && props.track && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  width: "100%"
                }}
              >
                {raceSummaryQuery.data && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px"
                    }}
                  >
                    <EuiIcon size="s" type="userAvatar" />
                    <EuiText size={"s"}>
                      {
                        raceSummaryQuery.data.data?.raceResults?.driver
                          .driverName
                      }
                    </EuiText>
                  </div>
                )}
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <EuiIcon size="s" type="calendar" />
                  <EuiText size={"s"}>
                    {format(new Date(props.time), "Pp")}
                  </EuiText>
                </div>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <PinIcon />
                  <EuiText size={"s"}>{props.track}</EuiText>
                </div>
              </div>
            )}
          </EuiModalHeader>

          <EuiModalBody>
            {raceSummaryQuery.isFetching && (
              <EuiSkeletonRectangle
                isLoading={true}
                width="100%"
                height="500px"
                borderRadius="s"
              />
            )}

            {raceSummaryQuery.isError && (
              <EuiCallOut
                title="Could not fetch Driver's performance data"
                color="warning"
              />
            )}

            {!raceSummaryQuery.isFetching && !raceSummaryQuery.data?.data && (
              <EuiEmptyPrompt
                iconType="folderExclamation"
                title={<h2>No stats for selected criteria</h2>}
                body={<p>Looks like there is no stats to display</p>}
              />
            )}

            {!raceSummaryQuery.isFetching && raceSummaryQuery.data?.data && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px"
                }}
              >
                <RacePlace data={raceSummaryQuery.data.data} />
                <FastestLapTimes
                  lapsDiff={raceSummaryQuery.data.data.lapsDiff}
                />
                <SeriesStats
                  data={raceSummaryQuery.data.data.raceSeriesStatsDiff}
                />
                <SeloResults
                  seloResult={raceSummaryQuery.data.data.seloResult}
                />
                <Ratings ratingsDiff={raceSummaryQuery.data.data.ratingsDiff} />
              </div>
            )}
          </EuiModalBody>

          <EuiModalFooter>
            <EuiButton onClick={() => setOpened(false)} fill>
              Close
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      )}
    </div>
  );
};
