import React, { FC, useState } from "react";
import { EuiBadge, EuiBasicTable, EuiTitle, EuiToolTip } from "@elastic/eui";
import { RaceSerie } from "../../types/raceSeries";
import { RaceStartTime } from "../shared/RaceStartTime";
import { IoCarSportSharp as AllowedCarIcon } from "react-icons/io5";
import { MdLocationOn as PinIcon } from "react-icons/md";
import {
  AverageGridSizeBadge,
  DriversPracticingNumberBadge,
  RegisteredDriversBadgeWithDriversList
} from "./RaceCard/RaceNumbers";
import { uniqBy } from "lodash";
import { SimpleRaceLicenceBadge } from "../RaceLicenceBadge";
import { R3EConnectMenu } from "../shared/R3eConnectMenu";
import { JoinPracticeButton } from "../shared/JoinPracticeButton";
import { RegisterToRaceButton } from "../shared/RegisterToRaceButton";
import { useUserContext } from "../../contexts/user";
import { RaceSeriesDetailsModal } from "../RaceSeriesDetails/RaceSeriesDetails";
import { TrackImage } from "../TrackImage";

interface Props {
  races: RaceSerie[];
  renderTime?(race: RaceSerie): React.ReactNode;
  renderNumbers?(race: RaceSerie): React.ReactNode;
  renderBadges?(race: RaceSerie): React.ReactNode;
  renderButtons?(race: RaceSerie): React.ReactNode;
}

export const RacesList: FC<Props> = props => {
  const { user } = useUserContext();
  const [raceModalDetails, setRaceModalDetails] = useState<{
    raceSeriesId: number;
    eventId: number;
    raceId: number;
  }>();

  return (
    <div
      style={{
        display: "flex",
        flexFlow: "column",
        gap: "20px",
        alignItems: "center"
      }}
    >
      <EuiBasicTable<RaceSerie>
        style={{ overflow: "auto", width: "100%" }}
        items={props.races}
        cellProps={(item, column) => {
          // @ts-ignore
          if (!["time", "track", "name", "cars"].includes(column.id)) {
            return {};
          }

          return {
            style: { cursor: "pointer" },
            onClick: () => {
              setRaceModalDetails({
                raceSeriesId: item.id,
                eventId: item.event.eventId,
                raceId: item.event.race.raceId
              });
            }
          };
        }}
        columns={[
          {
            id: "time",
            align: "center",
            width: "12%",
            render: (item: RaceSerie) => {
              if (props.renderTime) {
                return props.renderTime(item);
              }

              return (
                <EuiTitle size={"xs"}>
                  <span>
                    <RaceStartTime
                      startAtUtc={item.event.race.startAtUtc}
                      style={{
                        alignItems: "center",
                        flexFlow: "column"
                      }}
                    />
                  </span>
                </EuiTitle>
              );
            }
          },
          {
            id: "track",
            align: "center",
            width: "12%",
            render: (item: RaceSerie) => {
              return (
                <TrackImage
                  backgroundUrl={item.event.track.trackPhotoUrl}
                  imageUrl={item.event.track.trackMapUrl}
                  simShortCode={item.sim.shortCode}
                  style={{ height: "60px" }}
                />
              );
            }
          },
          {
            id: "name",
            width: "15%",
            render: (item: RaceSerie) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px"
                  }}
                >
                  <EuiTitle size={"xs"}>
                    <div>{item.name}</div>
                  </EuiTitle>
                  <div style={{ display: "flex", gap: "5px" }}>
                    {uniqBy(
                      item.event.raceSeriesVehicleClassGroups.groups,
                      "requiredLicense.raceLicenseLevel"
                    ).map(vehicleClassGroup => (
                      <SimpleRaceLicenceBadge
                        key={vehicleClassGroup.groupId}
                        short={true}
                        raceLicense={vehicleClassGroup.requiredLicense}
                        tooltip={"Required race license"}
                      />
                    ))}
                  </div>
                </div>
              );
            }
          },
          {
            id: "cars",
            width: "20%",
            render: (item: RaceSerie) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px"
                  }}
                >
                  <DescriptionRow>
                    <AllowedCarIcon />
                    <div
                      style={{ display: "flex", gap: "2px", flexWrap: "wrap" }}
                    >
                      {item.event.raceSeriesVehicleClassGroups.groups.map(c => (
                        <div key={c.groupId}>
                          {c.vehicleClasses.map(c => c.name).join(" ")}
                        </div>
                      ))}
                    </div>
                  </DescriptionRow>
                  <DescriptionRow>
                    <PinIcon />
                    <span>{item.event.track.trackName}</span>
                  </DescriptionRow>
                </div>
              );
            }
          },
          {
            id: "numbers",
            width: "12%",
            render: (item: RaceSerie) => {
              return (
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    alignItems: "center"
                  }}
                >
                  {props.renderNumbers?.(item) || (
                    <>
                      <AverageGridSizeBadge
                        count={item.event.race.averageGridSize}
                      />
                      <RegisteredDriversBadgeWithDriversList
                        drivers={item.event.race.registeredDrivers}
                      />
                      <DriversPracticingNumberBadge
                        count={item.event.practicingDriversCount}
                      />
                    </>
                  )}
                </div>
              );
            }
          },
          {
            id: "badges",
            width: "10%",
            render: (item: RaceSerie) => {
              if (props.renderBadges) {
                return props.renderBadges(item);
              }

              return (
                <div
                  style={{
                    display: "flex",
                    flexFlow: "wrap",
                    width: "200px",
                    gap: "5px"
                  }}
                >
                  <EuiToolTip
                    position="bottom"
                    content={<p>Race session length.</p>}
                  >
                    <EuiBadge iconType="flag">
                      {`${item.event.raceSessionDurationInMinutes} min.` || ""}
                    </EuiBadge>
                  </EuiToolTip>
                  {item.dlc.isNeeded && (
                    <EuiToolTip
                      position="bottom"
                      content={
                        <p>DLC required. Check series details for more info.</p>
                      }
                    >
                      <EuiBadge iconType="package" color="primary">
                        DLC
                      </EuiBadge>
                    </EuiToolTip>
                  )}
                </div>
              );
            }
          },
          {
            id: "buttons",
            width: "20%",
            align: "right",
            mobileOptions: {
              width: "100%"
            },
            render: (item: RaceSerie) => {
              if (props.renderButtons) {
                return props.renderButtons(item);
              }

              if (
                item.sim.shortCode === "R3E" &&
                user &&
                !user?.isRaceroomAccountConnected
              ) {
                return <R3EConnectMenu />;
              }

              return (
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    width: '100%',
                    minWidth: "240px",
                    justifyContent: 'flex-end'
                  }}
                >
                  <JoinPracticeButton raceSeries={item} />
                  <RegisterToRaceButton
                    race={item.event.race}
                    vehicleClassGroups={
                      item.event.raceSeriesVehicleClassGroups.groups
                    }
                    league={item.league}
                  />
                </div>
              );
            }
          }
        ]}
        tableLayout="auto"
      />
      {raceModalDetails && (
        <RaceSeriesDetailsModal
          {...raceModalDetails}
          setOpened={() => setRaceModalDetails(undefined)}
        />
      )}
    </div>
  );
};

const DescriptionRow = ({ children }: { children: React.ReactNode }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "5px"
      }}
    >
      {children}
    </div>
  );
};
