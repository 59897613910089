import React, { FC, useEffect, useMemo, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { fetchHotHoursRaces, fetchTrendingRaces } from "../../api/raceSeries";
import { RaceCard } from "./RaceCard/RaceCard";
import {
  EuiButton,
  EuiSkeletonRectangle,
  EuiSpacer,
  EuiText,
  EuiTitle
} from "@elastic/eui";
import { uniqBy } from "lodash";
import { RacesList } from "./RacesList";
import { useWebSocketServiceContext } from "../../contexts/webSocketService/webSocketService";
import { parseJsonOrNull, toggleElementByKey } from "../../utils/utils";
import { DriverRegistrationWSPayload } from "./useRaceSeriesWsEventsHandlers";
import { RaceSerie } from "../../types/raceSeries";
import TrendingRacesIconLight from "assets/icons/wired-outline-152-bar-chart-arrow_light.json";
import TrendingRacesIconDark from "assets/icons/wired-outline-152-bar-chart-arrow_dark.json";
import { AnimatedHeaderWithIcon } from "../shared/AnimatedIconHeader";

interface Props {}

export const TrendingRaces: FC<Props> = props => {
  const [showAll, setShowAll] = useState(false);

  const hotRacesQuery = useQuery(["hotHoursRaces"], fetchHotHoursRaces, {
    refetchOnWindowFocus: false,
    retry: 0
  });

  const trendingRacesQuery = useQuery(["trendingRaces"], fetchTrendingRaces, {
    refetchOnWindowFocus: false,
    refetchInterval: 5 * 60_000
  });

  const queryClient = useQueryClient();
  const webSocket = useWebSocketServiceContext();
  useEffect(() => {
    if (!webSocket.connected) {
      return;
    }

    const registeredDriversNumberChanged = (payload: string) => {
      const parsedPayload = parseJsonOrNull<DriverRegistrationWSPayload>(
        payload
      );

      if (!parsedPayload) {
        return;
      }

      queryClient.setQueryData<{ races: RaceSerie[] }>(
        ["hotHoursRaces"],
        oldDataList => {
          if (!oldDataList) {
            return oldDataList!; // i think this is a type issue with react-query
          }

          return {
            races: oldDataList.races.map(oldData => {
              if (parsedPayload.raceId !== oldData.event.race.raceId) {
                return oldData;
              }

              return {
                ...oldData,
                event: {
                  ...oldData.event,
                  race: {
                    ...oldData.event.race,
                    registeredDrivers: toggleElementByKey(
                      oldData.event.race.registeredDrivers,
                      parsedPayload,
                      "driverId"
                    )
                  }
                }
              };
            })
          };
        }
      );

      queryClient.setQueryData<{ races: RaceSerie[] }>(
        ["trendingRaces"],
        oldDataList => {
          if (!oldDataList) {
            return oldDataList!; // i think this is a type issue with react-query
          }

          return {
            races: oldDataList.races.map(oldData => {
              if (parsedPayload.raceId !== oldData.event.race.raceId) {
                return oldData;
              }

              return {
                ...oldData,
                event: {
                  ...oldData.event,
                  race: {
                    ...oldData.event.race,
                    registeredDrivers: toggleElementByKey(
                      oldData.event.race.registeredDrivers,
                      parsedPayload,
                      "driverId"
                    )
                  }
                }
              };
            })
          };
        }
      );
    };

    webSocket.instance?.on("DriverRegistered", registeredDriversNumberChanged);
    webSocket.instance?.on(
      "DriverUnregistered",
      registeredDriversNumberChanged
    );

    return () => {
      webSocket.instance?.off(
        "DriverRegistered",
        registeredDriversNumberChanged
      );
      webSocket.instance?.off(
        "DriverUnregistered",
        registeredDriversNumberChanged
      );
    };
  }, [queryClient, webSocket.connected, webSocket.instance]);

  const sortedRaces = useMemo(
    () =>
      uniqBy(
        [
          ...(hotRacesQuery.data?.races || []),
          ...(trendingRacesQuery.data?.races || [])
        ],
        "event.race.raceId"
      ).sort((a, b) => {
        return a.event.race.startAtUtc.localeCompare(b.event.race.startAtUtc);
      }),
    [trendingRacesQuery.data, hotRacesQuery.data]
  );

  useEffect(() => {
    if (!sortedRaces.length) {
      return;
    }

    const closestEventDate = new Date(
      [...sortedRaces].sort((a, b) =>
        a.event.race.startAtUtc.localeCompare(b.event.race.startAtUtc)
      )[0].event.race.startAtUtc
    );

    const timeout = setTimeout(() => {
      trendingRacesQuery.refetch();
      hotRacesQuery.refetch();
    }, +closestEventDate - +new Date());

    return () => {
      clearTimeout(timeout);
    };
  }, [hotRacesQuery, sortedRaces, trendingRacesQuery]);

  if (trendingRacesQuery.isLoading || hotRacesQuery.isLoading) {
    return (
      <EuiSkeletonRectangle
        isLoading={true}
        width="100%"
        height="300px"
        borderRadius="s"
      />
    );
  }

  if (!sortedRaces.length) {
    return null;
  }

  return (
    <div style={{ display: "flex", flexFlow: "column" }}>
      <AnimatedHeaderWithIcon
        lightLottieIcon={TrendingRacesIconLight}
        darkLottieIcon={TrendingRacesIconDark}
        title={
          <EuiTitle>
            <EuiText>Top trending races</EuiText>
          </EuiTitle>
        }
        subtitle={
          <EuiText color={"subdued"} size={"s"}>
            Discover our trending races!
          </EuiText>
        }
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          gap: "20px",
          flexWrap: "wrap"
        }}
      >
        {sortedRaces.slice(0, 3).map(race => (
          <RaceCard raceSerie={race} key={race.event.race.raceId} />
        ))}
      </div>
      <EuiSpacer size={"l"} />
      {sortedRaces.length > 3 && (
        <div>
          <EuiTitle size={"s"}>
            <div>Other trending races</div>
          </EuiTitle>
          <RacesList
            races={showAll ? sortedRaces.slice(3) : sortedRaces.slice(3, 6)}
          />
          {sortedRaces.length > 6 && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px"
              }}
            >
              <EuiButton onClick={() => setShowAll(!showAll)}>
                {showAll ? "Hide" : "Show all trending races"}
              </EuiButton>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
