import React, { FC } from "react";
import { RaceSummaryResponseDto } from "../../../types/profile";
import {
  EuiIcon,
  EuiPanel,
  EuiStat,
  EuiText,
  EuiTextColor,
  EuiTitle
} from "@elastic/eui";
import { colors, useEuiThemeTyped } from "../../../theme/eui_overrides";
import { AnimatedCounter } from "../AnimatedCounter";

interface Props {
  data: RaceSummaryResponseDto;
}

export const RacePlace: FC<Props> = ({ data }) => {
  const { euiTheme } = useEuiThemeTyped();

  const seasonPositionChange = data.seasonStandingsDiff.preRaceSeasonStandings
    .isEmpty
    ? 0
    : data.seasonStandingsDiff.preRaceSeasonStandings.position -
      data.seasonStandingsDiff.postRaceSeasonStandings.position;
  const eventPositionChange = data.eventStandingsDiff.preRaceEventStandings
    .isEmpty
    ? 0
    : data.eventStandingsDiff.preRaceEventStandings.position -
      data.eventStandingsDiff.postRaceEventStandings.position;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        gap: "20px"
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column"
        }}
      >
        <EuiTextColor
          color={getColor(data.raceResults.position)}
          style={{
            fontSize: "7em",
            textAlign: "center",
            WebkitTextStroke: "10px"
          }}
        >
          {getNumberWithOrdinal(data.raceResults.position)}
        </EuiTextColor>
        <EuiText style={{ textAlign: "center", fontSize: "2em" }}>
          {data.raceResults.numberOfDriversInRace
            ? `out of ${data.raceResults.numberOfDriversInRace}`
            : "place"}
        </EuiText>
      </div>

      <EuiPanel
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          alignItems: "center",
          width: "100%"
        }}
      >
        <EuiText>
          <h3 style={{ textTransform: "capitalize" }}>Points</h3>
        </EuiText>
        <EuiTitle size={"l"}>
          <EuiTextColor
            color={
              data.raceResults.points > 0 ? euiTheme.colors.winGreen : "default"
            }
          >
            <AnimatedCounter from={0} to={data.raceResults.points} />
          </EuiTextColor>
        </EuiTitle>
      </EuiPanel>

      <EuiPanel
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          alignItems: "center",
          width: "100%"
        }}
      >
        <EuiText>
          <h3 style={{ textTransform: "capitalize" }}>Standings</h3>
        </EuiText>
        <div
          style={{
            display: "flex",
            gap: "40px",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <EuiStat
            titleElement={"div"}
            title={
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                {getNumberWithOrdinal(
                  data.seasonStandingsDiff.postRaceSeasonStandings.position
                )}
                {data.seasonStandingsDiff.postRaceSeasonStandings
                  .standingsDriversCount ? (
                  <EuiText size={"m"}>
                    {`out of ${data.seasonStandingsDiff.postRaceSeasonStandings.standingsDriversCount}`}
                  </EuiText>
                ) : (
                  ""
                )}
              </div>
            }
            description={"Season standings position"}
          >
            {(seasonPositionChange === 0 ||
              data.seasonStandingsDiff.preRaceSeasonStandings.isEmpty) && (
              <EuiTextColor>
                <span>&nbsp;</span>
              </EuiTextColor>
            )}
            {seasonPositionChange < 0 && (
              <EuiTextColor color="danger">
                <span>
                  <EuiIcon type="sortDown" /> {Math.abs(seasonPositionChange)}
                </span>
              </EuiTextColor>
            )}
            {seasonPositionChange > 0 && (
              <EuiTextColor color={euiTheme.colors.winGreen}>
                <span>
                  <EuiIcon type="sortUp" /> {seasonPositionChange}
                </span>
              </EuiTextColor>
            )}
          </EuiStat>
          <EuiStat
            titleElement={"div"}
            title={
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                {getNumberWithOrdinal(
                  data.eventStandingsDiff.postRaceEventStandings.position
                )}
                {data.eventStandingsDiff.postRaceEventStandings
                  .standingsDriversCount ? (
                  <EuiText size={"m"}>
                    {`out of ${data.eventStandingsDiff.postRaceEventStandings.standingsDriversCount}`}
                  </EuiText>
                ) : (
                  ""
                )}
              </div>
            }
            description={"Weekly standings position"}
          >
            {(eventPositionChange === 0 ||
              data.eventStandingsDiff.preRaceEventStandings.isEmpty) && (
              <EuiTextColor>
                <span>&nbsp;</span>
              </EuiTextColor>
            )}
            {eventPositionChange < 0 && (
              <EuiTextColor color="danger">
                <span>
                  <EuiIcon type="sortDown" /> {Math.abs(eventPositionChange)}
                </span>
              </EuiTextColor>
            )}
            {eventPositionChange > 0 && (
              <EuiTextColor color={euiTheme.colors.winGreen}>
                <span>
                  <EuiIcon type="sortUp" /> {eventPositionChange}
                </span>
              </EuiTextColor>
            )}
          </EuiStat>
        </div>
      </EuiPanel>
    </div>
  );
};

function getNumberWithOrdinal(n: number) {
  var s = ["th", "st", "nd", "rd"],
    v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

const getColor = (n: number) => {
  if (n === 1) {
    return colors.gold;
  }

  if (n === 2) {
    return colors.silver;
  }

  if (n === 3) {
    return colors.bronze;
  }

  return "default";
};
