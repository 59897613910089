import { ROUTES } from "../constants/routes";
import { confirmEmail } from "../api/auth";
import { TOAST_LIFE_TIME } from "../components/GlobalToast/GlobalToast";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useUIContext } from "../contexts/ui";

export const useConfirmEmail = () => {
  const { pathname, search } = useLocation();
  const history = useHistory();
  const { emitSuccessToast, emitErrorToast } = useUIContext();

  const confirmEmailFromSearchParams = async () => {
    if (pathname === ROUTES.EMAIL_CONFIRMATION) {
      try {
        const searchParams = new URLSearchParams(search);
        const token = searchParams.get("token");
        if (token) {
          await confirmEmail(token);
        }
        emitSuccessToast({
          title: "Your email address has been confirmed. You can now login.",
          toastLifeTimeMs: TOAST_LIFE_TIME.INFINITE
        });
      } catch (error: any) {
        emitErrorToast({
          title: "Can't confirm your email address.",
          text: error.response?.data
        });
      } finally {
        history.push(ROUTES.HOME);
      }
    }
  };

  useEffect(() => {
    confirmEmailFromSearchParams();
  }, []);
};
