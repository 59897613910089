import { EuiButton } from "@elastic/eui";
import { NewsDetailContainer } from "../detail";
import { News } from "types/news";
import { Modal } from "components/shared/Modal";
import React from "react";
import { MarkdownFormat } from "../../MarkdownFormat";

type NewsListModalProps = {
  news: News;
  renderTrigger(setOpened: (opened: boolean) => void): React.ReactNode;
  toggleRead: (id: number) => void;
};

export const NewsListModal = ({
  news,
  renderTrigger,
  toggleRead
}: NewsListModalProps) => {
  return (
    <Modal
      style={{ minWidth: "60vw" }}
      title={<MarkdownFormat textSize="relative">{news.title}</MarkdownFormat>}
      body={<NewsDetailContainer newsId={news.newsId} />}
      renderTrigger={renderTrigger}
      alternativeAction={
        <EuiButton onClick={() => toggleRead(news.newsId)} fill>
          Mark as {news.isRead ? "unread" : "read"}
        </EuiButton>
      }
    />
  );
};
