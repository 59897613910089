import React, { useRef } from "react";
import { useParams } from "react-router-dom";
import {
  EuiForm,
  EuiFlexGroup,
  EuiFormRow,
  EuiSpacer,
  EuiFieldPassword,
  EuiFlexItem,
  EuiButton
} from "@elastic/eui";
import { useUIContext } from "contexts/ui";
import { useHistory } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import {
  UnauthorizedLayout,
  FormWrapper
} from "components/UnauthorizedLayout/UnauthorizedLayout";
import { resetPassword } from "api/auth";
import { ResetPasswordFields } from "types/auth";
import styled from "styled-components";

const HelpTextContainer = styled.div`
  margin-left: 1em;
  ul {
    line-height: 1.5em;
  }
`;

export const ResetPassword = () => {
  const { token } = useParams<{ token: string }>();
  const history = useHistory();
  const { setGlobalLoading, emitErrorToast, emitSuccessToast } = useUIContext();
  const { handleSubmit, register, errors, watch } = useForm();
  const newPasswordValue = useRef({});
  newPasswordValue.current = watch("NewPassword", "");

  const resetPasswordHandler: SubmitHandler<
    Omit<ResetPasswordFields, "Token">
  > = async ({ NewPassword }) => {
    setGlobalLoading({ state: true, offset: false });
    try {
      await resetPassword({ NewPassword, Token: token });
      setGlobalLoading({ state: false });
      emitSuccessToast({
        title: "Password changed successfully. You can now login."
      });
      history.push("/");
    } catch (error: any) {
      setGlobalLoading({ state: false });
      const responseErrorMessage =
        typeof error.response?.data === "string" ? error.response.data : null;
      const errorMessage =
        responseErrorMessage ||
        error.message ||
        "We couldn't reset your password at the moment. Please, try again later.";
      emitErrorToast({ title: errorMessage });
    }
  };
  return (
    // <UnauthorizedLayout>
    // <FormWrapper title="Reset password">
    <EuiForm
      component="form"
      data-testid="resetPasswordForm"
      onSubmit={handleSubmit(resetPasswordHandler)}
    >
      <EuiFlexGroup
        direction="column"
        alignItems="center"
        gutterSize="s"
        responsive={false}
      >
        <EuiFormRow
          isInvalid={Boolean(errors.NewPassword)}
          error={errors.NewPassword?.message}
        >
          <EuiFieldPassword
            name="NewPassword"
            inputRef={register({
              required: "New password is required"
            })}
            isInvalid={Boolean(errors.NewPassword)}
            placeholder="Password"
            aria-label="Password"
            data-testid="passwordField"
          />
        </EuiFormRow>
        <EuiSpacer size="m" />
        <EuiFormRow
          isInvalid={Boolean(errors.repeatPassword)}
          error={errors.repeatPassword?.message}
          helpText={
            <HelpTextContainer>
              <ul>
                <li>Min. 6 characters long</li>
                <li>1 digit</li>
                <li>1 uppercase letter</li>
                <li>1 lowercase letter</li>
              </ul>
            </HelpTextContainer>
          }
        >
          <EuiFieldPassword
            name="repeatPassword"
            inputRef={register({
              required: "Repeat the new password",
              validate: value =>
                value === newPasswordValue.current ||
                "The passwords don't match"
            })}
            isInvalid={Boolean(errors.repeatPassword)}
            placeholder="Repeat password"
            aria-label="Repeat password"
            data-testid="repeatPasswordField"
          />
        </EuiFormRow>
        <EuiSpacer size="m" />
        <EuiFlexItem>
          <EuiButton data-testid="submitResetPasswordButton" type="submit">
            Submit
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiForm>
    //</FormWrapper>
    // </UnauthorizedLayout>
  );
};
