import {
  Criteria,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiEmptyPrompt,
  EuiIcon,
  EuiLink,
  EuiSkeletonRectangle,
  EuiToolTip
} from "@elastic/eui";
import { fetchRaceHistory } from "api/profile";
import { ROUTES } from "constants/routes";
import { useUIContext } from "contexts/ui";
import { useTablePagination } from "hooks/useTablePagination";
import { useTableSort } from "hooks/useTableSort";
import React, { useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { RaceHistoryEntry } from "types/profile";
import { RaceTimeCell } from "./RaceTimeCell";
import { RaceSeriesDetailsModalWithTrigger } from "../../../RaceSeriesDetails/RaceSeriesDetails";
import { DriverPerformanceModal } from "../../../shared/RaceSummaryModal/DriverPerformanceModal";

export const RaceHistoryList = () => {
  const { driverId } = useParams<{ driverId: string }>();
  const { setGlobalLoading, emitErrorToast } = useUIContext();
  const {
    pagination,
    pageChangeHandler,
    setTotalItemCount,
    getPage
  } = useTablePagination<RaceHistoryEntry>();

  const {
    sortField,
    sortDirection,
    sortChangeHandler,
    sortData
  } = useTableSort<RaceHistoryEntry>({
    initialSortField: "raceTimeUtc",
    initialSortDirection: "desc"
  });

  const columns: EuiBasicTableColumn<RaceHistoryEntry>[] = useMemo(
    () => [
      {
        field: "raceTimeUtc",
        name: "Date",
        render: (raceTimeUtc: RaceHistoryEntry["raceTimeUtc"]) => (
          <RaceTimeCell raceTimeUtc={raceTimeUtc} />
        )
      },
      {
        name: "Race series",
        render: ({ series, raceSeriesId }: RaceHistoryEntry) => (
          <RaceSeriesDetailsModalWithTrigger
            raceSeriesId={raceSeriesId}
            renderTrigger={setOpened => (
              <EuiToolTip position="bottom" content="Show series details page">
                <EuiButtonEmpty
                  iconSide="right"
                  iconType="popout"
                  size={"s"}
                  onClick={() => setOpened(true)}
                >
                  {series}
                </EuiButtonEmpty>
              </EuiToolTip>
            )}
          />
        )
      },
      {
        field: "track",
        name: "Track"
      },
      {
        field: "isOfficial",
        name: "Official"
      },
      {
        field: "car",
        name: "Car"
      },
      {
        field: "finishPosition",
        name: "Finish position"
      },
      {
        field: "points",
        name: "Points",
        style: { minWidth: 60 }
      },
      {
        field: "safety",
        style: { minWidth: 50 },
        name: (
          <EuiToolTip content="Safety in Race">
            <span>
              SiR{"   "}
              <EuiIcon
                size="s"
                color="subdued"
                type="questionInCircle"
                className="eui-alignTop"
              />
            </span>
          </EuiToolTip>
        ),
        render: (safety: RaceHistoryEntry["safety"]) => <span>{safety}</span>
      },
      {
        field: "lapsConsistencyPercentage",
        style: { minWidth: 50 },
        name: (
          <EuiToolTip content="Consistency in Race">
            <span>
              CiR{"   "}
              <EuiIcon
                size="s"
                color="subdued"
                type="questionInCircle"
                className="eui-alignTop"
              />
            </span>
          </EuiToolTip>
        ),
        render: (
          lapsConsistencyPercentage: RaceHistoryEntry["lapsConsistencyPercentage"]
        ) => <span>{lapsConsistencyPercentage}</span>
      },
      {
        field: "aiSkill",
        style: { minWidth: 50 },
        name: (
          <EuiToolTip content="AI skill">
            <span>
              AI{" "}
              <EuiIcon
                size="s"
                color="subdued"
                type="questionInCircle"
                className="eui-alignTop"
              />
            </span>
          </EuiToolTip>
        ),
        render: (aiSkill: RaceHistoryEntry["aiSkill"]) => <span>{aiSkill}</span>
      },
      {
        name: "More",
        actions: [
          {
            name: "Driver's performance",
            description: "Show driver's detailed performance after the race",
            render: item => (
              <DriverPerformanceModal
                driverId={driverId}
                splitId={item.raceId}
                raceSeriesName={item.series}
                time={item.raceTimeUtc}
                track={item.track}
                renderTrigger={setOpened => (
                  <EuiToolTip content={"Driver's performance"}>
                    <EuiButtonIcon
                      iconType={"inspect"}
                      onClick={() => setOpened(true)}
                    />
                  </EuiToolTip>
                )}
              />
            )
          },
          {
            name: "Race details",
            render: ({ raceId }: RaceHistoryEntry) => (
              <EuiLink>
                <Link
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px"
                  }}
                  to={`${ROUTES.STATS}/${driverId}/race-history/${raceId}`}
                >
                  <EuiToolTip content={"Race details"}>
                    <EuiIcon size={"m"} type="push" />
                  </EuiToolTip>
                </Link>
              </EuiLink>
            )
          }
        ]
      }
    ],
    [driverId]
  );

  const tableChangeHandler = ({ page, sort }: Criteria<RaceHistoryEntry>) => {
    if (page) {
      pageChangeHandler(page);
    }
    if (sort) {
      sortChangeHandler(sort);
    }
  };

  const raceHistoryQuery = useQuery(
    ["raceHistoryQuery", driverId],
    fetchRaceHistory,
    {
      refetchOnWindowFocus: false,
      onError: (error: ResponseError) => {
        const errorMessage =
          error.response.data ||
          error.message ||
          "Can't get race history data.";
        emitErrorToast({ title: errorMessage });
      }
    }
  );

  useEffect(() => {
    setGlobalLoading({ state: raceHistoryQuery.isFetching });
  }, [raceHistoryQuery.isFetching, setGlobalLoading]);

  useEffect(() => {
    setTotalItemCount(raceHistoryQuery.data?.length || 0);
  }, [raceHistoryQuery.data?.length, setTotalItemCount]);

  if (raceHistoryQuery.isFetching) {
    return (
      <EuiSkeletonRectangle
        isLoading={true}
        width="100%"
        height="500px"
        borderRadius="s"
      />
    );
  }

  return raceHistoryQuery.data?.length ? (
    <EuiBasicTable<RaceHistoryEntry>
      items={getPage(sortData(raceHistoryQuery.data))}
      rowHeader="name"
      columns={columns}
      pagination={pagination}
      onChange={tableChangeHandler}
      tableLayout="auto"
      sorting={{
        enableAllColumns: true,
        sort: {
          field: sortField,
          direction: sortDirection
        }
      }}
    />
  ) : (
    <EuiEmptyPrompt
      iconType="folderExclamation"
      title={<h2>No race history data</h2>}
      body={<p>Looks like the is no race history data to display so far.</p>}
    />
  );
};
