import { EuiBetaBadge, EuiButton, EuiToolTip } from "@elastic/eui";
import { postJoinPractice } from "api/raceSeries";
import { TOAST_LIFE_TIME } from "components/GlobalToast/GlobalToast";
import { useUIContext } from "contexts/ui";
import { useUserContext } from "contexts/user";
import React from "react";
import { RaceSerie } from "types/raceSeries";
import { User } from "../../types/auth";
import { RaceLicense } from "../../types/profile";

type JoinPracticeButtonProps = {
  raceSeries: RaceSerie;
};
export const JoinPracticeButton: React.FC<JoinPracticeButtonProps> = ({
  raceSeries
}) => {
  const { waitingForPractice, setWaitingForPractice } = useUserContext();
  const { setLoginModalMode, user, userRaceLicense } = useUserContext();
  const { emitErrorToast, emitToast } = useUIContext();

  const joinPractice = async (raceSeriesId: number) => {
    setWaitingForPractice({
      raceSeriesId: raceSeriesId
    });

    try {
      await postJoinPractice(raceSeriesId);
      emitToast({
        title: "Preparing practice server",
        text: (
          <div>
            <p>
              Sometimes it can take <b>up to 5 minutes</b>, but usually it takes
              less.
            </p>
            <p>
              After session is ready you will see another message with links to
              practice session.
            </p>
          </div>
        ),
        toastLifeTimeMs: TOAST_LIFE_TIME.INFINITE
      });
    } catch (thrownError) {
      const error: any = thrownError;
      if (error.response?.status === 401) {
        setWaitingForPractice({
          raceSeriesId: 0
        });
        setLoginModalMode("login");
        return;
      }
      emitErrorToast({
        title: `Can't join practice session. ${error.response?.data || ""}`,
        toastLifeTimeMs: TOAST_LIFE_TIME.INFINITE
      });

      setWaitingForPractice({
        raceSeriesId: 0
      });
    }
  };

  const eligibility = calculateEligibility(raceSeries, user, userRaceLicense);

  return (
    <EuiToolTip
      position="bottom"
      content={
        !eligibility.eligible
          ? eligibility.reason
          : "Join online practice session."
      }
    >
      <div
        style={{
          position: "relative",
          width: "fit-content"
        }}
      >
        {!eligibility.eligible && (
          <div
            style={{
              position: "absolute",
              top: 0,
              right: -2
            }}
          >
            <EuiBetaBadge label={"?"} color="subdued" size="s" />
          </div>
        )}
        <EuiButton
          color={'text'}
          minWidth={0}
          isDisabled={!eligibility.eligible}
          isLoading={waitingForPractice?.raceSeriesId === raceSeries.id}
          onClick={
            eligibility.eligible ? () => joinPractice(raceSeries.id) : undefined
          }
        >
          Practice
        </EuiButton>
      </div>
    </EuiToolTip>
  );
};

const calculateEligibility = (
  rs: RaceSerie,
  user?: User | null,
  userRaceLicense?: RaceLicense
) => {
  const hasLicense = userRaceLicense
    ? rs.event.raceSeriesVehicleClassGroups.groups.some(
        g =>
          g.requiredLicense.raceLicenseLevel <= userRaceLicense.raceLicenseLevel
      )
    : true;

  const isLeagueMember = user
    ? user.leagues.some(l => l.id === rs.league.id)
    : true;

  const reason = !isLeagueMember
    ? `You're not a ${rs.league.name} league member`
    : !hasLicense
    ? `You don't have required race license`
    : "";

  return {
    eligible: hasLicense && isLeagueMember,
    reason: reason
  };
};
