import axios from "utils/axios";
import {
  GetUserProfileResponseDto,
  League,
  LoginFormFields,
  RefreshSteamDataResponseDto,
  Region,
  RegisterFormFields,
  ResetPasswordFields,
  ResetPasswordRequestField,
  SaveUserProfileRequestDto,
  SaveUserProfileResponseDto
} from "types/auth";

type LoginResponse = {
  user: {
    emailAddress: string;
    steamDisplayName: string;
    steamId: string;
    id: number;
    userRoles: Array<"Admin" | string>;
    leagues: Array<League>;
    isAdmin: boolean;
    isRaceroomAccountConnected: boolean;
    steamAvatarUrl: string;
  };
  token: string;
};

export const getAvailableRegions = async () => {
  const { data } = await axios.get<Region[]>(
    process.env.REACT_APP_ENDPOINT_DRIVER_ACCESS + "/registration/regions-list"
  );
  return data;
};

export const loginUser = async (loginFormData: LoginFormFields) => {
  const {
    data: { user, token }
  } = await axios.post<LoginResponse>(
    process.env.REACT_APP_ENDPOINT_DRIVER_ACCESS + "/login",
    loginFormData
  );
  return { token, ...user };
};

export const registerUser = (registerFormData: RegisterFormFields) =>
  axios.post(
    process.env.REACT_APP_ENDPOINT_DRIVER_ACCESS + "/registration",
    registerFormData
  );

export const requestPasswordReset = (
  resetPasswordRequestData: ResetPasswordRequestField
) =>
  axios.post(
    process.env.REACT_APP_ENDPOINT_DRIVER_ACCESS + "/lostpassword",
    resetPasswordRequestData
  );

export const resetPassword = (resetPasswordData: ResetPasswordFields) =>
  axios.put(
    process.env.REACT_APP_ENDPOINT_DRIVER_ACCESS + "/lostpassword",
    resetPasswordData
  );

export const confirmEmail = (token: string) =>
  axios.put(process.env.REACT_APP_ENDPOINT_DRIVER_ACCESS + "/registration", {
    token
  });

export const refreshToken = async () => {
  const {
    data: { user, token }
  } = await axios.get<LoginResponse>(
    process.env.REACT_APP_ENDPOINT_DRIVER_ACCESS + "/refreshtoken"
  );
  return { token, ...user };
};

export const getUser = async () => {
  const { data } = await axios.get<GetUserProfileResponseDto>(
    process.env.REACT_APP_ENDPOINT_DRIVER_ACCESS + "/user"
  );
  return data;
};

export const saveUser = async (
  userDetails: Partial<SaveUserProfileRequestDto>
) => {
  const { data } = await axios.put<SaveUserProfileResponseDto>(
    `${process.env.REACT_APP_ENDPOINT_DRIVER_ACCESS}/user`,
    userDetails
  );

  return data;
};

export const deleteUserAccount = async () => {
  const { data } = await axios.delete(
    `${process.env.REACT_APP_ENDPOINT_DRIVER_ACCESS}/user`
  );

  return data;
};

export const refreshSteamData = async () => {
  const { data } = await axios.patch<RefreshSteamDataResponseDto>(
    `${process.env.REACT_APP_ENDPOINT_DRIVER_ACCESS}/user/steam-refresh`
  );

  return data;
};

export const logout = () =>
  axios.delete(process.env.REACT_APP_ENDPOINT_DRIVER_ACCESS + "/login");
