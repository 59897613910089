import {
  EuiBadge,
  EuiBetaBadge,
  EuiButton,
  EuiHorizontalRule,
  EuiPopover,
  EuiPopoverTitle,
  EuiText,
  EuiToolTip
} from "@elastic/eui";
import { useUserContext } from "contexts/user";
import React, { FC, useState } from "react";
import { VehicleClassGroup } from "types/raceSeries";
import { useRegisterRace } from "./hooks/useRegisterRace";
import {
  AlreadyRegisteredModal,
  DecisionDialogData
} from "../RaceSeries/AlreadyRegisteredModal";
import { useRaceSeriesContext } from "components/RaceSeries/raceSeriesContext";
import { SimpleRaceLicenceBadge } from "components/RaceLicenceBadge";

type RegisterToRaceButtonProps = {
  race: { raceId: number };
  vehicleClassGroups: VehicleClassGroup[];
  league: { id: number; name: string };
  buttonText?: React.ReactNode;
};
export const RegisterToRaceButton: React.FC<RegisterToRaceButtonProps> = ({
  race,
  vehicleClassGroups,
  league,
  buttonText
}) => {
  const { registeredRaces, userRaceLicense, user } = useUserContext();

  const [
    decisionDialogData,
    setDecisionDialogData
  ] = useState<DecisionDialogData>();
  const { unregisterRace, registerRace } = useRegisterRace();

  const { registerRaceLoading } = useRaceSeriesContext();

  const isDriverRegistered = () => {
    return registeredRaces
      ? registeredRaces.some(r => r.raceId === race.raceId)
      : false;
  };

  const isButtonLoading = (raceId: number) => {
    return registerRaceLoading.some(r => r.raceId === raceId);
  };

  const doesHaveRequiredLicense = (raceLicenseLevel: number) => {
    return userRaceLicense
      ? userRaceLicense?.raceLicenseLevel >= raceLicenseLevel
      : true;
  };

  const isLeagueMember = () => {
    return user ? user.leagues.some(l => l.id === league.id) : true;
  };

  if (isDriverRegistered()) {
    return (
      <EuiButton
        title={"Unregister"}
        isDisabled={isButtonLoading(race.raceId)}
        isLoading={isButtonLoading(race.raceId)}
        color={"warning"}
        onClick={() => {
          unregisterRace(race.raceId);
        }}
        minWidth={110}
      >
        Unregister
      </EuiButton>
    );
  }

  return (
    <>
      <TooltipWithFloatingBadgeForButton
        content={
          (!vehicleClassGroups.some(vcg =>
            doesHaveRequiredLicense(vcg.requiredLicense.raceLicenseLevel)
          ) ||
            !isLeagueMember()) &&
          `You don't have required race license level`
        }
      >
        {vehicleClassGroups.length === 1 ? (
          <EuiButton
            minWidth={110}
            isDisabled={
              !doesHaveRequiredLicense(
                vehicleClassGroups[0].requiredLicense.raceLicenseLevel
              ) ||
              isButtonLoading(race.raceId) ||
              !isLeagueMember()
            }
            isLoading={isButtonLoading(race.raceId)}
            color={"primary"}
            onClick={() => {
              registerRace(race.raceId, vehicleClassGroups[0].classGroup).catch(
                error => {
                  if (!error.conflictingEvents) {
                    return;
                  }

                  setDecisionDialogData({
                    conflictingEvents: error.conflictingEvents,
                    attemptedRaceId: race.raceId,
                    attemptedClassGroup: vehicleClassGroups[0].classGroup
                  });
                }
              );
            }}
          >
            {buttonText || "Register"}
          </EuiButton>
        ) : (
          <RegisterToMulticlassPopup
            disabled={
              !vehicleClassGroups.some(vcg =>
                doesHaveRequiredLicense(vcg.requiredLicense.raceLicenseLevel)
              ) || !isLeagueMember()
            }
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              {vehicleClassGroups.map(vehicleClassGroup => {
                return (
                  <React.Fragment key={vehicleClassGroup.groupId}>
                    {
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "20px",
                          padding: "10px",
                          width: "100%",
                          flexWrap: "wrap"
                        }}
                      >
                        <EuiToolTip
                          content={`Class group ${vehicleClassGroup.classGroup}`}
                        >
                          <EuiBadge>CG{vehicleClassGroup.classGroup}</EuiBadge>
                        </EuiToolTip>
                        <SimpleRaceLicenceBadge
                          short={true}
                          raceLicense={vehicleClassGroup.requiredLicense}
                          tooltip={`Required race license: ${vehicleClassGroup.requiredLicense.raceLicenseName}`}
                        />
                        <ul>
                          {vehicleClassGroup.vehicleClasses.map(vc => (
                            <li key={vc.id}>
                              <EuiText size="s">{vc.name}</EuiText>
                            </li>
                          ))}
                        </ul>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            flex: 1,
                            minWidth: "160px"
                          }}
                        >
                          <TooltipWithFloatingBadgeForButton
                            content={
                              (!doesHaveRequiredLicense(
                                vehicleClassGroup.requiredLicense
                                  .raceLicenseLevel
                              ) ||
                                !isLeagueMember()) &&
                              `You don't have required race license level: ${vehicleClassGroup.requiredLicense.raceLicenseName}`
                            }
                          >
                            <EuiButton
                              isDisabled={
                                !doesHaveRequiredLicense(
                                  vehicleClassGroup.requiredLicense
                                    .raceLicenseLevel
                                ) ||
                                isButtonLoading(race.raceId) ||
                                !isLeagueMember()
                              }
                              isLoading={isButtonLoading(race.raceId)}
                              color={"primary"}
                              onClick={() => {
                                registerRace(
                                  race.raceId,
                                  vehicleClassGroup.classGroup
                                ).catch(error => {
                                  if (!error.conflictingEvents) {
                                    return;
                                  }

                                  setDecisionDialogData({
                                    conflictingEvents: error.conflictingEvents,
                                    attemptedRaceId: race.raceId,
                                    attemptedClassGroup:
                                      vehicleClassGroup.classGroup
                                  });
                                });
                              }}
                            >
                              Register
                            </EuiButton>
                          </TooltipWithFloatingBadgeForButton>
                        </div>
                      </div>
                    }
                    <EuiHorizontalRule margin="xs" />
                  </React.Fragment>
                );
              })}
            </div>
          </RegisterToMulticlassPopup>
        )}
      </TooltipWithFloatingBadgeForButton>
      {decisionDialogData && (
        <AlreadyRegisteredModal
          data={decisionDialogData}
          onClose={() => setDecisionDialogData(undefined)}
        />
      )}
    </>
  );
};

const TooltipWithFloatingBadgeForButton = ({
  content,
  children
}: {
  content: React.ReactNode;
  children: React.ReactNode;
}) => {
  return (
    <EuiToolTip position="bottom" content={content}>
      <div
        style={{
          position: "relative"
        }}
      >
        {!!content && (
          <div
            style={{
              position: "absolute",
              top: 0,
              right: -2
            }}
          >
            <EuiBetaBadge label={"?"} color="subdued" size="s" />
          </div>
        )}
        {children}
      </div>
    </EuiToolTip>
  );
};

type RegisterToMulticlassPopupProps = {
  disabled: boolean;
  children: React.ReactNode;
  buttonText?: React.ReactNode;
};
const RegisterToMulticlassPopup: FC<RegisterToMulticlassPopupProps> = props => {
  const [open, setOpen] = useState(false);

  return (
    <TooltipWithFloatingBadgeForButton
      content={props.disabled && `You don't have required race license level`}
    >
      <EuiPopover
        button={
          <EuiButton
            isDisabled={props.disabled}
            color="primary"
            onClick={() => setOpen(!open)}
            minWidth={110}
          >
            {props.buttonText || "Select class"}
          </EuiButton>
        }
        isOpen={open}
        closePopover={() => setOpen(false)}
        anchorPosition="upCenter"
      >
        <EuiPopoverTitle>Choose your class group</EuiPopoverTitle>
        {props.children}
      </EuiPopover>
    </TooltipWithFloatingBadgeForButton>
  );
};
