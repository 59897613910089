import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiSkeletonRectangle,
  EuiSpacer,
  EuiText,
  EuiTextColor
} from "@elastic/eui";
import React from "react";
import { EventCard } from "../EventCard";
import { useQuery } from "react-query";
import { fetchRaceSeasonEvents } from "api/raceSeries";
import { useUIContext } from "contexts/ui";
import { TOAST_LIFE_TIME } from "components/GlobalToast/GlobalToast";

const SectionTitle: React.FC<{}> = ({ children }) => (
  <EuiText textAlign="center">
    <h4>
      <EuiTextColor color="subdued">{children}</EuiTextColor>
    </h4>
    <EuiSpacer size="l" />
  </EuiText>
);

export const Schedule = ({ raceSeriesId }: { raceSeriesId: number | string }) => {
  const uiContext = useUIContext();
  const { emitErrorToast } = uiContext;
  const raceSeriesEventsQuery = useQuery(
    ["raceSeriesEventsQuery", raceSeriesId],
    fetchRaceSeasonEvents,
    {
      refetchOnWindowFocus: false,
      onError: () =>
        emitErrorToast({
          title: "Can't get season events.",
          toastLifeTimeMs: TOAST_LIFE_TIME.INFINITE
        })
    }
  );

  const upcomingEvents = raceSeriesEventsQuery.data?.events.filter(
    event => !event.isCurrentEvent && !event.isPastEvent
  );
  const pastEvents = raceSeriesEventsQuery.data?.events.filter(
    event => event.isPastEvent
  );

  if (raceSeriesEventsQuery.isLoading) {
    return (
      <EuiSkeletonRectangle
        isLoading={true}
        width="100%"
        height="100px"
        borderRadius="s"
      />
    );
  }

  return (
    <EuiFlexGroup>
      {upcomingEvents?.length ? (
        <EuiFlexItem>
          <SectionTitle>Upcoming events</SectionTitle>
          <EuiFlexGroup gutterSize="l" direction="column" responsive={false}>
            {upcomingEvents.map(event => (
              <EventCard key={event.eventSignature} event={event} />
            ))}
          </EuiFlexGroup>
        </EuiFlexItem>
      ) : null}
      <EuiSpacer size="xxl" />
      {pastEvents?.length ? (
        <EuiFlexItem>
          <SectionTitle>Past events</SectionTitle>
          <EuiFlexGroup gutterSize="l" direction="column" responsive={false}>
            {pastEvents.map(event => (
              <EventCard key={event.eventSignature} event={event} />
            ))}
          </EuiFlexGroup>
        </EuiFlexItem>
      ) : null}
    </EuiFlexGroup>
  );
};
