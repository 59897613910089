import GeoJSON from "ol/format/GeoJSON.js";
import Map from "ol/Map.js";
import View from "ol/View.js";
import { Vector as VectorSource } from "ol/source.js";
import { fromLonLat } from "ol/proj.js";

import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import VectorLayer from "ol/layer/Vector";
import { defaults as defaultControls, MousePosition } from "ol/control";
import { createStringXY } from "ol/coordinate";
import { Fill, Stroke, Style } from "ol/style";
import { calc_coordinates } from "./calc_cords";
import CircleStyle from "ol/style/Circle";
import { Geometry, Point } from "ol/geom";
import { Feature, MapBrowserEvent } from "ol";
import { buffer } from "ol/extent";
import { TelemetryTicksResponseDto } from "../../types/telemetryTicksResponseDto";
import TileLayer from "ol/layer/Tile";
import { OSM } from "ol/source";
import { EuiSwitch } from "@elastic/eui";

interface Props {
  data: { data: TelemetryTicksResponseDto; color: string }[];
  selected?: number;
  hovered: number | null;
  setHovered(value: number | null): void;
}

export const TelemetryMap: FC<Props> = props => {
  const ref = useRef<HTMLDivElement>(null);
  const featureOverlayRef = useRef<VectorLayer<Feature<Geometry>>>();
  const mapRef = useRef<Map>();
  const tileLayerRef = useRef<TileLayer<OSM>>();

  const [showMap, setShowMap] = useState(false);

  const vectors = useMemo(
    () =>
      props.data.map((d, index) => ({
        color: d.color,
        lapId: d.data.lapId,
        cords: d.data.telemetryTicks.car_z.map((tt, tickIndex) => {
          const cords = calc_coordinates(
            "interlagos",
            (tt.telemetryTick as number) * 1000,
            (props.data[index].data.telemetryTicks.car_x[tickIndex]
              .telemetryTick as number) * 1000
          );

          return [cords.Long, cords.Lat, tt.trackPositionMeters];
        }),
        source: new VectorSource({
          features: new GeoJSON()
            .readFeatures({
              type: "FeatureCollection",
              crs: {
                type: "name",
                properties: {
                  name: "EPSG:4326"
                }
              },
              features: [
                {
                  type: "Feature",
                  geometry: {
                    type: "LineString",
                    coordinates: d.data.telemetryTicks.car_z.map(
                      (tt, tickIndex) => {
                        const cords = calc_coordinates(
                          "interlagos",
                          (tt.telemetryTick as number) * 1000,
                          (props.data[index].data.telemetryTicks.car_x[
                            tickIndex
                          ].telemetryTick as number) * 1000
                        );

                        return [cords.Long, cords.Lat, tt.trackPositionMeters];
                      }
                    )
                  }
                }
              ]
            })
            .map(f => {
              f.getGeometry()?.transform("EPSG:4326", "EPSG:3857");

              return f;
            })
        }),
        style: new Style({
          stroke: new Stroke({
            color: d.color,
            width: 3
          }),
          fill: new Fill({
            color: d.color
          })
        })
      })),
    [props.data]
  );

  useEffect(() => {
    const mousePositionControl = new MousePosition({
      coordinateFormat: createStringXY(4),
      projection: "EPSG:4326"
    });

    tileLayerRef.current = new TileLayer({
      source: new OSM()
    });
    tileLayerRef.current.setVisible(false);

    mapRef.current = new Map({
      controls: defaultControls().extend([mousePositionControl]),
      layers: [
        tileLayerRef.current,
        ...vectors.map(
          v =>
            new VectorLayer({
              source: v.source,
              style: v.style
            })
        )
      ],
      target: ref.current ?? undefined,
      view: new View({
        center: fromLonLat(vectors[0].cords[0] as number[]),
        zoom: 16
      })
    });

    featureOverlayRef.current = new VectorLayer({
      source: new VectorSource(),
      map: mapRef.current,
      style: new Style({
        image: new CircleStyle({
          radius: 5,
          fill: new Fill({
            color: vectors[0].color
          })
        })
      })
    });
  }, []);

  useEffect(() => {
    if (!mapRef.current) {
      return;
    }

    const listener = function (evt: MapBrowserEvent<any>) {
      if (evt.dragging || !mapRef.current) {
        return;
      }

      const coordinate = mapRef.current.getEventCoordinate(evt.originalEvent);

      const selectedVector =
        vectors.find(v => v.lapId === props.selected) || vectors[0];
      const closestFeature = selectedVector.source.getClosestFeatureToCoordinate(
        coordinate
      );

      if (!closestFeature) {
        return;
      }

      const closestPoint = closestFeature
        .getGeometry()
        ?.getClosestPoint(coordinate);

      if (!closestPoint) {
        return;
      }

      let hoveredPoint = null;

      selectedVector.source.forEachFeatureInExtent(
        buffer(new Point(coordinate).getExtent(), 100.1),
        function () {
          hoveredPoint = Math.round(closestPoint[2]);
        }
      );

      props.setHovered(hoveredPoint);
    };

    mapRef.current.on("pointermove", listener);

    return () => {
      mapRef.current?.un("pointermove", listener);
    };
  }, [props.setHovered, props.selected, vectors]);

  useEffect(() => {
    if (!featureOverlayRef.current) {
      return;
    }

    featureOverlayRef.current.getSource()?.clear();

    if (props.hovered === null) {
      return;
    }

    const selectedVector =
      vectors.find(v => v.lapId === props.selected) || vectors[0];

    const coordinates = selectedVector.cords.find(d => d[2] === props.hovered);

    if (!coordinates) {
      return;
    }

    featureOverlayRef.current.setStyle(
      new Style({
        image: new CircleStyle({
          radius: 5,
          fill: new Fill({
            color: selectedVector.color
          })
        })
      })
    );

    featureOverlayRef.current
      .getSource()
      ?.addFeature(new Feature(new Point(fromLonLat(coordinates))));
  }, [vectors, props.hovered, props.selected]);

  return (
    <div style={{ position: "relative" }}>
      <div style={{ position: "absolute", top: 10, right: 20, zIndex: 999 }}>
        <EuiSwitch
          label={"Show map"}
          checked={showMap}
          onChange={() => {
            setShowMap(!showMap);
            tileLayerRef.current?.setVisible(!showMap);
          }}
        />
      </div>
      <div ref={ref} style={{ height: "80vh", width: "100%" }} />
    </div>
  );
};
