import React, { useEffect, useState } from "react";
import {
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  Legend, LineChart, Line
} from "recharts";
import { format } from "date-fns";
import { DriverRatingsHistory, RatingsHistoryEntry } from "types/profile";
import { euiPaletteColorBlind } from "@elastic/eui/lib/services";
import { useTheme } from "styled-components";
import { useMediaQuery } from "react-responsive";

type RatingsHistoryChartProps = {
  driverRatingHistory: DriverRatingsHistory;
  isOverview: boolean;
};

type DataEntry = {
  ratingDateUtc: string;
  safety: number;
  consistency: number;
};

export const RatingsHistoryChart = ({
  driverRatingHistory,
  isOverview,
}: RatingsHistoryChartProps) => {
  const theme = useTheme();
  console.log(theme);

  const { euiBreakpoints } = useTheme();
  const isMobile = useMediaQuery({ maxWidth: euiBreakpoints.m });
  const chartRatio = isMobile ? 2 : 4;

  const [
    safetyAxisColor,
    paceAxisColor,
    consistencyAxisColor
  ] = euiPaletteColorBlind();
  const requiredRacesColor = "#454852";

  const [data, setData] = useState<Array<DataEntry>>([])

  useEffect(() => {
    const newAreaData: any = driverRatingHistory.ratingHistory.map((ratingsHistory: RatingsHistoryEntry) => {
      const areaDataEntry: DataEntry = {
        ratingDateUtc: ratingsHistory.ratingDateUtc,
        safety: ratingsHistory.driverRating.safety,
        consistency: ratingsHistory.driverRating.consistency,
      };

      return areaDataEntry;
    });

    setData(newAreaData);
  }, [driverRatingHistory]);


  return (
    <ResponsiveContainer width="100%" aspect={2}>
      <LineChart data={data}>
        <XAxis
          dataKey="ratingDateUtc"
          tickMargin={25}
          height={70}
          axisLine={false}
          tickLine={false}
          tickFormatter={date => format(new Date(date), "dd.MM.yyyy")}
        />
        <YAxis
          tickMargin={25}
          width={50}
          axisLine={false}
          tickLine={false}
          tickCount={10}
          domain={[0, 100]}
          scale="linear"
        />
        <CartesianGrid opacity={0.1} vertical={false} strokeDasharray="5 5" />
        <Line
          name="Safety"
          type="linear"
          dataKey="safety"
          stroke={safetyAxisColor}
          strokeWidth={2}
        />
        <Line
          name="Consistency"
          type="linear"
          dataKey="consistency"
          stroke={consistencyAxisColor}
          strokeWidth={2}
        />
        <Tooltip
          contentStyle={{
            background: "black",
            border: 0,
            position: "static",
            borderRadius: theme.euiBorderRadius,
          }}
          labelClassName="euiToolTip__title"
          wrapperClassName="euiToolTip"
          labelStyle={{ marginBottom: theme.paddingSizes.xs, color: "white" }}
          labelFormatter={label => format(new Date(label), "dd.MM.yyyy H:m")}
        />
        <Legend iconType="plainline" />
      </LineChart>
    </ResponsiveContainer>
  );
};
