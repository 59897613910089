import * as Sentry from "@sentry/browser";
import { logout, refreshToken } from "api/auth";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { User } from "types/auth";
import { RaceLicense, UserRatings } from "types/profile";
import {
  ConnectionString,
  PracticeSession,
  RegisteredRace
} from "types/raceSeries";
import axios from "utils/axios";
import { getUserRatings } from "../utils/aprilFools";

type SetRegisteredRaceConnectionStrings = (
  connectionStrings: ConnectionString[]
) => void;

export type UserContextType = {
  user: User | null;
  userRatings: UserRatings;
  setUserRatings: React.Dispatch<React.SetStateAction<UserRatings>>;
  userRaceLicense?: RaceLicense;
  setUserRaceLicense: React.Dispatch<
    React.SetStateAction<RaceLicense | undefined>
  >;
  registeredRaces: RegisteredRace[] | null;
  nearestRegisteredRace: RegisteredRace | undefined;
  setRegisteredRaces: React.Dispatch<
    React.SetStateAction<RegisteredRace[] | null>
  >;
  setRegisteredRaceConnectionStrings: SetRegisteredRaceConnectionStrings;
  setUserWithHeaders: (user: User | null) => void;
  waitingForPractice: PracticeSession | null;
  setWaitingForPractice: React.Dispatch<
    React.SetStateAction<PracticeSession | null>
  >;
  loginModalMode: "login" | "register" | "confirmation" | null;
  setLoginModalMode: React.Dispatch<
    React.SetStateAction<"login" | "register" | "confirmation" | null>
  >;
  refreshUserToken(): Promise<void>;
};

type SetUser = {
  (user: User | null): void;
};

const setAxiosAuthHeader = (token: string | null = null) => {
  axios.defaults.headers.common.Authorization = token
    ? `Bearer ${token}`
    : null;
};

export const UserContext = createContext<UserContextType>(
  {} as UserContextType
);
UserContext.displayName = "UserContext";

export const UserContextProvider: React.FC<{}> = ({ children }) => {
  const [registeredRaces, setRegisteredRaces] = useState<
    RegisteredRace[] | null
  >(null);
  const [
    waitingForPractice,
    setWaitingForPractice
  ] = useState<PracticeSession | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [userRatings, setUserRatings] = useState<UserRatings>({});
  const [userRaceLicense, setUserRaceLicense] = useState<RaceLicense>();
  const [loginModalMode, setLoginModalMode] = useState<
    "login" | "register" | "confirmation" | null
  >(null);

  const nearestRegisteredRace = useMemo(
    () =>
      registeredRaces?.sort(
        (a, b) =>
          new Date(a.startTimeUtc).valueOf() -
          new Date(b.startTimeUtc).valueOf()
      )[0],
    [registeredRaces]
  );

  const setRegisteredRaceConnectionStrings: SetRegisteredRaceConnectionStrings = connectionStrings => {
    if (registeredRaces?.length) {
      var registeredRacesCopy = [...registeredRaces].sort(
        (a, b) =>
          new Date(a.startTimeUtc).valueOf() -
          new Date(b.startTimeUtc).valueOf()
      );
      registeredRacesCopy[0].raceConnectionStrings = connectionStrings;

      setRegisteredRaces(registeredRacesCopy);
    } else {
      throw new Error(
        "No registered event so far. Can't set connection string."
      );
    }
  };

  const setUserWithHeaders: SetUser = useCallback(user => {
    setAxiosAuthHeader(user?.token);
    setUser(user);
    if (user) {
      Sentry.setTag("userEmail", user?.emailAddress);
    }
  }, []);

  const refreshUserToken = () => {
    return refreshToken()
      .then(userData => {
        return setUserWithHeaders(userData);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const logoutAndReject = async () => {
    const sessionExpiredResponseError = {
      response: {
        data: "Your session has expired. You have been logged out.",
        status: 401
      }
    };
    try {
      await logout();
      setUserWithHeaders(null);
    } finally {
      return Promise.reject(sessionExpiredResponseError);
    }
  };

  useEffect(() => {
    axios.interceptors.response.use(
      response => response,
      async error => {
        const originalRequest = error.config;
        const response = error.response;

        if (originalRequest && response?.status === 401) {
          if (
            originalRequest.url.includes(
              process.env.REACT_APP_ENDPOINT_DRIVER_ACCESS
            )
          ) {
            return Promise.reject(error);
          }
          try {
            const refreshedUser = await refreshToken();
            setUserWithHeaders(refreshedUser);
            originalRequest.headers[
              "Authorization"
            ] = `Bearer ${refreshedUser.token}`;
            return axios(originalRequest);
          } catch (error) {
            await logoutAndReject();
          }
        }
        return Promise.reject(error);
      }
    );
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        userRatings: getUserRatings(userRatings, user?.id),
        setUserRatings,
        userRaceLicense,
        setUserRaceLicense,
        setUserWithHeaders,
        registeredRaces,
        nearestRegisteredRace,
        setRegisteredRaces,
        setRegisteredRaceConnectionStrings,
        waitingForPractice: waitingForPractice,
        setWaitingForPractice: setWaitingForPractice,
        loginModalMode,
        setLoginModalMode,
        refreshUserToken
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);
