import axios from "utils/axios";
import { AxiosResponse } from "axios";
import {
  CurrentSeason,
  ParticipationStatsResponseDto,
  RaceSerie,
  RaceSeriesSeason,
  RcoStatsResponseDto,
  RegisteredRace,
  TrendingRacesResponseDto,
  UpcomingRaceEntries
} from "types/raceSeries";
import { QueryFunction } from "react-query";
import { VehicleClassesDto } from "../types/vehicleClassesDto";
import { TracksDto } from "../types/tracksDto";
import { CarsDto } from "../types/carsDto";
import { SimsListDto } from "../types/simsListDto";

export const postJoinPractice = async (raceSeriesId: number) => {
  await axios.post(
    `${process.env.REACT_APP_ENDPOINT_RACE_SERIES}/joinpracticesession`,
    {
      RaceSeriesId: raceSeriesId
    }
  );
};

export const registerToRace = async (raceId: number, classGroup: number) => {
  const { data } = await axios.put<RegisteredRace>(
    `${process.env.REACT_APP_ENDPOINT_RACE_SERIES}/raceserieseventregistration/${raceId}/class-group/${classGroup}`,
    {}
  );
  return data;
};

export const unregisterRaceSerie = async (eventId: number) =>
  axios.delete(
    process.env.REACT_APP_ENDPOINT_RACE_SERIES +
      `/raceserieseventregistration/unregister/${eventId}`,
    {}
  );

export const fetchRaceSeries: QueryFunction<RaceSerie[]> = async () => {
  const { data } = await axios.get<RaceSerie[]>(
    process.env.REACT_APP_ENDPOINT_RACE_SERIES + "/raceseries"
  );

  return data;
};

export const fetchDriversCurrentEvents = () =>
  axios.get<{ driverRegisteredToEvents: RegisteredRace[] }>(
    process.env.REACT_APP_ENDPOINT_RACE_SERIES + "/DriverRegisteredToEvents "
  );

export const fetchRaceSeriesDetails: QueryFunction<RaceSerie> = async ({
  queryKey
}) => {
  const [, raceSeriesId, eventId, raceId] = queryKey;
  const params = new URLSearchParams();
  if (eventId) {
    params.append("eventId", eventId);
  }
  if (raceId) {
    params.append("raceId", raceId);
  }
  const { data } = (await axios.get(
    `${
      process.env.REACT_APP_ENDPOINT_RACE_SERIES
    }/raceseries/details/${raceSeriesId}?${params.toString()}`
  )) as AxiosResponse<{ raceSeries: RaceSerie }>;
  return data.raceSeries;
};
export const fetchRaceSeasonEvents: QueryFunction<CurrentSeason> = async ({
  queryKey
}) => {
  const [, raceSeriesId] = queryKey;
  const { data } = (await axios.get(
    `${process.env.REACT_APP_ENDPOINT_RACE_SERIES}/raceseries/current-season-events/${raceSeriesId}`
  )) as AxiosResponse<CurrentSeason>;
  return data;
};

export const fetchCurrentSeason = async () => {
  const { data } = await axios.get<RaceSeriesSeason>(
    process.env.REACT_APP_ENDPOINT_RACE_SERIES +
      "/raceseries/current-race-season"
  );
  return data;
};

export const fetchParticipationStats: QueryFunction<ParticipationStatsResponseDto> = async ({
  queryKey
}) => {
  const [, lastDays] = queryKey;
  const response = await axios.get<ParticipationStatsResponseDto>(
    `${process.env.REACT_APP_ENDPOINT_RACE_SERIES}/participationstats/hourly-stats?lastDays=${lastDays}`
  );

  return response.data;
};

export const fetchRcoStats: QueryFunction<RcoStatsResponseDto> = async ({}) => {
  const response = await axios.get<RcoStatsResponseDto>(
    `${process.env.REACT_APP_ENDPOINT_RACE_SERIES}/participationstats`
  );

  return response.data;
};
export const fetchUpcomingRaces: QueryFunction<UpcomingRaceEntries> = async ({
  queryKey
}) => {
  const [, eventId] = queryKey;
  const requestUrl =
    process.env.REACT_APP_ENDPOINT_RACE_SERIES +
    "/raceseries/event-upcoming-races/" +
    (eventId ? `${eventId}` : "");
  const response = await axios.get<UpcomingRaceEntries>(requestUrl);
  return response.data;
};

export const fetchSims: QueryFunction<SimsListDto> = async () => {
  const response = await axios.get<SimsListDto>(
    `${process.env.REACT_APP_ENDPOINT_RACE_SERIES}/lists/sims`
  );

  return response.data;
};

export const fetchVehicleClasses: QueryFunction<VehicleClassesDto> = async () => {
  const response = await axios.get<VehicleClassesDto>(
    `${process.env.REACT_APP_ENDPOINT_RACE_SERIES}/lists/vehicle-classes?simId=1`
  );

  return response.data;
};

export const fetchTracks: QueryFunction<TracksDto> = async () => {
  const response = await axios.get<TracksDto>(
    `${process.env.REACT_APP_ENDPOINT_RACE_SERIES}/lists/tracks?simId=1`
  );

  return response.data;
};

export const fetchCars: QueryFunction<CarsDto> = async () => {
  const response = await axios.get<CarsDto>(
    `${process.env.REACT_APP_ENDPOINT_RACE_SERIES}/lists/cars?simId=1`
  );

  return response.data;
};

export const fetchTrendingRaces: QueryFunction<TrendingRacesResponseDto> = async () => {
  const response = await axios.get<TrendingRacesResponseDto>(
    `${process.env.REACT_APP_ENDPOINT_RACE_SERIES}/participationstats/trending-races`
  );

  return response.data;
};

export const fetchHotHoursRaces: QueryFunction<TrendingRacesResponseDto> = async () => {
  const response = await axios.get<TrendingRacesResponseDto>(
    `${process.env.REACT_APP_ENDPOINT_RACE_SERIES}/participationstats/hot-hours-races`
  );

  return response.data;
};
