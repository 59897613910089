import {
  EuiButton,
  EuiDescriptionList,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSpacer
} from "@elastic/eui";
import React, { useState } from "react";
import { useRegisterRace } from "components/shared/hooks/useRegisterRace";
import { RaceTimeCell } from "../Stats/RaceHistory/RaceHistoryList/RaceTimeCell";

type ConflictingEvent = {
  raceSeriesId: number;
  raceSeriesName: string;
  classGroup: number;
  scheduledEventId: number;
  startTimeUtc: string;
};

export type DecisionDialogData = {
  conflictingEvents: ConflictingEvent[];
  attemptedRaceId: number;
  attemptedClassGroup: number;
};

type AlreadyRegisteredModalProps = {
  data: DecisionDialogData;
  onClose(): void;
};

export const AlreadyRegisteredModal = ({
  data,
  onClose
}: AlreadyRegisteredModalProps) => {
  const { registerRace, unregisterRace } = useRegisterRace();
  const [loading, setLoading] = useState(false);

  return (
    <EuiModal
      onClose={() => {
        if (loading) {
          return;
        }

        onClose();
      }}
    >
      <EuiModalHeader>
        <EuiModalHeaderTitle>Are you sure?</EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <p>
          You are already registered to a different race events at this time.
          Registering to this event will cancel your registration to the:
        </p>
        <EuiSpacer />
        <EuiDescriptionList
          type="column"
          align={"left"}
          gutterSize={"m"}
          listItems={data.conflictingEvents.map(conflictingEvent => ({
            title: conflictingEvent.raceSeriesName,
            description: (
              <RaceTimeCell raceTimeUtc={conflictingEvent.startTimeUtc} />
            )
          }))}
          style={{ maxWidth: "400px" }}
        />
      </EuiModalBody>

      <EuiModalFooter>
        <EuiButton isDisabled={loading} onClick={onClose}>
          No
        </EuiButton>
        <EuiButton
          fill
          isLoading={loading}
          onClick={() => {
            setLoading(true);

            Promise.all(
              data.conflictingEvents.map(
                async event => await unregisterRace(event.scheduledEventId)
              )
            )
              .then(() => {
                registerRace(data.attemptedRaceId, data.attemptedClassGroup);
              })
              .finally(() => {
                onClose();
              });
          }}
        >
          Unregister
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};
