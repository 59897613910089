import React, { FC, useEffect, useState } from "react";
import { refreshToken } from "../api/auth";
import { useUserContext } from "../contexts/user";
import { useUIContext } from "../contexts/ui";
import { useConfirmEmail } from "../hooks/useConfirmEmail";

interface Props {
  children: React.ReactNode;
}

export const GlobalAuthWrapper: FC<Props> = props => {
  const [loaded, setLoaded] = useState(false);

  useConfirmEmail();
  const { setUserWithHeaders } = useUserContext();
  const { setGlobalLoading } = useUIContext();

  useEffect(() => {
    if (loaded) {
      return;
    }

    setGlobalLoading({ state: true });

    refreshToken()
      .then(userData => setUserWithHeaders(userData))
      .catch(e => {
        console.log(e);
      })
      .finally(() => {
        setLoaded(true);
        setGlobalLoading({ state: false });
      });
  }, [loaded, setGlobalLoading, setUserWithHeaders]);

  if (!loaded) {
    return null;
  }

  return <>{props.children}</>;
};
