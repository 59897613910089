import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { refreshToken } from "api/auth";
import { TOAST_LIFE_TIME } from "components/GlobalToast/GlobalToast";
import { useUIContext } from "contexts/ui";
import { useUserContext } from "contexts/user";
import { AddEventListener, useEventEmitter } from "hooks/useEventEmitter";
import React, { createContext, useContext, useEffect, useState } from "react";
import { LostConnectionToastContent } from "./LostConnectionToastContent";

export type WebSocketServiceContextType = {
  connected: boolean;
  instance?: HubConnection;
  onReconnect: AddEventListener;
};

const WebSocketServiceContext = createContext(
  {} as WebSocketServiceContextType
);

let retryWSConnectionTimeoutHandler: number;

export const WebSocketServiceContextProvider: React.FC = ({ children }) => {
  const [connected, setConnected] = useState(false);
  const { user, setUserWithHeaders } = useUserContext();
  const { emitErrorToast } = useUIContext();
  const [lastConnectionId, setLastConnectionId] = useState<
    HubConnection["connectionId"]
  >(null);
  const wsConnection = React.useRef<HubConnection>();
  const [onReconnect, emitReconnect] = useEventEmitter();

  const refreshAccessToken = async () => {
    const refreshedUser = await refreshToken();
    setUserWithHeaders(refreshedUser);
  };

  let retryCount = 0; //declared here to prevent stale closure issue
  const initWSConnection = async (token: string) => {
    const retryTimeout = 5000;
    try {
      wsConnection.current = new HubConnectionBuilder()
        .withUrl(process.env.REACT_APP_ENDPOINT_RACESERIES_WEBSOCKETS, {
          accessTokenFactory: () => token
        })
        .build();
      await wsConnection.current.start();
      setConnected(wsConnection.current.state === "Connected");
      retryCount = 0;
      wsConnection.current.onclose(() => {
        setConnected(false);
      });
    } catch (error: any) {
      if (error.statusCode === 401) {
        refreshAccessToken();
        return;
      }
      if (retryCount > 2) {
        emitErrorToast({
          text: <LostConnectionToastContent />,
          toastLifeTimeMs: TOAST_LIFE_TIME.INFINITE
        });
      } else {
        const computedTimeout = retryTimeout * (retryCount + 1);
        console.error(
          `Failed to init race series web socket connection. Retrying in ${
            computedTimeout / 1000
          } seconds.`
        );
        console.error(error);
        retryWSConnectionTimeoutHandler = window.setTimeout(() => {
          retryCount = ++retryCount;
          initWSConnection(token);
        }, computedTimeout);
      }
    }
  };

  const closeWSConnection = () => {
    wsConnection.current?.stop();
    clearTimeout(retryWSConnectionTimeoutHandler);
  };

  useEffect(() => {
    if (user?.token && !connected) {
      initWSConnection(user.token);
    }
  }, [user, connected]);

  useEffect(() => {
    if (
      wsConnection.current?.connectionId &&
      wsConnection.current.connectionId !== lastConnectionId
    ) {
      if (lastConnectionId) {
        emitReconnect();
      }
      setLastConnectionId(wsConnection.current.connectionId);
    }
  }, [wsConnection.current?.connectionId]);

  useEffect(() => closeWSConnection, []);

  return (
    <WebSocketServiceContext.Provider
      value={{ connected, instance: wsConnection?.current, onReconnect }}
    >
      {children}
    </WebSocketServiceContext.Provider>
  );
};

export const useWebSocketServiceContext = () =>
  useContext(WebSocketServiceContext);
