import { useState } from "react";
import { Criteria } from "@elastic/eui";

export const DEFAULT_PAGINATION_CONFIG = {
  pageSizeOptions: [5, 10, 20, 50],
  initialPageSize: 20,
  initialTotalItemsCount: 0
};

type GetPage<T> = (items: T[]) => T[];

type UsePaginationConfig = {
  pageSizeOptions?: number[];
  initialPageSize?: number;
  initialTotalItemsCount?: number;
  controlledTotalCount?: number;
};

export function useTablePagination<S>({
  pageSizeOptions = DEFAULT_PAGINATION_CONFIG.pageSizeOptions,
  initialPageSize = DEFAULT_PAGINATION_CONFIG.initialPageSize,
  initialTotalItemsCount = DEFAULT_PAGINATION_CONFIG.initialTotalItemsCount,
  controlledTotalCount,
}: UsePaginationConfig = DEFAULT_PAGINATION_CONFIG) {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(() => {
    if (pageSizeOptions.includes(initialPageSize)) {
      return initialPageSize;
    }
    return pageSizeOptions[0];
  });
  const [totalItemCount, setTotalItemCount] = useState(initialTotalItemsCount);

  const pagination = {
    pageIndex,
    pageSize,
    totalItemCount: typeof controlledTotalCount === 'number' ? controlledTotalCount : totalItemCount,
    pageSizeOptions: [5, 10, 20, 50]
  };

  const pageChangeHandler = ({
    index,
    size
  }: NonNullable<Criteria<S>["page"]>) => {
    setPageIndex(index);
    setPageSize(size);
  };

  const getPage: GetPage<S> = items => {
    const sliceFrom = pageIndex * pageSize;
    return items.slice(sliceFrom, sliceFrom + pageSize);
  };

  return { pagination, setTotalItemCount, pageChangeHandler, getPage };
}
