import React, { useState } from "react";
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiFieldText,
  EuiFieldPassword,
  EuiLink,
  EuiButton,
  EuiSpacer,
  EuiForm,
  EuiFormRow
} from "@elastic/eui";
import { ResetPasswordRequest } from "./ResetPasswordRequest";
import { useForm, SubmitHandler } from "react-hook-form";
import { LoginFormFields } from "types/auth";

type LoginFormProps = {
  submitHandler: SubmitHandler<LoginFormFields>;
};

export const LoginForm = ({ submitHandler }: LoginFormProps) => {
  const [openResetPasswordRequest, setOpenResetPasswordRequest] = useState(
    false
  );
  const { handleSubmit, register, errors } = useForm();

  return (
    <>
      <EuiForm
        component="form"
        data-testid="loginForm"
        onSubmit={handleSubmit(submitHandler)}
      >
        <EuiFlexGroup
          direction="column"
          alignItems="center"
          gutterSize="none"
          responsive={false}
        >
          <EuiFormRow
            isInvalid={Boolean(errors.emailaddress)}
            error={errors.emailaddress?.message}
          >
            <EuiFieldText
              placeholder="E-mail"
              icon={"email"}
              isInvalid={Boolean(errors.emailaddress)}
              name="emailaddress"
              inputRef={register({
                required: "Email is required"
              })}
              aria-label="Email"
              data-testid="emailaddressField"
            />
          </EuiFormRow>
          <EuiSpacer size="m" />
          <EuiFormRow
            isInvalid={Boolean(errors.password)}
            error={errors.password?.message}
          >
            <EuiFieldPassword
              name="password"
              inputRef={register({
                required: "Password is required"
              })}
              isInvalid={Boolean(errors.password)}
              placeholder="Password"
              aria-label="Password"
              data-testid="passwordField"
            />
          </EuiFormRow>
          <EuiSpacer size="m" />
          <EuiFlexItem>
            <EuiLink
              data-testid="forgotPasswordButton"
              onClick={() => setOpenResetPasswordRequest(true)}
            >
              Forgot password?
            </EuiLink>
          </EuiFlexItem>
          <EuiSpacer size="xxl" />
          <EuiFlexItem>
            <EuiButton data-testid="loginButton" type="submit">
              Sign in
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiForm>
      <ResetPasswordRequest
        open={openResetPasswordRequest}
        closeHandler={() => setOpenResetPasswordRequest(false)}
      />
    </>
  );
};
