import React, { FC } from "react";
import { EuiCard, EuiToolTip } from "@elastic/eui";
import { TrackImage } from "../TrackImage";

interface Props {
  children: React.ReactNode;
  track: {
    trackPhotoUrl: string;
    trackMapUrl: string;
    name: string;
    country: string;
  };
  sim: { shortCode: string };
}

export const TrackTooltip: FC<Props> = props => {
  return (
    <EuiToolTip
      content={
        <EuiCard
          textAlign="left"
          image={
            <div
              style={{
                minHeight:
                  "100px" /*adding some height to prevent tooltip from jumping*/
              }}
            >
              <TrackImage
                backgroundUrl={props.track.trackPhotoUrl}
                imageUrl={props.track.trackMapUrl}
                simShortCode={props.sim.shortCode}
              />
            </div>
          }
          title={props.track.name}
          description={props.track.country}
        />
      }
    >
      <>{props.children}</>
    </EuiToolTip>
  );
};
