import React, { FC } from "react";
import { RaceSummaryResponseDto } from "../../../types/profile";
import { EuiIcon, EuiPanel, EuiText } from "@elastic/eui";
import { useEuiThemeTyped } from "../../../theme/eui_overrides";
import { Cell, Pie, PieChart } from "recharts";

interface Props {
  ratingsDiff: RaceSummaryResponseDto["ratingsDiff"];
}

export const Ratings: FC<Props> = props => {
  return (
    <EuiPanel
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "60px",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          alignItems: "center"
        }}
      >
        <EuiText>
          <h3 style={{ textTransform: "capitalize" }}>Overall rating</h3>
        </EuiText>
        <div style={{ display: "flex", gap: "40px" }}>
          <SingleElement
            label={"Safety"}
            post={props.ratingsDiff.postRaceOverallRating.safety}
            pre={props.ratingsDiff.preRaceOverallRating?.safety}
          />
          <SingleElement
            label={"Consistency"}
            post={props.ratingsDiff.postRaceOverallRating.consistency}
            pre={props.ratingsDiff.preRaceOverallRating?.consistency}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          alignItems: "center"
        }}
      >
        <EuiText>
          <h3 style={{ textTransform: "capitalize" }}>
            {props.ratingsDiff.postRaceLicenseRating.raceLicenseName} licence
            rating
          </h3>
        </EuiText>
        <div style={{ display: "flex", gap: "40px" }}>
          <SingleElement
            label={"Safety"}
            post={props.ratingsDiff.postRaceLicenseRating.safety}
            pre={props.ratingsDiff.preRaceLicenseRating?.safety}
          />
          <SingleElement
            label={"Consistency"}
            post={props.ratingsDiff.postRaceLicenseRating.consistency}
            pre={props.ratingsDiff.preRaceLicenseRating?.consistency}
          />
        </div>
      </div>
    </EuiPanel>
  );
};

interface ElementProps {
  label: string;
  post: number;
  pre?: number;
}

const SingleElement: FC<ElementProps> = React.memo(props => {
  const { euiTheme } = useEuiThemeTyped();

  const calculate = (post: number, pre?: number) => {
    if (!pre) {
      return [
        {
          name: "value",
          value: post,
          label: post,
          color: euiTheme.colors.subduedText
        },
        { name: "left", value: 100 - post, color: "transparent" }
      ];
    }

    if (post - pre > 0) {
      return [
        {
          name: "value",
          value: pre,
          label: post,
          color: euiTheme.colors.subduedText
        },
        { name: "diffUp", value: post - pre, color: euiTheme.colors.winGreen },
        { name: "left", value: 100 - post, color: "transparent" }
      ];
    }

    if (post - pre < 0) {
      return [
        {
          name: "value",
          value: post,
          label: post,
          color: euiTheme.colors.subduedText
        },
        { name: "diffDown", value: pre - post, color: euiTheme.colors.danger },
        { name: "left", value: 100 - pre, color: "transparent" }
      ];
    }

    return [
      {name: "value", value: post, label: post, color: euiTheme.colors.subduedText},
      { name: "left", value: 100 - post, color: "transparent" }
    ];
  };

  const data = calculate(props.post, props.pre);

  // https://github.com/recharts/recharts/issues/1664
  // added react.memo to not render this component too much as well
  // it's stupid, but it works, and other shits didn't
  const keyToActuallyRemountTheChartAndTriggerAnimationToMitigateLabelBug = Math.random();

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <EuiText>{props.label}</EuiText>
      <PieChart
        width={150}
        height={150}
        key={keyToActuallyRemountTheChartAndTriggerAnimationToMitigateLabelBug}
      >
        <Pie
          data={data}
          // isAnimationActive={false} // this is to fix the bug when label disappears after resize or toggle the modal
          startAngle={90}
          endAngle={-270}
          innerRadius={40}
          outerRadius={60}
          fill="#8884d8"
          paddingAngle={2}
          stroke={"none"}
          dataKey="value"
          labelLine={false}
          label={({ cx, cy, fill, name, value, label, color }) => {
            if (name === "value") {
              return (
                <text
                  x={cx}
                  y={cy}
                  dy={8}
                  textAnchor="middle"
                  fill={fill}
                  fontSize={euiTheme.size.l}
                >
                  {label}
                </text>
              );
            }

            if (name === "diffUp") {
              return (
                <>
                  <EuiIcon
                    size={"s"}
                    x={cx - 15}
                    y={cy + 10}
                    dy={8}
                    type={"sortUp"}
                    color={color}
                  />
                  <text
                    x={cx + 7}
                    y={cy + 15}
                    dy={8}
                    textAnchor="middle"
                    color={color}
                    fill={fill}
                  >
                    {value}
                  </text>
                </>
              );
            }

            if (name === "diffDown") {
              return (
                <>
                  <EuiIcon
                    size={"s"}
                    x={cx - 15}
                    y={cy + 10}
                    dy={8}
                    type={"sortDown"}
                    color={color}
                  />
                  <text
                    x={cx + 7}
                    y={cy + 15}
                    dy={8}
                    textAnchor="middle"
                    color={color}
                    fill={fill}
                  >
                    {value}
                  </text>
                </>
              );
            }

            return null;
          }}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
      </PieChart>
    </div>
  );
});
