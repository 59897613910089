import React, { FC, useState } from "react";
import { useQuery } from "react-query";
import { fetchRaceSeries, fetchSeasons } from "../../../api/admin";
import { useUIContext } from "../../../contexts/ui";
import {
  EuiAccordion,
  EuiListGroup,
  EuiListGroupItem,
  EuiSkeletonRectangle,
  EuiTitle,
  useEuiTheme
} from "@elastic/eui";
import {
  IRaceSeries,
  RaceSeriesResponseDto,
  Season,
  SeasonsListResponseDto
} from "../../../types/admin";
import { RaceSeries } from "./RaceSeries";

interface Props {}

export const SeasonsDashboard: FC<Props> = props => {
  const theme = useEuiTheme();
  const { emitErrorToast } = useUIContext();

  const [selectedSeason, setSelectedSeason] = useState<Season | null>(null);
  const seasons = useQuery<unknown, ResponseError, SeasonsListResponseDto>(
    ["adminSeasons"],
    fetchSeasons,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      onError: error => {
        emitErrorToast({
          title: error.response.data || error.message || "Can't fetch seasons"
        });
      }
    }
  );

  const [selectedSeries, setSelectedSeries] = useState<IRaceSeries | null>(
    null
  );
  const raceSeries = useQuery<unknown, ResponseError, RaceSeriesResponseDto>(
    ["adminRaceSeries", selectedSeason?.id],
    fetchRaceSeries,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: false,
      enabled: !!selectedSeason,
      onError: error => {
        emitErrorToast({
          title: error.response.data || error.message || "Can't fetch seasons"
        });
      }
    }
  );

  return (
    <div style={{ display: "flex", gap: "20px" }}>
      {seasons.isLoading && (
        <EuiSkeletonRectangle
          isLoading={true}
          width="100%"
          height="300px"
          borderRadius="s"
        />
      )}
      <EuiListGroup>
        {seasons.data?.seasonsList
          .slice()
          .reverse()
          .map(s => (
            <EuiListGroupItem
              key={s.id}
              onClick={() => setSelectedSeason(s)}
              isActive={s.id === selectedSeason?.id}
              label={s.name}
            />
          ))}
      </EuiListGroup>
      {selectedSeason && (
        <div style={{ flex: 1 }}>
          {raceSeries.isFetching && (
            <EuiSkeletonRectangle
              isLoading={true}
              width="100%"
              height="700px"
              borderRadius="s"
            />
          )}
          {raceSeries.data?.raceSeries.map(rs => (
            <EuiAccordion
              key={rs.id}
              id={rs.id.toString()}
              paddingSize={"m"}
              style={{
                borderBottom: `1px solid ${theme.euiTheme.colors.darkShade}`
              }}
              buttonContent={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "3px",
                    padding: "10px 0"
                  }}
                >
                  <EuiTitle size="xs">
                    <h3>{rs.name}</h3>
                  </EuiTitle>
                </div>
              }
              onToggle={isOpen =>
                isOpen ? setSelectedSeries(rs) : setSelectedSeries(null)
              }
              forceState={selectedSeries?.id === rs.id ? "open" : "closed"}
            >
              {selectedSeries?.id === rs.id && (
                <RaceSeries raceSeries={rs} season={selectedSeason} />
              )}
            </EuiAccordion>
          ))}
        </div>
      )}
    </div>
  );
};
