import React, { FC } from "react";
import { EuiIcon, EuiToolTip } from "@elastic/eui";

interface Props {
  title: React.ReactNode;
  tooltip: React.ReactNode;
}

export const TableHeaderCellWithInfoIcon: FC<Props> = ({ title, tooltip }) => {
  return (
    <EuiToolTip content={tooltip}>
      <div style={{ display: "flex", alignItems: 'center', gap: "3px" }}>
        <span>{title}</span>
        <EuiIcon
          size="s"
          color="subdued"
          type="questionInCircle"
          className="eui-alignTop"
        />
      </div>
    </EuiToolTip>
  );
};
