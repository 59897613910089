import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
*, *::before, *::after {
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

  html, body, #root {
    min-height: 100%;
  }

  table.euiTable {
    background-color: initial;
  }
`;
