import {
  EuiAccordion,
  EuiButtonIcon,
  EuiCopy,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiTitle,
  EuiToolTip
} from "@elastic/eui";
import React from "react";
import { useLocation } from "react-router-dom";
import { MarkdownFormat } from "../MarkdownFormat";

const buttonContent = (title: string, id: string) => (
  <div id={id}>
    <EuiFlexGroup gutterSize="s" alignItems="center" responsive={false}>
      <EuiFlexItem grow={false}>
        <EuiIcon type="questionInCircle" size="m" />
      </EuiFlexItem>

      <EuiFlexItem>
        <EuiTitle size="xs">
          <h3>{title}</h3>
        </EuiTitle>
      </EuiFlexItem>
    </EuiFlexGroup>
  </div>
);

export const Faq = () => {
  const { hash } = useLocation();
  return (
    <div>
      {sections.map(s => (
        <EuiAccordion
          key={`${s.hashId}AccordinId`}
          id={`${s.hashId}AccordinId`}
          className="euiAccordionForm"
          buttonClassName="euiAccordionForm__button"
          buttonContent={buttonContent(s.title, s.hashId)}
          extraAction={
            <EuiCopy
              textToCopy={`${
                window.location.protocol +
                "//" +
                window.location.host +
                window.location.pathname
              }#${s.hashId}`}
            >
              {copy => (
                <EuiToolTip title="Copy section url">
                  <EuiButtonIcon
                    onClick={copy}
                    iconType="link"
                    aria-label="Copy url"
                  />
                </EuiToolTip>
              )}
            </EuiCopy>
          }
          paddingSize="l"
          initialIsOpen={hash === `#${s.hashId}`}
        >
          <MarkdownFormat>{s.text}</MarkdownFormat>
        </EuiAccordion>
      ))}
    </div>
  );
};

const communityResources = `
NOTE: The videos below still mention the Clean Laps Challenge - this is no longer a feature of RCO. You can register to a race immediately, provided you have the correct License for it.

**What is racecraft.online?**
Great video from our fellow driver eKlasik where he describes the basis of how to use and navigate racecraft.online

!{video{"id":"2Fqa2HltlJc","title":"What is racecraft.online?", "maxWidth": "600px" }}
&nbsp;  
&nbsp;  
&nbsp;  

**Wie Kann ich bei racecraft.online mit Fahren**
Ein Video für unsere deutschsprachigen Fahrer

!{video{"id":"wpjzJEpU11w","title":"Wie Kann ich bei RaceCraftOnline mit Fahren", "maxWidth": "600px" }}
&nbsp;  
&nbsp;  
&nbsp;  

**Get hyped about racecraft.online! :)**

!{video{"id":"gka9VxgAP1U","title":"Will this Change Automobilista 2 Forever? Racecraft Online!", "maxWidth": "600px" }}
`;

const quickStartText = `
To start racing:

1. Create an account with your username and password
1. Link your Steam account
1. Register for any upcoming race that you have the correct License for (assuming you are a Rookie when you read this, this will be indicated by a grey "Rookie" or "R" icon)
    1. Click "Register" / “Select Class” to register for the next available race. Alternatively, click the "Calendar" button to register for a future timeslot.
1. At the bottom of your screen, you will see details for your next race
    1. At the indicated start time, the server will begin to boot up. This may take a couple of minutes.
    1. When the server is up, it will show the server number.
1. Switch to  AMS2, go to Multiplayer, and use the “Server Name” filter option to find the server. Once you’ve found your server, just join - RCO will automatically track your racing.
    1. Make sure the “Hide Empty Lobbies” filter option is set to NO.
    1. NOTE: The server only shows up after it’s done booting up. The server will not be visible while it is starting up.
1. After the race, it is very important not to leave the server, or return to pits, until AFTER you see the results screen and a pop-up informs you it is ok to leave. If you leave prematurely, the race logs will record your result as a DNF (even if you had already passed the finish line), and you will get 0 points for your efforts.
`;

const raceCraftOnlineText = `
racecraft.online (RCO for short) is a free ranked multiplayer service for sim racers built mostly by one and half guy :smile:
RCO hosts races automatically and the race results, as well as your performance during the race, are translated to RCO's custom rating.  

racecraft.online is about competition. It's about clean racing in a friendly environment. It's about free and easy access to online races for AMS2. It's about promoting driver safety and clean racing.

So what does RCO have to offer?  
:white_check_mark: Clean races  
:white_check_mark: Driver rating  
:white_check_mark: Online racing career  
:white_check_mark: Racing Licenses  
:white_check_mark: Racing Seasons  
:white_check_mark: Races around the clock  
:white_check_mark: Race servers around the globe
:white_check_mark: A friendly Discord community  
:white_check_mark: and more :smile:  
`;

const bugsText = `
There is a chance things will break and there may be bugs, and they will annoy you. We will do our best to fix bugs and issues as soon as possible.

RCO has a Discord server, where you can report issues and ask for support in [this topic](https://discord.com/channels/789131939361062963/1102886413701218366).

Be aware that we're building and running racecraft.online in our free time and we have families and full time jobs. We won’t be able to address all the issues right away. Please be patient
`;

const ams2Multi = `
Be aware that AMS2 multiplayer is not fully stable yet.  

Our stats show that drivers are disconnected on average in 5% of their races. Some drivers are disconnected more often, some don't experience disconnections at all.
When you experience a disconnection, please post your logs to the Reiza forums as explained in this [thread](https://forum.reizastudios.com/threads/multiplayer-logs-reports-thread.32731/).

There are a few community fixes for AMS2 multiplayer like this [Reddit post](https://www.reddit.com/r/AUTOMOBILISTA/s/L50FdHsdDO), but they are not a guaranteed fix.  

Also take a look at this [FAQ entry](https://www.racecraft.online/faq#serverDisconnects).
`;

const accountCreationText = `
Every new account needs to be confirmed through email. Unconfirmed accounts are deleted after 24 hours.

If you've provided an incorrect email address during account creation, you will need to wait 24 hours before you can try to create a new account.

Please also make sure to check your spam folder for confirmation email.
`;

const ratingText = `
**Overview**  
RCO tracks two main driver stats: Overall Rating (all races) and Race License Rating (races within a given License, e.g. Rookie).

Both your Overall and Race License Ratings are based on the average of your last 5 races **ONLY**. For the Race License Rating, it considers the last 5 races done with that respective License, regardless of how many races with other Licenses were done in between.

Rating is supposed to represent your skill over a given time. Rating cannot be grinded.

**Rating types**
There are 2 types of Rating: Safety, and Consistency.

**Safety**
Safety is based on your incidents per minute. Be aware that if you rage quit after an incident, your incident per minute will be high and your Safety will drop significantly. It's better to finish the race to reduce the incidents/minute ratio. All drivers who took part in an incident will get a Safety penalty, as we have no way to determine who is at fault.

**Consistency**
Consistency is based on your lap times. The more consistent your lap time, the more your Consistency Rating increases.

Your Ratings will be lower if you don't finish the race.

**Why did my Rating drop after a good race??**
Because your Rating is the average of the last 5 races. If your Rating goes down after a good race, it’s likely your newest result replaced an older, even better result, causing the average to go down. Don't worry, just keep doing good races and it will go up again.
`;

const sEloText = `
S.Elo is just your regular [Elo rating](https://en.wikipedia.org/wiki/Elo_rating_system) with Safety twist, and is only calculated while racing against other human drivers.

Like any other Elo-system, you gain rating when you beat opponents; the stronger your opponents, the more rating you gain. You lose rating when opponents beat you; the weaker your opponent, the more rating you lose. If you finish in the middle, you will have beaten each opponent behind you and will be beaten by every opponent ahead of you.

When you gain rating, it is multiplied by your Safety in Race (as a %). For example, if you had 0 incidents, you will gain 100% - but if you had lots of incidents, you might only gain 50% of the rating you would otherwise have gotten.

This is to encourage not only winning, but winning cleanly! The safer you drive, the higher your rating will be :wink:
`;

const structureText = `
racecraft.online hosts multiple Series ; each Series is made up of multiple weekly Events, with different track and/or class combos for each week; each Event includes multiple Races at different times; Races may be split into multiple  Splits  if enough drivers register to a Race, with the fastest drivers in the top  Split.

RCO races come in two main categories: Hourly and Weekly Series.

**Hourlies** are hosted all week long, at regular intervals, for example once every hour or once every few hours. These are often shorter sprint races that are fun to run multiple times, and are ideal for spontaneous racing, and improving your Ratings for a promotion to a higher License.

**Weeklies** are races that are only run one or two days per week, and only at 4 specific timeslots per day (to account for different time zones). These are often longer races, more challenging combinations, and might have additional rules like mandatory pit stops, rolling starts, accelerated tire wear and fuel use.
`;

const seasonsText = `
**Seasons**  
Each Season lasts for 13 weeks; as such, there are 13 Events within one Season.

**Official results**
As in some real life series and for example F1 up to the 80's, only the best results count towards the final standings. This is to compensate for missing races due to holidays, being sick, etc. The current Season has 13 Events - you need to participate in at least 8 Events for your results/points to become Official. Official results always rank above unofficial results in Standings, even if they are lower than unofficial results.

If you participated in more than 8 Events, then your points are the sum of points from your best 8 Events.
`;

const pointsText = `
**Points**  
Each Race will grant you points, which are tracked throughout the whole duration of the Season. The driver with the most points within a given Series (ClassGroup) wins that Series for that Season.

During each weekly Event, you can race in multiple Races. Average points from your best 25% Races (rounded up to the next whole number) will be counted as points for that Event.

**Points distribution**
Typically the winner gets 30 points, and points for any position below are spread proportionally. There are two modifiers:
1. There is a grid size modifier from -5 points in single-car grid (or ClassGroup) to +5 points in a full 30-car grid (or ClassGroup).
1. There is a 75-125% Strength of Field modifier, where a grid with AI at 100 and/or humans at 1500 s.Elo equals 100%.
`;

const raceLicenseText = `
**Overview:**

Each new driver starts with a Rookie Race License - this License will allow you to race in Rookie Series/ClassGroups.
There are multiple Race Licenses in racecraft.online:
Rookie, Bronze, Silver, Gold, Platinum and Alien.
Each Race License will allow you to race in different Series/ClassGroups.

Each Race License (except Rookie) has specific Rating requirements, both for Safety and Consistency.
`;

const raceLicenseRequirementText = `
**Race License Rating requirements:**  
\`Rookie\`: no Rating requirements  
\`Bronze\`: Safety 60, Consistency 60  
\`Silver\`: Safety 70, Consistency 70  
\`Gold\`: Safety 80, Consistency 80  
\`Platinum\`: Safety 90, Consistency 90  
\`Alien\`: Safety 95, Consistency 95  
`;

const raceLicensePromotionText = `
To get promoted to the next racing License, you first need to have done **5 races** in your current Race License (the races don't need to be consecutive). 

For example:
To get promotion from Rookie to Bronze, you need to have done at least 5 races in Rookie Series/ClassGroups. Likewise, to get promoted to Silver, you need to do at least 5 races in Bronze Series/ClassGroups, and so on.

Of course, you also need to meet the specific Rating requirements for the next class in order to be promoted. **BOTH** your  Overall and Race License Ratings must be above the required values for the next License level (see this [FAQ entry](https://www.racecraft.online/faq#RacingLicenseRequirement)).
After being promoted, you will have a grace period of 5 races :smile: If after 5 races within your new License level your Rating drops below the required values, you will be demoted to the previous License.

Note that RCO does not host races for every Race License in every Season. Currently Gold is the highest level races we offer, so Platinum is the highest achievable Race License.
`;

const raceLicenseDemotionText = `
Your racing License will be demoted if your Overall Rating or Race License Rating falls below the current License Rating requirements, **minus 5**.

For example:
If your current Race License is Bronze (requirements Safety: 60, Consistency: 60) and  your Safety or Consistency drops below 55, you will be demoted back to Rookie. 

Like mentioned above, there is an adjustment period for the first 6 races of a new Race License, during which you cannot be demoted.

To be promoted again, you will need to do 5 races in your current Race License and raise your Ratings back above the required values for the next Race License.
`;

//new segment
const DLCText = `
In AMS2 multiplayer, you do not have to own all the DLC to be able to join the races. For **tracks**, only the host (that is, the player who joins the lobby first) needs to own the track. After that, any other player can join the lobby and drive the track.
For **cars**, you always need to own the car you are going to drive. Often, we try to make sure at least one of the ClassGroups has a DLC-free option, but this is not always possible.
`;

const splitsText = `
There can be multiple Splits for a given Race, the maximum grid size of a Series determines how drivers will be split for a given Race.

For example, for a maximum grid size of 25, if there are 26 drivers registered to a Race, then RCO will create two races/Splits: both with 13 drivers in them. Alternatively, if there are 51 registrations, RCO will create 3 Splits with 17, 17 and 16 drivers respectively. If there are 76 registrations there will be 4 Splits, and so on.

Assignment to a particular Split is based on your s.Elo rating.
`;

//new segment
const multiclassText = `
Some Events on RCO are multiclass - this means that there will be multiple classes together on track during those races.

Each class of cars belongs to a ClassGroup (CG for short). Sometimes several classes belong to the same ClassGroup (for example GT3 and GT3 Gen 2), sometimes each class is in its own ClassGroup. Each ClassGroup can have different License requirements, despite being part of the same Race.
Results are calculated per ClassGroup, and each ClassGroup has its own separate standings.

When you register for a multiclass Race, you will be prompted to choose one of the available ClassGroups. You need to have the correct License for that ClassGroup in order to register.

As of now, you cannot see how many people have signed up for which ClassGroup, nor who is in which one (this is on our to-do list). Because of this, you are allowed to switch to a different class than you have registered for, in the AMS2 lobby before qualifying starts. For example, if you see you are alone in a class, or your friend picked a different class. However, you still need to have the correct License for that ClassGroup - picking a class car for which you do not have the required License will result in being kicked from the server.

In multiclass Races, qualifying is always private to make sure the different classes do not interface with each other’s efforts.
`;

//not a new segment, but currently not visible in FAQ
const sessionsDateAndTimeText = `
You can click on a Series card and under the Weather forecast tile you will see the in-game date and time the qualifying and race will be run.

Generally speaking, from mid March until late November we use the current date as the in-game race date. When the temperatures in Northern Hemisphere locations become too cold for good racing, we switch to custom dates or default dates, depending on the circumstances. For locations that have suitable temperatures we use the current date all year round. 

For some Events we use historical dates, for example to line up with real world events present or past.


For most Series the in-game qualifying time is set to random during daylight hours. In those cases the race time is set 2 hours after qualifying. In some Series we use custom starting times, for example for Endurance Events or for select Events that are run during sunset or at night.

In general time progression is set to 1x (real), except for Endurance Cup where it is set higher to simulate day-night transitions in longer races, like 24-hour Events.
`;

//not a new segment, but currently not visible in FAQ
const weatherText = `
Currently all Series use RCO’s "random non-rain" setting. That means depending on the length of the race there will be 1, 2, 3 or 4 weather slots which are synced to the race, and each slot is filled at random from Clear, Light Cloud, Medium Cloud, Heavy Cloud, Overcast.

Temperatures are random but appropriate for the time of year (see [Sessions date and time](https://www.racecraft.online/faq#SessionTime)), so cooler in Spring and Fall, and hotter during Summer.
`;

const adaptiveAiText = `
When there are less than 5 registrations for a Race, RCO will fill up the Race with AI drivers. In Races with AI, qualifying is set to private so the AI cars do not interfere with your qualifying efforts.

RCO tracks your laptimes and those of AI, and tries to match the AI to your skill level. You need to complete at least one Race on a given track and car combo so RCO can get your laptimes. After that, in the next Race with the same car and track, the Adaptive AI should be tailored to you.

Adaptive AI uses the average of your 10 best laps and compares them against AI lap times. Note that it's designed to put you in the middle of the field in order to push your limits and racecraft. The Adaptive AI will continuously adapt to your skill -  faster lap times will result in faster AI.

In Races where RCO doesn't know your laptimes, or the AI’s (for a given track and car combo), it will use your last known AI skill or default one, which is around 90.

Warning: AMS2 AI (and also human drivers) are not consistent between tracks and cars; for some combos your AI skill could be 100, for others 90 or 110, it isn’t consistent.

In Races with multiple human opponents, an average calculated AI skill is used, based on each human driver’s respective AI skill level. Keep in mind that it's difficult to match AI to all drivers in a Race - as such, you may face much easier or more difficult AI than would normally be intended.

We recommend at least 2 Races with one car and track combo to calibrate the Adaptive AI.
`;

//new segment
const AiAgressionText = `
As opposed to AI skill, AI aggression cannot be set by the dedicated server. Instead, this setting is taken from the local settings of the host player (that is, the first player who joined the lobby). This is just an AMS2 thing. Due to latency, an aggressive AI setting for the host player can become downright murderous for other players - the AI will “see” and respond to players too late to avoid contact.

So before joining a lobby, please make sure your AI aggression is set to LOW. You can find the setting in AMS2 in Single race > Opponent settings > Opponent aggression.
`;

const serversText = `
**Race servers** are run on demand - that's why there is a 2-3 minute waiting period before the race, as the server is booting up. If no one is registered for a Race, then the race server will not boot up.

Race servers access is whitelisted - only drivers registered to a given Race can join it.

**Practice servers** are joinable for anyone who owns a valid Race License for a given Series.
`;

const regionsText = `
Sessions are hosted on world regions chosen by users during account creation.For races where drivers from multiple world regions are registered, the region majority takes precedence.

Practice sessions are hosted in multiple world regions -  RCO will automatically direct you to a practice session with free slots, no matter in which world region it is hosted.
`;

const disconnectionText = `
There will be times when you will get disconnected from the practice/qualy/race session.

Make sure that you're using wired internet connection (i.e.not Wi-Fi); also, check for any active downloads and stop them, either from your PC or other smart devices (phones/tablets/TVs) downloading in the background.

Tuesday is Steam maintenance day - be aware the risk of disconnects is much higher than on other days. You can use [this website](https://steamstat.us/) to check if Steam services are up.

If everything else looks good then, there’s still a 95% chance that it's the AMS2 dedicated server’s fault, and about 5% that there’s something wrong on RCO’s side. In that case, please create a thread on the [Discord support forum](https://discord.com/channels/789131939361062963/1102886413701218366). Describe what goes wrong, and don’t forget to include the Race ID, Series name, and Race start time (use a [timestamp](https://sesh.fyi/timestamp/) so we don't have to ask which timezone you're talking about).

Here is a [Discord invite link](https://discord.gg/racecraft-online-789131939361062963) for your convenience! :smile:
`;

//new segment
const courtesyRulesText = `
There are some rules we cannot enforce proactively, but that we ask you to abide by:

1. Before a Race, check the front page and take note of how many players are registered for that Race. When you join the lobby as (one of) the first player(s), please wait a couple of minutes until all/most players have arrived, and have had a chance to select a car and livery.
1. Please be friendly and courteous in the lobby/chat. This is not always easy, but at the very least please refrain from being rude.
1. If you were involved in an incident with another player during the race, please refrain from name-calling or rudeness in the race chat. Instead, we recommend you save the replay, and discuss the incident on the RCO Discord [incidents thread](https://discord.com/channels/789131939361062963/1108297010714525757).
1. If you messed up and caused an incident with another player, please apologise after the race, in the lobby or in the post-race-chat on Discord.
`;

//new segment
const variousTpsText = `
In the [News section](https://www.racecraft.online/news) on the front page, there are several pinned items that will enhance your experience of RCO, like Online racing etiquette, Multiclass racing etiquette and how to find races with more human players. Check them out!
`;


const sections = [
  {
    title: "Community resources",
    hashId: "communityResources",
    text: communityResources
  },
  {
    title: "Quick start",
    hashId: "QuickStart",
    text: quickStartText
  },
  {
    title: "racecraft.online (aka RCO)",
    hashId: "RCO",
    text: raceCraftOnlineText
  },
  {
    title: "Troubleshooting",
    hashId: "Troubleshooting",
    text: bugsText
  },
  {
    title: "AMS2 Multiplayer",
    hashId: "Ams2Multiplayer",
    text: ams2Multi
  },
  {
    title: "Can't create account",
    hashId: "accountCreation",
    text: accountCreationText
  },
  {
    title: "Driver Rating",
    hashId: "DriverRating",
    text: ratingText
  },
  {
    title: "S.Elo Rating",
    hashId: "SElo",
    text: sEloText
  },
  {
    title: "Competition structure",
    hashId: "structure",
    text: structureText
  },
  {
    title: "Seasons",
    hashId: "Seasons",
    text: seasonsText
  },
  {
    title: "Points",
    hashId: "Points",
    text: pointsText
  },
  {
    title: "Racing License",
    hashId: "RacingLicense",
    text: raceLicenseText
  },
  {
    title: "Racing License requirements",
    hashId: "RacingLicenseRequirement",
    text: raceLicenseRequirementText
  },
  {
    title: "Racing License promotion",
    hashId: "RacingLicensePromotion",
    text: raceLicensePromotionText
  },
  {
    title: "Racing License demotion",
    hashId: "RacingLicenseDemotion",
    text: raceLicenseDemotionText
  },
  {
    title: "DLC",
    hashId: "DLC",
    text: DLCText
  },
  {
    title: "Splits",
    hashId: "Splits",
    text: splitsText
  },
  {
    title: "Multiclass",
    hashId: "Multiclass",
    text: multiclassText
  },
  {
    title: "Sessions date and time",
    hashId: "SessionTime",
    text: sessionsDateAndTimeText
  },
  {
     title: "Weather",
     hashId: "Weather",
     text: weatherText
  },
  {
    title: "Adaptive AI for AMS2",
    hashId: "AI",
    text: adaptiveAiText
  },
  {
    title: "AI Agression",
    hashId: "AiAgressionText",
    text: AiAgressionText
  },
  {
    title: "Race and practice servers",
    hashId: "Servers",
    text: serversText
  },
  {
    title: "Regions",
    hashId: "Regions",
    text: regionsText
  },
  {
    title: "Server disconnects",
    hashId: "serverDisconnects",
    text: disconnectionText
  },
  {
    title: "Courtesy Rules",
    hashId: "CourtesyRules",
    text: courtesyRulesText
  },
  {
    title: "Various Tips",
    hashId: "VariousiTps",
    text: variousTpsText
  },
];
