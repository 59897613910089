import React from "react";
import { useUIContext } from "contexts/ui";
import { EuiGlobalToastList } from "@elastic/eui";

export const TOAST_LIFE_TIME = {
  DEFAULT: 6000,
  INFINITE: 86400000
} as const;

export const GlobalToast = () => {
  const { toasts, removeToast } = useUIContext();
  return (
    <EuiGlobalToastList
      toasts={toasts}
      dismissToast={removeToast}
      toastLifeTimeMs={TOAST_LIFE_TIME.DEFAULT}
    />
  );
};
