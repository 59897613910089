import { EuiEmptyPrompt } from "@elastic/eui";
import React from "react";
import { News } from "types/news";
import { NewsListItem } from "./NewsListItem";
export interface NewsProps {
  data: News[];
  onMarkAsUnread: (newsId: number) => void;
  onMarkAsRead: (newsId: number) => void;
  allowedActions: string[];
}

export const NewsList = (props: NewsProps) => {
  const { data } = props;
  if (data.length === 0) {
    return (
      <EuiEmptyPrompt
        iconType="searchProfilerApp"
        title={<h2>{`No news.`}</h2>}
        body={<p>{`Looks like there are no news to display.`}</p>}
      />
    );
  }
  return (
    <>
      {data.map(news => {
        return (
          <NewsListItem
            key={news.newsId}
            news={news}
            newsId={news.newsId}
            {...props}
          />
        );
      })}
    </>
  );
};
