import React from "react";
import styled from "styled-components";
import { RaceSerie, VehicleClass } from "types/raceSeries";
import { EuiIcon } from "@elastic/eui";
import { SIM_LOGO } from "../../../constans";

const CoverImageShadowContainer = styled.div`
  position: relative;
  display: flex;
  height: 225px;
  transition: 0.5s filter ease-in-out;
  filter: drop-shadow(1px 2px 3px ${({ theme }) => theme.euiShadowColor});
`;

const CoverImg = styled.img`
  width: 100%;
  object-fit: cover;
  transition: 0.5s all ease-in-out;
  filter: blur(2px);
  -webkit-filter: blur(2px);
  opacity: 0.9;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 90%);
`;
const IconContainer = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
`;
const SimNameContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  background: linear-gradient(190deg, #000a 5%, transparent 35%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 90%);
  img {
    max-width: 40%;
    max-height: 20%;
    z-index: 1;
    margin: ${({ theme }) => theme.paddingSizes.s};
  }
`;

const ClassLogoContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  padding: 15px 10px 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 10px;
  flex-wrap: wrap;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 90%);
  filter: drop-shadow(1px 2px 3px ${({ theme }) => theme.euiShadowColor});
`;

type CoverImageProps = {
  sim: RaceSerie["sim"];
  imageUrl: string;
  vehicleClasses: VehicleClass[];
} & React.PropsWithoutRef<JSX.IntrinsicElements["div"]>;

export const CoverImage = ({
  sim,
  imageUrl,
  vehicleClasses,
  ...props
}: CoverImageProps) => (
  <CoverImageShadowContainer {...props}>
    <CoverImg src={`${imageUrl}`} className="coverImage" />
    <IconContainer>
      <EuiIcon size={"l"} type={"iInCircle"} />
    </IconContainer>
    <SimNameContainer>
      <img src={SIM_LOGO[sim.shortCode]} alt="simulator" />
      <div />
    </SimNameContainer>
    <ClassLogoContainer>
      {vehicleClasses.map(vcl => (
        <div
          key={vcl.id}
          style={{
            height: `${vehicleClasses.length === 1 ? 100 : 50}px`
          }}
        >
          <img
            height={"100%"}
            width={"100%"}
            key={vcl.id}
            src={`${vcl.vehicleClassLogoUrl}`}
            alt="vehicle-class-logo"
          />
        </div>
      ))}
    </ClassLogoContainer>
  </CoverImageShadowContainer>
);
