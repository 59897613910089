import {
  EuiComboBox,
  EuiComboBoxOptionOption,
  EuiEmptyPrompt,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
} from "@elastic/eui";
import { fetchDriverOwnedLicenses, fetchRatingsHistory } from "api/profile";
import { useUIContext } from "contexts/ui";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { DriverRatingsHistory, OwnedRaceLicense } from "types/profile";
import { RatingsHistoryChart } from "./RatingsHistoryChart";

const ALL_LICENCES_OPTION = {
  label: "Overall rating history",
  value: undefined,
  key: "allLicences"
};

type RatingsHistoryProps = {
  driverId?: string;
}

export const RatingsHistory : React.FC<RatingsHistoryProps> = ({
  driverId
}) => {
  const { setGlobalLoading, emitErrorToast } = useUIContext();
  const [ownedLicenses, setOwnedLicenses] = useState<OwnedRaceLicense[]>([] as OwnedRaceLicense[]);
  const [driverRatingsHistory, setDriverRatingsHistory] = useState<DriverRatingsHistory>();

  const [selectedLicense, setSelectedLicense] = useState<EuiComboBoxOptionOption<number>>(ALL_LICENCES_OPTION);
  
  const ratingsHistoryQuery = useQuery(
    ["ratingsHistoryQuery", driverId, selectedLicense.value],
    fetchRatingsHistory,
    {
      refetchOnWindowFocus: false,
      onError: (error: ResponseError) => {
        setDriverRatingsHistory(undefined);
        const errorMessage =
          error.response.data ||
          error.message ||
          "Can't get your ratings history.";
        emitErrorToast({ title: errorMessage });
      },
      onSuccess: (data: DriverRatingsHistory) => {
        setDriverRatingsHistory(data);
      }
    }
  );

  // https://stackoverflow.com/questions/60391343/use-usequery-when-a-state-variable-changes
  // const [getData, { loading, data }] = useLazyQuery(QUERY);

  // useEffect(() => { getData({ variables: input }) }, []);
  const {
    data: driverOwnedLicenses,
    refetch: queryDriverOwnedLicenses,
    isLoading: driverOwnedLicensesAreLoading
  } = useQuery(
    ["driverOwnedLicensesQuery", driverId],
    fetchDriverOwnedLicenses,
    {
      refetchOnWindowFocus: false,
      onError: (error: ResponseError) => {
        const errorMessage =
          error.response.data ||
          error.message ||
          "Can't get your ratings history.";
        emitErrorToast({ title: errorMessage });
      },
      onSuccess: (data) => {
        setOwnedLicenses(data);
      }
    }
  );

  useEffect(() => {
    setGlobalLoading({ state: ratingsHistoryQuery.isFetching });
  }, [ratingsHistoryQuery.isFetching, setGlobalLoading]);

  const isOverall = selectedLicense === ALL_LICENCES_OPTION;

  return (
    <EuiFlexGroup direction="column" gutterSize="l">
      <EuiFlexItem>
        <EuiFlexGroup alignItems="baseline" gutterSize="s">
          <EuiFlexItem grow={1}>
            <EuiComboBox
              selectedOptions={[selectedLicense]}
              isLoading={driverOwnedLicensesAreLoading}
              singleSelection={{ asPlainText: true }}
              options={[
                ALL_LICENCES_OPTION,
                ...(ownedLicenses|| []).map(license => ({
                  label: `${license.raceLicenseName} rating history`,
                  value: license.raceLicenseId,
                  key: String(license.raceLicenseId)
                }))
              ]}
              isClearable={false}
              onChange={([selectedOption]) =>
                setSelectedLicense(selectedOption)
              }
            />
          </EuiFlexItem>
          {driverRatingsHistory &&
            <EuiFlexItem grow={false}>
            </EuiFlexItem>
          }
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiSpacer size="s" />
      {driverRatingsHistory?.ratingHistory.length ? (
        <EuiFlexItem>
          <RatingsHistoryChart
            driverRatingHistory={driverRatingsHistory}
            isOverview={isOverall}
          />
        </EuiFlexItem>
      ) : (
        <EuiEmptyPrompt
          iconType="folderExclamation"
          title={<h2>No ratings history</h2>}
          body={
            <p>
              Looks like there is no ratings history data to display so far.
            </p>
          }
        />
      )}
    </EuiFlexGroup>
  );
};
