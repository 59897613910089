import { Toast } from "@elastic/eui/src/components/toast/global_toast_list";
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useState
} from "react";
import { GlobalMessageType } from "types/layout";
import { v4 as uuid } from "uuid";
import { PartialBy } from "../types/types";
import { euiOverrides } from "../theme/eui_overrides";
import { ThemeProvider } from "styled-components";
import euiDarkTheme from "@elastic/eui/dist/eui_theme_dark.json";
import euiLightTheme from "@elastic/eui/dist/eui_theme_light.json";
import { GlobalStyle } from "../styles/global";
import { EuiProvider } from "@elastic/eui";
import { ThemeMode, useSystemThemeDetector } from "./useSystemThemeDetector";

type LoadingSchema = {
  state: boolean;
  timestamp: number;
  offset: boolean;
};

export type EmitToast = { (toast: PartialBy<Toast, "id">): void };

type EmitDefinedToast = {
  (overrides: Partial<Toast>): void;
};

type RemoveToast = { (toast: Toast): void };

type SetGlobalLoading = (newLoading: Partial<LoadingSchema>) => number;

export type UIContextType = {
  themeMode: ThemeMode;
  switchTheme: () => void;
  globalLoading: LoadingSchema;
  setGlobalLoading: SetGlobalLoading;
  toasts: Toast[];
  emitToast: EmitToast;
  emitSuccessToast: EmitDefinedToast;
  emitErrorToast: EmitDefinedToast;
  removeToast: RemoveToast;
  clearToasts: () => void;
  setGlobalMessages: Dispatch<SetStateAction<GlobalMessageType[]>>;
  globalMessages: GlobalMessageType[];
};

export const UIContext = createContext({} as UIContextType);
UIContext.displayName = "UIContext";

export const UIContextProvider: React.FC = ({ children }) => {
  const { theme, themeMode, switchTheme } = useSystemThemeDetector();
  const [globalMessages, setGlobalMessages] = useState<GlobalMessageType[]>([]);

  const [loading, setLoading] = useState<LoadingSchema>({
    state: false,
    timestamp: 0,
    offset: true
  });

  const [toasts, setToasts] = useState<Toast[]>([] as Toast[]);

  const emitToast: EmitToast = useCallback(toast => {
    const newToast = {
      id: toast.id || uuid(),
      ...toast,
      text: <div>{toast.text}</div>
    };
    setToasts(prevToasts => [
      ...prevToasts.filter(t => t.id !== newToast.id),
      newToast
    ]);

    return newToast;
  }, []);

  const emitErrorToast: EmitDefinedToast = overrides => {
    const defaultErrorToast = {
      title: "Oops, there was an error",
      color: "danger" as const,
      iconType: "alert"
    };
    emitToast({ ...defaultErrorToast, ...overrides });
  };

  const emitSuccessToast: EmitDefinedToast = overrides => {
    const defaultSuccessToast = {
      title: "Great success!",
      color: "success" as const,
      iconType: "check"
    };
    emitToast({ ...defaultSuccessToast, ...overrides });
  };

  const removeToast: RemoveToast = useCallback(
    removedToast =>
      setToasts(prevToasts =>
        prevToasts.filter(toast => toast.id !== removedToast.id)
      ),
    []
  );

  const clearToasts = () => setToasts([]);

  const setGlobalLoading: SetGlobalLoading = useCallback(
    ({ state = true, timestamp = new Date().valueOf(), offset = true }) => {
      setLoading(prevLoading => {
        if (state || timestamp >= prevLoading.timestamp) {
          return { state, timestamp, offset };
        } else {
          return prevLoading;
        }
      });
      return timestamp;
    },
    []
  );

  const additionalTheme = {
    variant: theme,
    paddingSizes: {
      xs: "4px",
      s: "8px",
      m: "16px",
      l: "l",
      xl: "xl"
    },
    euiOverrides
  };

  return (
    <UIContext.Provider
      value={{
        globalLoading: loading,
        setGlobalLoading,
        toasts,
        emitToast,
        emitSuccessToast,
        emitErrorToast,
        removeToast,
        clearToasts,
        themeMode,
        switchTheme,
        globalMessages,
        setGlobalMessages
      }}
    >
      <EuiProvider
        colorMode={theme === "dark" ? "dark" : "light"}
        modify={euiOverrides}
      >
        <ThemeProvider
          theme={
            theme === "dark"
              ? { ...euiDarkTheme, ...additionalTheme }
              : { ...euiLightTheme, ...additionalTheme }
          }
        >
          <GlobalStyle />
          {children}
        </ThemeProvider>
      </EuiProvider>
    </UIContext.Provider>
  );
};

export const useUIContext = () => useContext(UIContext);
