import { TOAST_LIFE_TIME } from "components/GlobalToast/GlobalToast";
import { EmitToast, UIContextType } from "contexts/ui";
import { WebSocketServiceContextType } from "contexts/webSocketService/webSocketService";
import { cloneDeep } from "lodash";
import React, { useEffect } from "react";
import { QueryClient } from "react-query";
import { RaceSeriesEvent, RaceSerie, RegisteredDriver } from "types/raceSeries";

export type DriverRegistrationWSPayload = {
  eventId: number;
  raceId: number;
  raceSeriesEventId: number; // same as raceId
  driverId: number;
  regionName: string;
  regionId: number;
  isRegistrationConfirmed: boolean;
  steamDisplayName: string;
  steamAvatarUrl: string;
};
type ChangeDriversRegisteredToCount = {
  (
    series: RaceSerie[],
    driverRegistration: DriverRegistrationWSPayload,
    operation?: "increase" | "decrease",
    amount?: number
  ): RaceSerie[];
};
const changeDriversRegisteredToCount: ChangeDriversRegisteredToCount = (
  series = [],
  driverRegistration,
  operation = "increase",
  amount = 1
) =>
  series.map(serie => {
    if (serie.event.eventId === driverRegistration.eventId) {
      var registeredDrivers = serie.event.race.registeredDrivers;
      var upcomingRacesDriversCount = serie.event.upcomingRacesDriversCount;

      if (operation === "increase") {
        if (serie.event.race.raceId !== driverRegistration.raceId) {
          upcomingRacesDriversCount++;
        } else {
          registeredDrivers = [
            ...serie.event.race.registeredDrivers,
            driverRegistration as RegisteredDriver
          ];
        }
      } else {
        if (serie.event.race.raceId !== driverRegistration.raceId) {
          upcomingRacesDriversCount--;
        } else {
          registeredDrivers = registeredDrivers.filter(
            obj => obj.driverId !== driverRegistration.driverId
          );
        }
      }

      return {
        ...serie,
        event: {
          ...serie.event,
          upcomingRacesDriversCount: upcomingRacesDriversCount,
          race: {
            ...serie.event.race,
            registeredDrivers: registeredDrivers
          }
        }
      };
    } else {
      return serie;
    }
  });
type UpdateNearestEvent = {
  (
    series: RaceSerie[],
    serieId: number,
    updatedEvent: RaceSeriesEvent
  ): RaceSerie[];
};
const updateNearestEvent: UpdateNearestEvent = (
  series = [],
  serieId,
  updatedEvent
) =>
  series.map(serie => {
    if (serie.id === serieId) {
      return {
        ...serie,
        // powinno byc tak?
        event: { ...serie.event, ...updatedEvent },
        // test start
        //ale startAtUtc nie uaktualnia sie
        // event: { ...serie.event,
        //   race: {
        //     ...serie.event.race,
        //     startAtUtc: "2024-02-29T18:45:00Z"//updatedEvent.race.startAtUtc,

        //   },
        //     track: {
        //       ...serie.event.track,
        //       // za to track name sie uaktualnia
        //       trackName: "dupa"
        //     }
        // },
        //test end
        previousRace: {
          driversCount: serie.event.race.registeredDrivers.length
        }
      };
    }
    return serie;
  });

const driverRegisteredHandler = (
  raceSeriesSetter: React.Dispatch<React.SetStateAction<RaceSerie[]>>
) => (changeDriverRegistered: string) => {
  const registeredDriver = JSON.parse(
    changeDriverRegistered
  ) as DriverRegistrationWSPayload;
  raceSeriesSetter(prevSeries =>
    changeDriversRegisteredToCount(prevSeries, registeredDriver, "increase")
  );
};

const driverUnregisteredHandler = (
  raceSeriesSetter: React.Dispatch<React.SetStateAction<RaceSerie[]>>
) => (changeDriverRegistered: string) => {
  const unregisteredDriver = JSON.parse(
    changeDriverRegistered
  ) as DriverRegistrationWSPayload;
  raceSeriesSetter(prevSeries =>
    changeDriversRegisteredToCount(prevSeries, unregisteredDriver, "decrease")
  );
};

type ChangeDriversPracticing = {
  driversCount: number;
  worldRegion: string;
  raceSeriesId: number;
  raceSeriesEventId: number;
};

export const driversJoinedPracticeHandler = (
  raceSeriesSetter: React.Dispatch<React.SetStateAction<RaceSerie[]>>
) => (driversJoinedPractice: ChangeDriversPracticing) => {
  const { raceSeriesId, driversCount } = driversJoinedPractice;
  raceSeriesSetter(prevSeries =>
    updatePracticingDriversCount(
      prevSeries,
      raceSeriesId,
      driversCount,
      "increase"
    )
  );
};

export const driversLeftPracticeHandler = (
  raceSeriesSetter: React.Dispatch<React.SetStateAction<RaceSerie[]>>
) => (driversLeftPractice: ChangeDriversPracticing) => {
  const { raceSeriesId, driversCount } = driversLeftPractice;
  raceSeriesSetter(prevSeries =>
    updatePracticingDriversCount(
      prevSeries,
      raceSeriesId,
      driversCount,
      "decrease"
    )
  );
};

type ChangeDriversPracticingCount = {
  (
    series: RaceSerie[],
    raceSeriesId: number,
    driversCount: number,
    operation?: "increase" | "decrease"
  ): RaceSerie[];
};
const updatePracticingDriversCount: ChangeDriversPracticingCount = (
  series = [],
  raceSeriesId,
  driversCount,
  operation = "increase"
) =>
  series.map(serie => {
    const changeAmount = (
      currentAmount: number,
      changeAmount: number,
      operation: string
    ) =>
      operation === "increase"
        ? currentAmount + changeAmount
        : currentAmount - changeAmount >= 0
        ? currentAmount - changeAmount
        : 0;

    if (serie.id === raceSeriesId) {
      const allPracticingDrivers = changeAmount(
        serie.event.practicingDriversCount,
        driversCount,
        operation
      );
      return {
        ...serie,
        practicingDriversCount: allPracticingDrivers
      };
    } else {
      return serie;
    }
  });

const newRaceSeriesEventHandler = (
  raceSeriesSetter: React.Dispatch<React.SetStateAction<RaceSerie[]>>
) => (updatedSerieData: string) => {
  const { raceSeriesId, event } = JSON.parse(updatedSerieData);
  raceSeriesSetter(prevSeries =>
    updateNearestEvent(prevSeries, raceSeriesId, event)
  );
};

type ToastMessages = {
  access: string[];
  noAccess: string[];
};


type UseRaceSeriesWsEventHandler = (
  webSocket: WebSocketServiceContextType,
  raceSeriesSetter: React.Dispatch<React.SetStateAction<RaceSerie[]>>,
  uiContext: UIContextType,
  queryClient: QueryClient
) => void;
export const useRaceSeriesWsEventsHandler: UseRaceSeriesWsEventHandler = (
  webSocket,
  raceSeriesSetter,
  uiContext,
  queryClient
) => {
  const { emitToast } = uiContext;

  useEffect(() => {
    if (webSocket.connected) {
      webSocket.instance?.on(
        "DriverRegistered",
        driverRegisteredHandler(raceSeriesSetter)
      );
      webSocket.instance?.on(
        "DriverUnregistered",
        driverUnregisteredHandler(raceSeriesSetter)
      );
      webSocket.instance?.on(
        "DriversJoinedPracticeSession",
        driversJoinedPracticeHandler(raceSeriesSetter)
      );
      webSocket.instance?.on(
        "DriversLeftPracticeSession",
        driversLeftPracticeHandler(raceSeriesSetter)
      );
      webSocket.instance?.on(
        "NewRaceSeriesEvent",
        newRaceSeriesEventHandler(raceSeriesSetter)
      );
    }
  }, [webSocket.connected]);

  useEffect(() => {
    const removeOnReconnectListener = webSocket.onReconnect(() =>
      queryClient.invalidateQueries("raceSeriesQuery")
    );
    return () => removeOnReconnectListener();
  }, [webSocket.onReconnect]);

  useEffect(
    () => () => {
      if (webSocket.connected) {
        webSocket.instance?.off("DriverRegistered");
        webSocket.instance?.off("DriverUnregistered");
        webSocket.instance?.off("NewRaceSeriesEvent");
        webSocket.instance?.off("DriversJoinedPracticeSession");
        webSocket.instance?.off("DriversLeftPracticeSession");
      }
    },
    []
  );
};
