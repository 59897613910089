import React, { FC } from "react";
import { useQuery } from "react-query";
import { fetchDriverYearlyStats } from "../../../api/profile";
import { useUIContext } from "../../../contexts/ui";
import {
  DriverYearlyStats,
  DriverYearlyStatsDto
} from "../../../types/profile";
import { useTablePagination } from "../../../hooks/useTablePagination";
import { useTableSort } from "../../../hooks/useTableSort";
import {
  EuiBasicTable,
  EuiEmptyPrompt,
  EuiIcon,
  EuiCard,
  EuiSkeletonRectangle
} from "@elastic/eui";
import { TableHeaderCellWithInfoIcon } from "../../shared/TableHeaderCellWithInfoIcon";

interface Props {
  driverId: string;
}

export const YearlyStats: FC<Props> = ({ driverId }) => {
  const { emitErrorToast } = useUIContext();

  const { data, status } = useQuery<
    unknown,
    ResponseError,
    DriverYearlyStatsDto
  >(["driverYearlyStats", driverId], fetchDriverYearlyStats, {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    onError: error => {
      const errorMessage =
        error.response.data || error.message || "Can't fetch yearly stats";
      emitErrorToast({ title: errorMessage });
    },
    onSuccess: data => {
      setTotalItemCount(data.years.length || 0);
    }
  });

  const { pagination, pageChangeHandler, setTotalItemCount, getPage } =
    useTablePagination<DriverYearlyStats>({ initialPageSize: 5, initialTotalItemsCount: data?.years.length });
  const { sortField, sortDirection, sortChangeHandler, sortData } =
    useTableSort<DriverYearlyStats>({
      initialSortField: "year",
      initialSortDirection: "desc"
    });

  if (status === "loading") {
    return (
      <EuiSkeletonRectangle
        isLoading={true}
        width="100%"
        height="200px"
        borderRadius="s"
      />
    );
  }

  if (!data?.years.length) {
    return (
      <EuiCard title={"Yearly stats"} layout={"horizontal"}>
        <EuiEmptyPrompt
          iconType="folderExclamation"
          title={<h2>No yearly stats</h2>}
          body={<p>Looks like the is no yearly stats to display so far.</p>}
        />
      </EuiCard>
    );
  }

  return (
    <EuiCard title={"Yearly stats"} layout={"horizontal"}>
      <EuiBasicTable<DriverYearlyStats>
        style={{ maxHeight: "400px", overflow: "auto" }}
        items={getPage(sortData(data.years))}
        rowHeader="name"
        columns={[
          {
            field: "year",
            name: (
              <div style={{ display: "flex", gap: "5px" }}>
                <EuiIcon type="calendar" />
                <div>Year</div>
              </div>
            )
          },
          {
            field: "races",
            name: "Races"
          },
          {
            field: "wins",
            name: (
              <TableHeaderCellWithInfoIcon
                title={"Wins"}
                tooltip={"Wins (wins percentage)"}
              />
            ),
            render: (item, record) => {
              return (
                <span>
                  <span>{item}</span> <span>({record.winPercentage}%)</span>
                </span>
              );
            }
          },
          {
            field: "topFive",
            name: (
              <TableHeaderCellWithInfoIcon
                title={"Top5"}
                tooltip={"Top5 (Top5 percentage)"}
              />
            ),
            render: (item, record) => {
              return (
                <span>
                  <span>{item}</span> <span>({record.topFivePercentage}%)</span>
                </span>
              );
            }
          },
          {
            field: "poles",
            name: "Poles"
          },
          {
            field: "averageNumberOfIncidents",
            name: (
              <TableHeaderCellWithInfoIcon
                title={"aNoI"}
                tooltip={"Average number of Incidends"}
              />
            )
          },
          {
            field: "averageIncidentsPerMinute",
            name: (
              <TableHeaderCellWithInfoIcon
                title={"aIpM"}
                tooltip={"Average incidends per minute"}
              />
            )
          },
          {
            field: "averageStart",
            name: (
              <TableHeaderCellWithInfoIcon
                title={"aS"}
                tooltip={"Average start"}
              />
            )
          },
          {
            field: "averageFinish",
            name: (
              <TableHeaderCellWithInfoIcon
                title={"aF"}
                tooltip={"Average finish"}
              />
            )
          },
          {
            field: "laps",
            name: "Laps"
          },
          {
            field: "lapsLed",
            name: (
              <TableHeaderCellWithInfoIcon
                title={"Laps led"}
                tooltip={"Laps led (laps led percentage)"}
              />
            ),
            render: (item, record) => {
              return (
                <span>
                  <span>{item}</span> <span>({record.lapsLedPercentage}%)</span>
                </span>
              );
            }
          }
        ]}
        pagination={pagination}
        onChange={({ page, sort }) => {
          if (page) {
            pageChangeHandler(page);
          }
          if (sort) {
            sortChangeHandler(sort);
          }
        }}
        tableLayout="auto"
        sorting={{
          enableAllColumns: true,
          sort: {
            field: sortField,
            direction: sortDirection
          }
        }}
      />
    </EuiCard>
  );
};
