import {
  EuiBadge,
  EuiIcon,
  EuiProgress,
  EuiSkeletonRectangle,
  EuiText,
  EuiTitle,
  EuiToolTip
} from "@elastic/eui";
import { fetchCurrentSeason } from "api/raceSeries";
import { TOAST_LIFE_TIME } from "components/GlobalToast/GlobalToast";
import { useUIContext } from "contexts/ui";
import React from "react";
import { useQuery } from "react-query";
import { format } from "date-fns";

export const RaceSeriesHeader = () => {
  const uiContext = useUIContext();
  const { emitErrorToast } = uiContext;
  const currentSeasonQuery = useQuery(
    "currentSeasonQuery",
    fetchCurrentSeason,
    {
      refetchOnWindowFocus: false,
      onError: () =>
        emitErrorToast({
          title: "Can't get current season data.",
          toastLifeTimeMs: TOAST_LIFE_TIME.INFINITE
        })
    }
  );

  const currentSeason = currentSeasonQuery.data;

  if (currentSeasonQuery.isLoading) {
    return (
      <EuiSkeletonRectangle
        isLoading={true}
        width="100%"
        height="50px"
        borderRadius="s"
      />
    );
  }

  if (!currentSeason) {
    return null;
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          marginBottom: "5px",
          flexFlow: "wrap"
        }}
      >
        <EuiIcon type="calendar" size={"l"} />
        <EuiTitle size={"s"}>
          <span>{currentSeason.seasonName}</span>
        </EuiTitle>
        {currentSeason.seasonStartUtc && currentSeason.seasonEndUtc && (
          <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <EuiText color={"subdued"}>
              {format(new Date(currentSeason.seasonStartUtc), "d.LL.y")}
            </EuiText>
            <EuiIcon type="sortRight" color={"subdued"} />
            <EuiText color={"subdued"}>
              {format(new Date(currentSeason.seasonEndUtc), "d.LL.y")}
            </EuiText>
          </div>
        )}
        <EuiBadge>{`week ${currentSeason.currentWeekNumber}/${currentSeason.currentYear}`}</EuiBadge>
      </div>
      <EuiToolTip
        title="Season progress"
        content={`${currentSeason.seasonProgressPercentage}%`}
        display="block"
        position="bottom"
      >
        <EuiProgress
          size="s"
          value={currentSeason.seasonProgressPercentage}
          max={100}
        />
      </EuiToolTip>
    </div>
  );
};
