import {
  Criteria,
  EuiBadge,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiButtonEmpty,
  EuiEmptyPrompt,
  EuiLink,
  EuiTabbedContent,
  EuiTabbedContentTab
} from "@elastic/eui";
import { ROUTES } from "constants/routes";
import { useUIContext } from "contexts/ui";
import {
  DEFAULT_PAGINATION_CONFIG,
  useTablePagination
} from "hooks/useTablePagination";
import { useTableSort } from "hooks/useTableSort";
import { useUrlSearch } from "hooks/useUrlSearch";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  DriverBase,
  SeriesStandingsSelection,
  StandingsEventDriver,
  StandingsRaceSeriesDriver,
  StandingsSeasonDriver
} from "types/standings";
import getPodiumIcon from "../../utils/getPodiumIcon";
import { useIsMobile } from "hooks/useIsMobile";

type SeasonStandingsProps = {
  seasonStandingsData?: StandingsSeasonDriver[];
  seriesStandingsData?: StandingsRaceSeriesDriver[];
  eventStandingsData?: StandingsEventDriver[];
  setSelectedSeries: React.Dispatch<
    React.SetStateAction<SeriesStandingsSelection | undefined>
  >;
  defaultPageSize?: number;
};

const positionRenderer = (position: number) => {
  let content = getPodiumIcon(position);
  content += position;

  return <div>{content}</div>;
};

// todo; man, this whole component...
export const StandingsTable = ({
  seasonStandingsData = [],
  seriesStandingsData,
  eventStandingsData,
  setSelectedSeries,
  defaultPageSize = DEFAULT_PAGINATION_CONFIG.initialPageSize
}: SeasonStandingsProps) => {
  const {
    pagination,
    pageChangeHandler,
    setTotalItemCount,
    getPage
  } = useTablePagination<
    StandingsSeasonDriver | StandingsRaceSeriesDriver | StandingsEventDriver
  >({
    initialPageSize: defaultPageSize
  });

  const { getUrlSearchValue, setUrlSearch } = useUrlSearch();
  const { search } = useLocation();
  useEffect(() => {
    setSelectedClassGroup(new URLSearchParams(search).get("classGroup") || "1");
  }, [search]);
  const { isMobile } = useIsMobile();
  const [selectedClassGroup, setSelectedClassGroup] = useState(
    getUrlSearchValue("classGroup") || "1"
  );
  const [classGroupsTabs, setClassGroupsTabs] = useState(
    [] as EuiTabbedContentTab[]
  );

  const history = useHistory();
  const baseColumns = [
    {
      field: "country",
      name: "Country"
    },
    {
      field: "incidentsAverage",
      name: "Incidents Average"
    },
    {
      field: "incidentsPerMinuteAverage",
      name: "Incidents / minute"
    }
  ];

  const seriesStandingsColumns = [
    {
      field: "position",
      name: "Position",
      align: "center",
      render: positionRenderer
    },
    {
      name: "Driver Name",
      render: ({ driverId, driverName }: DriverBase) => (
        <EuiButtonEmpty
          onClick={() => {
            history.push(`${ROUTES.STATS}/${driverId}`);
          }}
        >
          {driverName}
        </EuiButtonEmpty>
      )
    },
    {
      field: "classGroup",
      name: "Class group",
      render: (classGroup: number) => <EuiBadge>CG{classGroup}</EuiBadge>
    },
    {
      field: "isStandingOfficial",
      name: "Official standing"
    },
    ...baseColumns,
    {
      field: "points",
      name: "Points"
    },
    {
      field: "finishPositionAverage",
      name: "Finish Position Average"
    }
  ];

  const eventStandingsColumns = [
    {
      field: "position",
      name: "Position",
      render: positionRenderer
    },
    {
      name: "Driver Name",
      render: ({ driverId, driverName }: DriverBase) => (
        <EuiButtonEmpty
          onClick={() => {
            history.push(`${ROUTES.STATS}/${driverId}`);
          }}
        >
          {driverName}
        </EuiButtonEmpty>
      )
    },
    {
      field: "classGroup",
      name: "Class Group",
      render: (classGroup: number) => <EuiBadge>CG{classGroup}</EuiBadge>
    },
    { field: "numberOfRaces", name: "Races" },
    ...baseColumns,
    { field: "bestTime", name: "Best Time" },
    { field: "finishPositionAverage", name: "Finish Position Average" },
    { field: "pointsAverage", name: "Points Average" }
  ];

  const {
    sortField,
    sortDirection,
    sortChangeHandler,
    sortData
  } = useTableSort<
    StandingsSeasonDriver | StandingsRaceSeriesDriver | StandingsEventDriver
  >({ initialSortField: "position" });

  const { globalLoading } = useUIContext();
  const tableChangeHandler = ({
    page,
    sort
  }: Criteria<
    StandingsSeasonDriver | StandingsRaceSeriesDriver | StandingsEventDriver
  >) => {
    if (page) {
      pageChangeHandler(page);
    }
    if (sort) {
      sortChangeHandler(sort);
    }
  };

  useEffect(() => {
    setTotalItemCount(seasonStandingsData.length);
  }, [seasonStandingsData]);

  useEffect(() => {
    if (eventStandingsData) {
      var classGroupsIds = Array.from(
        new Set(eventStandingsData.map(s => s.classGroup.toString()))
      );
      setTotalItemCount(
        eventStandingsData.filter(
          s => s.classGroup.toString() == selectedClassGroup
        ).length
      );

      var classGroupsTabs = classGroupsIds
        .map(cg => {
          return {
            id: `cg${cg}`,
            name: `CG${cg}`,
            content: <></>
          } as EuiTabbedContentTab;
        })
        .sort((a, b) => a.id.localeCompare(b.id));
      setClassGroupsTabs(classGroupsTabs);
    }
  }, [eventStandingsData, selectedClassGroup]);

  useEffect(() => {
    if (seriesStandingsData) {
      getUrlSearchValue("classGroup")
        ? setSelectedClassGroup(getUrlSearchValue("classGroup") as string)
        : setSelectedClassGroup("1");
      var classGroupsIds = Array.from(
        new Set(seriesStandingsData.map(s => s.classGroup.toString()))
      );
      setTotalItemCount(
        seriesStandingsData.filter(
          s => s.classGroup.toString() == selectedClassGroup
        ).length
      );

      var classGroupsTabs = classGroupsIds
        .map(cg => {
          return {
            id: `cg${cg}`,
            name: `CG${cg}`,
            content: <></>
          } as EuiTabbedContentTab;
        })
        .sort((a, b) => a.id.localeCompare(b.id));
      setClassGroupsTabs(classGroupsTabs);
    } else {
      setClassGroupsTabs([]);
      setSelectedClassGroup("1");
      setUrlSearch({ classGroup: undefined });
    }
  }, [seriesStandingsData, selectedClassGroup]);

  const seasonStandingsColumns: EuiBasicTableColumn<StandingsSeasonDriver>[] = useMemo(
    () => [
      {
        field: "raceSeriesName",
        name: "Race series",
        render: (serieName: string, row: StandingsSeasonDriver) => (
          <EuiLink
            onClick={() => {
              setUrlSearch({
                series: row.raceSeriesId,
                classGroup: row.classGroup
              });
              setSelectedSeries({
                value: row.raceSeriesId,
                label: serieName
              });
            }}
          >
            {serieName}
          </EuiLink>
        )
      },
      {
        field: "classGroup",
        name: "Class group",
        render: (classGroup: number) => <EuiBadge>CG{classGroup}</EuiBadge>
      },
      {
        name: "Leader",
        render: ({ driverId, driverName }: StandingsSeasonDriver) => (
          <EuiButtonEmpty
            onClick={() => {
              history.push(`${ROUTES.STATS}/${driverId}/ratings-history`);
            }}
          >
            {"🥇 " + driverName}
          </EuiButtonEmpty>
        )
      },
      {
        field: "isStandingOfficial",
        name: "Official standing"
      },
      ...baseColumns,
      {
        field: "points",
        name: "Points"
      },
      {
        field: "finishPositionAverage",
        name: "Finish Position Average"
      }
      // {
      //   field: "raceSeriesName",
      //   name: "Actions",
      //   actions: [
      //   {
      //     name: ({raceSeriesName}) => (`Show ${raceSeriesName} drivers standings`),
      //     // description: (raceSeriesName : StandingsSeasonDriver) => (`Show ${raceSeriesName} drivers standings`),
      //     description: 'Show standings',
      //     type: 'icon',
      //     icon: 'arrowRight',
      //     onClick: ({raceSeriesName, raceSeriesId}) => (
      //           setSelectedSeries({ value: raceSeriesId, label: raceSeriesName }))
      //     }
      // ],
      // }
    ],
    [setSelectedSeries, seasonStandingsData]
  );

  const filteredEventStandingsData = eventStandingsData?.filter(
    esd => esd.classGroup.toString() === selectedClassGroup
  );
  const filteredSeriesStandingsData = seriesStandingsData?.filter(
    esd => esd.classGroup.toString() === selectedClassGroup
  );

  const currentNoDataLabel = filteredEventStandingsData
    ? "event"
    : filteredSeriesStandingsData
    ? "series"
    : "season";

  return (
    filteredEventStandingsData ||
    filteredSeriesStandingsData ||
    seasonStandingsData
  ).length && !globalLoading.state ? (
    <>
      {classGroupsTabs.length > 0 ? (
        <EuiTabbedContent
          tabs={classGroupsTabs}
          size={isMobile ? "m" : "l"}
          initialSelectedTab={classGroupsTabs.find(
            t => t.id === `cg${selectedClassGroup?.toString()}`
          )}
          onTabClick={(tab: EuiTabbedContentTab) => {
            setSelectedClassGroup(() => (tab.id.match(/\d+/) || ["1"])[0]);
            setUrlSearch({
              classGroup: (tab.id.match(/\d+/) || ["1"])[0]
            });
            return;
          }}
        />
      ) : null}
      <EuiBasicTable
        items={getPage(
          sortData(
            filteredEventStandingsData ||
              filteredSeriesStandingsData ||
              seasonStandingsData
          )
        )}
        rowHeader="name"
        hasActions={true}
        columns={
          filteredEventStandingsData
            ? eventStandingsColumns
            : filteredSeriesStandingsData
            ? seriesStandingsColumns
            : seasonStandingsColumns
        }
        pagination={pagination}
        onChange={tableChangeHandler}
        sorting={{
          enableAllColumns: true,
          sort: {
            field: sortField,
            direction: sortDirection
          }
        }}
      />
    </>
  ) : (
    <EuiEmptyPrompt
      iconType="folderExclamation"
      title={<h2>{`No standings data.`}</h2>}
      body={
        <p>{`Looks like there are no ${currentNoDataLabel} standings data to display. Change or clear ${currentNoDataLabel} selection to display standings.`}</p>
      }
    />
  );
};
