import { useEuiTheme } from "@elastic/eui";

export interface ThemeExtensions {
  colors: {
    winGreen: string;
    gold: string;
    silver: string;
    bronze: string;
  };
}

export const colors = {
  winGreen: "#80d89b",
  gold: "#daa511",
  silver: "#757575",
  bronze: "#a5621b"
};

export const useEuiThemeTyped = () => useEuiTheme<ThemeExtensions>();

export const euiOverrides = {
  colors: {
    LIGHT: {
      primary: "#4ac2bf",
      winGreen: colors.winGreen,
      gold: colors.gold,
      silver: colors.silver,
      bronze: colors.bronze
    },
    DARK: {
      primary: "#4ac2bf",
      winGreen: colors.winGreen,
      gold: colors.gold,
      silver: colors.silver,
      bronze: colors.bronze
    }
  },
  font: {
    family: "Inter"
  }
};
