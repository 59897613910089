import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiTab,
  EuiTabs,
  EuiTitle
} from "@elastic/eui";
import { fetchDriverPublicProfile } from "api/profile";
import StatsDarkIcon from "assets/icons/stats-dark.json";
import StatsLightIcon from "assets/icons/stats-light.json";
import { SingleRatingStat } from "components/Layout/BottomBar/Ratings/SingleRatingStat";
import { AnimatedIconHeader } from "components/shared/AnimatedIconHeader";
import { ROUTES } from "constants/routes";
import { useUIContext } from "contexts/ui";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { Switch, useHistory, useLocation, useParams } from "react-router-dom";
import { DriverPublicProfile } from "types/profile";
import { SEloHistory } from "./SEloHistory/SEloHistory";
import { RaceHistory } from "./RaceHistory/RaceHistory";
import { RatingsHistory } from "./RatingsHistory/RatingsHistory";
import { RaceLicenceBadge } from "../RaceLicenceBadge";
import { Details } from "./Details/Details";
import { AppRoute } from "../Router/AppGlobalRoute";
import { ShareButtonsWrapper } from "../ShareButtons/ShareButtonsWrapper";
import { useIsMobile } from "../../hooks/useIsMobile";
import { getUserRatings } from "../../utils/aprilFools";

export const Stats = () => {
  type DefinedRoutes = typeof ROUTES[keyof typeof ROUTES];
  const { driverId } = useParams<{ driverId: string }>();
  const history = useHistory();
  const location = useLocation();
  const { emitErrorToast } = useUIContext();
  const { isMobile } = useIsMobile();
  const [
    driverPublicProfile,
    setDriverPublicProfile
  ] = useState<DriverPublicProfile>({
    steamDisplayName: "",
    id: 0,
    currentOverallRating: {},
    raceLicense: {
      raceLicenseLevel: 0,
      raceLicenseName: "none"
    },
    aiSkill: 0
  });

  type Tab = {
    id: string;
    name: string;
    route: DefinedRoutes;
    component: JSX.Element;
    exact?: boolean;
  };

  const tabs: Readonly<Tab[]> = [
    {
      id: "details",
      name: "Details",
      route: `${ROUTES.STATS}/${driverId}/`,
      component: <Details driverId={driverId} />,
      exact: true
    },
    {
      id: "selo-view",
      name: "S.Elo history",
      route: `${ROUTES.STATS}/${driverId}/selo-view`,
      component: <SEloHistory driverId={driverId} />
    },
    {
      id: "ratings-history",
      name: "Ratings history",
      route: `${ROUTES.STATS}/${driverId}/ratings-history`,
      component: <RatingsHistory driverId={driverId} />
    },
    {
      id: "race-history",
      name: "Race history",
      route: `${ROUTES.STATS}/${driverId}/race-history`,
      component: <RaceHistory />
    }
  ];

  const driverPublicProfileQuery = useQuery(
    ["driverPublidProfileQuery", driverId],
    fetchDriverPublicProfile,
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      onError: () => {
        emitErrorToast({
          title: "We couldn't get standings data for the selected season."
        });
      },
      onSuccess: data => {
        if (data) {
          setDriverPublicProfile({
            ...data,
            currentOverallRating: getUserRatings(
              data.currentOverallRating,
              driverId
            )
          });
        }
      }
    }
  );

  return (
    <EuiFlexGroup direction="column" gutterSize="none">
      <div style={{ display: "flex", gap: "10px", alignItems: "flex-start" }}>
        <AnimatedIconHeader
          title={`Stats`}
          darkLottieIcon={StatsDarkIcon}
          lightLottieIcon={StatsLightIcon}
        />
        <ShareButtonsWrapper />
      </div>
      <EuiSpacer size="l" />

      <EuiFlexGroup
        responsive={false}
        gutterSize="xs"
        alignItems="center"
        justifyContent="flexStart"
      >
        <EuiFlexItem
          component="span"
          grow={false}
          style={{ marginLeft: 15, marginRight: 5 }}
        >
          <EuiTitle>
            <h1>
              {driverPublicProfileQuery.isFetching
                ? "Loading"
                : driverPublicProfile.steamDisplayName}
            </h1>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem component="span" grow={false} style={{ marginLeft: 5 }}>
          <RaceLicenceBadge
            raceLicense={driverPublicProfile.raceLicense}
            tooltip="Driver race license"
            fetching={driverPublicProfileQuery.isFetching}
            driverId={driverPublicProfile.id}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="l" />
      <EuiFlexGroup
        responsive={false}
        gutterSize="m"
        alignItems="flexStart"
        justifyContent="flexStart"
      >
        <EuiFlexItem grow={false}>
          <SingleRatingStat
            isLoading={driverPublicProfileQuery.isFetching}
            description={isMobile ? "S" : "Safety"}
            title={driverPublicProfile.currentOverallRating.safety}
            titleSize="s"
            descriptionElement="h6"
            textAlign="center"
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <SingleRatingStat
            isLoading={driverPublicProfileQuery.isFetching}
            description={isMobile ? "C" : "Consistency"}
            title={driverPublicProfile.currentOverallRating.consistency}
            titleSize="s"
            descriptionElement="h6"
            textAlign="center"
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <SingleRatingStat
            isLoading={driverPublicProfileQuery.isFetching}
            description="S.elo"
            title={driverPublicProfile.currentOverallRating.sElo}
            titleSize="s"
            descriptionElement="h6"
            textAlign="center"
          />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="xxl" />
      <EuiFlexItem>
        <EuiTabs>
          {tabs.map(tab => (
            <EuiTab
              key={tab.id}
              onClick={() => history.push(tab.route)}
              isSelected={
                tab.id ===
                (tabs.find(t => location.pathname.includes(t.id))?.id ||
                  tabs[0].id)
              }
            >
              {tab.name}
            </EuiTab>
          ))}
        </EuiTabs>
        <EuiSpacer />
        <Switch>
          {tabs.map(({ route, component, id, exact }) => (
            <AppRoute key={id} path={route} exact={exact}>
              {component}
            </AppRoute>
          ))}
        </Switch>
        <EuiSpacer />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
