import { z } from "zod";

export const updateWeeklyEventValidator = z.object({
  track: z.object({
    value: z.number(),
    label: z.string()
  }),
  classGroups: z
    .array(
      z.object({
        classGroupRank: z.coerce.number().min(1),
        raceLicenseId: z.object({ value: z.number(), label: z.string() }),
        vehicleClasses: z
          .array(z.object({ value: z.number(), label: z.string() }))
          .min(1)
      })
    )
    .refine(
      items =>
        new Set(items.map(item => item.classGroupRank)).size === items.length,
      {
        message: "Class group ranks must be unique"
      }
    ),
  raceDurationInMinutes: z.coerce.number().min(1),
  qualyDurationInMinutes: z.coerce.number().min(1),
  inGameQualyStartDate: z.any(),
  inGameRaceStartDate: z.any()
});
