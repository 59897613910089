import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

type AnimatedDriversCounterProps = {
  countValue: number;
};

const AnimatedDriversCounter: React.FC<AnimatedDriversCounterProps> = ({
  countValue
}) => {
  const [valueKey, setValueKey] = useState(1);
  const [currentValue, setCurrentValue] = useState(1);
  const [upwardsAnimation, setUpwardsAnimation] = useState(true);
  useEffect(() => {
    setUpwardsAnimation(countValue > currentValue);
    const setValueTimeout = setTimeout(() => {
      setValueKey(countValue);
    });
    return () => {
      clearTimeout(setValueTimeout);
    };
  }, [countValue]);

  return (
    <span style={{ position: "relative" }}>
      <AnimatePresence
        exitBeforeEnter
        onExitComplete={() => setCurrentValue(countValue)}
      >
        <motion.span
          data-testid="driversCounterValue"
          key={valueKey}
          initial={{
            opacity: 0,
            y: upwardsAnimation ? "-100%" : "100%",
            position: "absolute"
          }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: upwardsAnimation ? "100%" : "-100%" }}
        >
          {currentValue}
        </motion.span>
      </AnimatePresence>
      <span style={{ visibility: "hidden" }}>{currentValue}</span>
    </span>
  );
};

export default AnimatedDriversCounter;
