import React, { FC } from "react";
import { EuiCallOut } from "@elastic/eui";

interface Props {
  children: React.ReactNode;
}

export const MaintenancePage: FC<Props> = props => {
  if (process.env.REACT_APP_SHOW_MAINTENANCE !== "true") {
    return <>{props.children}</>;
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh"
      }}
    >
      <EuiCallOut>
        <p
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <strong>We're cooking something special for you.</strong>
        </p>
        <p
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <strong> We'll be back soon :)</strong>
        </p>
      </EuiCallOut>
    </div>
  );
};
