import axios from "../utils/axios";
import {
  RaceLicensesResponseDto,
  RaceSeriesResponseDto,
  SeasonsListResponseDto,
  UpdateWeeklyEventRequestDto,
  VehiclesAndTracksResponseDto,
  WeeklyEventsResponseDto
} from "../types/admin";
import { QueryFunction } from "react-query";

export const fetchSeasons: QueryFunction<SeasonsListResponseDto> = async () => {
  const response = await axios.get<SeasonsListResponseDto>(
    process.env.REACT_APP_ENDPOINT_RACE_SERIES +
      "/management/seasonschedule/seasons-list"
  );

  return response.data;
};

export const fetchRaceSeries: QueryFunction<RaceSeriesResponseDto> = async ({
  queryKey
}) => {
  const [, seasonId] = queryKey;

  const response = await axios.get<RaceSeriesResponseDto>(
    process.env.REACT_APP_ENDPOINT_RACE_SERIES +
      `/management/seasonschedule/season/${seasonId}/race-series`
  );

  return response.data;
};

export const fetchWeeklyEvents: QueryFunction<WeeklyEventsResponseDto> = async ({
  queryKey
}) => {
  const [, seasonId, raceSeriesId] = queryKey;

  const response = await axios.get<WeeklyEventsResponseDto>(
    process.env.REACT_APP_ENDPOINT_RACE_SERIES +
      `/management/seasonschedule/season/${seasonId}/race-series/${raceSeriesId}/weekly-events`
  );

  return response.data;
};

export const fetchRaceLicences: QueryFunction<RaceLicensesResponseDto> = async ({
  queryKey
}) => {
  const response = await axios.get<RaceLicensesResponseDto>(
    process.env.REACT_APP_ENDPOINT_RACE_SERIES + `/lists/race-licenses`
  );

  return response.data;
};

export const fetchVehiclesAndTracks: QueryFunction<VehiclesAndTracksResponseDto> = async ({
  queryKey
}) => {
  const [, simId] = queryKey;

  const response = await axios.get<VehiclesAndTracksResponseDto>(
    process.env.REACT_APP_ENDPOINT_RACE_SERIES +
      `/management/seasonschedule/vehicle-classes-and-tracks/sim/${simId}`
  );

  return response.data;
};

export const updateWeeklyEvent = async (data: {
  payload: UpdateWeeklyEventRequestDto;
  params: { id: number };
}) => {
  const response = await axios.put<VehiclesAndTracksResponseDto>(
    process.env.REACT_APP_ENDPOINT_RACE_SERIES +
      `/management/seasonschedule/weekly-event/${data.params.id}`,
    data.payload
  );

  return response.data;
};
