import React  from 'react';
import {
  EuiButton,
  EuiFieldText,
  EuiFlexGroup,
  EuiForm,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSpacer,
  EuiTextArea
} from "@elastic/eui";
import { zodResolver } from "@hookform/resolvers/zod";
import { postUsersFeedback } from "api/usersFeedback";
import { useUIContext } from "contexts/ui";
import { useUserContext } from "contexts/user";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import {
  UsersFeedbackPaylod,
  usersFeedbackPayloadValidator
} from "types/usersFeedback";

type FeedbackModalProps = {
  open: boolean;
  closeHandler: () => void;
};

export const FeedbackModal = ({ open, closeHandler }: FeedbackModalProps) => {
  const { emitErrorToast, emitSuccessToast } = useUIContext();
  const { user } = useUserContext();

  const {
    handleSubmit,
    register,
    errors,
    setValue
  } = useForm<UsersFeedbackPaylod>({
    resolver: zodResolver(usersFeedbackPayloadValidator)
  });

  const submitUsersFeedbackMutation = useMutation(postUsersFeedback, {
    onSuccess: () => {
      emitSuccessToast({
        title: "Your feedback has been sent ot our team. Thank you! 🙌🏻"
      });
      closeHandler();
    },
    onError: (error: any) => {
      const responseErrorMessage = error?.response?.data?.message;
      const errorMessage =
        responseErrorMessage ||
        error.message ||
        "We couldn't send your feedback at the moment. Please try again later.";
      emitErrorToast({ text: errorMessage });
    }
  });

  useEffect(() => {
    if (!open) {
      return;
    }
    if (user?.emailAddress) {
      setValue("email", user?.emailAddress);
    }
    if (user?.steamDisplayName) {
      setValue("userName", user?.steamDisplayName);
    }
  }, [user, open]);

  return open ? (
    <EuiModal onClose={closeHandler}>
      <EuiForm
        component="form"
        onSubmit={handleSubmit(
          submitUsersFeedbackMutation.mutate as SubmitHandler<UsersFeedbackPaylod>
        )}
      >
        <EuiModalHeader>
          <EuiModalHeaderTitle>Send feedback</EuiModalHeaderTitle>
        </EuiModalHeader>
        <EuiModalBody>
          <EuiFlexGroup
            direction="column"
            alignItems="stretch"
            gutterSize="none"
            responsive={false}
          >
            <EuiFormRow
              isInvalid={Boolean(errors.userName)}
              error={errors.userName?.message}
            >
              <EuiFieldText
                placeholder="Name"
                icon="user"
                isInvalid={Boolean(errors.userName)}
                name="userName"
                inputRef={register}
                disabled={submitUsersFeedbackMutation.isLoading}
                aria-label="Name"
              />
            </EuiFormRow>
            <EuiFormRow
              isInvalid={Boolean(errors.email)}
              error={errors.email?.message}
            >
              <EuiFieldText
                placeholder="E-mail"
                icon="email"
                isInvalid={Boolean(errors.email)}
                name="email"
                inputRef={register}
                disabled={
                  submitUsersFeedbackMutation.isLoading ||
                  Boolean(user?.emailAddress)
                }
                aria-label="E-mail"
              />
            </EuiFormRow>
            <EuiFormRow
              isInvalid={Boolean(errors.subject)}
              error={errors.subject?.message}
            >
              <EuiFieldText
                placeholder="Subject"
                icon="tag"
                isInvalid={Boolean(errors.subject)}
                name="subject"
                inputRef={register}
                disabled={submitUsersFeedbackMutation.isLoading}
                aria-label="Subject"
              />
            </EuiFormRow>
            <EuiFormRow
              isInvalid={Boolean(errors.content)}
              error={errors.content?.message}
            >
              <EuiTextArea
                placeholder="Your message"
                isInvalid={Boolean(errors.content)}
                name="content"
                inputRef={register}
                disabled={submitUsersFeedbackMutation.isLoading}
                aria-label="Message"
              />
            </EuiFormRow>
            <EuiSpacer size="m" />
          </EuiFlexGroup>
        </EuiModalBody>
        <EuiModalFooter>
          <EuiButton
            type="submit"
            fill
            isLoading={submitUsersFeedbackMutation.isLoading}
          >
            Submit
          </EuiButton>
        </EuiModalFooter>
      </EuiForm>
    </EuiModal>
  ) : null;
};
