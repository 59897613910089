import React, { FC, useMemo } from "react";
import {
  TelemetryTick,
  TelemetryTicksResponseDto,
  TelemetryTickType
} from "../../types/telemetryTicksResponseDto";
import { EuiAutoSizer } from "@elastic/eui";
import { UplotChart } from "./UplotChart";
import uPlot from "uplot";

interface Props {
  data: ChartsData;
  hoveredValue: number | null;
  setHovered(value: number): void;
}
export interface ChartDetails
  extends Omit<TelemetryTicksResponseDto, "telemetryTicks"> {
  color: string;
  telemetryTicks: TelemetryTick[];
}
export type ChartsData = Record<
  TelemetryTickType,
  {
    chartData: [xValues: number[], ...yValues: number[][]];
    details: ChartDetails[];
  }
>;

export const TelemetryCharts: FC<Props> = props => {
  let syncKey = useMemo(() => uPlot.sync("chlosta"), []);

  return (
    <div
      style={{
        width: "100%",
        height: "80vh",
        display: "flex",
        flexDirection: "column"
      }}
    >
      {Object.entries(props.data).map(([key, val], index) => (
        <div style={{ width: "100%", flex: 1 }} key={key}>
          <EuiAutoSizer>
            {({ height, width }: any) => (
              <UplotChart
                data={val}
                title={key}
                syncKey={syncKey}
                height={height}
                width={width}
                value={props.hoveredValue}
                setHovered={index === 0 ? props.setHovered : undefined}
              />
            )}
          </EuiAutoSizer>
        </div>
      ))}
    </div>
  );
};
