import React, { FC, useState } from "react";
import { EuiButton, EuiConfirmModal } from "@elastic/eui";
import { useMutation } from "react-query";
import axios from "../../utils/axios";
import { useUIContext } from "../../contexts/ui";
import { useUserContext } from "../../contexts/user";

interface Props {}

export const R3ERemoveIntegration: FC<Props> = props => {
  const { emitErrorToast, emitSuccessToast } = useUIContext();
  const { user, refreshUserToken } = useUserContext();

  const [opened, setOpened] = useState(false);

  const mutation = useMutation(
    () => {
      return axios.put(`${process.env.REACT_APP_ENDPOINT_DRIVER_ACCESS}/user`, {
        RaceroomUserId: 0
      });
    },
    {
      onSuccess: () => {
        emitSuccessToast({
          title: "R3e user name was unassigned from your rco account"
        });

        refreshUserToken();
      },
      onError: (error: any) => {
        const responseErrorMessage = error?.response?.data?.message;
        const errorMessage =
          responseErrorMessage ||
          error.message ||
          "Something went wrong. Please try again later.";
        emitErrorToast({ text: errorMessage });
      }
    }
  );

  if (!user?.isRaceroomAccountConnected) {
    return null;
  }

  return (
    <div>
      <EuiButton
        isLoading={mutation.isLoading}
        color={"warning"}
        onClick={() => setOpened(true)}
        fill
      >
        Disconnect
      </EuiButton>

      {opened && (
        <EuiConfirmModal
          title="Remove connection with R3E account?"
          onCancel={() => setOpened(false)}
          onConfirm={() => mutation.mutate()}
          isLoading={mutation.isLoading}
          cancelButtonText="Cancel"
          confirmButtonText="Remove connection with R3E"
          buttonColor="warning"
          defaultFocusedButton="cancel"
        >
          <p>
            You are about to un-assign your current R3E account name from your
            RCO account.
          </p>
          <p>You can always assign it back later :)</p>
        </EuiConfirmModal>
      )}
    </div>
  );
};
