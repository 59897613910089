import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiHealth,
  EuiHorizontalRule,
  EuiLink,
  EuiPanel,
  EuiText,
  EuiTitle
} from "@elastic/eui";
import { format } from "date-fns";
import { News } from "types/news";
import { NewsActionsProps } from "./NewsActions";
import { NewsListModal } from "./NewsListItemModal";
import React from "react";
import { MarkdownFormat } from "../../MarkdownFormat";

export interface NewsListItemProps {
  news: News;
}

export const NewsListItem = (props: NewsListItemProps & NewsActionsProps) => {
  const { news } = props;
  return (
    <div>
      <EuiFlexGroup gutterSize="none" alignItems="center" responsive={false}>
        <EuiPanel color="subdued" borderRadius="none" hasShadow={false}>
          <EuiFlexItem>
            <NewsListModal
              news={news}
              renderTrigger={setOpened => (
                <div>
                  <EuiHealth color={news.isRead ? "subdued" : "accent"} />
                  <EuiTitle size="xs">
                    <EuiLink
                      onClick={() => {
                        if (!news.isRead) {
                          props.onMarkAsRead(news.newsId);
                        }
                        setOpened(true);
                      }}
                    >
                      <MarkdownFormat color={news.isRead ? "subdued" : "text"}>
                        {news.title}
                      </MarkdownFormat>
                    </EuiLink>
                  </EuiTitle>
                  <EuiText color="subdued" size="xs">
                    <p>{format(new Date(news.createdAtUtc), "dd.MM.yyyy")}</p>
                  </EuiText>
                </div>
              )}
              toggleRead={
                props.news.isRead ? props.onMarkAsUnread : props.onMarkAsRead
              }
            />
          </EuiFlexItem>
        </EuiPanel>
      </EuiFlexGroup>
      <EuiHorizontalRule margin="none" />
    </div>
  );
};
