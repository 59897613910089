import React from "react";
import { Switch, Route } from "react-router-dom";
import { RaceHistoryList } from "./RaceHistoryList/RaceHistoryList";
import { RaceDetails } from "./RaceDetails/RaceDetails";
import { ROUTES } from "constants/routes";

export const RaceHistory = () => (
  <Switch>
    <Route path={`${ROUTES.RACE_HISTORY}/:raceId`}>
      <RaceDetails />
    </Route>
    <Route path={`${ROUTES.RACE_HISTORY}`}>
      <RaceHistoryList />
    </Route>
  </Switch>
);
