import React, { FC } from "react";
import { EuiButton, EuiCopy } from "@elastic/eui";

interface Props {
  url?: string;
}

export const ShareCurrentUrl: FC<Props> = props => {
  return (
    <EuiCopy
      textToCopy={props.url || window.location.href}
      anchorClassName="eui-fullWidth"
    >
      {copy => (
        <EuiButton
          iconType={"share"}
          title={"Copy url to clipboard"}
          color={"text"}
          onClick={copy}
        >
          Copy url
        </EuiButton>
      )}
    </EuiCopy>
  );
};
