import { EuiFlexGroup, EuiFlexGroupProps, EuiFlexItem } from "@elastic/eui";
import {
  commonVariants,
  MotionDiv,
  MotionEuiText
} from "components/shared/MotionComponents";
import React from "react";

export const zoomInVariant = commonVariants.zoomIn(20);

export const IconWithLabel = ({
  icon,
  label,
  justifyContent,
  gap
}: {
  icon: React.ReactNode;
  label: React.ReactNode;
  justifyContent?: EuiFlexGroupProps["justifyContent"];
  gap?: EuiFlexGroupProps["gutterSize"];
}) => (
  <EuiFlexItem grow={false}>
    <EuiFlexGroup
      gutterSize={gap || "s"}
      justifyContent={justifyContent || "flexEnd"}
      alignItems="center"
      responsive={false}
    >
      <EuiFlexItem grow={false}>
        <MotionDiv variants={zoomInVariant}>{icon}</MotionDiv>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <MotionEuiText variants={commonVariants.fromRightZoomIn()}>
          {React.isValidElement(label) ? (
            <label.type {...label.props} style={{ lineHeight: "unset" }} />
          ) : null}
        </MotionEuiText>
      </EuiFlexItem>
    </EuiFlexGroup>
  </EuiFlexItem>
);
