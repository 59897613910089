import React, { FC, useState } from "react";
import { EuiButtonIcon, EuiContextMenuPanel, EuiPopover } from "@elastic/eui";
import { TwitterShare } from "./TwitterShare";
import { FacebookShare } from "./FacebookShare";
import { ShareCurrentUrl } from "./ShareCurrentUrl";
import { ReactComponent as Share } from "assets/icons/share.svg";

interface Props {
  url?: string;
}

export const ShareMenuFallback: FC<Props> = props => {
  const [opened, setOpened] = useState(false);

  return (
    <EuiPopover
      button={
        <EuiButtonIcon
          iconType={Share}
          onClick={() => setOpened(!opened)}
          color={"text"}
          size={"m"}
          display={"base"}
        />
      }
      isOpen={opened}
      closePopover={() => setOpened(false)}
      panelPaddingSize="none"
      panelStyle={{ display: "flex", flexDirection: "column", gap: "5px" }}
    >
      <EuiContextMenuPanel
        size="m"
        items={[
          <div key="twitter" style={{ padding: "10px" }}>
            <TwitterShare url={props.url} />
          </div>,
          <div key="fb" style={{ padding: "10px" }}>
            <FacebookShare url={props.url} />
          </div>,
          <div key="share" style={{ padding: "10px" }}>
            <ShareCurrentUrl url={props.url} />
          </div>
        ]}
      />
    </EuiPopover>
  );
};
