import { EuiBadge, EuiIcon, EuiSpacer, EuiText } from "@elastic/eui";
import { IconWithLabel } from "components/shared/IconWithLabel";
import { format } from "date-fns";
import React from "react";
import styled from "styled-components";
import { RegisteredRace } from "types/raceSeries";

const IconsRow = styled.div`
  display: flex;
  gap: 1rem;
`;

export const RegisteredToText = ({
  text,
  startDate,
  classGroup
}: {
  text: string;
  startDate?: RegisteredRace["startTimeUtc"];
  classGroup?: number;
}) => (
  <>
    <EuiText>
      <h5>{text}</h5>

    </EuiText>
    {startDate ? (
      <>
        <EuiSpacer size="xs" />
        <IconsRow>
          {classGroup ? <EuiBadge>CG{classGroup}</EuiBadge> : null}
          <IconWithLabel
            gap="xs"
            justifyContent="flexStart"
            icon={<EuiIcon type="calendar" color="text" size="s" />}
            label={
              <EuiText size="xs">
                <p>{format(new Date(startDate), "d.LL")}</p>
              </EuiText>
            }
          />
          <IconWithLabel
            gap="xs"
            justifyContent="flexStart"
            icon={<EuiIcon type="clock" color="text" size="s" />}
            label={
              <EuiText size="xs">
                <p>{format(new Date(startDate), "k.mm")}</p>
              </EuiText>
            }
          />
        </IconsRow>
      </>
    ) : null}
  </>
);
