import { useState } from "react";
import { Direction, Criteria } from "@elastic/eui";

type UseTableSortConfig<T> = {
  initialSortField: keyof T;
  initialSortDirection?: Direction;
};

function defaultSorter<T>(items: T[], field: keyof T, direction: Direction) {
  const moveUp = direction === "asc" ? 1 : -1;
  const moveDown = direction === "asc" ? -1 : 1;
  return items.sort((a, b) =>
    a[field] > b[field] ? moveUp : a[field] < b[field] ? moveDown : 0
  );
}

export function useTableSort<S>({
  initialSortField,
  initialSortDirection = "asc"
}: UseTableSortConfig<S>) {
  const [sortField, setSortField] = useState<keyof S>(initialSortField);
  const [sortDirection, setSortDirection] = useState<Direction>(
    initialSortDirection
  );
  const sortChangeHandler = ({
    direction,
    field
  }: NonNullable<Criteria<S>["sort"]>) => {
    setSortField(field);
    setSortDirection(direction);
  };

  const sortData = (items: S[]) =>
    defaultSorter<S>(items, sortField, sortDirection);
  return { sortField, sortDirection, sortChangeHandler, sortData };
}
