import React, { FC, useEffect } from "react";
import { TOAST_LIFE_TIME } from "./GlobalToast/GlobalToast";
import { useUIContext } from "../contexts/ui";
import { EuiButton } from "@elastic/eui";
import { v4 as uuid } from "uuid";

const id = uuid();
interface Props {}
type CookieAcknowledgement = "false" | "true";
const cookieAcknowledgementKey = "cookie-acknowledgement";

const getLocalStorageCookie = () =>
  (localStorage.getItem(cookieAcknowledgementKey) as CookieAcknowledgement) ||
  "false";
const setLocalStorageCookie = () => {
  localStorage.setItem(cookieAcknowledgementKey, "true");
};

export const CookieCheckToast: FC<Props> = () => {
  const { emitToast, clearToasts } = useUIContext();
  useEffect(() => {
    const checkCookiesToast = async () => {
      const check = getLocalStorageCookie();
      if (check === "false") {
        emitToast({
          id,
          title: "We use cookies!",
          color: "primary",
          text: (
            <div>
              <div style={{ marginBottom: "20px" }}>
                In order for Racecraft.online to function properly we are using
                cookies! :)
              </div>
              <EuiButton
                onClick={() => {
                  setLocalStorageCookie();
                  clearToasts();
                }}
              >
                Acknowledge
              </EuiButton>
            </div>
          ),
          toastLifeTimeMs: TOAST_LIFE_TIME.INFINITE
        });
      }
    };
    checkCookiesToast();
  }, []);

  return null;
};
