import React, { FC, useEffect } from "react";
import { useUIContext } from "../contexts/ui";
import { useWebSocketServiceContext } from "../contexts/webSocketService/webSocketService";
import { TOAST_LIFE_TIME } from "./GlobalToast/GlobalToast";
import { DriverPerformanceModal } from "./shared/RaceSummaryModal/DriverPerformanceModal";
import { useUserContext } from "../contexts/user";

interface Props {}

export const RaceSummaryToast: FC<Props> = () => {
  const { emitToast } = useUIContext();
  const webSocket = useWebSocketServiceContext();
  const { user } = useUserContext();

  useEffect(() => {
    if (!webSocket.connected || !user?.id) {
      return;
    }

    webSocket.instance?.on("RaceResultsReady", (message: string) => {
      const payload: {
        raceSeriesEventId: number;
        raceId: number;
        splitId: number;
      } = JSON.parse(message);

      emitToast({
        title: "Race finished!",
        color: "primary",
        text: (
          <div>
            <div style={{ marginBottom: "20px" }}>
              Checkout your last race summary
            </div>
            <DriverPerformanceModal
              driverId={user.id}
              splitId={payload.splitId}
            />
          </div>
        ),
        toastLifeTimeMs: TOAST_LIFE_TIME.INFINITE
      });
    });

    return () => {
      webSocket.instance?.off("RaceResultsReady");
    };
  }, [
    emitToast,
    user?.id,
    webSocket.connected,
    webSocket.instance
  ]);

  return null;
};
