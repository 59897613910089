import React, { useEffect, useState } from "react";
import {
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  Legend, LineChart, Line
} from "recharts";
import { format } from "date-fns";
import { DriverSEloHistory, SEloHistoryEntry } from "types/profile";
import { euiPaletteColorBlind } from "@elastic/eui/lib/services";
import { useTheme } from "styled-components";

type SEloHistoryChartProps = {
  driverSEloHistory: DriverSEloHistory;
};

type SEloEntry = {
  sEloDateUtc: string;
  sEloValue: number;
};

export const SEloHistoryChart = ({
  driverSEloHistory,
}: SEloHistoryChartProps) => {
  const theme = useTheme();

  const [
    sEloAxisColor,
  ] = euiPaletteColorBlind();

  const [data, setData] = useState<Array<SEloEntry>>([])

  useEffect(() => {
    const newAreaData: any = driverSEloHistory.eloHistory.map((h: SEloHistoryEntry) => {
      const areaDataEntry: SEloEntry = {
        sEloDateUtc: h.createdAtUtc,
        sEloValue: h.sEloValue
      };

      return areaDataEntry;
    });

    setData(newAreaData);
  }, [driverSEloHistory]);

  return (
    <ResponsiveContainer width="100%" aspect={2}>
      <LineChart data={data}>
        <XAxis
          dataKey="sEloDateUtc"
          tickMargin={25}
          height={70}
          axisLine={false}
          tickLine={false}
          tickFormatter={date => format(new Date(date), "dd.MM.yyyy")}
        />
        <YAxis
          tickMargin={25}
          width={80}
          axisLine={false}
          tickLine={false}
          tickCount={10}
          domain={[driverSEloHistory.sEloMinValue -20, driverSEloHistory.sEloMaxValue + 20]}
          scale="linear"
        />
        <CartesianGrid opacity={0.1} vertical={false} strokeDasharray="5 5" />
        <Line
          name="S.ELO"
          type="linear"
          dataKey="sEloValue"
          stroke={sEloAxisColor}
          strokeWidth={2}
        />
        <Tooltip
          contentStyle={{
            background: "black",
            border: 0,
            position: "static",
            borderRadius: theme.euiBorderRadius,
          }}
          labelClassName="euiToolTip__title"
          wrapperClassName="euiToolTip"
          labelStyle={{ marginBottom: theme.paddingSizes.xs, color: "white" }}
          labelFormatter={label => format(new Date(label), "dd.MM.yyyy H:m")}
        />
        <Legend iconType="plainline" />
      </LineChart>
    </ResponsiveContainer>
  );
};
