import {
  EuiBadge,
  EuiFlexGroup,
  EuiHorizontalRule,
  EuiIcon,
  EuiSpacer,
  EuiTitle,
  EuiToolTip
} from "@elastic/eui";
import React from "react";
import { IoCarSportSharp as AllowedCarIcon } from "react-icons/io5";
import { MdLocationOn as PinIcon } from "react-icons/md";
import { useMediaQuery } from "react-responsive";
import { useTheme } from "styled-components";
import { PreviousRace, RaceSerie, RaceSeriesEvent } from "types/raceSeries";
import { RaceNumbers } from "../RaceNumbers";
import { SimpleRaceLicenceBadge } from "../../../RaceLicenceBadge";
import { RaceStartTime } from "../../../shared/RaceStartTime";
import { uniqBy } from "lodash";

type RaceCardContentProps = {
  startAtUtc: string;
  raceFrequency: RaceSerie["humanReadeableFrequency"];
  raceSessionLength: RaceSeriesEvent["raceSessionDurationInMinutes"];
  carSetup: RaceSerie["carSetup"];
  trackName: string;
  event: RaceSeriesEvent;
  previousRace: PreviousRace;
  seriesName: string;
  sim: RaceSerie["sim"];
  raceSeries: RaceSerie;
};

export const RaceCardContent: React.FC<RaceCardContentProps> = ({
  startAtUtc,
  raceFrequency,
  raceSessionLength,
  carSetup,
  trackName,
  previousRace,
  event,
  seriesName,
  sim,
  raceSeries,
}) => {
  const { euiBreakpoints } = useTheme();

  const isMobile = useMediaQuery({ maxWidth: euiBreakpoints.s });

  return (
    <div style={{ padding: "0 16px" }}>
      <RaceNumbers
        event={event}
        previousRace={previousRace}
      />
      <EuiSpacer size="m" />

      <EuiFlexGroup
        style={{
          height:
            "55px" /* arbitrary fixed height to reserve space for always 2 rows */
        }}
        alignItems="flexStart"
        gutterSize={isMobile ? "s" : "m"}
        wrap={true}
        responsive={false}
      >
        {uniqBy(
          event.raceSeriesVehicleClassGroups.groups,
          "requiredLicense.raceLicenseLevel"
        ).map(vehicleClassGroup => (
          <SimpleRaceLicenceBadge
            key={vehicleClassGroup.groupId}
            raceLicense={vehicleClassGroup.requiredLicense}
            tooltip={"Required race license"}
          />
        ))}
        <EuiToolTip position="bottom" content={<p>Race session length.</p>}>
          <EuiBadge iconType="flag">
            {`${raceSessionLength} min.` || ""}
          </EuiBadge>
        </EuiToolTip>
        <EuiToolTip position="bottom" content={<p>Race frequency.</p>}>
          <EuiBadge iconType="refresh">{`${raceFrequency}` || ""}</EuiBadge>
        </EuiToolTip>
        <EuiToolTip position="bottom" content={<p>Car setup.</p>}>
          <EuiBadge iconType="wrench">{`${carSetup}` || ""}</EuiBadge>
        </EuiToolTip>
        {sim.shortCode === "ACC" && (
          <EuiToolTip
            position="bottom"
            content={<p>ACC track medals required</p>}
          >
            <EuiBadge iconType="starFilled" color="warning">
              3 required
            </EuiBadge>
          </EuiToolTip>
        )}
        {raceSeries.dlc.isNeeded && (
          <EuiToolTip
            position="bottom"
            content={<p>DLC required. Check series details for more info.</p>}
          >
            <EuiBadge iconType="package" color="primary">
              DLC
            </EuiBadge>
          </EuiToolTip>
        )}
      </EuiFlexGroup>
      <EuiSpacer size="m" />

      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <EuiTitle size={"s"}>
          <div>{seriesName}</div>
        </EuiTitle>
        <EuiHorizontalRule margin="none" />

        <DescriptionRow>
          <EuiIcon type="calendar" size="s" />
          <EuiTitle size={"xs"}>
            <span>
              <RaceStartTime
                startAtUtc={startAtUtc}
              />
            </span>
          </EuiTitle>
        </DescriptionRow>
        <DescriptionRow>
          <AllowedCarIcon />
          <span>
            {event.raceSeriesVehicleClassGroups.groups
              .map(c => c.vehicleClasses.map(c => c.name).join(", "))
              .join(", ")}
          </span>
        </DescriptionRow>
        <DescriptionRow>
          <PinIcon />
          <span>{trackName}</span>
        </DescriptionRow>
      </div>
    </div>
  );
};

const DescriptionRow = ({ children }: { children: React.ReactNode }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "20px"
      }}
    >
      {children}
    </div>
  );
};
