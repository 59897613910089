import {
  EuiBeacon,
  EuiButton,
  EuiButtonIcon,
  EuiCopy,
  EuiFieldText,
  EuiHorizontalRule,
  EuiPopover,
  EuiText,
  EuiToolTip
} from "@elastic/eui";
import React, { useState } from "react";
import { ConnectionString } from "types/raceSeries";
import { useEuiThemeTyped } from "../../../../theme/eui_overrides";

type RaceStartButtonProps = {
  connectionStrings: ConnectionString[];
};

export const RaceStartButton = ({
  connectionStrings
}: RaceStartButtonProps) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const { euiTheme } = useEuiThemeTyped();

  const connections = connectionStrings.map(cs => {
    if (cs.screenType === "VR") {
      return (
        <WrapperWithIcon>
          <EuiButton
            iconType={"glasses"}
            onClick={() => {
              window.open(cs.connectionString);
              setPopoverOpen(false);
            }}
          >
            Join VR
          </EuiButton>
        </WrapperWithIcon>
      );
    }

    if (cs.screenType === "RegularScreen") {
      return (
        <WrapperWithIcon>
          <EuiButton
            iconType={"fullScreen"}
            onClick={() => {
              window.open(cs.connectionString);
              setPopoverOpen(false);
            }}
          >
            Join
          </EuiButton>
        </WrapperWithIcon>
      );
    }

    // screenType === ServerName
    return (
      <EuiToolTip
        content={
          <div>
            <EuiText>Server name.</EuiText>
            <EuiText>Find it in in game server browser.</EuiText>
          </div>
        }
      >
        <WrapperWithIcon>
          <EuiFieldText
            style={{ border: `1px solid ${euiTheme.colors.primary}` }}
            append={
              <EuiCopy textToCopy={connectionStrings[0].connectionString}>
                {copy => (
                  <EuiButtonIcon
                    onClick={copy}
                    iconType="copyClipboard"
                    aria-label="Copy server name"
                  />
                )}
              </EuiCopy>
            }
            readOnly
            value={connectionStrings[0].connectionString}
          />
        </WrapperWithIcon>
      </EuiToolTip>
    );
  });

  if (connections.length === 1) {
    return <>{connections}</>;
  }

  return (
    <EuiPopover
      id="raceStartButton"
      button={
        <WrapperWithIcon>
          <EuiButton
            iconType={popoverOpen ? "arrowDown" : "arrowUp"}
            onClick={() => setPopoverOpen(!popoverOpen)}
          >
            Select to join
          </EuiButton>
        </WrapperWithIcon>
      }
      isOpen={popoverOpen}
      closePopover={() => setPopoverOpen(false)}
      anchorPosition="upCenter"
    >
      {connections.map((c, index) => (
        <div>
          {c}
          {index !== connections.length - 1 && (
            <EuiHorizontalRule margin={"xs"} />
          )}
        </div>
      ))}
    </EuiPopover>
  );
};

const WrapperWithIcon = (props: { children: React.ReactNode }) => {
  return (
    <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
      <div>
        <EuiBeacon />
      </div>
      {props.children}
    </div>
  );
};
