import {
  EuiBeacon,
  EuiButtonIcon,
  EuiHorizontalRule,
  EuiPopover,
  EuiStat,
  EuiTitle
} from "@elastic/eui";
import styled from "styled-components";
import { fetchDriversCurrentEvents } from "api/raceSeries";
import { TOAST_LIFE_TIME } from "components/GlobalToast/GlobalToast";
import { useUIContext } from "contexts/ui";
import { useUserContext } from "contexts/user";
import { useWebSocketServiceContext } from "contexts/webSocketService/webSocketService";
import { formatDistanceToNow } from "date-fns";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { RegisteredRace } from "types/raceSeries";
import { RaceStartButton } from "./RaceStartButton";
import { useRaceServersWsEventsHandler } from "./useRaceServersWsEventsHandler";
import { RaceStartTime } from "../../../shared/RaceStartTime";
import { useTimer } from "../../../../hooks/useTimer";

export const RaceEventTimer: React.FC = () => {
  const webSocket = useWebSocketServiceContext();

  const userContext = useUserContext();
  const { setRegisteredRaces, nearestRegisteredRace } = userContext;
  const uiContext = useUIContext();
  const { emitErrorToast } = uiContext;
  useRaceServersWsEventsHandler(webSocket, userContext, uiContext);

  const driversCurrentEventQuery = useQuery(
    "driversCurrentEventQuery",
    fetchDriversCurrentEvents,
    {
      refetchOnWindowFocus: false,
      onError: () =>
        emitErrorToast({
          title: "Can't get your ratings.",
          toastLifeTimeMs: TOAST_LIFE_TIME.INFINITE
        }),
      onSuccess: ({ data, status }) => {
        if (status === 204) {
          setRegisteredRaces(null);
        }
        if (status === 200) {
          setRegisteredRaces(data.driverRegisteredToEvents);
        }
      }
    }
  );

  useEffect(() => {
    const removeOnReconnectListener = webSocket.onReconnect(() => {
      driversCurrentEventQuery.refetch();
    });
    return () => removeOnReconnectListener();
  }, [webSocket.onReconnect]);

  if (!nearestRegisteredRace) {
    return null;
  }

  return (
    <RaceEventTimerComponent
      key={nearestRegisteredRace.raceSeriesEventId}
      nearestRegisteredRace={nearestRegisteredRace}
    />
  );
};

interface Props {
  nearestRegisteredRace: RegisteredRace;
}

const RaceEventTimerComponent = ({ nearestRegisteredRace }: Props) => {
  const [opened, setOpened] = useState(false);

  const { date, isInPast } = useTimer(nearestRegisteredRace.startTimeUtc);

  useEffect(() => {
    // it should just open the popup in da face once
    setOpened(isInPast);
  }, [isInPast]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "5px"
      }}
    >
      {nearestRegisteredRace.raceConnectionStrings?.length ? (
        <RaceStartButton
          connectionStrings={nearestRegisteredRace.raceConnectionStrings}
        />
      ) : (
        <DivWithScale
          style={{
            display: "flex",
            alignItems: "center",
            gap: "15px",
            cursor: "help"
          }}
        >
          <EuiBeacon color={isInPast ? "danger" : "warning"} />
          <EuiPopover
            ownFocus={false}
            isOpen={opened}
            closePopover={() => setOpened(false)}
            anchorPosition="upCenter"
            button={
              <EuiStat
                titleElement={"div"}
                titleSize={"xxs"}
                description={`${nearestRegisteredRace.raceSeriesName} | ${nearestRegisteredRace.trackName} `}
                onClick={() => setOpened(true)}
                title={
                  <RaceStartTime
                    startAtUtc={nearestRegisteredRace.startTimeUtc}
                  />
                }
              />
            }
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <EuiTitle size={"xxs"}>
                <span>Nearest race</span>
              </EuiTitle>
              <EuiButtonIcon
                style={{ marginRight: "-12px", marginTop: "-15px" }}
                color={"text"}
                iconType={"cross"}
                onClick={() => setOpened(false)}
              />
            </div>
            <EuiHorizontalRule margin={"xs"} />
            {isInPast ? (
              <div>
                <p>
                  Sometimes it can take <b>up to 5 minutes</b> to boot the
                  server up, but usually it's faster.
                </p>
                <p>
                  When the server is ready you will see the connection info
                  here.
                </p>
              </div>
            ) : (
              <div>
                <p>
                  The race will start in <b>{formatDistanceToNow(date)}</b>
                </p>
                <p>You will see the connection info then.</p>
              </div>
            )}
          </EuiPopover>
        </DivWithScale>
      )}
    </div>
  );
};

const DivWithScale = styled.div`
  transition: 0.2s all ease-in-out;

  &:hover {
    transform: scale(1.04);
  }
`;
