import React from "react";
import {
  EuiForm,
  EuiFlexGroup,
  EuiFormRow,
  EuiFieldText,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
  EuiButtonEmpty,
  EuiButton
} from "@elastic/eui";
import { useUIContext } from "contexts/ui";
import { useForm, SubmitHandler } from "react-hook-form";
import { ResetPasswordRequestField } from "types/auth";
import { requestPasswordReset } from "api/auth";

type ResetPasswordRequestProps = {
  open: boolean;
  closeHandler: () => void;
};

export const ResetPasswordRequest = ({
  open,
  closeHandler
}: ResetPasswordRequestProps) => {
  const { handleSubmit, register, errors } = useForm();
  const { setGlobalLoading, emitErrorToast, emitSuccessToast } = useUIContext();

  const requestPasswordResetHandler: SubmitHandler<ResetPasswordRequestField> = async resetPasswordRequestFormData => {
    closeHandler();
    setGlobalLoading({ state: true, offset: false });
    try {
      await requestPasswordReset(resetPasswordRequestFormData);
      emitSuccessToast({
        title: "We've sent you an e-mail message with reset password link."
      });
    } catch (error: any) {
      const responseErrorMessage =
        typeof error.response?.data === "string" ? error.response.data : null;
      const errorMessage =
        responseErrorMessage ||
        error.message ||
        "We couldn't send you a reset password message. Please, check if you've entered your email address correctly and try again.";
      emitErrorToast({ title: errorMessage });
    } finally {
      setGlobalLoading({ state: false });
    }
  };

  return open ? (
    <EuiModal onClose={closeHandler} initialFocus="[name=email]">
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>Reset password</h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <EuiForm
          id="modalFormId"
          component="form"
          data-testid="resetPasswordRequestForm"
          onSubmit={handleSubmit(requestPasswordResetHandler)}
        >
          <EuiFlexGroup
            direction="column"
            alignItems="center"
            gutterSize="s"
            responsive={false}
          >
            <EuiFormRow
              style={{ width: "100%" }}
              isInvalid={Boolean(errors.email)}
              error={errors.emailaddress?.email}
            >
              <EuiFieldText
                inputRef={register({
                  required: "Email is required"
                })}
                isInvalid={Boolean(errors.email)}
                placeholder="E-mail"
                icon={"email"}
                name="email"
                type="email"
                required
                aria-label="Email"
                data-testid="emailField"
              />
            </EuiFormRow>
          </EuiFlexGroup>
        </EuiForm>
      </EuiModalBody>

      <EuiModalFooter>
        <EuiButtonEmpty onClick={closeHandler}>Cancel</EuiButtonEmpty>
        <EuiButton type="submit" form="modalFormId" fill>
          Submit
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  ) : null;
};
