import { useHistory, useLocation } from "react-router-dom";

const filterOutUndefined = (obj: Record<string, any>) =>
  Object.entries(obj)
    .filter(([, value]) => !(value === undefined || value === "undefined"))
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

export const useUrlSearch = () => {
  const { search } = useLocation();
  const { replace: replaceUrl } = useHistory();

  const setUrlSearch = (searchParamsToSet: Record<string, any>) => {
    const currentSearchParams = new URLSearchParams(search);
    const newSearchParams = new URLSearchParams(searchParamsToSet);
    replaceUrl({
      search: new URLSearchParams(
        filterOutUndefined({
          ...Object.fromEntries(currentSearchParams),
          ...Object.fromEntries(newSearchParams)
        })
      ).toString()
    });
  };
  const getUrlSearchValue = (key: string) => {
    const searchParams = new URLSearchParams(search);
    return searchParams.get(key) || undefined;
  };

  return { getUrlSearchValue, setUrlSearch };
};
