import React, { FC, useMemo } from "react";
import { RaceSummaryResponseDto } from "../../../types/profile";
import {
  EuiBadge,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiPanel,
  EuiText
} from "@elastic/eui";
import { EuiBadgeProps } from "@elastic/eui/src/components/badge/badge";
import { colors } from "../../../theme/eui_overrides";

type TableItem = {
  title: string;
  pre: { value: number; suffix?: number; prefix?: number };
  post: { value: number; suffix?: number; prefix?: number };
  inverse?: boolean;
};

interface Props {
  data: RaceSummaryResponseDto["raceSeriesStatsDiff"];
}

export const SeriesStats: FC<Props> = ({ data }) => {
  const preparedData: {
    columns: EuiBasicTableColumn<TableItem>[];
    items: TableItem[];
  } = useMemo(() => {
    return {
      columns: [
        {
          field: "pre",
          name: "Before",
          align: "right",
          width: "35%",
          render: (item: TableItem["pre"]) => {
            if (data.preRaceSeriesStatsSnapshot.isEmpty) {
              return <EuiBadge>-</EuiBadge>;
            }

            return (
              <div
                style={{ display: "flex", gap: "5px", alignItems: "center" }}
              >
                {typeof item.prefix === "number" && (
                  <EuiBadge>{item.prefix}%</EuiBadge>
                )}

                <EuiBadge>{item.value}</EuiBadge>
              </div>
            );
          }
        },
        {
          field: "title",
          name: "",
          align: "center"
        },
        {
          field: "post",
          name: "After",
          align: "left",
          width: "35%",
          render: (item: TableItem["post"], record: TableItem) => {
            return (
              <div
                style={{ display: "flex", gap: "5px", alignItems: "center" }}
              >
                <EuiBadge
                  {...getBadgeProps(
                    // yes i know it's complex, you can blame me
                    record.inverse ? record.pre.value : item.value,
                    record.inverse ? item.value : record.pre.value,
                    data.preRaceSeriesStatsSnapshot.isEmpty
                  )}
                >
                  {item.value}
                </EuiBadge>
                {typeof item.suffix === "number" && (
                  <EuiBadge
                    {...getBadgeProps(
                      record.inverse ? record.pre.prefix : item.suffix,
                      record.inverse ? item.suffix : record.pre.prefix,
                      data.preRaceSeriesStatsSnapshot.isEmpty
                    )}
                  >
                    {item.suffix}%
                  </EuiBadge>
                )}
              </div>
            );
          }
        }
      ],
      items: [
        {
          title: "wins",
          pre: {
            value: data.preRaceSeriesStatsSnapshot.wins,
            prefix: data.preRaceSeriesStatsSnapshot.winsPercentage
          },
          post: {
            value: data.postRaceSeriesStatsSnapshot.wins,
            suffix: data.postRaceSeriesStatsSnapshot.winsPercentage
          }
        },
        {
          title: "top5",
          pre: {
            value: data.preRaceSeriesStatsSnapshot.topFive,
            prefix: data.preRaceSeriesStatsSnapshot.topFivePercentage
          },
          post: {
            value: data.postRaceSeriesStatsSnapshot.topFive,
            suffix: data.postRaceSeriesStatsSnapshot.topFivePercentage
          }
        },
        {
          title: "poles",
          pre: {
            value: data.preRaceSeriesStatsSnapshot.poles
          },
          post: {
            value: data.postRaceSeriesStatsSnapshot.poles
          }
        },
        {
          title: "avg. inc",
          inverse: true,
          pre: {
            value: data.preRaceSeriesStatsSnapshot.incidentsPerMinuteAverage
          },
          post: {
            value: data.postRaceSeriesStatsSnapshot.incidentsPerMinuteAverage
          }
        },
        {
          title: "avg. start",
          inverse: true,
          pre: {
            value: data.preRaceSeriesStatsSnapshot.averageStartPosition
          },
          post: {
            value: data.postRaceSeriesStatsSnapshot.averageStartPosition
          }
        },
        {
          title: "avg. finish",
          inverse: true,
          pre: {
            value: data.preRaceSeriesStatsSnapshot.finishPositionAverage
          },
          post: {
            value: data.postRaceSeriesStatsSnapshot.finishPositionAverage
          }
        },
        {
          title: "laps led",
          pre: {
            value: data.preRaceSeriesStatsSnapshot.lapsLed,
            prefix: data.preRaceSeriesStatsSnapshot.lapsLedPercentage
          },
          post: {
            value: data.postRaceSeriesStatsSnapshot.lapsLed,
            suffix: data.postRaceSeriesStatsSnapshot.lapsLedPercentage
          }
        }
      ]
    };
  }, [data]);

  return (
    <EuiPanel
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "60px",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          alignItems: "center",
          width: "100%"
        }}
      >
        <EuiText>
          <h3 style={{ textTransform: "capitalize" }}>Series stats</h3>
        </EuiText>
        <EuiBasicTable<TableItem>
          style={{ overflow: "auto", width: "100%" }}
          items={preparedData.items}
          rowHeader="name"
          columns={preparedData.columns}
          tableLayout="auto"
        />
      </div>
    </EuiPanel>
  );
};

const getBadgeProps = (
  current: number = 0,
  toCompare: number = 0,
  preIsEmpty?: boolean
): EuiBadgeProps => {
  if (preIsEmpty) {
    return { color: "default" };
  }

  if (current > toCompare) {
    return { color: colors.winGreen };
  }

  if (current < toCompare) {
    return { color: "danger" };
  }

  return { color: "default" };
};
