import { EuiFlexGroup, EuiToolTip } from "@elastic/eui";
import {
  MotionIconWrapper,
  commonVariants
} from "components/shared/MotionComponents";
import React from "react";
import {
  RaceSeriesEvent,
  WeatherCondition as WeatherConditionType
} from "types/raceSeries";
import { WeatherCondition } from "./WeatherCondition";

const pinVariants = commonVariants.fromTopZoomIn(-5);
pinVariants.animate = {
  ...pinVariants.animate,
  transition: { type: "spring", bounce: 0.7, delay: 0.9 }
};

type WeatherNameProps = {
  event: RaceSeriesEvent;
};

export const RaceEventWeather = ({ event }: WeatherNameProps) => {
  const raceWeather = [
    event.race.weather.weatherSlot1,
    event.race.weather.weatherSlot2,
    event.race.weather.weatherSlot3,
    event.race.weather.weatherSlot4
  ].filter((v: WeatherConditionType | "") => v !== undefined && v !== "");
  return (
    <EuiFlexGroup alignItems="center">
      {raceWeather.map((weather, i, arr) => {
        const divider = i < arr.length - 1 ? <>{">"}</> : null;
        return (
          <React.Fragment key={i}>
            <EuiToolTip content={weather}>
              <MotionIconWrapper variants={pinVariants} size={2}>
                <WeatherCondition weatherCondition={weather} />
              </MotionIconWrapper>
            </EuiToolTip>
            {divider}
          </React.Fragment>
        );
      })}
    </EuiFlexGroup>
  );
};
