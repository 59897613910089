import React, { FC, useState } from "react";
import { useQuery } from "react-query";
import { fetchTelemetrySessions } from "../../api/profile";
import { useUIContext } from "../../contexts/ui";
import {
  Criteria,
  EuiBasicTable,
  EuiButton,
  EuiButtonIcon,
  EuiCard,
  EuiComboBox,
  EuiComboBoxOptionOption,
  EuiEmptyPrompt,
  EuiImage,
  EuiLink,
  EuiSkeletonRectangle,
  EuiText,
  EuiToolTip
} from "@elastic/eui";
import { useUserContext } from "../../contexts/user";
import { formatDistanceToNowStrict } from "date-fns";
import { TelemetrySession } from "../../types/telemetrySessionsResponseDto";
import {
  fetchCars,
  fetchSims,
  fetchTracks,
  fetchVehicleClasses
} from "../../api/raceSeries";
import StatsDarkIcon from "../../assets/icons/stats-dark.json";
import StatsLightIcon from "../../assets/icons/stats-light.json";
import { AnimatedIconHeader } from "../shared/AnimatedIconHeader";
import { Car } from "../../types/carsDto";
import { generatePath, Link } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { TrackTooltip } from "./TrackTooltip";
import { SimpleTimeWithClockIcon } from "./SimpleTimeWithClockIcon";

interface Props {}

export const Telemetry: FC<Props> = () => {
  const { user } = useUserContext();
  const { emitErrorToast } = useUIContext();

  const [selectedSim, setSelectedSim] = useState<
    EuiComboBoxOptionOption<number>
  >();
  const simsQuery = useQuery(["simList"], fetchSims, {
    refetchOnWindowFocus: false,
    onError: () => emitErrorToast({ title: "We couldn't get the list of sims" })
  });

  const [selectedVehicleClass, setSelectedVehicleClass] = useState<
    EuiComboBoxOptionOption<number>
  >();
  const vehiclesQuery = useQuery(["vehicleClassList"], fetchVehicleClasses, {
    refetchOnWindowFocus: false,
    onError: () =>
      emitErrorToast({ title: "We couldn't get the list of vehicle classes" })
  });

  const [selectedCar, setSelectedCar] = useState<
    EuiComboBoxOptionOption<Car>
  >();
  const carsQuery = useQuery(["carsList"], fetchCars, {
    refetchOnWindowFocus: false,
    onError: () => emitErrorToast({ title: "We couldn't get the list of cars" })
  });

  const [selectedTrack, setSelectedTrack] = useState<
    EuiComboBoxOptionOption<number>
  >();
  const tracksQuery = useQuery(["tracksList"], fetchTracks, {
    refetchOnWindowFocus: false,
    onError: () =>
      emitErrorToast({ title: "We couldn't get the list of tracks" })
  });

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const telemetry = useQuery(
    [
      "telemetrySessions",
      user?.id,
      selectedSim?.value,
      selectedVehicleClass?.value,
      selectedCar?.value?.carId,
      selectedTrack?.value,
      pageIndex + 1,
      pageSize
    ],
    fetchTelemetrySessions,
    {
      refetchOnWindowFocus: false,
      onError: () => emitErrorToast({ title: "We couldn't get telemetry data" })
    }
  );

  if (
    !vehiclesQuery.data ||
    !carsQuery.data ||
    !tracksQuery.data ||
    !simsQuery.data
  ) {
    return (
      <EuiSkeletonRectangle
        isLoading={true}
        width="100%"
        height="200px"
        borderRadius="s"
      />
    );
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      <AnimatedIconHeader
        title={`Telemetry`}
        darkLottieIcon={StatsDarkIcon}
        lightLottieIcon={StatsLightIcon}
      />
      <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
        <EuiComboBox<number>
          placeholder="Select a sim"
          singleSelection={{ asPlainText: true }}
          options={simsQuery.data.sims.map(v => ({
            value: v.simId,
            label: v.name
          }))}
          selectedOptions={selectedSim ? [selectedSim] : []}
          onChange={value => {
            setSelectedSim(value[0]);
            setPageIndex(0);
          }}
        />
        <EuiComboBox<number>
          placeholder="Select a vehicle class"
          singleSelection={{ asPlainText: true }}
          options={vehiclesQuery.data.vehicleClasses
            .filter(vc =>
              selectedCar?.value?.vehicleClassId
                ? selectedCar.value.vehicleClassId === vc.vehicleClassId
                : true
            )
            .map(v => ({
              value: v.vehicleClassId,
              label: v.vehicleClassName
            }))}
          selectedOptions={selectedVehicleClass ? [selectedVehicleClass] : []}
          onChange={value => {
            setSelectedVehicleClass(value[0]);
            setPageIndex(0);
          }}
        />
        <EuiComboBox<Car>
          placeholder="Select a car"
          singleSelection={{ asPlainText: true }}
          options={carsQuery.data.cars
            .filter(c =>
              selectedVehicleClass?.value
                ? selectedVehicleClass.value === c.vehicleClassId
                : true
            )
            .map(v => ({
              value: v,
              label: v.model
            }))}
          selectedOptions={selectedCar ? [selectedCar] : []}
          onChange={value => {
            setSelectedCar(value[0]);
            if (value[0]?.value) {
              setSelectedVehicleClass(
                value[0]?.value && {
                  value: value[0].value.vehicleClassId,
                  label: value[0].value.vehicleClassName
                }
              );
            }

            setPageIndex(0);
          }}
        />
        <EuiComboBox<number>
          placeholder="Select a track"
          singleSelection={{ asPlainText: true }}
          options={tracksQuery.data.tracks.map(v => ({
            value: v.trackId,
            label: v.name
          }))}
          selectedOptions={selectedTrack ? [selectedTrack] : []}
          onChange={value => {
            setSelectedTrack(value[0]);
            setPageIndex(0);
          }}
        />
      </div>
      {telemetry.isFetching && (
        <EuiSkeletonRectangle
          isLoading={true}
          width="100%"
          height="200px"
          borderRadius="s"
        />
      )}
      {!telemetry.data ||
        (telemetry.data.result.sessions.length < 1 && (
          <EuiEmptyPrompt
            iconType="folderExclamation"
            title={<h2>No telemetry for you my son</h2>}
            body={
              <p>
                Looks like there is no telemetry to display for the search
                criteria. Try to do some races or ask discord for help if you
                thincc it's a mistake.
              </p>
            }
          />
        ))}

      {telemetry.data && telemetry.data.result.sessions.length > 0 && (
        <EuiCard
          titleElement={"span"}
          title={
            <div
              style={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
                flexWrap: "wrap"
              }}
            ></div>
          }
          layout={"horizontal"}
        >
          <EuiBasicTable<TelemetrySession>
            style={{ overflow: "auto" }}
            items={telemetry.data.result.sessions}
            rowHeader="name"
            columns={[
              {
                name: "Sim",
                render: (item: TelemetrySession) => {
                  if (item.sim.shortCode === "AMS2") {
                    return (
                      <EuiToolTip content={item.sim.name}>
                        <EuiImage
                          hasShadow
                          size={40}
                          alt={item.sim.name}
                          src={
                            "https://storage.googleapis.com/rco-images/trivia/ams2_almost_square_logo_smoll.jpg"
                          }
                          onClick={() =>
                            setSelectedSim({
                              value: item.sim.simId,
                              label: item.sim.name
                            })
                          }
                        />
                      </EuiToolTip>
                    );
                  }

                  return (
                    <EuiToolTip content={item.sim.name}>
                      <div>{item.sim.shortCode}</div>
                    </EuiToolTip>
                  );
                }
              },
              {
                name: "Series",
                render: (record: TelemetrySession) => {
                  return (
                    <EuiLink>
                      <Link
                        to={generatePath(ROUTES.TELEMETRY_SESSION, {
                          sessionId: record.sessionId,
                          sessionType: record.sessionType
                        })}
                      >
                        {record.event.raceSeriesName}
                      </Link>
                    </EuiLink>
                  );
                }
              },
              {
                field: "sessionType",
                name: "Session",
                style: { whiteSpace: "nowrap" }
              },
              {
                field: "sessionDateUtc",
                name: "Date",
                render: (item: TelemetrySession["sessionDateUtc"]) => {
                  return (
                    <span>
                      {formatDistanceToNowStrict(new Date(item), {
                        addSuffix: true
                      })}
                    </span>
                  );
                }
              },
              {
                field: "numberOfLaps",
                name: "Laps"
              },
              {
                field: "cleanLapsPercentage",
                name: "Clean laps",
                render: (item: TelemetrySession["cleanLapsPercentage"]) => {
                  return <span>{item}%</span>;
                }
              },
              {
                field: "fastestLap",
                name: "Fastest lap",
                render: (item: TelemetrySession["fastestLap"]) => {
                  return <SimpleTimeWithClockIcon time={item.slice(3, -4)} />;
                }
              },
              {
                name: "Vehicle Class",
                render: (record: TelemetrySession) => {
                  return (
                    <span>
                      {record.vehicle.vehicleClassName}{" "}
                      <EuiToolTip content={"Filter by this vehicle"}>
                        <EuiButtonIcon
                          iconType={"filter"}
                          onClick={() =>
                            setSelectedVehicleClass({
                              value: record.vehicle.vehicleClassId,
                              label: record.vehicle.vehicleClassName
                            })
                          }
                        />
                      </EuiToolTip>
                      <EuiText
                        style={{ display: "inline" }}
                        color={"subdued"}
                        size={"relative"}
                      >
                        ({record.vehicle.vehicleModel})
                      </EuiText>
                      <EuiToolTip content={"Filter by this car"}>
                        <EuiButtonIcon
                          iconType={"filter"}
                          onClick={() => {
                            setSelectedCar(
                              carsQuery.data.cars
                                .filter(
                                  c => c.carId === record.vehicle.vehicleId
                                )
                                .map(c => ({
                                  value: c,
                                  label: c.model
                                }))[0]
                            );
                            setSelectedVehicleClass({
                              value: record.vehicle.vehicleClassId,
                              label: record.vehicle.vehicleClassName
                            });
                          }}
                        />
                      </EuiToolTip>
                    </span>
                  );
                }
              },
              {
                name: "Track",
                render: (record: TelemetrySession) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <TrackTooltip track={record.track} sim={record.sim}>
                        <EuiLink style={{ cursor: "default" }}>
                          {record.track.name}{" "}
                          <EuiText
                            style={{ display: "inline" }}
                            color={"subdued"}
                            size={"relative"}
                          >
                            ({record.track.country})
                          </EuiText>
                        </EuiLink>
                      </TrackTooltip>
                      <EuiToolTip content={"Filter by this track"}>
                        <EuiButtonIcon
                          iconType={"filter"}
                          onClick={() =>
                            setSelectedTrack({
                              value: record.track.trackId,
                              label: record.track.name
                            })
                          }
                        />
                      </EuiToolTip>
                    </div>
                  );
                }
              },
              {
                name: "",
                render: (record: TelemetrySession) => {
                  return (
                    <EuiButton>
                      <Link
                        to={generatePath(ROUTES.TELEMETRY_SESSION, {
                          sessionId: record.sessionId,
                          sessionType: record.sessionType
                        })}
                      >
                        Details
                      </Link>
                    </EuiButton>
                  );
                }
              }
            ]}
            pagination={{
              pageIndex,
              pageSize,
              totalItemCount: telemetry.data.totalCount,
              pageSizeOptions: [5, 10, 20, 50]
            }}
            onChange={({ page }: Criteria<TelemetrySession>) => {
              if (page) {
                setPageIndex(page.index);
                setPageSize(page.size);
              }
            }}
            tableLayout="auto"
          />
        </EuiCard>
      )}
    </div>
  );
};
