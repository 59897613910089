import React, { FC } from "react";
import { ReactComponent as fbLogo } from "assets/icons/square-facebook.svg";
import { objectToGetParams } from "../../utils/objectToGetParams";
import { EuiButton } from "@elastic/eui";

interface Props {
  url?: string;
}

export const FacebookShare: FC<Props> = (props) => {
  return (
    <EuiButton
      iconType={fbLogo}
      title={"Share to facebook"}
      color={"text"}
      href={`https://www.facebook.com/sharer/sharer.php${objectToGetParams({
        u: props.url || window.location.href
      })}`}
      target="_blank"
      rel="noreferrer"
    >
      Facebook
    </EuiButton>
  );
};
