import { useCallback, useState } from "react";

type Callback = () => void;
export type AddEventListener = (callback: Callback) => () => void;
type EventEmitter = () => void;

export const useEventEmitter = () => {
  const [callbacksQueue, setCallbacksQueue] = useState<
    Record<symbol, Callback>
  >({});

  const removeEventCallback = (callbackId: symbol) =>
    setCallbacksQueue(currentCallbacksQueue => {
      const {
        // TODO: update TS - https://github.com/microsoft/TypeScript/pull/44512
        // @ts-ignore
        [callbackId]: removedCallback,
        ...trimmedCallbacksQueue
      } = currentCallbacksQueue;
      return trimmedCallbacksQueue;
    });

  const addEventCallback: AddEventListener = useCallback(callback => {
    let listenerId: symbol = Symbol();
    setCallbacksQueue(currentCallbacksQueue => ({
      ...currentCallbacksQueue,
      [listenerId]: callback
    }));
    return () => removeEventCallback(listenerId);
  }, []);

  const fireCallbacksQueue: EventEmitter = useCallback(() => {
    Object.getOwnPropertySymbols(callbacksQueue).forEach(callbackId => {
      // TODO: update TS - https://github.com/microsoft/TypeScript/pull/44512
      // @ts-ignore
      callbacksQueue[callbackId]();
    });
  }, [callbacksQueue]);

  return [addEventCallback, fireCallbacksQueue] as const;
};
