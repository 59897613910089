import { WeatherCondition as WeatherConditionType } from "types/raceSeries";
import {
  WiAlien,
  WiBarometer,
  WiCloud,
  WiCloudy,
  WiDayCloudy,
  WiDayCloudyHigh,
  WiDayFog,
  WiDayHaze,
  WiDayRain,
  WiDayStormShowers,
  WiDaySunny,
  WiFog,
  WiNightFog,
  WiRain,
  WiThunderstorm
} from "react-icons/wi";
import React from "react";

export interface WeatherConditionProps {
  weatherCondition: WeatherConditionType;
}

const mapper: { [key in WeatherConditionType]: JSX.Element } = {
  Clear: <WiDaySunny />,
  LightCloud: <WiDayCloudy />,
  MediumCloud: <WiDayCloudyHigh />,
  HeavyCloud: <WiCloudy />,
  Overcast: <WiCloud />, // not sure
  LightRain: <WiDayRain />,
  Rain: <WiRain />,
  Storm: <WiDayStormShowers />,
  ThunderStorm: <WiThunderstorm />,
  Foggy: <WiDayFog />,
  FogWithRain: <WiNightFog />, // not sure
  HeavyFog: <WiFog />,
  HeavyFogWithRain: <WiFog />,
  Random: <WiAlien />,
  Hazy: <WiDayHaze />,
  "real/historic": <WiBarometer />
};

export const WeatherCondition = (props: WeatherConditionProps) => {
  const { weatherCondition } = props;
  return mapper[weatherCondition] ?? <WiDaySunny />;
};
