import React, { CSSProperties, FC } from "react";
import { useTheme } from "styled-components";
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  format,
  formatDistanceToNowStrict,
  isPast
} from "date-fns";
import { useTimer } from "../../hooks/useTimer";
import { RaceSerie } from "../../types/raceSeries";
import { EuiText } from "@elastic/eui";

export interface Props {
  startAtUtc: string | Date;
  eventType?: RaceSerie["eventType"];
  style?: CSSProperties;
}

export const RaceStartTime: FC<Props> = ({ startAtUtc, eventType, style }) => {
  if (eventType === "featured") {
    return (
      <RaceStartTimeComponentFeatured
        key={startAtUtc.toString()}
        startAtUtc={startAtUtc}
        style={style}
      />
    );
  }

  return (
    <RaceStartTimeComponent
      key={startAtUtc.toString()}
      startAtUtc={startAtUtc}
      style={style}
    />
  );
};

const RaceStartTimeComponentFeatured: FC<Props> = ({ startAtUtc, style }) => {
  const theme = useTheme();
  const { date } = useTimer(startAtUtc);

  const getStyles = () => {
    if (differenceInMinutes(date, new Date()) < 10) {
      return {
        color: theme.euiColorDangerText
      };
    }

    if (differenceInMinutes(date, new Date()) < 60) {
      return { color: theme.euiColorWarningText };
    }

    return {};
  };

  return (
    <TimeWrapper
      style={{
        color: theme.ei,
        display: "flex",
        flexFlow: "column",
        ...style
      }}
    >
      <div>{format(date, "EEEE")}</div>
      <EuiText size={"xs"} style={getStyles()}>
        nearest: {formatDistanceToNowStrict(date, { addSuffix: true })}
      </EuiText>
    </TimeWrapper>
  );
};

const RaceStartTimeComponent: FC<Props> = ({ startAtUtc, style }) => {
  const theme = useTheme();
  const { date } = useTimer(startAtUtc);

  if (isPast(date)) {
    return (
      <TimeWrapper style={style}>
        <div>BOOTING</div>
      </TimeWrapper>
    );
  }

  if (differenceInMinutes(date, new Date()) < 10) {
    return (
      <TimeWrapper
        style={{
          color: theme.euiColorDangerText,
          ...style
        }}
      >
        <div>{format(date, "HH:mm")}</div>
        <div>
          (
          {formatDistanceToNowStrict(date, {
            addSuffix: true,
            unit:
              differenceInSeconds(date, new Date()) < 60 ? "second" : "minute"
          })}
          )
        </div>
      </TimeWrapper>
    );
  }

  if (differenceInMinutes(date, new Date()) < 60) {
    return (
      <TimeWrapper
        style={{
          color: theme.euiColorWarningText,
          ...style
        }}
      >
        <div>{format(date, "HH:mm")}</div>
        <div>
          (
          {formatDistanceToNowStrict(date, { addSuffix: true, unit: "minute" })}
          )
        </div>
      </TimeWrapper>
    );
  }

  if (differenceInHours(date, new Date()) < 24) {
    return (
      <TimeWrapper style={style}>
        <div>{format(date, "HH:mm")}</div>
        <div>({formatDistanceToNowStrict(date, { addSuffix: true })})</div>
      </TimeWrapper>
    );
  }

  if (differenceInDays(date, new Date()) < 7) {
    return (
      <TimeWrapper style={style}>
        <div>{format(date, "HH:mm")}</div>
        <div>{format(date, "EEEE")}</div>
      </TimeWrapper>
    );
  }

  return (
    <TimeWrapper style={style}>
      <div>{format(date, "HH:mm")}</div>
      <div>{format(date, "dd.MM.YYY")}</div>
    </TimeWrapper>
  );
};

const TimeWrapper: FC<{
  children: React.ReactNode;
  style?: CSSProperties;
}> = props => {
  return (
    <div
      style={{
        display: "flex",
        gap: "5px",
        fontWeight: 700,
        flexWrap: "wrap",
        ...props.style
      }}
    >
      {props.children}
    </div>
  );
};
