import {
  Criteria,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiBetaBadge,
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiEmptyPrompt,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiSkeletonRectangle,
  EuiSpacer,
  EuiTabbedContent,
  EuiTabbedContentTab,
  EuiText,
  EuiToolTip
} from "@elastic/eui";
import { fetchRaceDetails } from "api/profile";
import { MotionIconWrapper } from "components/shared/MotionComponents";
import { ROUTES } from "constants/routes";
import { useUIContext } from "contexts/ui";
import { format } from "date-fns";
import { useTablePagination } from "hooks/useTablePagination";
import { useTableSort } from "hooks/useTableSort";
import React, { useEffect, useMemo, useState } from "react";
import { MdLocationOn as PinIcon } from "react-icons/md";
import { useQuery } from "react-query";
import { Link, useHistory, useParams } from "react-router-dom";
import { RaceDetailsDriver } from "../../../../types/profile";
import getPodiumIcon from "../../../../utils/getPodiumIcon";
import { LapsDetailsModal } from "./LapsDetailsModal";
import { RaceLicenceBadge } from "../../../RaceLicenceBadge";
import { DriverPerformanceModal } from "../../../shared/RaceSummaryModal/DriverPerformanceModal";
import { useUrlSearch } from "hooks/useUrlSearch";
import { useIsMobile } from "hooks/useIsMobile";

export const RaceDetails = () => {
  const history = useHistory();
  const { emitErrorToast, setGlobalLoading } = useUIContext();
  const { raceId, driverId: driverProfileId } = useParams<{
    raceId: string;
    driverId: string;
  }>();
  const [
    driverDetailsPreview,
    setDriverDetailsPreview
  ] = useState<RaceDetailsDriver>();

  const[drivers, setDrivers] = useState<RaceDetailsDriver[]>([]);

  const { getUrlSearchValue, setUrlSearch } = useUrlSearch();
  const [selectedClassGroup, setSelectedClassGroup] = useState<string>(
      // getUrlSearchValue("classGroup") ? getUrlSearchValue("classGroup") : "1"
    );

  const { isMobile } = useIsMobile();
  const [classGroupsTabs, setClassGroupsTabs] = useState([] as EuiTabbedContentTab[])

  const {
    pagination,
    pageChangeHandler,
    setTotalItemCount,
    getPage
  } = useTablePagination<RaceDetailsDriver>();

  const {
    sortField,
    sortDirection,
    sortChangeHandler,
    sortData
  } = useTableSort<RaceDetailsDriver>({ initialSortField: "finishPosition" });

  const tableChangeHandler = ({ page, sort }: Criteria<RaceDetailsDriver>) => {
    if (page) {
      pageChangeHandler(page);
    }
    if (sort) {
      sortChangeHandler(sort);
    }
  };

  const raceDetailsQuery = useQuery(
    ["raceDetailsQuery", raceId],
    fetchRaceDetails,
    {
      refetchOnWindowFocus: false,
      onError: () => emitErrorToast({ title: "We couldn't get race details." }),
      onSuccess: (data) => {
        var classGroupsIds = data.classGroups.map(s => s.classGroup.toString());
        setSelectedClassGroup(data?.classGroups[0].classGroup.toString());
        

        var classGroupsTabs = classGroupsIds.map(cg => {
          return {
            id: `cg${cg}`,
            name: `CG${cg}`,
            content: <></>
          } as EuiTabbedContentTab;
        }
        );
        setClassGroupsTabs(classGroupsTabs);
      }
    }
  );

  useEffect(() => {
    setGlobalLoading({ state: raceDetailsQuery.isFetching });
  }, [raceDetailsQuery.isFetching, setGlobalLoading]);


  useEffect(() => {
      let drivers = raceDetailsQuery.data?.classGroups.filter(cg => cg.classGroup == Number(selectedClassGroup))[0]?.drivers;
      setTotalItemCount(drivers?.length || 0);
      setDrivers(drivers || []);
  }, [selectedClassGroup]);

  // useEffect(() => {
  //   setTotalItemCount(raceDetailsQuery.data?.classGroups[Number(selectedClassGroup)].drivers.length || 0);
  // }, [raceDetailsQuery.data?.classGroups[0].drivers.length, selectedClassGroup]);

  const columns: EuiBasicTableColumn<RaceDetailsDriver>[] = useMemo(
    () => [
      {
        name: "Driver",
        width: "130px",
        render: ({
          driverId,
          driverName,
          finishPosition
        }: RaceDetailsDriver) => {
          let content = getPodiumIcon(finishPosition);
          content += driverName;

          return (
            <EuiButtonEmpty
              onClick={() => {
                history.push(`${ROUTES.STATS}/${driverId}/ratings-history`);
              }}
            >
              {content}
            </EuiButtonEmpty>
          );
        }
      },
      {
        name: "Race license",
        width: "110px",
        render: ({ driverLicense, driverId }: RaceDetailsDriver) => {
          return (
            <RaceLicenceBadge
              raceLicense={{
                raceLicenseLevel: driverLicense.licenseLevel,
                raceLicenseName: driverLicense.name
              }}
              tooltip="Drivers current race license"
              driverId={driverId}
            />
          );
        }
      },
      {
        field: "startingPosition",
        name: "Start",
        width: "60",
        align: "center"
      },
      {
        field: "finishPosition",
        name: "Finish",
        width: "80",
        align: "center"
      },
      {
        field: "points",
        name: "Points",
        align: "center"
      },
      {
        field: "bestTime",
        name: "Best time"
      },
      {
        field: "gapToBestLap",
        name: "Gap"
      },
      {
        field: "car",
        name: "Car"
      },
      {
        field: "incidentsCount",
        name: "Incidents",
        align: "center"
      },
      {
        field: "dnf",
        name: "DNF",
        align: "center"
      },
      {
        field: "retired",
        name: "Retired",
        align: "center"
      },
      {
        name: "More",
        actions: [
          {
            name: "Driver's performance",
            description: "Show driver's detailed performance after the race",
            render: item => (
              <DriverPerformanceModal
                driverId={item.driverId}
                splitId={raceId}
                raceSeriesName={raceDetailsQuery.data?.seriesName}
                time={raceDetailsQuery.data?.startTimeUtc}
                track={raceDetailsQuery.data?.track}
                renderTrigger={setOpened => (
                  <EuiToolTip content={"Driver's performance"}>
                    <EuiButtonIcon
                      iconType={"inspect"}
                      onClick={() => setOpened(true)}
                    />
                  </EuiToolTip>
                )}
              />
            )
          },
          {
            name: "Laps details",
            description: "Show laps details",
            type: "icon",
            icon: "editorOrderedList",
            onClick: driver => setDriverDetailsPreview(driver)
          }
        ]
      }
    ],
    [
      history,
      raceDetailsQuery.data?.seriesName,
      raceDetailsQuery.data?.startTimeUtc,
      raceDetailsQuery.data?.track,
      raceId
    ]
  );

  return (
    <EuiFlexGroup direction="column">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <Link
          to={`${ROUTES.STATS}/${driverProfileId}/race-history`}
          style={{ display: "flex", gap: "5px" }}
        >
          <EuiIcon type="arrowLeft" /> Go back to race list
        </Link>
        {raceDetailsQuery.data && (
          <DriverPerformanceModal
            driverId={driverProfileId}
            splitId={raceId}
            raceSeriesName={raceDetailsQuery.data.seriesName}
            time={raceDetailsQuery.data.startTimeUtc}
            track={raceDetailsQuery.data.track}
          />
        )}
      </div>
      {raceDetailsQuery.isFetching && (
        <EuiSkeletonRectangle
          isLoading={true}
          width="100%"
          height="500px"
          borderRadius="s"
        />
      )}
      {!raceDetailsQuery.isFetching && !drivers?.length && (
        <EuiFlexItem>
          <EuiEmptyPrompt
            iconType="folderExclamation"
            title={<h2>No race details data</h2>}
            body={<p>Looks like the is no race details data to display.</p>}
          />
        </EuiFlexItem>
      )}
      {raceDetailsQuery.data &&  drivers?.length && (
        <>
          <EuiSpacer size={"xs"} />
          <EuiFlexItem grow={false}>
            <EuiFlexGroup justifyContent="spaceBetween" wrap>
              <EuiFlexItem grow={false}>
                <EuiText>
                  <h2>
                    {raceDetailsQuery.data.seriesName}&nbsp;
                    <EuiBetaBadge
                      label={`#${raceDetailsQuery.data.raceId}`}
                      title="Race in series"
                    />
                  </h2>
                </EuiText>
              </EuiFlexItem>
              <DoubleDetailsSection
                firstElement={<EuiIcon type="calendar" />}
                text={format(
                  new Date(raceDetailsQuery.data.startTimeUtc),
                  "Pp"
                )}
              />
              <DoubleDetailsSection
                firstElement={
                  <MotionIconWrapper size={2}>
                    <PinIcon />
                  </MotionIconWrapper>
                }
                text={raceDetailsQuery.data.track}
              />
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem>
          { classGroupsTabs.length > 0 ?
              <EuiTabbedContent
                tabs={classGroupsTabs}
                size={isMobile ? "m" : "l"}
                initialSelectedTab={classGroupsTabs.find(t => t.id === `cg${selectedClassGroup?.toString()}`)}
                onTabClick={(tab: EuiTabbedContentTab) => {
                    setSelectedClassGroup(() => (tab.id.match(/\d+/) || ["1"])[0]);
                    setUrlSearch({
                      classGroup: (tab.id.match(/\d+/) || ["1"])[0]
                    })
                    return;
                }}
              />
            :
            null
            }
            <EuiBasicTable<RaceDetailsDriver>
              items={getPage(sortData(drivers))}
              rowHeader="name"
              columns={columns}
              pagination={pagination}
              onChange={tableChangeHandler}
              sorting={{
                enableAllColumns: true,
                sort: {
                  field: sortField,
                  direction: sortDirection
                }
              }}
            />
          </EuiFlexItem>
        </>
      )}
      <LapsDetailsModal
        driver={driverDetailsPreview}
        onClose={() => setDriverDetailsPreview(undefined)}
      />
    </EuiFlexGroup>
  );
};

type DoubleDetailsSectionProps = {
  firstElement: React.ReactNode;
  text: string;
};
const DoubleDetailsSection = ({
  firstElement,
  text
}: DoubleDetailsSectionProps) => (
  <EuiFlexItem grow={false}>
    <EuiFlexGroup
      gutterSize="xs"
      alignItems="center"
      wrap={false}
      responsive={false}
    >
      <EuiFlexItem grow={false}>{firstElement}</EuiFlexItem>
      <EuiFlexItem>
        <EuiText>
          <p>{text}</p>
        </EuiText>
      </EuiFlexItem>
    </EuiFlexGroup>
  </EuiFlexItem>
);