import { EuiFlexGroup, EuiFlexItem } from "@elastic/eui";
import {
  MotionEuiText,
  MotionIconWrapper,
  commonVariants
} from "components/shared/MotionComponents";
import React from "react";
import { MdLocationOn as PinIcon } from "react-icons/md";
import styled from "styled-components";

const trackNameVariants = {
  initial: { opacity: 0, x: 10 },
  animate: { opacity: 1, x: 0, transition: { type: "spring", delay: 1.1 } },
  exit: { opacity: 0, x: 10 }
};

const pinVariants = commonVariants.fromTopZoomIn(-5);
pinVariants.animate = {
  ...pinVariants.animate,
  transition: { type: "spring", bounce: 0.7, delay: 0.9 }
};

const StyledPinIcon = styled(PinIcon)`
  margin-left: -0.1em;
`;

type TrackNameProps = {
  trackName: string;
};

const StyledTrackName = styled("p")`
  font-size: ${({ theme }) => theme.euiFontSizeM};
}`;

export const TrackName = ({ trackName }: TrackNameProps) => (
  <EuiFlexGroup
    gutterSize="xs"
    alignItems="center"
    wrap={false}
    responsive={false}
  >
    <EuiFlexItem grow={false}>
      <MotionIconWrapper variants={pinVariants} size={2}>
        <StyledPinIcon />
      </MotionIconWrapper>
    </EuiFlexItem>
    <EuiFlexItem>
      <MotionEuiText variants={trackNameVariants}>
        <StyledTrackName>{trackName}</StyledTrackName>
      </MotionEuiText>
    </EuiFlexItem>
  </EuiFlexGroup>
);
