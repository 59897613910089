import React from "react";
import { useUserContext } from "contexts/user";
import { EuiFlexItem } from "@elastic/eui";
import GlobalMessage from "../GlobalMessage/GlobalMessage";
import styled from "styled-components";
import { getGlobalMessages } from "api/layout";
import { useQuery } from "react-query";
import { useUIContext } from "contexts/ui";
import { GlobalMessages } from "types/layout";

const CompensatingDonationBar = styled(EuiFlexItem)`
  padding-top: ${({ theme }) => theme.euiSizeXXL};
  margin: ${({ theme }) => theme.euiHeaderHeightCompensation} 0 0 0;
  @media (max-width: ${({ theme }) => theme.euiBreakpoints.s}) {
    padding-top: ${({ theme }) => theme.euiSize};
  }
`;

const Banner = () => {
  const { user } = useUserContext();
  const { emitErrorToast, setGlobalMessages, globalMessages } = useUIContext();

  useQuery("getGlobalMessagesQuery", getGlobalMessages, {
    refetchOnWindowFocus: false,
    onSuccess: (data: GlobalMessages) => {
      setGlobalMessages(data.globalMessages);
    },
    onError: (error: ResponseError) => {
      const errorMessage = "Can't get global messages.";
      emitErrorToast({ title: errorMessage });
    }
  });

  if (!user || globalMessages.length < 1) {
    return null;
  }
  return (
    <CompensatingDonationBar>
      <GlobalMessage messages={globalMessages} />
    </CompensatingDonationBar>
  );
};

export default Banner;
