import React, { FC, useEffect } from "react";
import { TOAST_LIFE_TIME } from "./GlobalToast/GlobalToast";
import { useUIContext } from "../contexts/ui";
import { useWebSocketServiceContext } from "../contexts/webSocketService/webSocketService";
import { EuiButton } from "@elastic/eui";
import { v4 as uuid } from "uuid";

const id = uuid();

interface Props {}

export const RefreshTheAppToast: FC<Props> = () => {
  const { emitToast } = useUIContext();
  const webSocket = useWebSocketServiceContext();

  useEffect(() => {
    if (!webSocket.connected) {
      return;
    }

    webSocket.instance?.on("ReloadApp", () => {
      emitToast({
        id,
        title: "Good news!",
        color: "primary",
        text: (
          <div>
            <div style={{ marginBottom: "20px" }}>
              We have just upgraded RCO app! Smash <strong>the Button</strong> to reload :)
            </div>
            <EuiButton onClick={() => window.location.reload()}>
              This Button
            </EuiButton>
          </div>
        ),
        toastLifeTimeMs: TOAST_LIFE_TIME.INFINITE
      });
    });

    return () => {
      webSocket.instance?.off("ReloadApp");
    };
  }, [emitToast, webSocket.connected, webSocket.instance]);

  return null;
};
