import { useCallback, useEffect, useState } from "react";
import lightTheme from "theme/eui_theme_amsterdam_light_mod.scss";
import darkTheme from "theme/eui_theme_amsterdam_dark_mod.scss";

export type ThemeMode = "auto" | "light" | "dark";

export const useSystemThemeDetector = () => {
  const getCurrentSystemTheme = () =>
    window.matchMedia("(prefers-color-scheme: dark)").matches
      ? "dark"
      : "light";
  const themeFromLocalStorage = (localStorage.getItem("theme") as ThemeMode) || "auto";

  const [theme, setTheme] = useState<"light" | "dark">(
    themeFromLocalStorage === "auto" ? getCurrentSystemTheme() : themeFromLocalStorage
  );
  const [themeMode, setThemeMode] = useState<ThemeMode>(themeFromLocalStorage);
  const handleSetThemeMode = useCallback((themeMode: ThemeMode) => {
    localStorage.setItem("theme", themeMode);
    setThemeMode(themeMode);
  }, []);

  const switchTheme = () => {
    if (themeMode === "auto") {
      handleSetThemeMode("light");
    }
    if (themeMode === "light") {
      handleSetThemeMode("dark");
    }
    if (themeMode === "dark") {
      handleSetThemeMode("auto");
    }
  };

  useEffect(() => {
    if (theme === "dark") {
      lightTheme.unuse();
      darkTheme.use();
    } else {
      darkTheme.unuse();
      lightTheme.use();
    }
  }, [theme]);

  const mqListener = useCallback((e: MediaQueryListEvent) => {
    setTheme(e.matches ? "dark" : "light");
  }, []);
  useEffect(() => {
    if (themeMode !== "auto") {
      setTheme(themeMode);
      return;
    }

    setTheme(getCurrentSystemTheme());
    const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
    darkThemeMq.addEventListener("change", mqListener);
    return () => darkThemeMq.removeEventListener("change", mqListener);
  }, [mqListener, themeMode]);

  return { theme, themeMode, switchTheme };
};
