import { EuiComboBox, EuiFlexGroup, EuiFlexItem } from "@elastic/eui";
import {
  fetchEventsList,
  fetchRaceSeasonsList,
  fetchRaceSeriesList
} from "api/standings";
import { useUIContext } from "contexts/ui";
import { useUrlSearch } from "hooks/useUrlSearch";
import React from "react";
import { useQuery } from "react-query";
import {
  EventsListItem,
  EventsStandingsSelection,
  SeasonStandingsSelection,
  SeriesStandingsSelection
} from "types/standings";

const getEventLabel = (event: EventsListItem) =>
  `week ${event.weekNumber}, ${event.year} - ${event.trackName}`;

type StandingsSelectorProps = {
  enableSeasons?: boolean;
  enableSeries?: boolean;
  enableEvents?: boolean;
  defaultSeasonId?: number | string;
  defaultSeriesId?: number | string;
  defaultEventId?: number | string;
  selectedSeason?: SeasonStandingsSelection;
  setSelectedSeason: React.Dispatch<
    React.SetStateAction<SeasonStandingsSelection | undefined>
  >;
  selectedSeries?: SeriesStandingsSelection;
  setSelectedSeries: React.Dispatch<
    React.SetStateAction<SeriesStandingsSelection | undefined>
  >;
  selectedEvent?: EventsStandingsSelection;
  setSelectedEvent: React.Dispatch<
    React.SetStateAction<EventsStandingsSelection | undefined>
  >;
};

export const StandingSelector = ({
  selectedSeason,
  setSelectedSeason,
  selectedSeries,
  setSelectedSeries,
  selectedEvent,
  setSelectedEvent,
  enableSeasons = true,
  enableSeries = true,
  enableEvents = true,
  defaultSeasonId,
  defaultSeriesId,
  defaultEventId
}: StandingsSelectorProps) => {
  const { getUrlSearchValue, setUrlSearch } = useUrlSearch();

  const changeSeason = (option: SeasonStandingsSelection[]) => {
    setSelectedSeason(option[0]);
    setSelectedSeries(undefined);
    setSelectedEvent(undefined);
    setUrlSearch({
      season: option[0]?.value,
      series: undefined,
      event: undefined,
      
    });
  };
  const changeSeries = (option: SeriesStandingsSelection[]) => {
    setSelectedSeries(option[0]);
    setSelectedEvent(undefined);
    setUrlSearch({
      series: option[0]?.value,
      event: undefined,
      classGroup: "1"
    });
  };
  const changeEvent = (option: EventsStandingsSelection[]) => {
    setSelectedEvent(option[0]);
    setUrlSearch({
      event: option[0]?.value
    });
  };

  const { emitErrorToast } = useUIContext();

  const raceSeasonsListQuery = useQuery(
    "raceSeasonsListQuery",
    fetchRaceSeasonsList,
    {
      refetchOnWindowFocus: false,
      onSuccess: seasons => {
        const defaultSeason = seasons.find(
          season => season.seasonId === defaultSeasonId
        );
        const currentSeason = seasons.find(season => season.isCurrentSeason);
        const urlSearchSeasonId = Number(getUrlSearchValue("season"));
        const matchedSearchSeason = seasons.find(
          season => season.seasonId === urlSearchSeasonId
        );
        const seasonToSet =
          defaultSeason || matchedSearchSeason || currentSeason;
        if (seasonToSet) {
          setSelectedSeason({
            label: seasonToSet.seasonName,
            value: seasonToSet.seasonId
          });
        }
        if (!matchedSearchSeason && currentSeason) {
          setUrlSearch({
            season: currentSeason.seasonId
          });
        }
      },
      onError: () =>
        emitErrorToast({ title: "We couldn't get the race seasons list" })
    }
  );

  const raceSeriesListQuery = useQuery(
    ["raceSeriesListQuery", selectedSeason?.value],
    fetchRaceSeriesList,
    {
      enabled: Boolean(selectedSeason),
      refetchOnWindowFocus: false,
      onSuccess: series => {
        const defaultSeries = series.find(
          serie => serie.raceSeriesId === defaultSeriesId
        );
        const urlSearchSeriesId = Number(getUrlSearchValue("series"));
        const matchedSearchSeries = series.find(
          singleSeries => singleSeries.raceSeriesId === urlSearchSeriesId
        );
        if (defaultSeries) {
          setSelectedSeries({
            label: defaultSeries.raceSeriesName,
            value: defaultSeries.raceSeriesId
          });
        } else if (matchedSearchSeries) {
          setSelectedSeries({
            label: matchedSearchSeries.raceSeriesName,
            value: matchedSearchSeries.raceSeriesId
          });
        }
      },
      onError: () =>
        emitErrorToast({ title: "We couldn't get the race series list" })
    }
  );

  const eventsListQuery = useQuery(
    ["eventsListQuery", selectedSeason?.value, selectedSeries?.value],
    fetchEventsList,
    {
      enabled: Boolean(selectedSeason && selectedSeries),
      refetchOnWindowFocus: false,
      onSuccess: events => {
        const defaultEvent = events.find(
          event => event.raceSeriesEventId === defaultEventId
        );
        const urlSearchEventId = Number(getUrlSearchValue("event"));
        const matchedSearchEvent = events.find(
          event => event.raceSeriesEventId === urlSearchEventId
        );
        if (defaultEvent) {
          setSelectedEvent({
            label: getEventLabel(defaultEvent),
            value: defaultEvent.raceSeriesEventId
          });
        } else if (matchedSearchEvent) {
          setSelectedEvent({
            label: getEventLabel(matchedSearchEvent),
            value: matchedSearchEvent.raceSeriesEventId
          });
        }
      },
      onError: () =>
        emitErrorToast({ title: "We couldn't get the race series events list" })
    }
  );

  return (
    <EuiFlexGroup gutterSize="s" alignItems="center">
      <EuiComboBox<number>
        fullWidth
        selectedOptions={selectedSeason ? [selectedSeason] : []}
        placeholder="Select season"
        isLoading={raceSeasonsListQuery.isLoading}
        singleSelection={{ asPlainText: true }}
        options={raceSeasonsListQuery.data?.map(season => ({
          label: season.seasonName,
          value: season.seasonId,
          key: String(season.seasonId)
        }))}
        onChange={changeSeason}
        isDisabled={!enableSeasons}
      />
      <EuiComboBox<number>
        fullWidth
        selectedOptions={selectedSeries ? [selectedSeries] : []}
        placeholder="Select series"
        isLoading={raceSeriesListQuery.isLoading}
        singleSelection={{ asPlainText: true }}
        isDisabled={!selectedSeason || !enableSeries}
        options={raceSeriesListQuery.data?.map(series => ({
          label: series.raceSeriesName,
          value: series.raceSeriesId,
          key: String(series.raceSeriesId)
        }))}
        onChange={changeSeries}
      />
      <EuiComboBox<number>
        fullWidth
        selectedOptions={selectedEvent ? [selectedEvent] : []}
        placeholder="Select race series event"
        isLoading={eventsListQuery.isLoading}
        singleSelection={{ asPlainText: true }}
        isDisabled={!selectedSeries || !enableEvents}
        options={eventsListQuery.data?.map(event => ({
          label: getEventLabel(event),
          value: event.raceSeriesEventId,
          key: String(event.raceSeriesEventId)
        }))}
        onChange={changeEvent}
      />
    </EuiFlexGroup>
  );
};
