import {
  EuiBadge,
  EuiButton,
  EuiButtonIcon,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiNotificationBadge,
  EuiSkeletonRectangle,
  EuiSpacer,
  EuiTabbedContent,
  EuiTitle,
  EuiToolTip
} from "@elastic/eui";
import { fetchRaceSeriesDetails } from "api/raceSeries";
import StandingsDarkIcon from "assets/icons/standings-dark.json";
import StandingsLightIcon from "assets/icons/standings-light.json";
import { TOAST_LIFE_TIME } from "components/GlobalToast/GlobalToast";
import { AnimatedIconHeader } from "components/shared/AnimatedIconHeader";
import { useUIContext } from "contexts/ui";
import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { Details } from "./Details";
import { SeriesInfo } from "./Tabs/Info";
import { Schedule } from "./Tabs/Schedule";
import { Link, useHistory, useParams } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { Standings } from "./Tabs/Standings";
import { JoinPracticeButton } from "components/shared/JoinPracticeButton";
import { RegisterToRaceButton } from "components/shared/RegisterToRaceButton";
import { R3EConnectMenu } from "../shared/R3eConnectMenu";
import { useUserContext } from "../../contexts/user";
import { Dlcs } from "./Tabs/Dlcs";
import { RaceNumbers } from "../RaceSeries/RaceCard/RaceNumbers";
import { RaceStartTime } from "../shared/RaceStartTime";
import { UpcomingRacesList } from "../shared/UpcomingRaces/UpcomingRacesList";
import { useSearchParams } from "../../hooks/useSearchParams";
import { RaceSerie } from "../../types/raceSeries";
import { useWebSocketServiceContext } from "../../contexts/webSocketService/webSocketService";
import { parseJsonOrNull, toggleElementByKey } from "../../utils/utils";
import { DriverRegistrationWSPayload } from "../RaceSeries/useRaceSeriesWsEventsHandlers";
import { UserAvatar } from "../shared/UserAvatar";
import { uniqBy } from "lodash";
import { SimpleRaceLicenceBadge } from "../RaceLicenceBadge";

const RaceSeriesDetailsComponent = ({
  raceSeriesId,
  eventId,
  raceId
}: {
  raceSeriesId: string | number;
  eventId?: string | number | null;
  raceId?: string | number | null;
}) => {
  const { user } = useUserContext();
  const { emitErrorToast } = useUIContext();

  const raceSeriesDetailsQuery = useQuery(
    ["raceSeriesDetailsQuery", raceSeriesId, eventId, raceId],
    fetchRaceSeriesDetails,
    {
      refetchOnWindowFocus: false,
      onError: () =>
        emitErrorToast({
          title: "Can't get race series details.",
          toastLifeTimeMs: TOAST_LIFE_TIME.INFINITE
        })
    }
  );
  // todo remove this all ws thing when we agree on api, or refactor and add races[] to raceSeriesContext
  // oooooorrr maybe it's not that bad?
  // probably it's not a good idea to keep everything in races[] with current model where there's single raceseries.event.race
  const queryClient = useQueryClient();
  const webSocket = useWebSocketServiceContext();
  useEffect(() => {
    if (!webSocket.connected) {
      return;
    }

    const registeredDriversNumberChanged = (payload: string) => {
      const parsedPayload = parseJsonOrNull<DriverRegistrationWSPayload>(
        payload
      );

      if (!parsedPayload) {
        return;
      }

      queryClient.setQueryData<RaceSerie>(
        ["raceSeriesDetailsQuery", raceSeriesId, eventId, raceId],
        oldData => {
          if (!oldData) {
            return oldData!; // i think this is a type issue with react-query
          }

          return {
            ...oldData,
            event: {
              ...oldData.event,
              race: {
                ...oldData.event.race,
                registeredDrivers: toggleElementByKey(
                  oldData.event.race.registeredDrivers,
                  parsedPayload,
                  "driverId"
                )
              }
            }
          };
        }
      );
    };

    webSocket.instance?.on("DriverRegistered", registeredDriversNumberChanged);
    webSocket.instance?.on(
      "DriverUnregistered",
      registeredDriversNumberChanged
    );

    return () => {
      webSocket.instance?.off(
        "DriverRegistered",
        registeredDriversNumberChanged
      );
      webSocket.instance?.off(
        "DriverUnregistered",
        registeredDriversNumberChanged
      );
    };
  }, [
    eventId,
    queryClient,
    raceId,
    raceSeriesId,
    webSocket.connected,
    webSocket.instance
  ]);

  const raceSeriesDetails = raceSeriesDetailsQuery.data;

  if (raceSeriesDetailsQuery.isLoading || !raceSeriesDetails) {
    return (
      <EuiSkeletonRectangle
        isLoading={true}
        width="100%"
        height="60vh"
        borderRadius="s"
      >
        ...
      </EuiSkeletonRectangle>
    );
  }

  return (
    <>
      <EuiFlexGroup direction="column" gutterSize="none">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {raceSeriesDetails?.name && (
            <AnimatedIconHeader
              title={raceSeriesDetails.name}
              darkLottieIcon={StandingsDarkIcon}
              lightLottieIcon={StandingsLightIcon}
            />
          )}
        </div>
        <EuiSpacer size="l" />
        <EuiCard title={"Race"} layout={"horizontal"}>
          <div
            style={{
              display: "flex",
              gap: "10px",
              marginBottom: "10px",
              flexFlow: "wrap"
            }}
          >
            {uniqBy(
              raceSeriesDetails.event.raceSeriesVehicleClassGroups.groups,
              "requiredLicense.raceLicenseLevel"
            ).map(vehicleClassGroup => (
              <SimpleRaceLicenceBadge
                key={vehicleClassGroup.groupId}
                raceLicense={vehicleClassGroup.requiredLicense}
                tooltip={"Required race license"}
              />
            ))}
            <EuiToolTip position="bottom" content={<p>Race session length.</p>}>
              <EuiBadge iconType="flag">
                {`${raceSeriesDetails.event.raceSessionDurationInMinutes} min.` ||
                  ""}
              </EuiBadge>
            </EuiToolTip>
            <EuiToolTip position="bottom" content={<p>Race frequency.</p>}>
              <EuiBadge iconType="refresh">
                {`${raceSeriesDetails.humanReadeableFrequency}` || ""}
              </EuiBadge>
            </EuiToolTip>
            <EuiToolTip position="bottom" content={<p>Car setup.</p>}>
              <EuiBadge iconType="wrench">
                {`${raceSeriesDetails.carSetup}` || ""}
              </EuiBadge>
            </EuiToolTip>
            {raceSeriesDetails.sim.shortCode === "ACC" && (
              <EuiToolTip
                position="bottom"
                content={<p>ACC track medals required</p>}
              >
                <EuiBadge iconType="starFilled" color="warning">
                  3 required
                </EuiBadge>
              </EuiToolTip>
            )}
            {raceSeriesDetails.dlc.isNeeded && (
              <EuiToolTip
                position="bottom"
                content={
                  <p>DLC required. Check series details for more info.</p>
                }
              >
                <EuiBadge iconType="package" color="primary">
                  DLC
                </EuiBadge>
              </EuiToolTip>
            )}
          </div>

          <div
            style={{
              display: "flex",
              gap: "10px",
              justifyContent: "space-between",
              flexWrap: "wrap"
            }}
          >
            <div
              style={{
                // dis is kinda `css hack` to make the second element with flex: 1 take the full width ONLY when it's wrapped :)
                flex: 999,
                display: "flex",
                alignItems: "center",
                gap: "20px"
              }}
            >
              <EuiTitle size={"xs"}>
                <RaceStartTime
                  startAtUtc={raceSeriesDetails.event.race.startAtUtc}
                />
              </EuiTitle>
              <RaceNumbers
                event={raceSeriesDetails.event}
                previousRace={raceSeriesDetails.previousRace}
              />
            </div>
            <div style={{ flex: 1 }}>
              {raceSeriesDetails.sim.shortCode === "R3E" &&
              user &&
              !user?.isRaceroomAccountConnected ? (
                <R3EConnectMenu />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "10px"
                  }}
                >
                  <JoinPracticeButton raceSeries={raceSeriesDetails} />
                  <RegisterToRaceButton
                    race={raceSeriesDetails.event.race}
                    vehicleClassGroups={
                      raceSeriesDetails.event.raceSeriesVehicleClassGroups
                        .groups
                    }
                    league={raceSeriesDetails.league}
                  />
                </div>
              )}
            </div>
          </div>
          {raceSeriesDetails.event.race.registeredDrivers.length > 0 && (
            <div
              style={{
                display: "flex",
                gap: "20px",
                flexWrap: "wrap",
                maxHeight: "300px",
                overflow: "auto",
                marginTop: "10px"
              }}
            >
              {raceSeriesDetails.event.race.registeredDrivers.map(d => (
                <EuiLink key={d.driverId}>
                  <Link
                    to={`${ROUTES.STATS}/${d.driverId}`}
                    target={"_blank"}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      padding: "5px 0"
                    }}
                  >
                    <UserAvatar
                      name={d.steamDisplayName}
                      imageUrl={d.steamAvatarUrl}
                    />
                    <EuiTitle size={"xxs"}>
                      <span>{d.steamDisplayName}</span>
                    </EuiTitle>
                  </Link>
                </EuiLink>
              ))}
            </div>
          )}
        </EuiCard>
        <EuiSpacer size="l" />
        {raceSeriesDetails ? (
          <EuiFlexItem>
            <Details raceSeriesDetails={raceSeriesDetails} />
          </EuiFlexItem>
        ) : null}
        <EuiSpacer size="l" />
        <EuiTabbedContent
          tabs={[
            {
              id: "upcoming",
              name: "Upcoming races",
              append:
                raceSeriesDetails.event.upcomingRacesDriversCount +
                  raceSeriesDetails.event.race.registeredDrivers.length >
                0 ? (
                  <EuiNotificationBadge size={"m"} color={"accent"}>
                    {raceSeriesDetails.event.upcomingRacesDriversCount +
                      raceSeriesDetails.event.race.registeredDrivers.length}
                  </EuiNotificationBadge>
                ) : null,
              content: (
                <UpcomingRacesList
                  event={raceSeriesDetails.event}
                  raceSeriesId={raceSeriesDetails.id}
                  filterOutNearestRace={false}
                  league={raceSeriesDetails.league}
                />
              )
            },
            {
              id: "info",
              name: "Info",
              content: <SeriesInfo raceSeriesDetails={raceSeriesDetails} />
            },
            {
              id: "dlcs",
              name: "DLCs",
              append: (
                <EuiNotificationBadge
                  size={"m"}
                  color={
                    raceSeriesDetails.dlc.dlcs.length < 1 ? "subdued" : "accent"
                  }
                >
                  {raceSeriesDetails.dlc.dlcs.length}
                </EuiNotificationBadge>
              ),
              content: (
                <>
                  <EuiSpacer />
                  <Dlcs dlc={raceSeriesDetails.dlc} />
                </>
              )
            },
            {
              id: "schedule",
              name: "Schedule",
              content: (
                <>
                  <EuiSpacer />
                  <Schedule raceSeriesId={raceSeriesDetails.id} />
                </>
              )
            },
            {
              id: "seriesStandings",
              name: "Standings",
              content: (
                <>
                  <EuiSpacer />
                  <Standings raceSeriesId={raceSeriesDetails.id} />
                </>
              )
            }
          ]}
          size={`xl`}
        />
      </EuiFlexGroup>
    </>
  );
};

export const RaceSeriesDetailsModal = ({
  raceSeriesId,
  eventId,
  raceId,
  setOpened
}: {
  raceSeriesId: number;
  eventId?: number;
  raceId?: number;
  setOpened(value: boolean): void;
}) => {
  return (
    <EuiModal onClose={() => setOpened(false)} style={{ minWidth: "60vw" }}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <div style={{ display: "flex", gap: "30px" }}>
            <div>Series details</div>
            <EuiToolTip
              position="bottom"
              content="Show series details on separated page"
            >
              <Link
                to={`${ROUTES.RACE_SERIES_DETAILS}/${raceSeriesId}?eventId=${eventId}&raceId=${raceId}`}
              >
                <EuiButtonIcon iconType="push" />
              </Link>
            </EuiToolTip>
          </div>
        </EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <RaceSeriesDetailsComponent
          raceSeriesId={raceSeriesId}
          eventId={eventId}
          raceId={raceId}
        />
      </EuiModalBody>

      <EuiModalFooter>
        <EuiButton onClick={() => setOpened(false)} fill>
          Close
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};

export const RaceSeriesDetailsModalWithTrigger = ({
  raceSeriesId,
  eventId,
  raceId,
  renderTrigger
}: {
  raceSeriesId: number;
  eventId?: number;
  raceId?: number;
  renderTrigger(setOpened: (opened: boolean) => void): React.ReactNode;
}) => {
  const [opened, setOpened] = useState(false);

  return (
    <>
      {renderTrigger(setOpened)}

      {opened && (
        <RaceSeriesDetailsModal
          raceSeriesId={raceSeriesId}
          eventId={eventId}
          raceId={raceId}
          setOpened={setOpened}
        />
      )}
    </>
  );
};

export const RaceSeriesDetails = () => {
  const { raceSeriesId } = useParams<{ raceSeriesId: string }>();
  const searchParams = useSearchParams();
  const history = useHistory();

  return (
    <>
      <Link to="" onClick={() => history.push(ROUTES.HOME)}>
        <EuiButton iconSide="left" iconType="arrowLeft">
          Race series list
        </EuiButton>
      </Link>
      <EuiSpacer size="xl" />
      <RaceSeriesDetailsComponent
        raceSeriesId={raceSeriesId}
        eventId={searchParams.get("eventId")}
        raceId={searchParams.get("raceId")}
      />
    </>
  );
};
