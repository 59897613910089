import React from "react";
import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiToolTip } from "@elastic/eui";
import { RegisteredRace } from "types/raceSeries";
import { RegisteredToText } from "./RegisteredToText";

type RegisteredEventProps = {
  raceEvent: RegisteredRace;
  unregisterHandler: (raceSeriesEventId: number, classGroup: number) => void;
  actionButtonOverride?: JSX.Element;
  unregisterPending?: boolean;
};
export const RegisteredEvent = ({
  raceEvent,
  unregisterHandler,
  actionButtonOverride,
  unregisterPending
}: RegisteredEventProps) => (
  <EuiFlexGroup responsive={false} gutterSize="m" alignItems="center">
    <EuiFlexItem grow={false}>
      <RegisteredToText
        text={raceEvent.raceSeriesName}
        startDate={raceEvent.startTimeUtc}
        classGroup={raceEvent.classGroup}
      />
    </EuiFlexItem>
    {actionButtonOverride ? (
      actionButtonOverride
    ) : (
      <EuiFlexItem grow={false}>
        <EuiToolTip content="Unregister">
          <EuiButton
            color="warning"
            size="s"
            iconType="cross"
            onClick={() => unregisterHandler(raceEvent.raceSeriesEventId, raceEvent.classGroup)} 
            isLoading={unregisterPending}
            minWidth={0}
          />
        </EuiToolTip>
      </EuiFlexItem>
    )}
  </EuiFlexGroup>
);
