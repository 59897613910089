import { Moment } from "moment/moment";
import moment from "moment";
import { useUserContext } from "../contexts/user";
import { RaceLicense, UserRatings } from "../types/profile";

export const isAprilFool = (date: Date | string | Moment = new Date()) => {
  return moment(date).month() === 3 && moment(date).date() === 1;
};

export const useCeo = (id?: number) => {
  const { user } = useUserContext();

  return id ? id === 24 : user?.id === 24;
};

export const getUserRatings = (
  actualUserRatings: UserRatings,
  driverId?: string | number
): UserRatings => {
  if (isAprilFool()) {
    if (driverId?.toString() === "24") {
      return {
        ...actualUserRatings,
        safety: 0,
        consistency: 0,
        sElo: 69
      };
    }

    return {
      ...actualUserRatings,
      safety: 100,
      consistency: 100,
      sElo: (actualUserRatings.sElo || 1500) * 10
    };
  }

  return actualUserRatings;
};

export const useRaceLicenceForAprilsFool = (
  raceLicense: RaceLicense,
  userId?: number
): RaceLicense => {
  const userIsRoszman = useCeo(userId);

  if (isAprilFool()) {
    if (userIsRoszman) {
      return { raceLicenseName: "Rookie", raceLicenseLevel: 0 };
    }

    return { raceLicenseLevel: 5, raceLicenseName: "Alien" };
  }

  return raceLicense;
};
