import { EuiIcon } from "@elastic/eui";
import { commonVariants } from "components/shared/MotionComponents";
import { format } from "date-fns";
import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";
import { SeasonStandings } from "types/standings";

const SeasonStartEndContainer = styled(motion.div)`
  color: ${({ theme }) => theme.euiTextSubduedColor};
  display: flex;
`;

const AnimatedChild = styled(motion.div)`
  display: flex;
  align-items: center;
  svg {
    margin-right: ${({ theme }) => theme.paddingSizes.s};
  }
  &:nth-child(3) {
    svg {
      margin: auto ${({ theme }) => theme.paddingSizes.s};
    }
  }
`;

const animationVariants = commonVariants.fromLeftZoomIn(-20, 50);

const containerVariants = {
  show: {
    transition: {
      staggerChildren: 0.15
    }
  },
  unmount: {
    transition: {
      when: "afterChildren",
      staggerChildren: 0.05,
      staggerDirection: -1
    }
  }
};

type SeasonStartEndSubheaderProps = {
  startDateUtc : string,
  endDateUtc : string
};

export const SeasonStartEndSubheader = ({
  startDateUtc, endDateUtc
} : 
  SeasonStartEndSubheaderProps
) => {
  const startDate = format(new Date(startDateUtc), "d.LL.y");
  const endDate = format(new Date(endDateUtc), "d.LL.y");

  const item = {
    hidden: animationVariants.initial,
    show: animationVariants.animate,
    unmount: animationVariants.exit
  };

  return (
    <SeasonStartEndContainer
      variants={containerVariants}
      initial="hidden"
      animate="show"
      exit="unmount"
    >
      <AnimatedChild variants={item}>
        <EuiIcon type="calendar" />
      </AnimatedChild>
      <AnimatedChild variants={item}>{startDate}</AnimatedChild>
      <AnimatedChild variants={item}>
        <EuiIcon type="sortRight" />
      </AnimatedChild>
      <AnimatedChild variants={item}>{endDate}</AnimatedChild>
    </SeasonStartEndContainer>
  );
};
