import { EuiFlexItem, EuiPanel, EuiText } from "@elastic/eui";
import { TrackName } from "components/RaceSeries/RaceCard/RaceCardHeader/TrackName";
import React from "react";
import styled from "styled-components";
import { RaceSeriesSeasonEvent } from "types/raceSeries";
import { VehicleClass } from "./VehcileClass";

const EventCardHeader = styled(EuiText)`
  flex-grow: 1;
  @media (max-width: ${({ theme }) => theme.euiBreakpoints.m}) {
    flex-basis: 100%;
    margin-bottom: 0.5rem;
  }
`;

const PanelContentContainer = styled.div<{ pastEvent: boolean }>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  opacity: ${({ pastEvent }) => (pastEvent ? 0.3 : 1)};
`;

type EventCardProps = {
  event: RaceSeriesSeasonEvent;
};

export const EventCard = ({ event }: EventCardProps) => (
  <EuiFlexItem key={event.eventSignature} grow={false}>
    <EuiPanel hasShadow>
      <PanelContentContainer pastEvent={event.isPastEvent}>
        <EventCardHeader size="s">
          <h3 className="eui-textNoWrap">{event.eventSignature}</h3>
        </EventCardHeader>
        <div>
          <VehicleClass vehicleClassName={event.vehicleClass.name} />
        </div>
        <div>
          <TrackName trackName={event.track.trackName} />
        </div>
      </PanelContentContainer>
    </EuiPanel>
  </EuiFlexItem>
);
