import React, { FC } from "react";
import {
  EuiIcon,
  EuiPanel,
  EuiStat,
  EuiText,
  EuiTextColor
} from "@elastic/eui";
import { RaceSummaryResponseDto } from "../../../types/profile";
import { useEuiThemeTyped } from "../../../theme/eui_overrides";

interface Props {
  seloResult: RaceSummaryResponseDto["seloResult"];
}

export const SeloResults: FC<Props> = ({ seloResult }) => {
  const { euiTheme } = useEuiThemeTyped();

  return (
    <EuiPanel
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        alignItems: "center",
        width: "100%"
      }}
    >
      <EuiText>
        <h3 style={{ textTransform: "capitalize" }}>S.Elo</h3>
      </EuiText>
      <div
        style={{
          display: "flex",
          gap: "20px",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <EuiStat
          title={seloResult.newSElo}
          description={"S.Elo"}
          textAlign="left"
        >
          <>
            {seloResult.sEloChange < 0 && (
              <EuiTextColor color="danger">
                <span>
                  <EuiIcon type="sortDown" /> {Math.abs(seloResult.sEloChange)}
                </span>
              </EuiTextColor>
            )}
            {seloResult.sEloChange === 0 && (
              <EuiTextColor>
                <span>&nbsp;</span>
              </EuiTextColor>
            )}
            {seloResult.sEloChange > 0 && (
              <EuiTextColor color={euiTheme.colors.winGreen}>
                <span>
                  <EuiIcon type="sortUp" /> {seloResult.sEloChange}
                </span>
              </EuiTextColor>
            )}
          </>
        </EuiStat>
        <EuiText size={"m"}>
          <span>VS</span>
        </EuiText>

        <EuiStat
          title={seloResult.splitStrenghtOfField}
          description={"SoF in race"}
          textAlign="left"
        >
          <EuiTextColor>
            <span>&nbsp;</span>
          </EuiTextColor>
        </EuiStat>
      </div>
    </EuiPanel>
  );
};
