import React, { FC, useEffect, useState } from "react";
import {
  EuiButtonIcon,
  EuiDatePicker,
  EuiDatePickerRange,
  EuiToolTip
} from "@elastic/eui";
import moment from "moment/moment";
import { Moment } from "moment";

interface Props {
  startTime: string;
  endTime: string;

  onChange([from, to]: [Moment, Moment]): void;
}

export const UpcomingRacesRangePicker: FC<Props> = ({ onChange, ...props }) => {
  const minDate = moment(props.startTime);
  const maxDate = moment(props.endTime);
  const [startDate, setStartDate] = useState(minDate);
  const [endDate, setEndDate] = useState(maxDate);

  useEffect(() => {
    onChange([startDate, endDate]);
  }, [startDate, endDate, onChange]);

  return (
    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
      <EuiDatePickerRange
        startDateControl={
          <EuiDatePicker
            selected={startDate}
            onChange={date => {
              if (!date) {
                return;
              }

              setStartDate(date);
            }}
            startDate={startDate}
            endDate={endDate}
            minDate={minDate}
            maxDate={endDate}
            dateFormat={"DD/MM/yyyy HH:mm"}
            aria-label="Start date"
            showTimeSelect={true}
            timeFormat="HH:mm"
          />
        }
        endDateControl={
          <EuiDatePicker
            selected={endDate}
            onChange={date => {
              if (!date) {
                return;
              }

              setEndDate(date);
            }}
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            maxDate={maxDate}
            dateFormat={"DD/MM/yyyy HH:mm"}
            aria-label="End date"
            showTimeSelect={true}
            timeFormat="HH:mm"
          />
        }
      />
      <EuiToolTip content={"Apply default date ranges"}>
        <EuiButtonIcon
          size={"s"}
          iconType={"eraser"}
          onClick={() => {
            setStartDate(minDate);
            setEndDate(maxDate);
          }}
        />
      </EuiToolTip>
    </div>
  );
};
