import React from "react";
import { EuiFlexGroup, EuiFlexItem, EuiButton } from "@elastic/eui";

export const LostConnectionToastContent = () => (
  <>
    <p>
      Connection to the update service has been lost and can't be renew right
      now. We recommend to refresh the page to ensure that all the data is up to
      date.
    </p>
    <EuiFlexGroup justifyContent="flexEnd" gutterSize="s">
      <EuiFlexItem grow={false}>
        <EuiButton onClick={() => window.location.reload()} size="s">
          Refresh page
        </EuiButton>
      </EuiFlexItem>
    </EuiFlexGroup>
  </>
);
