import {
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiPopover
} from "@elastic/eui";
import { useUserContext } from "contexts/user";
import React, { useState } from "react";
import { RegisteredEvent } from "./RegisteredEvent";
import { RegisteredToText } from "./RegisteredToText";
import { useRaceSeriesContext } from "../../../RaceSeries/raceSeriesContext";
import { useRegisterRace } from "../../../shared/hooks/useRegisterRace";

export const RegisteredTo = () => {
  const { nearestRegisteredRace, registeredRaces } = useUserContext();
  const { registerRaceLoading } = useRaceSeriesContext();
  const { unregisterRace } = useRegisterRace();

  const [registeredListOpen, setRegisteredListOpen] = useState(false);

  return (
    <div style={{ display: "flex", justifyContent: "flex-start", flex: 1 }}>
      {!registeredRaces || !nearestRegisteredRace ? (
        <RegisteredToText text="&nbsp;Not registered to any race" />
      ) : registeredRaces?.length === 1 ? (
        <RegisteredEvent
          raceEvent={nearestRegisteredRace}
          unregisterHandler={unregisterRace}
          unregisterPending={registerRaceLoading.some( r => r.raceId === 
            nearestRegisteredRace?.raceSeriesEventId
          )}
        />
      ) : (
        <RegisteredEvent
          raceEvent={nearestRegisteredRace}
          unregisterHandler={unregisterRace}
          actionButtonOverride={
            <EuiPopover
              repositionOnScroll
              button={
                <EuiButtonIcon
                  display="base"
                  iconType={registeredListOpen ? "arrowDown" : "arrowUp"}
                  aria-label="Next"
                  size="m"
                  onClick={() =>
                    setRegisteredListOpen(prevOpenState => !prevOpenState)
                  }
                />
              }
              isOpen={registeredListOpen}
              closePopover={() => setRegisteredListOpen(false)}
            >
              <EuiFlexGroup direction="column" gutterSize="s">
                {registeredRaces.map((race, index) => (
                  <React.Fragment key={race.raceSeriesEventId}>
                    <EuiFlexItem>
                      <RegisteredEvent
                        raceEvent={race}
                        unregisterHandler={unregisterRace}
                        unregisterPending={registerRaceLoading.some(r => r.raceId ===
                          race.raceSeriesEventId
                        )}
                      />
                    </EuiFlexItem>
                    {index + 1 !== registeredRaces.length && (
                      <EuiHorizontalRule margin="xs" />
                    )}
                  </React.Fragment>
                ))}
              </EuiFlexGroup>
            </EuiPopover>
          }
        />
      )}
    </div>
  );
};
