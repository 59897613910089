import React, { FC } from "react";
import { EuiAvatar, EuiAvatarProps } from "@elastic/eui";

interface Props {}

export const UserAvatar: FC<EuiAvatarProps> = props => {
  const { imageUrl, ...restProps } = props;

  if (imageUrl?.includes("fef49e7fa7e1997310d705b2a6158ff8dc1cdfeb_medium")) {
    return <EuiAvatar data-testid="userAvatar" {...restProps} />;
  }

  return <EuiAvatar data-testid="userAvatar" {...props} />;
};
