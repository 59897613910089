import React from "react";
import { EuiFlexGroup, EuiFlexItem, EuiSpacer } from "@elastic/eui";
import {
  fetchEventStandings,
  fetchSeasonStandings,
  fetchSeriesStandings
} from "api/standings";
import StandingsDarkIcon from "assets/icons/standings-dark.json";
import StandingsLightIcon from "assets/icons/standings-light.json";
import { AnimatedIconHeader } from "components/shared/AnimatedIconHeader";
import { commonVariants } from "components/shared/MotionComponents";
import { useUIContext } from "contexts/ui";
import { motion } from "framer-motion";
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import {
  EventsStandingsSelection,
  SeasonStandingsSelection,
  SeriesStandingsSelection
} from "types/standings";
import { SeasonStartEndSubheader } from "../shared/SeasonStartEndSubheader";
import { StandingSelector } from "../shared/StandingSelector";
import { StandingsTable } from "../shared/StandingsTable";

export const Standings = () => {
  const { setGlobalLoading, emitErrorToast } = useUIContext();

  const [selectedSeason, setSelectedSeason] =
    useState<SeasonStandingsSelection>();
  const [selectedSeries, setSelectedSeries] =
    useState<SeriesStandingsSelection>();
  const [selectedEvent, setSelectedEvent] =
    useState<EventsStandingsSelection>();

  const seasonStandingsQuery = useQuery(
    ["seasonStandinsQuery", selectedSeason?.value],
    fetchSeasonStandings,
    {
      enabled: Boolean(selectedSeason),
      cacheTime: 0,
      refetchOnWindowFocus: false,
      onError: () => {
        setSelectedSeason(undefined);
        emitErrorToast({
          title: "We couldn't get standings data for the selected season."
        });
      }
    }
  );

  const seriesStandingsQuery = useQuery(
    ["seriesStandinsQuery", selectedSeason?.value, selectedSeries?.value],
    fetchSeriesStandings,
    {
      enabled: Boolean(selectedSeason && selectedSeries),
      cacheTime: 0,
      refetchOnWindowFocus: false,
      onError: () => {
        setSelectedSeries(undefined);
        emitErrorToast({
          title: "We couldn't get standings data for the selected race series."
        });
      }
    }
  );

  const eventStandingsQuery = useQuery(
    ["eventStandinsQuery", selectedEvent?.value],
    fetchEventStandings,
    {
      enabled: Boolean(selectedSeason && selectedSeries && selectedEvent),
      cacheTime: 0,
      refetchOnWindowFocus: false,
      onError: () => {
        setSelectedEvent(undefined);
        emitErrorToast({
          title:
            "We couldn't get standings data for the selected race series event."
        });
      }
    }
  );

  const currentStandingsTitle = useMemo(() => {
    const currentStandingsName =
      selectedEvent?.label || selectedSeries?.label || selectedSeason?.label;
    return currentStandingsName
      ? `Standings - ${currentStandingsName}`
      : "Standings";
  }, [selectedSeason, selectedSeries, selectedEvent]);

  useEffect(() => {
    setGlobalLoading({
      state:
        seasonStandingsQuery.isLoading ||
        seriesStandingsQuery.isLoading ||
        eventStandingsQuery.isLoading
    });
  }, [
    seasonStandingsQuery.isLoading,
    seriesStandingsQuery.isLoading,
    eventStandingsQuery.isLoading
  ]);

  return (
    <>
      <EuiFlexGroup direction="column" gutterSize="none">
        <AnimatedIconHeader
          title={currentStandingsTitle}
          subtitle={
            seasonStandingsQuery?.data?.season ? (
              <SeasonStartEndSubheader
                startDateUtc={seasonStandingsQuery?.data.season.startUtc}
                endDateUtc={seasonStandingsQuery?.data.season.endUtc}
              />
            ) : undefined
          }
          darkLottieIcon={StandingsDarkIcon}
          lightLottieIcon={StandingsLightIcon}
        />
        <EuiSpacer size="xxl" />
        <EuiFlexItem>
          <motion.div {...commonVariants.zoomOut()}>
            <StandingSelector
              selectedSeason={selectedSeason}
              setSelectedSeason={setSelectedSeason}
              selectedSeries={selectedSeries}
              setSelectedSeries={setSelectedSeries}
              selectedEvent={selectedEvent}
              setSelectedEvent={setSelectedEvent}
            />
          </motion.div>
          <EuiSpacer size="xxl" />
          <StandingsTable
            seasonStandingsData={seasonStandingsQuery.data?.drivers}
            seriesStandingsData={seriesStandingsQuery.data?.drivers}
            eventStandingsData={eventStandingsQuery.data?.drivers}
            setSelectedSeries={setSelectedSeries}
          />
          <EuiSpacer />
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
};
