import React from "react";
import { EuiButton, EuiEmptyPrompt } from "@elastic/eui";

type NoRaceSeriesProps = {
  refetchData: () => void;
};

export const NoRaceSeries = ({ refetchData }: NoRaceSeriesProps) => {
  return (
    <EuiEmptyPrompt
      iconType="folderExclamation"
      title={<h2>No racing series</h2>}
      body={
        <p>
          Looks like there are no race series to display. You may want to try
          fetching them again.
        </p>
      }
      actions={
        <EuiButton
          color="primary"
          fill
          iconType="refresh"
          onClick={refetchData}
        >
          Refetch race series
        </EuiButton>
      }
    />
  );
};
