import React, { ComponentType, CSSProperties, FC } from "react";
import { EuiBadge, EuiBetaBadge, EuiIcon, EuiToolTip } from "@elastic/eui";
import { RaceLicense } from "../types/profile";
import { useTheme } from "styled-components";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as RookieGauge } from "assets/licenceBadges/rookie.svg";
import { ReactComponent as BronzeGauge } from "assets/licenceBadges/bronze.svg";
import { ReactComponent as SilverGauge } from "assets/licenceBadges/silver.svg";
import { ReactComponent as GoldGauge } from "assets/licenceBadges/gold.svg";
import { ReactComponent as PlatinumGauge } from "assets/licenceBadges/platinum.svg";
import { ReactComponent as AlienGauge } from "assets/licenceBadges/alien.svg";
import { useRaceLicenceForAprilsFool } from "../utils/aprilFools";

export const badgePropsMap: Record<number, [CSSProperties, ComponentType]> = {
  0: [{ backgroundColor: "#2e2d25", color: "#fff" }, RookieGauge],
  1: [{ backgroundColor: "#9C652C", color: "#fff" }, BronzeGauge],
  2: [{ backgroundColor: "#828080", color: "#fff" }, SilverGauge],
  3: [{ backgroundColor: "#CBAE36", color: "#fff" }, GoldGauge],
  4: [{ backgroundColor: "#F0EFEE", color: "#000" }, PlatinumGauge],
  5: [{ backgroundColor: "#56DF5A", color: "#fff" }, AlienGauge]
};

interface Props {
  fetching?: boolean;
  raceLicense: RaceLicense;
  tooltip: string;
  short?: boolean;
  driverId?: number;
}

export const RaceLicenceBadge: FC<Props> = props => {
  const { euiBreakpoints } = useTheme();
  const isMobile = useMediaQuery({ maxWidth: euiBreakpoints.s });

  const raceLicense: RaceLicense = useRaceLicenceForAprilsFool(
    props.raceLicense,
    props.driverId
  );

  if (props.fetching) {
    return (
      <EuiBetaBadge
        label={
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <EuiIcon type={"visGauge"} />
            <div>...</div>
          </div>
        }
        tooltipContent="Loading..."
        alignment={"middle"}
      />
    );
  }

  return (
    <EuiBetaBadge
      style={badgePropsMap[raceLicense.raceLicenseLevel][0]}
      label={
        <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <EuiIcon type={badgePropsMap[raceLicense.raceLicenseLevel][1]} />
          <div>
            {isMobile
              ? raceLicense.raceLicenseName[0]
              : raceLicense.raceLicenseName}
          </div>
        </div>
      }
      tooltipContent={props.tooltip}
      alignment={"middle"}
    />
  );
};

export const SimpleRaceLicenceBadge: FC<Props> = props => {
  if (props.fetching) {
    return null;
  }

  return (
    <EuiToolTip content={props.tooltip}>
      <EuiBadge
        iconType={badgePropsMap[props.raceLicense.raceLicenseLevel][1]}
        style={badgePropsMap[props.raceLicense.raceLicenseLevel][0]}
      >
        {props.short
          ? props.raceLicense.raceLicenseName[0]
          : props.raceLicense.raceLicenseName}
      </EuiBadge>
    </EuiToolTip>
  );
};
