import { EuiFlexGroup } from "@elastic/eui";
import React from "react";
import BottomBar from "./BottomBar/BottomBar";
import Header from "./Header/Header";
import { useUserContext } from "contexts/user";
import { SteamMaintenanceWarning } from "./Header/SteamMaintenanceWarning";
import { useGeneralWsEventsHandler } from "./useGeneralWsEventsHandler";
import { RefreshTheAppToast } from "../RefreshTheAppToast";
import { RaceSummaryToast } from "../RaceSummaryToast";
import { CookieCheckToast } from "../CookieCheckToast";
import { AutoTriggeredWelcomeModal } from "../WelcomeModal/AutoTriggeredWelcomeModal";
import { GlobalToast } from "../GlobalToast/GlobalToast";

export const GlobalLayout: React.FC = ({ children }) => {
  const { user } = useUserContext();
  useGeneralWsEventsHandler();

  return (
    <EuiFlexGroup
      justifyContent="center"
      alignItems="center"
      gutterSize="none"
      direction="column"
    >
      <Header />
      {children}
      {user ? <BottomBar /> : null}

      {user && (
        <div style={{ position: "absolute", top: "50px", right: "2px" }}>
          <SteamMaintenanceWarning />
        </div>
      )}
      <RefreshTheAppToast />
      <RaceSummaryToast />
      <CookieCheckToast />
      <AutoTriggeredWelcomeModal />
      <GlobalToast />
    </EuiFlexGroup>
  );
};
