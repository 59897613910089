import { isEqual } from 'lodash';

export const parseJsonOrNull = <T>(value: any): T | null => {
  try {
    return JSON.parse(value);
  } catch (e) {
    console.error("could not parse json", e);

    return null;
  }
};

export const toggleElementByKey = <T>(
  array: T[],
  element: T,
  key: keyof T
): T[] =>
  array.some(a => a[key] === element[key])
    ? array.filter(a => a[key] !== element[key])
    : [...array, element];

export const toggleElement = <T>(array: T[] = [], element: T, compare: (a: T, b: T) => boolean = isEqual) =>
  array.some(item => compare(item, element))
    ? array.filter(item => !compare(item, element))
    : [...array, element];
