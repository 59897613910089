import React, { FC } from "react";
import { EuiButton, EuiText, EuiTitle, EuiToolTip } from "@elastic/eui";
import {
  DriversPracticingNumberBadge,
  RegisteredDriversBadge
} from "./RaceCard/RaceNumbers";
import { RaceSerie } from "../../types/raceSeries";
import CalendarIconLight from "../../assets/icons/wired-outline-28-calendar_light.json";
import CalendarIconDark from "../../assets/icons/wired-outline-28-calendar_dark.json";
import { AnimatedHeaderWithIcon } from "../shared/AnimatedIconHeader";
import { R3EConnectMenu } from "../shared/R3eConnectMenu";
import { JoinPracticeButton } from "../shared/JoinPracticeButton";
import { UpcomingRacesPopover } from "./RaceCard/UpcomingRacesPopover";
import { RacesList } from "./RacesList";
import { RaceStartTime } from "../shared/RaceStartTime";
import { useUserContext } from "../../contexts/user";

interface Props {
  races: RaceSerie[];
}

export const WeeklyRaces: FC<Props> = props => {
  const { user } = useUserContext();
  const allRegisteredDrivers = props.races.reduce((acc, item) => {
    return (
      acc +
      item.event.race.registeredDrivers.length +
      item.event.upcomingRacesDriversCount
    );
  }, 0);
  const practicingDrivers = props.races.reduce((acc, item) => {
    return acc + item.event.practicingDriversCount;
  }, 0);

  const sortedRaces = props.races.sort((a, b) =>
    a.event.race.startAtUtc.localeCompare(b.event.race.startAtUtc)
  );

  return (
    <div>
      <AnimatedHeaderWithIcon
        lightLottieIcon={CalendarIconLight}
        darkLottieIcon={CalendarIconDark}
        title={
          <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
            <EuiTitle>
              <EuiText>Weekly races</EuiText>
            </EuiTitle>
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <EuiToolTip content="Drivers registered to all weeklies">
                <RegisteredDriversBadge
                  count={allRegisteredDrivers}
                  icon={"users"}
                />
              </EuiToolTip>
              <DriversPracticingNumberBadge count={practicingDrivers} />
            </div>
          </div>
        }
        subtitle={
          <EuiText color={"subdued"} size={"s"}>
            Prepare for our weekly events. They happen on specific days of the
            week, in four time zone slots.
          </EuiText>
        }
      />
      <RacesList
        races={sortedRaces}
        renderTime={(item: RaceSerie) => {
          return (
            <EuiTitle size={"xs"}>
              <span>
                <RaceStartTime
                  startAtUtc={item.event.race.startAtUtc}
                  eventType={"featured"}
                  style={{
                    alignItems: "center"
                  }}
                />
              </span>
            </EuiTitle>
          );
        }}
        renderNumbers={(item: RaceSerie) => {
          return (
            <>
              <EuiToolTip content="Drivers registered to all races in this weekly">
                <RegisteredDriversBadge
                  icon={"users"}
                  count={
                    item.event.race.registeredDrivers.length +
                    item.event.upcomingRacesDriversCount
                  }
                />
              </EuiToolTip>
              <DriversPracticingNumberBadge
                count={item.event.practicingDriversCount}
              />
            </>
          );
        }}
        renderButtons={(record: RaceSerie) => {
          if (
            record.sim.shortCode === "R3E" &&
            user &&
            !user?.isRaceroomAccountConnected
          ) {
            return <R3EConnectMenu />;
          }

          return (
            <div
              style={{
                display: "flex",
                gap: "5px",
                minWidth: "220px",
                width: "100%",
                justifyContent: "flex-end"
              }}
            >
              <JoinPracticeButton raceSeries={record} />
              <UpcomingRacesPopover
                event={record.event}
                raceSeriesId={record.id}
                league={record.league}
                filterOutNearestRace={false}
                renderTrigger={onClick => (
                  <EuiButton
                    color="primary"
                    onClick={onClick}
                    minWidth={0}
                    iconType={"calendar"}
                  >
                    Calendar
                  </EuiButton>
                )}
              />
            </div>
          );
        }}
      />
    </div>
  );
};
