import ams2Logo from "./assets/simLogos/ams2.webp";
import acLogo from "./assets/simLogos/ac.svg";
import accLogo from "./assets/simLogos/acc.png";
import r3eLogo from "./assets/simLogos/r3e.png";

export const SIM_LOGO = {
  AMS2: ams2Logo,
  AC: acLogo,
  ACC: accLogo,
  R3E: r3eLogo
} as Record<string, string>;
