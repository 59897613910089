import React, { FC } from "react";
import { EuiButtonIcon } from "@elastic/eui";
import { ShareMenuFallback } from "./ShareMenuFallback";
import { useUIContext } from "../../contexts/ui";
import { isMobileDevice } from "../../utils/isMobileDevice";
import { ReactComponent as Share } from "assets/icons/share.svg";

interface Props {
  url?: string;
}

export const ShareButtonsWrapper: FC<Props> = props => {
  const { emitToast } = useUIContext();

  if (!!navigator.share && isMobileDevice()) {
    return (
      <EuiButtonIcon
        iconType={Share}
        onClick={async () => {
          try {
            await navigator.share({
              title: "racecraft.online",
              text: "Look at this",
              url: props.url || window.location.href
            });
          } catch (err: any) {
            if (err?.toString().includes("AbortError")) {
              // user manually aborts the share, nothing to do here
              return;
            }

            emitToast({
              title: "Info",
              color: "warning",
              text: "Could not invoke native share operation for some reason :|"
            });
          }
        }}
        color={"text"}
        display={"base"}
        size={"m"}
      />
    );
  }

  return <ShareMenuFallback url={props.url} />;
};
