import React, { FC, useState } from "react";
import {
  EuiCard,
  EuiIcon,
  EuiLink,
  EuiNotificationBadge,
  EuiPopover,
  EuiTitle,
  EuiToolTip
} from "@elastic/eui";
import { GoHistory } from "react-icons/go";
import AnimatedDriversCounter from "./AnimatedDriversCounter";
import {
  PreviousRace,
  RaceSeriesEvent,
  RegisteredDriver
} from "../../../types/raceSeries";
import { ReactComponent as RobotIcon } from "assets/icons/robot.svg";
import { EuiNotificationBadgeV80ColorHack } from "../../shared/EuiNotificationBadgeV80ColorHack";
import { useEuiThemeTyped } from "../../../theme/eui_overrides";
import { IconType } from "@elastic/eui/src/components/icon/icon";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { UserAvatar } from "../../shared/UserAvatar";

interface Props {
  event: RaceSeriesEvent;
  previousRace: PreviousRace;
}

export const RaceNumbers: FC<Props> = ({ previousRace, event }) => {
  return (
    <div style={{ display: "flex", gap: "10px" }}>
      {event.race.averageGridSize > 0 ? (
        <AverageGridSizeBadge count={event.race.averageGridSize} />
      ) : null}
      <RegisteredDriversBadgeWithDriversList
        drivers={event.race.registeredDrivers}
      />
      <AiNumberBadge event={event} />
      <DriversPracticingNumberBadge count={event.practicingDriversCount} />
    </div>
  );
};

type BadgeProps = { count: number };
export const AverageGridSizeBadge: FC<BadgeProps> = ({ count }) => {
  const { euiTheme } = useEuiThemeTyped();

  return (
    <EuiToolTip content="Average grid size for this race">
      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <EuiIcon type={"stats"} />
        <EuiNotificationBadgeV80ColorHack color={euiTheme.colors.darkShade}>
          <AnimatedDriversCounter countValue={Math.round(count)} />
        </EuiNotificationBadgeV80ColorHack>
      </div>
    </EuiToolTip>
  );
};

export const DriversPracticingNumberBadge: FC<BadgeProps> = ({ count }) => {
  return (
    <EuiToolTip content="Drivers practicing">
      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <EuiIcon type={"training"} />
        <EuiNotificationBadgeV80ColorHack
          color={count > 0 ? "success" : "subdued"}
        >
          <AnimatedDriversCounter countValue={count} />
        </EuiNotificationBadgeV80ColorHack>
      </div>
    </EuiToolTip>
  );
};

export const PreviousRaceDriversCountBadge: FC<BadgeProps> = ({ count }) => {
  return (
    <EuiToolTip content="Drivers registered to last race">
      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <GoHistory />
        <EuiNotificationBadge color="subdued">
          <AnimatedDriversCounter countValue={count} />
        </EuiNotificationBadge>
      </div>
    </EuiToolTip>
  );
};

export const RegisteredDriversBadgeWithDriversList: FC<{
  drivers: RegisteredDriver[];
}> = ({ drivers }) => {
  const [opened, setOpened] = useState(false);

  return (
    <EuiPopover
      // this is because in the table EuiPopover inherits it's lineHeight and displays bad
      style={{ lineHeight: 0.5 }}
      panelPaddingSize={"none"}
      onClick={e => {
        e.stopPropagation();
      }}
      isOpen={opened}
      closePopover={() => setOpened(false)}
      button={
        <EuiToolTip content={opened ? "" : "Click to see the drivers"}>
          <div
            onClick={event => {
              event.stopPropagation();
              setOpened(!opened);
            }}
          >
            <RegisteredDriversBadge count={drivers.length} />
          </div>
        </EuiToolTip>
      }
    >
      <EuiCard title={"Registered drivers"}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            maxHeight: "300px",
            overflow: "auto"
          }}
        >
          {drivers.map(d => (
            <EuiLink key={d.driverId}>
              <Link
                to={`${ROUTES.STATS}/${d.driverId}`}
                target={"_blank"}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  padding: "5px 0"
                }}
              >
                <UserAvatar
                  name={d.steamDisplayName}
                  imageUrl={d.steamAvatarUrl}
                />
                <EuiTitle size={"xxs"}>
                  <span>{d.steamDisplayName}</span>
                </EuiTitle>
              </Link>
            </EuiLink>
          ))}
          {drivers.length < 1 && (
            <div>
              <p>No one registered yet.</p>
              <p>You can be the first one to fill in the grid!</p>
            </div>
          )}
        </div>
      </EuiCard>
    </EuiPopover>
  );
};

export const RegisteredDriversBadge: FC<{ count: number; icon?: IconType }> = ({
  count,
  icon = "userAvatar"
}) => {
  return (
    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
      <EuiIcon type={icon} style={{ cursor: "pointer" }} />
      <EuiNotificationBadge
        color={count > 0 ? "accent" : "subdued"}
        style={{ cursor: "pointer" }}
      >
        <AnimatedDriversCounter countValue={count} />
      </EuiNotificationBadge>
    </div>
  );
};

export const AiNumberBadge: FC<{ event: RaceSeriesEvent }> = ({ event }) => {
  return (
    <EuiToolTip content="AIs">
      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <EuiIcon type={RobotIcon} />
        <EuiNotificationBadge color="subdued">
          <AnimatedDriversCounter
            countValue={
              event.race.registeredDrivers.length >= event.aiCutoffThreshold
                ? 0
                : event.maxAiInRace === 0
                ? 0
                : event.maxAiInRace - event.race.registeredDrivers.length
            }
          />
        </EuiNotificationBadge>
      </div>
    </EuiToolTip>
  );
};
