import React, { FC, useState } from "react";
import {
  EuiButton,
  EuiCard,
  EuiFieldText,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSpacer,
  EuiText,
  EuiTextColor
} from "@elastic/eui";
import { useMutation } from "react-query";
import { deleteUserAccount, logout } from "../../api/auth";
import { useUIContext } from "../../contexts/ui";
import { useUserContext } from "../../contexts/user";
import { useEuiThemeTyped } from "../../theme/eui_overrides";

interface Props {}

export const DangerZone: FC<Props> = () => {
  const [opened, setOpened] = useState(false);
  const [confirmationValue, setConfirmationValue] = useState("");

  const { user } = useUserContext();
  const { euiTheme } = useEuiThemeTyped();
  const { emitSuccessToast, emitErrorToast } = useUIContext();

  const mutation = useMutation(deleteUserAccount);

  if (!user) {
    return null;
  }

  return (
    <EuiCard
      style={{ border: `1px solid ${euiTheme.colors.danger}` }}
      title={<EuiTextColor color={"danger"}>Danger zone</EuiTextColor>}
      layout={"horizontal"}
    >
      <EuiSpacer size={"l"} />

      <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <EuiText size={"m"}>
          Once you delete your account, there is no going back. Please be
          certain.
        </EuiText>

        <div>
          <EuiButton
            color={"danger"}
            isLoading={mutation.isLoading}
            onClick={() => setOpened(true)}
          >
            Delete your account
          </EuiButton>
        </div>
      </div>

      {opened && (
        <EuiModal onClose={() => setOpened(false)} maxWidth={true}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>Delete your account</EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>
            <EuiFormRow
              label={
                <div>
                  Please type <b>{user.emailAddress}</b> to confirm
                </div>
              }
            >
              <EuiFieldText
                value={confirmationValue}
                onChange={e => setConfirmationValue(e.target.value)}
              />
            </EuiFormRow>
          </EuiModalBody>

          <EuiModalFooter>
            <EuiButton
              disabled={confirmationValue !== user.emailAddress}
              isLoading={mutation.isLoading}
              fill
              color={"danger"}
              onClick={() =>
                mutation
                  .mutateAsync()
                  .then(() => {
                    emitSuccessToast({
                      title:
                        "Your account was deleted. See you on another track :]"
                    });
                    return logout();
                  })
                  .then(() => {
                    window.location.assign("/");
                  })
                  .catch((error: any) => {
                    const responseErrorMessage = error?.response?.data?.message;
                    const errorMessage =
                      responseErrorMessage ||
                      error.message ||
                      "Something went wrong. Please try again later or contact support.";
                    emitErrorToast({ text: errorMessage });
                  })
              }
            >
              Permanently delete my account
            </EuiButton>

            <EuiButton
              color={"text"}
              isLoading={mutation.isLoading}
              onClick={() => {
                setConfirmationValue("");
                setOpened(false);
              }}
            >
              Close
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      )}
    </EuiCard>
  );
};
