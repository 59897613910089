import React, { FC, useEffect, useRef } from "react";
import uPlot from "uplot";
import "uplot/dist/uPlot.min.css";
import { EuiText } from "@elastic/eui";
import { ChartDetails } from "./TelemetryCharts";
import "./uplotChart.css";

const legendAsTooltipPlugin = ({
  setHovered
}: {
  setHovered?(value: number): void;
}): uPlot.Plugin => {
  let legendEl: HTMLDivElement;

  const init = (u: uPlot) => {
    legendEl = u.root.querySelector(".u-legend")!;

    legendEl.classList.remove("u-inline");
    legendEl.classList.add("u-legend__tiny");

    uPlot.assign(legendEl.style, {
      textAlign: "left",
      pointerEvents: "none",
      display: "none",
      position: "absolute",
      left: 0,
      top: 0,
      zIndex: 100,
      boxShadow: "2px 2px 10px rgba(0,0,0,0.5)",
      backgroundColor: "rgba(255, 249, 196, 0.92)",
      color: "black",
      width: "100px"
    });

    // hide series color markers
    // const idents = legendEl.querySelectorAll(".u-marker");

    // for (let i = 0; i < idents.length; i++)
    //   idents[i].style.display = "none";

    const overEl = u.over;
    overEl.style.overflow = "visible";

    // move legend into plot bounds
    overEl.appendChild(legendEl);

    // show/hide tooltip on enter/exit
    // overEl.addEventListener("mouseenter", () => {legendEl.style.display = 'block';});
    // overEl.addEventListener("mouseleave", () => {legendEl.style.display = "none";});

    // let tooltip exit plot
    //	overEl.style.overflow = "visible";
  };

  function update(u: uPlot) {
    const { idx } = u.cursor;
    const left = u.cursor.left || 0;

    if (!idx || u.rect.width < left) {
      legendEl.style.display = "none";
      return;
    }

    setHovered?.(idx);

    const translateLeftOff = u.rect.width / 2 < left ? -110 : 10;

    legendEl.style.display = "block";
    legendEl.style.transform = `translate(${left + translateLeftOff}px,0px)`;
  }

  return {
    hooks: {
      init: init,
      setCursor: update
    }
  };
};

interface Props {
  title: string;
  data: { chartData: uPlot.AlignedData; details: ChartDetails[] };
  syncKey: uPlot.SyncPubSub;
  height: number;
  width: number;
  value: number | null;
  setHovered?(value: number): void;
}

export const UplotChart: FC<Props> = props => {
  const ref = useRef<HTMLDivElement>(null);
  const uplotRef = useRef<uPlot>();

  useEffect(() => {
    if (!uplotRef.current) {
      return;
    }

    if (!props.value) {
      uplotRef.current.setCursor({
        left: -10,
        top: -10
      });
      return;
    }

    uplotRef.current.setCursor({
      left: uplotRef.current.valToPos(props.value, "x"),
      top: 20
    });
  }, [props.value]);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    uplotRef.current = new uPlot(
      {
        // title: props.title,
        width: props.width,
        height: props.height,
        axes: [
          {
            size: 10,
            gap: 0,
            values: (u, splits) => splits.map(v => null)
          }
        ],
        cursor: {
          lock: true,
          focus: {
            prox: -1
          },
          sync: {
            key: props.syncKey.key,
            setSeries: true,
            match: [(own, ext) => own === ext, (own, ext) => own === ext],
            filters: {
              pub: () => true
            }
          }
        },
        series: [
          {
            label: "metres"
          },
          ...props.data.details.map(d => ({
            label: d.lapId.toString(),
            points: { show: false },
            stroke: d.color
          }))
        ],
        plugins: [legendAsTooltipPlugin({ setHovered: props.setHovered })],
        scales: { x: { time: false } }
      },
      props.data.chartData,
      ref.current
    );

    return () => {
      uplotRef.current?.destroy();
    };
    // intentional, width and height are updated separately
    // just keep it stable and don't complaing ;p
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!uplotRef.current) {
      return;
    }

    uplotRef.current.setSize({
      width: props.width,
      height: props.height
    });
  }, [props.width, props.height]);

  return (
    <div style={{ display: "flex", position: "relative", width: props.width }}>
      <div ref={ref} style={{ flex: 1 }} />
      <EuiText
        size={"xs"}
        style={{ position: "absolute", bottom: 10, right: 20 }}
      >
        {props.title}
      </EuiText>
    </div>
  );
};
