import React, { FC } from "react";
import { useQuery } from "react-query";
import { fetchTelemetrySessionDetails } from "../../api/profile";
import { useUserContext } from "../../contexts/user";
import { useUIContext } from "../../contexts/ui";
import { Link, useParams } from "react-router-dom";
import { AnimatedIconHeader } from "../shared/AnimatedIconHeader";
import StatsDarkIcon from "../../assets/icons/stats-dark.json";
import StatsLightIcon from "../../assets/icons/stats-light.json";
import {
  EuiButton,
  EuiCard,
  EuiEmptyPrompt,
  EuiLink,
  EuiSkeletonRectangle,
  EuiStat,
  EuiText,
  EuiTitle
} from "@elastic/eui";
import { TelemetrySessionLap } from "../../types/telemetrySessionDetailsDto";
import { TelemetrySessionTypCard } from "./TelemetrySessionTypCard";
import { format } from "date-fns";
import { TrackTooltip } from "./TrackTooltip";
import { SimpleTimeWithClockIcon } from "./SimpleTimeWithClockIcon";
import { ROUTES } from "../../constants/routes";

interface Props {}

export const TelemetrySession: FC<Props> = props => {
  const { user } = useUserContext();
  const { emitErrorToast } = useUIContext();
  const { sessionId, sessionType } = useParams<{
    sessionId: string;
    sessionType: string;
  }>();

  const telemetrySession = useQuery(
    ["telemetrySessionDetails", user?.id, sessionId, sessionType],
    fetchTelemetrySessionDetails,
    {
      refetchOnWindowFocus: false,
      onError: () =>
        emitErrorToast({ title: "We couldn't get telemetry session data" })
    }
  );

  if (telemetrySession.isFetching) {
    return (
      <EuiSkeletonRectangle
        isLoading={true}
        width="100%"
        height="200px"
        borderRadius="s"
      />
    );
  }

  if (!telemetrySession.data || telemetrySession.data.laps.length < 1) {
    return (
      <div>
        <EuiButton>
          <Link to={ROUTES.TELEMETRY}>Go back to telemetry</Link>
        </EuiButton>
        <EuiEmptyPrompt
          iconType="folderExclamation"
          title={<h2>No telemetry for you my son</h2>}
          body={
            <p>
              Looks like there is no telemetry for this session to display. Ask
              discord for help if you thincc it's a mistake.
            </p>
          }
        />
      </div>
    );
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      <div>
        <EuiButton iconSide={"left"} iconType={"arrowLeft"}>
          <Link to={ROUTES.TELEMETRY}>Go back to telemetry</Link>
        </EuiButton>
      </div>

      <AnimatedIconHeader
        title={`Telemetry session details`}
        darkLottieIcon={StatsDarkIcon}
        lightLottieIcon={StatsLightIcon}
      />

      <EuiCard
        titleElement={"span"}
        title={
          <div
            style={{
              display: "flex",
              gap: "20px",
              alignItems: "center",
              flexWrap: "wrap"
            }}
          >
            <EuiTitle>
              <h2>{telemetrySession.data.laps[0].event.raceSeriesName}</h2>
            </EuiTitle>
            -
            <EuiText style={{ display: "flex", gap: "10px" }}>
              Week {telemetrySession.data.laps[0].event.eventWeek} /{" "}
              {telemetrySession.data.laps[0].event.eventYear}{" "}
              <EuiText color={"subdued"}>
                (
                {format(
                  new Date(telemetrySession.data.laps[0].lapDateUtc),
                  "dd.MM.YYY HH:mm"
                )}
                )
              </EuiText>
            </EuiText>
          </div>
        }
        layout={"horizontal"}
      >
        <div
          style={{
            display: "flex",
            gap: "20px",
            justifyContent: "space-evenly",
            marginTop: "20px"
          }}
        >
          <EuiStat
            descriptionElement={"div"}
            title={"Laps"}
            description={
              <div>
                {telemetrySession.data.laps.length}{" "}
                <EuiText
                  style={{ display: "inline" }}
                  color={"subdued"}
                  size={"relative"}
                >
                  {formatCleanLaps(telemetrySession.data.laps)}
                </EuiText>
              </div>
            }
            titleSize={"xxs"}
            reverse={true}
          />
          <EuiStat
            descriptionElement={"div"}
            title={"Track"}
            description={
              <TrackTooltip
                track={telemetrySession.data.laps[0].track}
                sim={telemetrySession.data.laps[0].sim}
              >
                <EuiLink style={{ cursor: "default" }}>
                  {telemetrySession.data.laps[0].track.name}{" "}
                  <EuiText
                    style={{ display: "inline" }}
                    color={"subdued"}
                    size={"relative"}
                  >
                    ({telemetrySession.data.laps[0].track.country})
                  </EuiText>
                </EuiLink>
              </TrackTooltip>
            }
            titleSize={"xxs"}
            reverse={true}
          />
          <EuiStat
            descriptionElement={"div"}
            title={"Car"}
            description={
              <div>
                {telemetrySession.data.laps[0].vehicle.vehicleClassName}{" "}
                <EuiText
                  style={{ display: "inline" }}
                  color={"subdued"}
                  size={"relative"}
                >
                  ({telemetrySession.data.laps[0].vehicle.vehicleModel})
                </EuiText>
              </div>
            }
            titleSize={"xxs"}
            reverse={true}
          />
          <EuiStat
            descriptionElement={"div"}
            title={"Fastest lap"}
            description={
              <SimpleTimeWithClockIcon
                time={[...telemetrySession.data.laps]
                  .sort((a, b) =>
                    a.lapTime.localeCompare(b.lapTime, undefined, {
                      numeric: true
                    })
                  )[0]
                  .lapTime.slice(3, -4)}
              />
            }
            titleSize={"xxs"}
            reverse={true}
          />
          <EuiStat
            descriptionElement={"div"}
            title={"Average lap"}
            description={
              <SimpleTimeWithClockIcon
                time={getAverageLap(telemetrySession.data.laps)}
              />
            }
            titleSize={"xxs"}
            reverse={true}
          />
        </div>
      </EuiCard>
      <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <TelemetrySessionTypCard
          laps={[
            ...telemetrySession.data.laps.filter(
              l => l.sessionType === "practice"
            )
          ].sort((a, b) => a.lapNumber - b.lapNumber)}
          existingAnalyses={telemetrySession.data.exisitingTelemetryAnalyses}
        />
        <TelemetrySessionTypCard
          laps={[
            ...telemetrySession.data.laps.filter(
              l => l.sessionType === "qualify"
            )
          ].sort((a, b) => a.lapNumber - b.lapNumber)}
          existingAnalyses={telemetrySession.data.exisitingTelemetryAnalyses}
        />
        <TelemetrySessionTypCard
          laps={[
            ...telemetrySession.data.laps.filter(l => l.sessionType === "race")
          ].sort((a, b) => a.lapNumber - b.lapNumber)}
          existingAnalyses={telemetrySession.data.exisitingTelemetryAnalyses}
        />
      </div>
    </div>
  );
};

const formatCleanLaps = (laps: TelemetrySessionLap[]) => {
  const cleanLaps = laps.filter(l => l.isValid).length;

  if (cleanLaps === laps.length) {
    return "(100% clean :>)";
  }

  return `(${cleanLaps} clean, ${(cleanLaps / laps.length) * 100}%)`;
};

const getAverageLap = (laps: TelemetrySessionLap[]) => {
  const timeInSeconds =
    laps
      .map(l => {
        const [hours, minutes, seconds] = l.lapTime.split(":").map(Number);

        return hours * 60 * 60 + minutes * 60 + seconds;
      })
      .reduce((acc, t) => acc + t, 0) / laps.length;

  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = timeInSeconds - minutes * 60;

  return `${minutes.toString().padStart(2, "0")}:${seconds
    .toFixed(3)
    .padStart(6, "0")}`;
};
