import {
  EuiButton,
  EuiButtonIcon,
  EuiFlexItem,
  EuiHeader,
  EuiHeaderSection,
  EuiHeaderSectionItem,
  EuiTitle,
  EuiToolTip
} from "@elastic/eui";
import { fetchUserRaceLicense } from "api/profile";
import { TOAST_LIFE_TIME } from "components/GlobalToast/GlobalToast";
import { Logo } from "components/shared/Logo";
import { ROUTES } from "constants/routes";
import { useUIContext } from "contexts/ui";
import { useUserContext } from "contexts/user";
import { useWebSocketServiceContext } from "contexts/webSocketService/webSocketService";
import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import { RaceLicense } from "types/profile";
import { AppMenu } from "./AppMenu/AppMenu";
import { UserMenu } from "./UserMenu/UserMenu";
import { RaceLicenceBadge } from "../../RaceLicenceBadge";
import { WelcomeModalWithTrigger } from "../../WelcomeModal/WelcomeModalWithTrigger";
import { BiLogoPatreon } from "react-icons/bi";
import { SiBuymeacoffee } from "react-icons/si";

const HeaderRoot = styled(EuiHeader)`
  overflow: hidden;
`;

const HeaderSectionVerticalyCentered = styled(EuiHeaderSection)`
  align-items: center;
`;

const Header = () => {
  const { userRaceLicense, setUserRaceLicense } = useUserContext();
  const webSocket = useWebSocketServiceContext();
  const { emitErrorToast, emitSuccessToast } = useUIContext();
  const { user } = useUserContext();
  const { setLoginModalMode } = useUserContext();

  const { euiBreakpoints } = useTheme();
  const isMobile = useMediaQuery({ maxWidth: euiBreakpoints.s });

  useQuery("userRaceLicenceQuery", fetchUserRaceLicense, {
    enabled: user != null,
    refetchOnWindowFocus: false,
    onError: () => emitErrorToast({ title: "Can't get your race license." }),
    onSuccess: raceLicense => setUserRaceLicense(raceLicense)
  });

  useEffect(() => {
    if (user === null) {
      setUserRaceLicense(undefined);
    }
  }, [user]);

  useEffect(() => {
    if (webSocket.connected) {
      webSocket?.instance?.on(
        "DriverRaceLicensePromotion",
        (eventMessage: string) => {
          const updatedRaceLicense = JSON.parse(eventMessage) as RaceLicense;
          setUserRaceLicense(updatedRaceLicense);
          emitSuccessToast({
            title: `You have been promoted to ${updatedRaceLicense.raceLicenseName} race license`,
            toastLifeTimeMs: TOAST_LIFE_TIME.INFINITE
          });
        }
      );
      webSocket?.instance?.on(
        "DriverRaceLicenseDegradation",
        (eventMessage: string) => {
          const updatedRaceLicense = JSON.parse(eventMessage) as RaceLicense;
          setUserRaceLicense(updatedRaceLicense);
          emitErrorToast({
            title: `You have been demoted to ${updatedRaceLicense.raceLicenseName} race license`,
            toastLifeTimeMs: TOAST_LIFE_TIME.INFINITE
          });
        }
      );
    }
  }, [webSocket.connected]);

  useEffect(() => {
    const removeOnReconnectListener = webSocket.onReconnect(() => {
      // userRaceLicenceQuery.refetch();
    });
    return () => removeOnReconnectListener();
  }, [webSocket.onReconnect]);

  return (
    // <div>
    <HeaderRoot position="fixed">
      <EuiHeaderSection grow={false}>
        <EuiHeaderSectionItem border="none">
          <Link to={ROUTES.HOME}>
            <EuiTitle size={isMobile ? "xs" : "m"}>
              <Logo aria-label="Go to home page" />
            </EuiTitle>
          </Link>
        </EuiHeaderSectionItem>
      </EuiHeaderSection>
      <HeaderSectionVerticalyCentered id="headerAdditionalContent" />
      <EuiHeaderSection
        side="right"
        style={{ display: "flex", gap: isMobile ? "2px" : "10px" }}
      >
        <EuiHeaderSectionItem>
          <EuiToolTip content={"Visit our patreon"}>
            <EuiButtonIcon
              href={"https://www.patreon.com/racecraftonline"}
              target={"_blank"}
              iconSize="l"
              style={{ color: "#ff5900" }}
              iconType={BiLogoPatreon}
            />
          </EuiToolTip>
        </EuiHeaderSectionItem>
        <EuiHeaderSectionItem>
          <EuiToolTip content={"Buy me a coffee :)"}>
            <EuiButtonIcon
              href={"https://www.buymeacoffee.com/racecraftonline"}
              target={"_blank"}
              iconSize="l"
              style={{ color: "#ffdd00" }}
              iconType={SiBuymeacoffee}
            />
          </EuiToolTip>
        </EuiHeaderSectionItem>
        <EuiHeaderSectionItem>
          <WelcomeModalWithTrigger />
        </EuiHeaderSectionItem>
        {user == null ? (
          <EuiHeaderSectionItem>
            <EuiButton
              size="s"
              onClick={() => {
                setLoginModalMode('login');
              }}
            >
              Sign in
            </EuiButton>
          </EuiHeaderSectionItem>
        ) : null}
        {userRaceLicense ? (
          <EuiHeaderSectionItem>
            <EuiFlexItem grow={false}>
              <RaceLicenceBadge
                raceLicense={userRaceLicense}
                tooltip="Your current race license"
              />
            </EuiFlexItem>
          </EuiHeaderSectionItem>
        ) : null}
        <EuiHeaderSectionItem border="none">
          {user ? <UserMenu /> : null}
        </EuiHeaderSectionItem>
        <EuiHeaderSectionItem border="none">
          <AppMenu />
        </EuiHeaderSectionItem>
      </EuiHeaderSection>
    </HeaderRoot>
    //   <EuiHeader
    //       position={'fixed'}
    //       sections={[
    //         {
    //           items: [
    //             <EuiHeaderSectionItemButton aria-label="Account menu">
    //               <EuiAvatar type="space" name="Default Space" size="s" />
    //             </EuiHeaderSectionItemButton>,
    //           ],
    //           borders: 'right',
    //         },
    //         {
    //           items: [
    //             <EuiHeaderSectionItemButton
    //               aria-label="News feed: Updates available"
    //               notification={true}
    //             >
    //               <EuiIcon type="cheer" size="m" />
    //             </EuiHeaderSectionItemButton>,
    //           ],
    //           borders: 'none',
    //         },
    //       ]}
    //     />
    // </div>
  );
};

export default Header;
