import { MutableRefObject, useEffect, useRef, useState } from "react";

export const useIsVisible = (
  options: IntersectionObserverInit = {
    root: null, // Use the viewport as the root
    rootMargin: "0px",
    threshold: Array.from(Array(101).keys(), x => x / 100)
  }
): [MutableRefObject<null | HTMLDivElement>, number] => {
  const [visibilityPercentage, setVisibilityPercentage] = useState(0);
  const elementRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const intersectionRatio = entry.intersectionRatio;
        setVisibilityPercentage(intersectionRatio * 100);
      });
    }, options);

    const currentElement = elementRef.current;
    if (currentElement) {
      observer.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
    };
  }, [options]);

  return [elementRef, visibilityPercentage];
};
