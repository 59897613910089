import {
  EuiButtonIcon,
  EuiCopy,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem
} from "@elastic/eui";
import { TOAST_LIFE_TIME } from "components/GlobalToast/GlobalToast";
import { UIContextType } from "contexts/ui";
import { UserContextType } from "contexts/user";
import { WebSocketServiceContextType } from "contexts/webSocketService/webSocketService";
import React, { useEffect } from "react";
import { ConnectionString } from "types/raceSeries";
import { ScreenTypeMenuItem } from "./ScreenTypeMenuItem";

type UsePracticeSessionWsEventsHandler = (
  webSocket: WebSocketServiceContextType,
  userContext: UserContextType,
  uiContext: UIContextType
) => void;

type PracticeSessionMessageProps = {
  practiceConnectionStrings: ConnectionString[];
};
const PracticeSessionMessage = ({
  practiceConnectionStrings
}: PracticeSessionMessageProps) =>
  practiceConnectionStrings[0].screenType === "ServerName" ? (
    <EuiFlexGroup direction="column" gutterSize="m">
      <EuiFlexItem>This is server name you need find to join</EuiFlexItem>
      <EuiFlexItem>
        <EuiFieldText
          fullWidth
          compressed
          append={
            <EuiCopy textToCopy={practiceConnectionStrings[0].connectionString}>
              {copy => (
                <EuiButtonIcon
                  onClick={copy}
                  iconType="copyClipboard"
                  aria-label="Copy server name"
                />
              )}
            </EuiCopy>
          }
          readOnly
          value={practiceConnectionStrings[0].connectionString}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  ) : (
    <EuiFlexGroup gutterSize="m">
      {practiceConnectionStrings.map(connectionString => (
      <EuiFlexGroup direction="column" gutterSize="m">
        <EuiFlexItem>Practice server password is 'rco'</EuiFlexItem>
        <EuiFlexItem>
          <ScreenTypeMenuItem {...connectionString} onClick={window.open} />
        </EuiFlexItem>
      </EuiFlexGroup>
      ))}
    </EuiFlexGroup>
  );

export const useRaceServersWsEventsHandler: UsePracticeSessionWsEventsHandler = (
  webSocket,
  userContext,
  uiContext
) => {
  const {
    setRegisteredRaces,
    nearestRegisteredRace,
    setRegisteredRaceConnectionStrings,
    waitingForPractice,
    setWaitingForPractice
  } = userContext;
  const { emitErrorToast, emitSuccessToast } = uiContext;

  const stopPracticeSessionConnectionStringListener = () => {
    if (webSocket.connected) {
      webSocket?.instance?.off("PracticeSessionReady");
    }
  };

  const startPracticeSessionConnectionStringListener = () => {
    if (webSocket.connected) {
      webSocket?.instance?.on(
        "PracticeSessionReady",
        (eventMessage: string) => {
          const {
            practiceConnectionStrings,
            raceSeriesId
          }: {
            practiceConnectionStrings: ConnectionString[];
            raceSeriesId: number;
          } = JSON.parse(eventMessage);
          if (
            raceSeriesId === waitingForPractice?.raceSeriesId &&
            practiceConnectionStrings.length
          ) {
            stopPracticeSessionConnectionStringListener();
            setWaitingForPractice(null);
            emitSuccessToast({
              title: `Practice session is ready.`,
              text: (
                <PracticeSessionMessage
                  practiceConnectionStrings={practiceConnectionStrings}
                />
              ),
              toastLifeTimeMs: TOAST_LIFE_TIME.INFINITE
            });
          }
        }
      );
    }
  };

  useEffect(() => {
    if (waitingForPractice?.raceSeriesId) {
      startPracticeSessionConnectionStringListener();
    }
  }, [webSocket.connected, waitingForPractice]);

  const startRaceSessionReadyListener = () => {
    if (webSocket.connected) {
      webSocket.instance?.on("RaceSessionReady", (eventPayload: string) => {
        const {
          raceConnectionStrings
        }: { raceConnectionStrings: ConnectionString[] } = JSON.parse(
          eventPayload
        );
        setRegisteredRaceConnectionStrings(raceConnectionStrings);
      });
    }
  };
  const stopRaceSessionReadyListener = () => {
    if (webSocket.connected) {
      webSocket.instance?.off("RaceSessionReady");
    }
  };
  const startNotEnoughCompetitionServersListener = () => {
    if (webSocket.connected) {
      webSocket.instance?.on("NotEnoughCompetitionServers", () => {
        emitErrorToast({
          title: `Our race servers pool has depleted :/`,
          text: `Sorry, but there are not enough servers at the moment to start the race from ${nearestRegisteredRace?.raceSeriesName}. You've been unriegistered from the race. We're working to fix it.`,
          toastLifeTimeMs: TOAST_LIFE_TIME.INFINITE
        });
      });
    }
  };
  const stopNotEnoughCompetitionServersListener = () => {
    if (webSocket.connected) {
      webSocket.instance?.off("NotEnoughCompetitionServers");
    }
  };
  const startEndRaceListener = () => {
    if (webSocket.connected) {
      webSocket.instance?.on("RaceEnd", (eventMessage: string) => {
        const { raceSeriesEventId } = JSON.parse(eventMessage);
        if (nearestRegisteredRace?.raceSeriesEventId === raceSeriesEventId) {
          setRegisteredRaces(null);
        }
      });
    }
  };
  const stopEndRaceListener = () => {
    if (webSocket.connected) {
      webSocket.instance?.off("RaceEnd");
    }
  };
  const startNotEnoughDriversToRunRace = () => {
    if (webSocket.connected) {
      webSocket.instance?.on(
        "NotEnoughDriversToRunRace",
        (eventMessage: string) => {
          const { raceSeriesEventId } = JSON.parse(eventMessage);
          emitErrorToast({
            title: `Not enough drivers :(`,
            text: `Sorry, but there are not enough drivers to start ${nearestRegisteredRace?.raceSeriesName} race.`,
            toastLifeTimeMs: TOAST_LIFE_TIME.INFINITE
          });
        }
      );
    }
  };
  const stopNotEnoughDriversToRunRace = () => {
    if (webSocket.connected) {
      webSocket.instance?.off("NotEnoughDriversToRunRace");
    }
  };

  useEffect(() => {
    return () => {
      stopRaceSessionReadyListener();
      stopNotEnoughCompetitionServersListener();
      stopEndRaceListener();
      stopRaceSessionReadyListener();
      stopNotEnoughDriversToRunRace();
    };
  }, []);

  useEffect(() => {
    if (nearestRegisteredRace?.raceSeriesEventId) {
      startEndRaceListener();
      startNotEnoughCompetitionServersListener();
    }
  }, [webSocket.connected, nearestRegisteredRace?.raceSeriesEventId]);

  useEffect(() => {
    if (
      nearestRegisteredRace &&
      "milisecondsTillRaceStart" in nearestRegisteredRace
    ) {
      startRaceSessionReadyListener();
      startNotEnoughDriversToRunRace();
    }
  }, [webSocket.connected, nearestRegisteredRace?.milisecondsTillRaceStart]);
};
