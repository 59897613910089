import React from "react";
export interface NotificationItemProps {
  item: React.ReactNode;
  notification: React.ReactNode;
}
export const NotificationItem = (props: NotificationItemProps) => {
  const { item, notification } = props;
  return (
    <div style={{ position: "relative" }}>
      {item}
      <span
        style={{
          position: "absolute",
          top: "10px",
          right: "10px"
        }}
      >
        {notification}
      </span>
    </div>
  );
};
