import React from "react";
import { EuiCallOut, EuiFlexGroup, EuiFlexItem } from "@elastic/eui";
import { GlobalMessageType } from "types/layout";
import { MarkdownFormat } from "../../MarkdownFormat";

type GlobalMessageProps = {
  messages: GlobalMessageType[];
};

const GlobalMessage = ({ messages }: GlobalMessageProps) => {
  return (
    <EuiFlexGroup direction="column">
      {messages.map(m => (
        <EuiFlexItem grow={10} key={m.id}>
          <EuiCallOut color={m.messageType}>
            <MarkdownFormat>{m.markdownBody}</MarkdownFormat>
          </EuiCallOut>
        </EuiFlexItem>
      ))}
    </EuiFlexGroup>
  );
};

export default GlobalMessage;
