import React from "react";
import { format } from "date-fns";
import { RaceHistoryEntry } from "types/profile";
import { EuiIcon } from "@elastic/eui";
import styled from "styled-components";

const RaceTimeCellRow = styled.div`
  white-space: nowrap;
`;

export const RaceTimeCell = ({
  raceTimeUtc
}: {
  raceTimeUtc: RaceHistoryEntry["raceTimeUtc"];
}) => {
  return (
    <div>
      <RaceTimeCellRow>
        <EuiIcon type="calendar" />{" "}
        {format(new Date(raceTimeUtc), "dd.MM.yyyy")}
      </RaceTimeCellRow>
      <RaceTimeCellRow>
        <EuiIcon type="clock" /> {format(new Date(raceTimeUtc), "H:mm")}
      </RaceTimeCellRow>
    </div>
  );
};
