import React, { FC } from "react";
import {
  EuiMarkdownFormat,
  EuiMarkdownFormatProps,
  getDefaultEuiMarkdownParsingPlugins,
  getDefaultEuiMarkdownProcessingPlugins
} from "@elastic/eui";
import { YouTubeVideoContainer } from "./YouTubeVideoContainer";
import { LiteYouTubeProps } from "react-lite-youtube-embed";

interface Props extends EuiMarkdownFormatProps {}

export const MarkdownFormat: FC<Props> = props => {
  return (
    <EuiMarkdownFormat
      parsingPluginList={exampleParsingList}
      processingPluginList={exampleProcessingList}
      {...props}
    />
  );
};

// source https://eui.elastic.co/v80.0.0/#/editors-syntax/markdown-plugins
function VideoMarkdownParser(this: any) {
  const Parser = this.Parser;
  const tokenizers = Parser.prototype.blockTokenizers;
  const methods = Parser.prototype.blockMethods;

  function tokenizeVideo(eat: any, value: any, silent: any) {
    if (value.startsWith("!{video") === false) return false;

    const nextChar = value[7];

    if (nextChar !== "{" && nextChar !== "}") return false;

    if (silent) {
      return true;
    }

    const hasConfiguration = nextChar === "{";

    let match = "!{video";
    let configuration = {};

    if (hasConfiguration) {
      let configurationString = "";

      let openObjects = 0;

      for (let i = 7; i < value.length; i++) {
        const char = value[i];
        if (char === "{") {
          openObjects++;
          configurationString += char;
        } else if (char === "}") {
          openObjects--;
          if (openObjects === -1) {
            break;
          }
          configurationString += char;
        } else {
          configurationString += char;
        }
      }

      match += configurationString;
      try {
        configuration = JSON.parse(configurationString);
      } catch (e) {
        const now = eat.now();
        // @ts-ignore
        this.file.fail(`Unable to parse video JSON configuration: ${e}`, {
          line: now.line,
          column: now.column + 7
        });
      }
    }

    match += "}";

    return eat(match)({
      type: "ytVideo",
      ...configuration
    });
  }

  tokenizers.video = tokenizeVideo;
  methods.splice(methods.indexOf("text"), 0, "video");
}

interface IViewMarkdownRenderer extends LiteYouTubeProps {
  maxWidth?: string;
}
const VideoMarkdownRenderer = ({
  maxWidth,
  ...props
}: IViewMarkdownRenderer) => {
  return (
    <div style={{ maxWidth: maxWidth }}>
      <YouTubeVideoContainer {...props} />
    </div>
  );
};
const exampleParsingList = getDefaultEuiMarkdownParsingPlugins();
exampleParsingList.push(VideoMarkdownParser);

const exampleProcessingList = getDefaultEuiMarkdownProcessingPlugins();
exampleProcessingList[1][1].components.ytVideo = VideoMarkdownRenderer;
