import React from "react";
import { useUIContext } from "contexts/ui";
import styled from "styled-components";
import Lottie from "react-lottie-player";
import { motion } from "framer-motion";
import LoaderAnimation from "./loader_animation.json";

const LoaderContainer = styled(motion.div)<{ $offset: boolean }>`
  display: flex;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: ${({ theme }) => theme.euiPageBackgroundColor}e6;
  margin-top: ${({ theme, $offset: offset }) =>
    offset ? theme.euiHeaderChildSize : 0};
  backdrop-filter: blur(20px);
  z-index: ${({ theme }) => theme.euiZLevel6 + 1};
  div {
    cursor: progress;
  }
`;

export const Loader = () => {
  const { globalLoading } = useUIContext();
  return globalLoading.state ? (
    <LoaderContainer
      initial={{ opacity: 0, scale: 2 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 2 }}
      $offset={globalLoading.offset}
    >
      <Lottie
        animationData={LoaderAnimation}
        rendererSettings={{ preserveAspectRatio: "xMidYMid meet" }}
        play={true}
        style={{ width: "100%", height: "100%" }}
      />
    </LoaderContainer>
  ) : null;
};
