import React, { FC, useState } from "react";
import {
  EuiButton,
  EuiFieldText,
  EuiFormControlLayout,
  EuiLink,
  EuiModal,
  EuiModalBody,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiPanel,
  EuiStat,
  EuiText
} from "@elastic/eui";
import {
  ExistingTelemetryAnalyse,
  TelemetrySessionLap
} from "../../types/telemetrySessionDetailsDto";
import { TrackTooltip } from "./TrackTooltip";
import { useMutation } from "react-query";
import { addLapToExistingAnal, createNewAnal } from "../../api/profile";
import { generatePath, useHistory } from "react-router-dom";
import { ROUTES } from "../../constants/routes";

interface Props {
  lap: TelemetrySessionLap;
  existingAnalyses: ExistingTelemetryAnalyse[];
  renderTrigger(setOpened: (opened: boolean) => void): React.ReactNode;
}

export const AddToAnalysisModal: FC<Props> = props => {
  const [opened, setOpened] = useState(false);
  const [description, setDescription] = useState("");

  const history = useHistory();

  const addLapMutation = useMutation(addLapToExistingAnal);
  const createAnalMutation = useMutation(createNewAnal);

  return (
    <>
      {props.renderTrigger(setOpened)}

      {opened && (
        <EuiModal onClose={() => setOpened(false)}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>Add to analysis</EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              <div>
                <EuiFormControlLayout
                  append={
                    <EuiButton
                      disabled={
                        !description ||
                        createAnalMutation.isLoading ||
                        addLapMutation.isLoading
                      }
                      onClick={() => {
                        createAnalMutation
                          .mutateAsync({
                            Laps: [props.lap.lapId],
                            Name: "test name",
                            SimId: props.lap.sim.simId,
                            TrackId: props.lap.track.trackId,
                            VehicleId: props.lap.vehicle.vehicleId
                          })
                          .then(res => {
                            history.push(
                              generatePath(ROUTES.TELEMETRY_ANALYSIS, {
                                analKey: res.analysisKey
                              })
                            );
                          });
                      }}
                    >
                      Create a new analyse
                    </EuiButton>
                  }
                >
                  <EuiFieldText
                    placeholder="Description"
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                    aria-label="Use aria labels when no actual label is in use"
                  />
                </EuiFormControlLayout>
              </div>
              <div>
                {props.existingAnalyses.map(item => (
                  <EuiPanel
                    key={item.key}
                    hasBorder={true}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "20px"
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "20px",
                        justifyContent: "space-evenly",
                        marginTop: "20px"
                      }}
                    >
                      <EuiStat
                        descriptionElement={"div"}
                        title={"Laps"}
                        description={<div>{item.lapIds.length}</div>}
                        titleSize={"xxs"}
                        reverse={true}
                      />
                      <EuiStat
                        descriptionElement={"div"}
                        title={"Track"}
                        description={
                          <TrackTooltip track={item.track} sim={props.lap.sim}>
                            <EuiLink style={{ cursor: "default" }}>
                              {item.track.name}{" "}
                              <EuiText
                                style={{ display: "inline" }}
                                color={"subdued"}
                                size={"relative"}
                              >
                                ({item.track.country})
                              </EuiText>
                            </EuiLink>
                          </TrackTooltip>
                        }
                        titleSize={"xxs"}
                        reverse={true}
                      />
                      <EuiStat
                        descriptionElement={"div"}
                        title={"Car"}
                        description={
                          <div>
                            {item.vehicle.vehicleClassName}{" "}
                            <EuiText
                              style={{ display: "inline" }}
                              color={"subdued"}
                              size={"relative"}
                            >
                              ({item.vehicle.vehicleModel})
                            </EuiText>
                          </div>
                        }
                        titleSize={"xxs"}
                        reverse={true}
                      />
                    </div>
                    <div>
                      {item.lapIds.includes(props.lap.lapId) ? (
                        <EuiButton
                          isLoading={
                            addLapMutation.isLoading ||
                            createAnalMutation.isLoading
                          }
                          onClick={() => {
                            history.push(
                              generatePath(ROUTES.TELEMETRY_ANALYSIS, {
                                analKey: item.key
                              })
                            );
                          }}
                        >
                          Go
                        </EuiButton>
                      ) : (
                        <EuiButton
                          isLoading={
                            addLapMutation.isLoading ||
                            createAnalMutation.isLoading
                          }
                          onClick={() => {
                            addLapMutation
                              .mutateAsync({
                                analysisKey: item.key,
                                laps: [props.lap.lapId]
                              })
                              .then(res => {
                                history.push(
                                  generatePath(ROUTES.TELEMETRY_ANALYSIS, {
                                    analKey: res.analysisKey
                                  })
                                );
                              });
                          }}
                        >
                          Add
                        </EuiButton>
                      )}
                    </div>
                  </EuiPanel>
                ))}
              </div>
            </div>
          </EuiModalBody>
        </EuiModal>
      )}
    </>
  );
};
