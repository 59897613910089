import { fetchNewsDetails } from "api/news";
import React from "react";
import { useQuery } from "react-query";
import { NewsDetail } from "./NewsDetail";

export const NewsDetailContainer = (props: { newsId: number }) => {
  const newsDetailQuery = useQuery(
    ["newsDetailQuery", props.newsId],
    fetchNewsDetails,
    {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 10
    }
  );

  return (
    <NewsDetail
      news={newsDetailQuery.data}
      isLoading={newsDetailQuery.isLoading}
    />
  );
};
