import { QueryFunction } from "react-query";
import {
  RaceSeasonListItem,
  RaceSeriesListItem,
  EventsListItem,
  SeasonStandings,
  RaceSeriesStandings,
  EventStandings
} from "types/standings";
import axios from "utils/axios";

export const fetchRaceSeasonsList: QueryFunction<
  RaceSeasonListItem[]
> = async () => {
  const { data } = await axios.get(
    process.env.REACT_APP_ENDPOINT_DRIVER_PROFILE +
      "/standings/race-seasons-list"
  );
  return data;
};

export const fetchRaceSeriesList: QueryFunction<RaceSeriesListItem[]> = async ({
  queryKey
}) => {
  const [, seasonId] = queryKey;
  const { data } = await axios.get(
    process.env.REACT_APP_ENDPOINT_DRIVER_PROFILE +
      `/standings/race-series-list?seasonId=${seasonId}`
  );
  return data;
};

export const fetchEventsList: QueryFunction<EventsListItem[]> = async ({
  queryKey
}) => {
  const [, seasonId, raceSeriesId] = queryKey;
  const { data } = await axios.get(
    process.env.REACT_APP_ENDPOINT_DRIVER_PROFILE +
      `/standings/race-series-events-list?raceSeriesId=${raceSeriesId}&seasonId=${seasonId}`
  );
  return data;
};

export const fetchSeasonStandings: QueryFunction<SeasonStandings> = async ({
  queryKey
}) => {
  const [, seasonId] = queryKey;
  const { data } = await axios.get<SeasonStandings>(
    process.env.REACT_APP_ENDPOINT_DRIVER_PROFILE +
      `/standings/season/${seasonId}`
  );
  return data;
};

export const fetchSeriesStandings: QueryFunction<RaceSeriesStandings> = async ({
  queryKey
}) => {
  const [, seasonId, seriesId] = queryKey;
  const { data } = await axios.get<RaceSeriesStandings>(
    process.env.REACT_APP_ENDPOINT_DRIVER_PROFILE +
      `/standings/race-series?raceseriesid=${seriesId}&seasonid=${seasonId}`
  );
  return data;
};

export const fetchEventStandings: QueryFunction<EventStandings> = async ({
  queryKey
}) => {
  const [, raceSeriesEventId] = queryKey;
  const { data } = await axios.get<EventStandings>(
    process.env.REACT_APP_ENDPOINT_DRIVER_PROFILE +
      `/standings/race-series-event/${raceSeriesEventId}`
  );
  return data;
};
