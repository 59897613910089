import React, { FC, useState } from "react";
import { WelcomeModal } from "./WelcomeModal";
import { EuiButtonIcon, EuiToolTip } from "@elastic/eui";

interface Props {}

export const WelcomeModalWithTrigger: FC<Props> = () => {
  const [opened, setOpened] = useState(false);

  return (
    <>
      <EuiToolTip content={"Help"}>
        <EuiButtonIcon
          onClick={() => setOpened(true)}
          iconType="help"
          color={"text"}
          iconSize="l"
          aria-label="welcomeModal"
        />
      </EuiToolTip>
      {opened && (
        <WelcomeModal
          onClose={() => setOpened(false)}
          closeButtonText={"Close"}
        />
      )}
    </>
  );
};
