import { EuiFlexGroup, EuiFlexItem } from "@elastic/eui";
import {
  MotionEuiText,
  MotionIconWrapper,
  commonVariants
} from "components/shared/MotionComponents";
import React from "react";
import { IoCarSportSharp  as CarIcon } from "react-icons/io5";
import styled from "styled-components";

const trackNameVariants = {
  initial: { opacity: 0, x: 10 },
  animate: { opacity: 1, x: 0, transition: { type: "spring", delay: 1.1 } },
  exit: { opacity: 0, x: 10 }
};

const pinVariants = commonVariants.fromTopZoomIn(-5);
pinVariants.animate = {
  ...pinVariants.animate,
  transition: { type: "spring", bounce: 0.7, delay: 0.9 }
};

const StyledCarIcon = styled(CarIcon)`
  margin-left: -0.1em;
`;

type VehicleClassProps = {
  vehicleClassName: string;
};

const StyledVehicleClassName = styled("p")`
  font-size: ${({ theme }) => theme.euiFontSizeM};
}`;

export const VehicleClass = ({ vehicleClassName }: VehicleClassProps) => (
  <EuiFlexGroup
    gutterSize="xs"
    alignItems="center"
    wrap={false}
    responsive={false}
  >
    <EuiFlexItem grow={false}>
      <MotionIconWrapper variants={pinVariants} size={2}>
        <StyledCarIcon />
      </MotionIconWrapper>
    </EuiFlexItem>
    <EuiFlexItem>
      <MotionEuiText variants={trackNameVariants}>
        <StyledVehicleClassName>{vehicleClassName}</StyledVehicleClassName>
      </MotionEuiText>
    </EuiFlexItem>
  </EuiFlexGroup>
);