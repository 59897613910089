import {
  EuiAbsoluteTab,
  EuiBadge,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiIcon,
  EuiModal,
  EuiModalBody,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSpacer
} from "@elastic/eui";
import React from "react";
import { Lap, RaceDetails as RaceDetailsType } from "types/profile";

type Driver = RaceDetailsType["drivers"][0];

type LapsDetailsModalProps = {
  driver?: Driver;
  onClose: () => void;
};

const columns: Array<EuiBasicTableColumn<Lap>> = [
  {
    field: "lapNumber",
    name: "Lap number",
  },
  {
    field: "position",
    name: "Position"
  },
  {
    field: "sector1",
    name: "Sector 1",
    // footer: ({ items }:{ items : Lap[]}) => <EuiBadge color="success">{items.find(i => i.sector1.isDriverFastestSector)?.sector1.sectorTime}</EuiBadge>,
    render: (sector1 : Lap['sector1']) => {
      if(sector1.isSessionFastestSector)
      {
        return <EuiBadge color="darkviolet" iconType="starFilled">{sector1.sectorTime}</EuiBadge>
      }
      else if(sector1.isDriverFastestSector)
      {
        return <EuiBadge color="success">{sector1.sectorTime}</EuiBadge>
      }
      return `${sector1.sectorTime}`;
    }
  },
  {
    field: "sector2",
    name: "Sector 2",
    // footer: ({ items }:{ items : Lap[]}) => <EuiBadge color="success">{items.find(i => i.sector2.isDriverFastestSector)?.sector2.sectorTime}</EuiBadge>,
    render: (sector2 : Lap['sector2']) => {
      if(sector2.isSessionFastestSector)
      {
        return <EuiBadge color="darkviolet" iconType="starFilled">{sector2.sectorTime}</EuiBadge>
      }
      else if(sector2.isDriverFastestSector)
      {
        return <EuiBadge color="success">{sector2.sectorTime}</EuiBadge>
      }
      return `${sector2.sectorTime}`;
    }
  },
  {
    field: "sector3",
    name: "Sector 3",
    // footer: ({ items }:{ items : Lap[]}) => <EuiBadge color="success">{items.find(i => i.sector3.isDriverFastestSector)?.sector3.sectorTime}</EuiBadge>,
    render: (sector3 : Lap['sector3']) => {
      if(sector3.isSessionFastestSector)
      {
        return <EuiBadge color="darkviolet" iconType="starFilled">{sector3.sectorTime}</EuiBadge>
      }
      else if(sector3.isDriverFastestSector)
      {
        return <EuiBadge color="success">{sector3.sectorTime}</EuiBadge>
      }
      return `${sector3.sectorTime}`;
    }
  },
  {
    field: "time",
    name: "Lap",
  },

];

export const LapsDetailsModal = ({
  driver,
  onClose
}: LapsDetailsModalProps) => {
  return driver ? (
    <EuiModal onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>Laps details - {driver.driverName}</h1>
          <EuiSpacer size="s" />
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiBasicTable
          compressed
          items={driver.laps}
          rowHeader="lap"
          columns={columns}
        />
      </EuiModalBody>
    </EuiModal>
  ) : null;
};
