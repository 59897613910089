import React, { useCallback, useEffect, useMemo } from "react";
import {
  EuiButton,
  EuiComboBox,
  EuiComboBoxOptionOption,
  EuiFieldPassword,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiIconTip,
  EuiSpacer,
  EuiSwitch,
  EuiToolTip
} from "@elastic/eui";
import SteamAccountDetails from "./SteamAccountDetails";
import { ReactComponent as SteamLogo } from "assets/icons/steam.svg";
import { useUIContext } from "contexts/ui";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Region, RegisterFormFields } from "types/auth";
import { useQuery } from "react-query";
import { getAvailableRegions } from "api/auth";
import styled from "styled-components";
import {
  countryCodesToComboBoxOptions,
  getComboBoxOptionFromCountryCode,
  INTERNATIONAL_CC
} from "../../../utils/countries";

type RegisterFormProps = {
  submitHandler: SubmitHandler<RegisterFormFields>;
};

const HelpTextContainer = styled.div`
  max-width: fit-content;
`;

const linkSteamAccount = () => {
  window.open(
    process.env.REACT_APP_STEAM_REGISTER_PROVIDER + "/auth/steam",
    "_blank",
    "width=800, height=600"
  );
};

const fetchedRegionsToComboBoxOptions = (fetchedRegions?: Region[]) =>
  fetchedRegions?.map?.(({ worldRegionId, worldRegionName }) => ({
    value: worldRegionId,
    label: worldRegionName
  }));

function getComboBoxOptionByValue<T = string>(
  availableComboBoxOptions: EuiComboBoxOptionOption<T>[],
  value: T
) {
  return (
    availableComboBoxOptions.find(
      availableOption => availableOption.value === value
    ) || { vlaue: "", label: "" }
  );
}

export const RegisterForm = ({ submitHandler }: RegisterFormProps) => {
  const {
    handleSubmit,
    register,
    errors,
    clearErrors,
    formState,
    trigger,
    setValue,
    getValues,
    watch,
    control
  } = useForm();

  const setFormValues = useCallback(
    (values: Record<string, boolean | string | undefined>) =>
      Object.entries(values).forEach(([property, value]) =>
        setValue(property, value)
      ),
    [setValue]
  );

  const { emitErrorToast } = useUIContext();

  const getSteamData = useCallback(
    () => getValues(["steamId", "steamDisplayName", "avatarUrl"]),
    [getValues]
  );

  const gotSteamData = useMemo(() => {
    const { steamId, steamDisplayName, avatarUrl } = getSteamData();
    return steamDisplayName && avatarUrl && steamId;
  }, [
    watch("steamId"),
    watch("steamDisplayName"),
    watch("avatarUrl"),
    getSteamData
  ]);

  const regionsQuery = useQuery("regionsQuery", getAvailableRegions, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: fetchedRegionsToComboBoxOptions
  });

  const steamDataMessageHandler = useCallback(
    (event: any) => {
      if (event.origin === process.env.REACT_APP_STEAM_REGISTER_PROVIDER) {
        const { steamId, displayName, avatarUrl, countryCode, ok, steamUser } = event.data;
        if (ok) {
          setFormValues({
            steamId,
            steamDisplayName: displayName,
            avatarUrl,
            countryCode: countryCode || INTERNATIONAL_CC
          });
        } else {
          emitErrorToast({
            title:
              "We couldn't connect your steam account. Please, try again later."
          });
        }
      }
    },
    [setFormValues, emitErrorToast]
  );

  const clearSteamData = () =>
    setFormValues({
      steamId: undefined,
      steamDisplayName: undefined,
      avatarUrl: undefined,
      countryCode: undefined
    });

  useEffect(() => {
    register("prefferedWorldRegion", { required: "Region is required." });
    register("countryCode", { required: "Country is required." });
    register("steamId", { required: "Steam data is required." });
    register("steamDisplayName", { required: "Steam data is required." });
    register("avatarUrl");
    window.addEventListener("message", steamDataMessageHandler, true);
    return () =>
      window.removeEventListener("message", steamDataMessageHandler, true);
  }, []);

  useEffect(() => {
    if (gotSteamData) {
      clearErrors(["steamDisplayName", "steamId", "countryCode"]);
    }
  }, [gotSteamData]);

  return (
    <EuiForm
      data-testid="registerForm"
      component="form"
      onSubmit={handleSubmit(submitHandler)}
      autoComplete="off"
    >
      <EuiFlexGroup
        direction="column"
        alignItems="center"
        gutterSize="s"
        responsive={false}
      >
        <EuiSpacer size="m" />
        <EuiFormRow
          style={{ width: "100%" }}
          isInvalid={Boolean(errors.emailaddress)}
          error={errors.emailaddress?.message}
        >
          <EuiFieldText
            placeholder="E-mail"
            icon={"email"}
            isInvalid={Boolean(errors.emailaddress)}
            name="emailaddress"
            inputRef={register({
              required: "Email is required",
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Entered value does not match email format"
              }
            })}
            aria-label="Email"
            data-testid="emailaddressField"
          />
        </EuiFormRow>
        <EuiFormRow
          style={{ width: "100%" }}
          isInvalid={Boolean(errors.emailaddressConfirmation)}
          error={errors.emailaddressConfirmation?.message}
        >
          <EuiFieldText
            placeholder="E-mail confirmation"
            icon={"empty"}
            isInvalid={Boolean(errors.emailaddressConfirmation)}
            name="emailaddressConfirmation"
            inputRef={register({
              required: "Email confirmation is required",
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Entered value does not match email format"
              },
              validate: value => {
                return value === getValues("emailaddress")
                  ? true
                  : "Emails do not match";
              }
            })}
            aria-label="Email confirmation"
            data-testid="emailaddressConfirmationField"
          />
        </EuiFormRow>
        <EuiSpacer size="m" />

        <EuiFormRow
          style={{ width: "100%" }}
          isInvalid={Boolean(errors.password)}
          error={errors.password?.message}
        >
          <EuiFieldPassword
            name="password"
            inputRef={register({
              required: "Password is required"
            })}
            isInvalid={Boolean(errors.password)}
            placeholder="Password"
            aria-label="Password"
            data-testid="passwordField"
            append={[
              <EuiIconTip content="At least 6 characters long, 1 digit, 1 lowercase letter, 1 uppercase letter" />
            ]}
          />
        </EuiFormRow>
        <EuiSpacer size="m" />

        <EuiFormRow
          style={{ width: "100%" }}
          isInvalid={Boolean(errors.prefferedWorldRegion)}
          error={errors.prefferedWorldRegion?.message}
        >
          <EuiComboBox
            selectedOptions={
              watch("prefferedWorldRegion") && regionsQuery.data
                ? [
                    getComboBoxOptionByValue(
                      regionsQuery.data,
                      watch("prefferedWorldRegion")
                    )
                  ]
                : undefined
            }
            isInvalid={Boolean(errors.prefferedWorldRegion)}
            placeholder="Where do you want to race?"
            singleSelection={{ asPlainText: true }}
            isClearable={false}
            isLoading={regionsQuery.isLoading}
            options={regionsQuery.data}
            onBlur={() => {
              if (formState.isSubmitted) {
                trigger("prefferedWorldRegion");
              }
            }}
            onSearchChange={searchValue => {
              if (searchValue) {
                setValue("prefferedWorldRegion", undefined);
              }
            }}
            onChange={selectedValues => {
              setValue("prefferedWorldRegion", selectedValues[0]?.value);
              clearErrors("prefferedWorldRegion");
            }}
          />
        </EuiFormRow>
        <EuiSpacer size="m" />

        <EuiFormRow
          style={{ width: "100%", display: gotSteamData ? "initial" : "none" }}
          isInvalid={Boolean(errors.countryCode)}
          error={errors.countryCode?.message}
          helpText={
            <HelpTextContainer>
              Your country. If you don't want to disclose your nationality
              select <em>International</em> option.
            </HelpTextContainer>
          }
        >
          <EuiComboBox
            selectedOptions={[
              getComboBoxOptionFromCountryCode(watch("countryCode"))
            ]}
            isInvalid={Boolean(errors.countryCode)}
            placeholder="Select your country"
            singleSelection={{ asPlainText: true }}
            isClearable={false}
            options={countryCodesToComboBoxOptions()}
            onBlur={() => {
              if (formState.isSubmitted) {
                trigger("countryCode");
              }
            }}
            onSearchChange={searchValue => {
              if (searchValue) {
                setValue("countryCode", undefined);
              }
            }}
            onChange={selectedValues => {
              setValue("countryCode", selectedValues[0]?.value);
              clearErrors("countryCode");
            }}
          />
        </EuiFormRow>

        <Controller
          control={control}
          defaultValue={true}
          name="isMarketingAllowed"
          render={({ onChange, onBlur, value }) => (
            <EuiFormRow
              helpText={
                <div>
                  We will not spam you. You'll get info about special events,
                  where you could race with great number of other drivers or
                  cool new features we release. You shouldn't expect more than
                  one mail per month :)
                </div>
              }
            >
              <EuiSwitch
                label="I would like to be in touch with rco"
                checked={value}
                onChange={event => {
                  onChange(event.target.checked);
                }}
                onBlur={onBlur}
              />
            </EuiFormRow>
          )}
        />

        <EuiSpacer size="m" />

        <EuiFormRow
          isInvalid={
            Boolean(errors.steamDisplayName) || Boolean(errors.steamId)
          }
          error={errors.steamDisplayName?.message || errors.steamId?.message}
        >
          <>
            {gotSteamData ? (
              <SteamAccountDetails
                steamDetails={getSteamData()}
                clear={clearSteamData}
              />
            ) : (
              <EuiToolTip
                position="bottom"
                content="This button will open steamcommunity.com in separate window."
              >
                <EuiButton iconType={SteamLogo} onClick={linkSteamAccount}>
                  Link your steam account
                </EuiButton>
              </EuiToolTip>
            )}
          </>
        </EuiFormRow>
        <EuiSpacer size="xxl" />
        <EuiFlexItem>
          <EuiButton data-testid="registerButton" type="submit">
            Sign up
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiForm>
  );
};
