import { QueryFunction } from "react-query";
import {
  BestTimesForVehicleResponseDto,
  DrivenTrackResponseDto,
  DrivenVehicleModelResponseDto as DrivenVehicleClassResponseDto,
  DriverPublicProfile,
  DriverRatingsHistory,
  DriverSEloHistory,
  DriverSeriesStatsDto,
  DriverYearlyStatsDto,
  LeaderboardEntry,
  OwnedRaceLicense,
  RaceDetails,
  RaceHistoryEntry,
  RaceLicense,
  UserRatings
} from "types/profile";
import axios from "utils/axios";
import { TelemetrySessionsResponseDto } from "../types/telemetrySessionsResponseDto";
import { TelemetrySessionDetailsDto } from "../types/telemetrySessionDetailsDto";
import { AddToExistingAnalysisRequestDto } from "../types/addToExistingAnalysisRequestDto";
import { CreateNewAnal } from "../types/createNewAnal";
import { AnalDetailsResponseDto } from "../types/analDetailsResponseDto";
import { TelemetryTicksResponseDto } from "../types/telemetryTicksResponseDto";
import { RemoveLapFromAnalysisRequestDto } from "../types/removeLapFromAnalysisRequestDto";
import { buildSearchParams } from "../utils/url";

export const fetchUserRatings = async () => {
  const response = await axios.get<UserRatings>(
    process.env.REACT_APP_ENDPOINT_DRIVER_PROFILE + "/driverratings"
  );
  return response.data;
};

export const fetchLeaderboard = async () => {
  const response = await axios.get<LeaderboardEntry[]>(
    process.env.REACT_APP_ENDPOINT_DRIVER_PROFILE + "/ratingsleaderboard"
  );
  return response.data;
};

export const fetchUserRaceLicense = async () => {
  const response = await axios.get<RaceLicense>(
    process.env.REACT_APP_ENDPOINT_DRIVER_PROFILE + "/driverracelicense"
  );
  return response.data;
};

export const fetchDriverPublicProfile: QueryFunction<DriverPublicProfile> = async ({
  queryKey
}) => {
  const [, driverId] = queryKey;
  const requestUrl =
    process.env.REACT_APP_ENDPOINT_DRIVER_PROFILE +
    "/driverprofile" +
    (driverId ? `/${driverId}` : "");
  const response = await axios.get<DriverPublicProfile>(requestUrl);
  return response.data;
};

export const fetchSEloHistory: QueryFunction<DriverSEloHistory> = async ({
  queryKey
}) => {
  const [, driverId] = queryKey;
  const requestUrl =
    process.env.REACT_APP_ENDPOINT_DRIVER_PROFILE +
    "/driverratingshistory/selo" +
    (driverId ? `/${driverId}` : "");
  const response = await axios.get<DriverSEloHistory>(requestUrl);
  return response.data;
};

export const fetchRatingsHistory: QueryFunction<DriverRatingsHistory> = async ({
  queryKey
}) => {
  const [, driverId, raceLicenseId] = queryKey;
  const requestUrl =
    process.env.REACT_APP_ENDPOINT_DRIVER_PROFILE +
    "/driverratingshistory/v3" +
    (driverId ? `/${driverId}` : "") +
    (raceLicenseId ? `/${raceLicenseId}` : "");
  const response = await axios.get<DriverRatingsHistory>(requestUrl);
  return response.data;
};

// export const fetchDriverOwnedLicenses = async () => {
//   const response = await axios.get<OwnedRaceLicense[]>(
//     process.env.REACT_APP_ENDPOINT_DRIVER_PROFILE + "/driverracelicense/owned"
//   );
//   return response.data;
// };

export const fetchDriverOwnedLicenses: QueryFunction<
  OwnedRaceLicense[]
> = async ({ queryKey }) => {
  const [, driverId] = queryKey;
  const requestUrl =
    process.env.REACT_APP_ENDPOINT_DRIVER_PROFILE +
    "/driverracelicense/v2/owned" +
    (driverId ? `/${driverId}` : "");
  const response = await axios.get<OwnedRaceLicense[]>(requestUrl);
  return response.data;
};

export const fetchRaceHistory: QueryFunction<RaceHistoryEntry[]> = async ({
  queryKey
}) => {
  const [, driverId] = queryKey;
  const requestUrl =
    process.env.REACT_APP_ENDPOINT_DRIVER_PROFILE +
    "/racehistory/v2" +
    (driverId ? `/${driverId}` : "");
  const response = await axios.get<RaceHistoryEntry[]>(requestUrl);
  return response.data;
};

// export const fetchRaceHistory = async () => {
//   const response = await axios.get<RaceHistoryEntry[]>(
//     process.env.REACT_APP_ENDPOINT_DRIVER_PROFILE + "/racehistory"
//   );
//   return response.data;
// };

export const fetchRaceDetails: QueryFunction<RaceDetails> = async ({
  queryKey
}) => {
  const [, raceId] = queryKey;
  const response = await axios.get(
    `${process.env.REACT_APP_ENDPOINT_DRIVER_PROFILE}/racehistory/${raceId}`
  );
  return response.data;
};

export const fetchDriverYearlyStats: QueryFunction<DriverYearlyStatsDto> = async ({
  queryKey
}) => {
  const [, driverId] = queryKey;
  const response = await axios.get<DriverYearlyStatsDto>(
    `${process.env.REACT_APP_ENDPOINT_DRIVER_PROFILE}/driverstats/${driverId}`
  );

  return response.data;
};

export const fetchDriverSeriesStats: QueryFunction<DriverSeriesStatsDto> = async ({
  queryKey
}) => {
  const [, driverId, seasonId] = queryKey;
  const response = await axios.get<DriverSeriesStatsDto>(
    `${process.env.REACT_APP_ENDPOINT_DRIVER_PROFILE}/driverstats/${driverId}/season/${seasonId}`
  );

  return response.data;
};

export const fetchDrivenVehicleModels: QueryFunction<DrivenVehicleClassResponseDto> = async ({
  queryKey
}) => {
  const [, driverId] = queryKey;
  const response = await axios.get<DrivenVehicleClassResponseDto>(
    `${process.env.REACT_APP_ENDPOINT_DRIVER_PROFILE}/besttimes/driven-vehicle-classes/${driverId}`
  );

  return response.data;
};

export const fetchBestTimesForVehicle: QueryFunction<BestTimesForVehicleResponseDto> = async ({
  queryKey
}) => {
  const [, driverId, vehicleClassId, trackId] = queryKey;

  const searchParams = new URLSearchParams();
  if (vehicleClassId) {
    searchParams.append("vehicleclassid", vehicleClassId.toString());
  }
  if (trackId) {
    searchParams.append("trackId", trackId.toString());
  }

  const response = await axios.get<BestTimesForVehicleResponseDto>(
    `${
      process.env.REACT_APP_ENDPOINT_DRIVER_PROFILE
    }/besttimes/${driverId}?${searchParams.toString()}`
  );

  return response.data;
};

export const fetchDrivenTracks: QueryFunction<DrivenTrackResponseDto> = async ({
  queryKey
}) => {
  const [, driverId] = queryKey;
  const response = await axios.get<DrivenTrackResponseDto>(
    `${process.env.REACT_APP_ENDPOINT_DRIVER_PROFILE}/besttimes/driven-tracks/${driverId}`
  );

  return response.data;
};

export const fetchTelemetrySessions: QueryFunction<TelemetrySessionsResponseDto> = async ({
  queryKey
}) => {
  const [
    ,
    driverId,
    simId,
    vehicleClassId,
    carId,
    trackId,
    pageNumber,
    pageSize
  ] = queryKey;

  const searchParams = new URLSearchParams();
  if (simId) {
    searchParams.append("simId", simId.toString());
  }
  if (vehicleClassId) {
    searchParams.append("vehicleClassId", vehicleClassId.toString());
  }
  if (carId) {
    searchParams.append("vehicleId", carId.toString());
  }
  if (trackId) {
    searchParams.append("trackId", trackId.toString());
  }
  if (pageNumber) {
    searchParams.append("pageNumber", pageNumber.toString());
  }
  if (pageSize) {
    searchParams.append("pageSize", pageSize.toString());
  }

  const response = await axios.get<TelemetrySessionsResponseDto>(
    `${
      process.env.REACT_APP_ENDPOINT_DRIVER_PROFILE
    }/telemetry/driver/${driverId}/sessions?${searchParams.toString()}`
  );

  return response.data;
};

export const fetchTelemetrySessionDetails: QueryFunction<TelemetrySessionDetailsDto> = async ({
  queryKey
}) => {
  const [, driverId, sessionId, sessionType] = queryKey;
  const response = await axios.get<TelemetrySessionDetailsDto>(
    `${process.env.REACT_APP_ENDPOINT_DRIVER_PROFILE}/telemetry/driver/${driverId}/session/${sessionType}/${sessionId}`
  );

  return response.data;
};

export const fetchAnalDetails: QueryFunction<AnalDetailsResponseDto> = async ({
  queryKey
}) => {
  const [, analKey] = queryKey;
  const response = await axios.get<AnalDetailsResponseDto>(
    `${process.env.REACT_APP_ENDPOINT_DRIVER_PROFILE}/telemetry/analysis/${analKey}`
  );

  return response.data;
};

export const fetchLapDetails: QueryFunction<TelemetryTicksResponseDto> = async ({
  queryKey
}) => {
  const [, lapId, properties] = queryKey;

  const searchParams = buildSearchParams({ property: properties.split(",") });

  const response = await axios.get<TelemetryTicksResponseDto>(
    `${
      process.env.REACT_APP_ENDPOINT_DRIVER_PROFILE
    }/telemetry/lap/${lapId}?${searchParams.toString()}`
  );

  return response.data;
};

export const createNewAnal = async (payload: CreateNewAnal) => {
  const response = await axios.post<{ analysisKey: string }>(
    `${process.env.REACT_APP_ENDPOINT_DRIVER_PROFILE}/telemetry/analysis`,
    payload
  );

  return response.data;
};

export const addLapToExistingAnal = async (
  payload: AddToExistingAnalysisRequestDto
) => {
  const response = await axios.put<{ analysisKey: string }>(
    `${process.env.REACT_APP_ENDPOINT_DRIVER_PROFILE}/telemetry/analysis/add-laps`,
    payload
  );

  return response.data;
};

export const removeLapFromAnal = async (
  payload: RemoveLapFromAnalysisRequestDto
) => {
  const response = await axios.put<{ AnalysisKey: string; Laps: number[] }>(
    `${process.env.REACT_APP_ENDPOINT_DRIVER_PROFILE}/telemetry/analysis/remove-laps`,
    payload
  );

  return response.data;
};
