import React, { FC, useState } from "react";
import { useQuery } from "react-query";
import { fetchRaceSeasonsList } from "../../../api/standings";
import { useUIContext } from "../../../contexts/ui";
import { fetchDriverSeriesStats } from "../../../api/profile";
import {
  EuiBasicTable,
  EuiCard,
  EuiEmptyPrompt,
  EuiLink,
  EuiLoadingSpinner,
  EuiSkeletonRectangle,
  EuiSuperSelect
} from "@elastic/eui";
import { DriverRaceSeries } from "../../../types/profile";
import { TableHeaderCellWithInfoIcon } from "../../shared/TableHeaderCellWithInfoIcon";
import { useTablePagination } from "../../../hooks/useTablePagination";
import { useTableSort } from "../../../hooks/useTableSort";
import { ROUTES } from "../../../constants/routes";
import { Link } from "react-router-dom";

interface Props {
  driverId: string;
}

export const SeriesStats: FC<Props> = props => {
  const { emitErrorToast } = useUIContext();

  const [selectedSeasonId, setSelectedSeasonId] = useState<number>();

  const seasonsQuery = useQuery("raceSeasonsListQuery", fetchRaceSeasonsList, {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    onSuccess: data => {
      setSelectedSeasonId(
        data.find(season => season.isCurrentSeason)?.seasonId ||
          data[0]?.seasonId
      );
    },
    onError: () =>
      emitErrorToast({ title: "We couldn't get the race seasons list" })
  });
  const seriesQuery = useQuery(
    ["driverRaceSeries", props.driverId, selectedSeasonId],
    fetchDriverSeriesStats,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled: !!selectedSeasonId,
      onError: () =>
        emitErrorToast({ title: "We couldn't get race series details" })
    }
  );

  const { pagination, pageChangeHandler, getPage } =
    useTablePagination<DriverRaceSeries>({
      initialPageSize: 5,
      controlledTotalCount: seriesQuery.data?.raceSeries.length
    });
  const { sortField, sortDirection, sortChangeHandler, sortData } =
    useTableSort<DriverRaceSeries>({
      initialSortField: "raceSeriesName",
      initialSortDirection: "desc"
    });

  if (seasonsQuery.isFetching || seriesQuery.status === "loading") {
    return (
      <EuiSkeletonRectangle
        isLoading={true}
        width="100%"
        height="200px"
        borderRadius="s"
      />
    );
  }

  if (!selectedSeasonId || !seasonsQuery.data || seasonsQuery.data.length < 1) {
    return (
      <EuiCard title={"Series stats"} layout={"horizontal"}>
        <EuiEmptyPrompt
          iconType="folderExclamation"
          title={<h2>No series stats</h2>}
          body={<p>Looks like the is no seasons available for you yet</p>}
        />
      </EuiCard>
    );
  }

  return (
    <EuiCard
      title={
        <div
          style={{
            display: "flex",
            gap: "20px",
            alignItems: "center",
            flexWrap: "wrap"
          }}
        >
          <span>Series stats</span>
          <EuiSuperSelect
            style={{ minWidth: "250px" }}
            options={seasonsQuery.data.map(season => ({
              value: season.seasonId.toString(),
              inputDisplay: season.seasonName
            }))}
            valueOfSelected={selectedSeasonId.toString()}
            onChange={value => setSelectedSeasonId(Number(value))}
          />
        </div>
      }
      layout={"horizontal"}
    >
      <div
        style={{
          position: "relative",
          opacity: seriesQuery.isFetching ? 0.5 : 1
        }}
      >
        {seriesQuery.isFetching && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, 0)"
            }}
          >
            <EuiLoadingSpinner size="xl" />
          </div>
        )}
        {(!seriesQuery.data || seriesQuery.data.raceSeries.length < 1) && (
          <EuiEmptyPrompt
            iconType="folderExclamation"
            title={<h2>No stats for selected season</h2>}
            body={<p>Looks like there is no stats to display</p>}
          />
        )}
        {seriesQuery.data && seriesQuery.data.raceSeries.length > 0 && (
          <EuiBasicTable<DriverRaceSeries>
            style={{ maxHeight: "400px", overflow: "auto" }}
            items={getPage(sortData(seriesQuery.data.raceSeries))}
            rowHeader="name"
            columns={[
              {
                field: "raceSeriesName",
                name: "Series name",
                render: (item, record) => (
                  <EuiLink>
                    <Link
                      to={`${ROUTES.STANDINGS}/?season=${selectedSeasonId}&series=${record.raceSeriesId}`}
                    >
                      {item}
                    </Link>
                  </EuiLink>
                )
              },
              {
                field: "races",
                name: "Races"
              },
              {
                field: "wins",
                name: (
                  <TableHeaderCellWithInfoIcon
                    title={"Wins"}
                    tooltip={"Wins (wins percentage)"}
                  />
                ),
                render: (item, record) => {
                  return (
                    <span>
                      <span>{item}</span> <span>({record.winPercentage}%)</span>
                    </span>
                  );
                }
              },
              {
                field: "topFive",
                name: (
                  <TableHeaderCellWithInfoIcon
                    title={"Top5"}
                    tooltip={"Top5 (Top5 percentage)"}
                  />
                ),
                render: (item, record) => {
                  return (
                    <span>
                      <span>{item}</span>{" "}
                      <span>({record.topFivePercentage}%)</span>
                    </span>
                  );
                }
              },
              {
                field: "poles",
                name: "Poles"
              },
              {
                field: "averageNumberOfIncidents",
                name: (
                  <TableHeaderCellWithInfoIcon
                    title={"aNoI"}
                    tooltip={"Average number of Incidends"}
                  />
                )
              },
              {
                field: "averageIncidentsPerMinute",
                name: (
                  <TableHeaderCellWithInfoIcon
                    title={"aIpM"}
                    tooltip={"Average incidends per minute"}
                  />
                )
              },
              {
                field: "averageStart",
                name: (
                  <TableHeaderCellWithInfoIcon
                    title={"aS"}
                    tooltip={"Average start"}
                  />
                )
              },
              {
                field: "averageFinish",
                name: (
                  <TableHeaderCellWithInfoIcon
                    title={"aF"}
                    tooltip={"Average finish"}
                  />
                )
              },
              {
                field: "pointsPerRace",
                name: (
                  <TableHeaderCellWithInfoIcon
                    title={"PpR"}
                    tooltip={"Points per race"}
                  />
                )
              },
              {
                field: "laps",
                name: "Laps"
              },
              {
                field: "lapsLed",
                name: (
                  <TableHeaderCellWithInfoIcon
                    title={"Laps led"}
                    tooltip={"Laps led (laps led percentage)"}
                  />
                ),
                render: (item, record) => {
                  return (
                    <span>
                      <span>{item}</span>{" "}
                      <span>({record.lapsLedPercentage}%)</span>
                    </span>
                  );
                }
              }
            ]}
            pagination={pagination}
            onChange={({ page, sort }) => {
              if (page) {
                pageChangeHandler(page);
              }
              if (sort) {
                sortChangeHandler(sort);
              }
            }}
            tableLayout="auto"
            sorting={{
              enableAllColumns: true,
              sort: {
                field: sortField,
                direction: sortDirection
              }
            }}
          />
        )}
      </div>
    </EuiCard>
  );
};
