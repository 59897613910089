import { EuiFlexGroup, EuiFlexItem, EuiPanel } from "@elastic/eui";
import React from "react";
import styled, { useTheme } from "styled-components";
import { RaceSerie } from "types/raceSeries";
import { CoverImage } from "./CoverImage";
import { RaceCardContent } from "./RaceCardContent/RaceCardContent";
import { RaceCardFooter } from "./RaceCardFooter";
import { useMediaQuery } from "react-responsive";
import { RaceSeriesDetailsModalWithTrigger } from "../../RaceSeriesDetails/RaceSeriesDetails";

const Panel = styled(EuiPanel)`
  overflow: hidden;
  position: relative;
  cursor: default !important;
  transition: 0.2s all ease-in-out;
  &:hover {
    transform: scale(1.01);
    .coverImageShadowContainer {
      filter: drop-shadow(5px 0px 8px ${({ theme }) => theme.euiShadowColor});
    }
    .blurredBackground {
      filter: blur(60px)
        ${({ theme: { variant } }) =>
          variant === "light" ? "saturate(10)" : null};
      opacity: ${({ theme: { varinat } }) =>
        varinat === "dark" ? 0.28 : 0.08};
    }
    .coverImage {
      opacity: 1;
    }
  }
`;
const BlurredBackground = styled.div<{ image: string }>`
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  transition: 0.5s all ease-in-out;
  background: url(${props => props.image}) center top / cover;
  opacity: ${({ theme: { varinat } }) => (varinat === "dark" ? 0.3 : 0.1)};
  filter: blur(50px)
    ${({ theme: { variant } }) => (variant === "light" ? "saturate(10)" : null)};
`;

type RaceCardProps = {
  raceSerie: RaceSerie;
};
export const RaceCard: React.FC<RaceCardProps> = React.memo(({ raceSerie }) => {
  const { euiBreakpoints } = useTheme();
  const isMobile = useMediaQuery({ maxWidth: euiBreakpoints.s });

  return (
    <EuiFlexItem
      style={{
        minWidth: isMobile ? "300px" : "400px",
        maxWidth: isMobile ? "350px" : "400px"
      }}
    >
      <Panel paddingSize="none" hasShadow>
        <BlurredBackground
          image={`${raceSerie.coverImageUrl}`}
          className="blurredBackground"
        />
        <RaceSeriesDetailsModalWithTrigger
          raceSeriesId={raceSerie.id}
          eventId={raceSerie.event.eventId}
          raceId={raceSerie.event.race.raceId}
          renderTrigger={setOpened => (
            <EuiFlexGroup
              direction={"column"}
              gutterSize={"none"}
              onClick={() => setOpened(true)}
              style={{ cursor: "pointer", position: "relative", zIndex: 9 }}
            >
              <CoverImage
                sim={raceSerie.sim}
                imageUrl={raceSerie.coverImageUrl}
                vehicleClasses={raceSerie.event.raceSeriesVehicleClassGroups.groups.flatMap(
                  g => g.vehicleClasses
                )}
                className="coverImageShadowContainer"
              />
              <RaceCardContent
                startAtUtc={raceSerie.event.race.startAtUtc}
                raceFrequency={raceSerie.humanReadeableFrequency}
                raceSessionLength={raceSerie.event.raceSessionDurationInMinutes}
                carSetup={raceSerie.carSetup}
                trackName={raceSerie.event.track.trackName}
                event={raceSerie.event}
                seriesName={raceSerie.name}
                previousRace={raceSerie.previousRace}
                sim={raceSerie.sim}
                raceSeries={raceSerie}
              />
            </EuiFlexGroup>
          )}
        />
        <RaceCardFooter raceSeries={raceSerie} />
      </Panel>
    </EuiFlexItem>
  );
});
