import { registerToRace, unregisterRaceSerie } from "api/raceSeries";
import axios from "axios";
import { useRaceSeriesContext } from "components/RaceSeries/raceSeriesContext";
import { useUIContext } from "contexts/ui";
import { useUserContext } from "contexts/user";
import { useCallback } from "react";
import { RegisteredRace } from "types/raceSeries";

export const useRegisterRace = () => {
  const { toggleRegisteredRaceLoading } = useRaceSeriesContext();
  const { setRegisteredRaces } = useUserContext();
  const { setLoginModalMode } = useUserContext();
  const { emitErrorToast } = useUIContext();

  const registerRace = useCallback(
    (raceId: number, classGroup: number) => {
      return new Promise(async (resolve, reject) => {
        try {
          toggleRegisteredRaceLoading({ raceId });
          const registeredRace = await registerToRace(raceId, classGroup);
          setRegisteredRaces(prevRegisteredRaces =>
            [...(prevRegisteredRaces || []), registeredRace].sort()
          );
        } catch (error: any) {
          if (
            axios.isAxiosError(error) &&
            error.response?.data?.conflictingEvents
          ) {
            reject({
              conflictingEvents: error.response?.data.conflictingEvents
            });
            return;
          }

          if (error.response?.status === 401) {
            setLoginModalMode("login");
            return;
          }

          emitErrorToast({
            title: `Can't register to the event. ${error.response?.data || ""}`
          });
        } finally {
          toggleRegisteredRaceLoading({ raceId });
        }
      });
    },
    [
      emitErrorToast,
      setLoginModalMode,
      toggleRegisteredRaceLoading,
      setRegisteredRaces
    ]
  );

  const unregisterRace = useCallback(
    async (eventId: number) => {
      try {
        toggleRegisteredRaceLoading({ raceId: eventId });
        await unregisterRaceSerie(eventId);
        setRegisteredRaces(prevRegisteredRaces =>
          [...(prevRegisteredRaces || [])].filter(
            (race: RegisteredRace) => race.raceSeriesEventId !== eventId
          )
        );
      } catch (error: any) {
        emitErrorToast({
          title: `Can't unregister from the event. ${
            error.response?.data || ""
          }`
        });
      }

      toggleRegisteredRaceLoading({ raceId: eventId });
    },
    [emitErrorToast, toggleRegisteredRaceLoading, setRegisteredRaces]
  );

  return {
    registerRace: registerRace,
    unregisterRace: unregisterRace
  };
};
