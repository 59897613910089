import React, { CSSProperties, FC } from "react";
import { EuiNotificationBadge } from "@elastic/eui";
import { useEuiThemeTyped } from "../../theme/eui_overrides";
import { EuiNotificationBadgeProps } from "@elastic/eui/src/components/badge/notification_badge/badge_notification";

interface Props extends Omit<EuiNotificationBadgeProps, "color"> {
  color: "accent" | "success" | "subdued" | CSSProperties["backgroundColor"];
  children: React.ReactNode;
}

// hack to add custom color to the badge. Done mainly due to the fact that you can f00kin find this `subdued` color. It's totally custom created just for the badge internally...
// todo remove with migration to eui > 90 or sth
export const EuiNotificationBadgeV80ColorHack: FC<Props> = props => {
  const { euiTheme } = useEuiThemeTyped();

  if (props.color === "success") {
    return (
      <EuiNotificationBadge
        style={{
          backgroundColor: euiTheme.colors.success
        }}
        color={"accent"}
      >
        {props.children}
      </EuiNotificationBadge>
    );
  }

  if (props.color === "accent" || props.color === "subdued") {
    return (
      <EuiNotificationBadge color={props.color as "accent" | "subdued"}>
        {props.children}
      </EuiNotificationBadge>
    );
  }

  return (
    <EuiNotificationBadge
      style={{
        backgroundColor: props.color
      }}
      color={"accent"}
    >
      {props.children}
    </EuiNotificationBadge>
  );
};
