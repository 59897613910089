import React, { FC, useEffect } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useUserContext } from "../../contexts/user";
import { User } from "../../types/auth";
import { ROUTES } from "../../constants/routes";
import Banner from "../Layout/Banner/Banner";
import styled, { useTheme } from "styled-components";

interface Props extends RouteProps {
  documentTitle?: string;
  roles?: User["userRoles"];
  onlyForAuthenticatedUsers?: boolean;
  fullWidth?: boolean;
}

export const AppGlobalRoute: FC<Props> = ({ fullWidth, ...appRouteProps }) => {
  const theme = useTheme();

  if (fullWidth) {
    return (
      <div style={{ width: "100%", marginTop: theme.euiHeaderHeight }}>
        <AppRoute {...appRouteProps} />
      </div>
    );
  }

  return (
    <>
      <GlobalLayoutItem>{<Banner />}</GlobalLayoutItem>
      <GlobalLayoutItem>
        <AppRoute {...appRouteProps} />
      </GlobalLayoutItem>
    </>
  );
};

export const AppRoute: FC<Props> = ({
  documentTitle = "racecraft.online - Sim Racing Ranked Matchmaking System for Automobilista 2",
  roles,
  onlyForAuthenticatedUsers,
  ...routeProps
}) => {
  const { user } = useUserContext();

  useEffect(() => {
    document.title = documentTitle;
  }, [documentTitle]);

  if (onlyForAuthenticatedUsers && !user) {
    return <Redirect to={ROUTES.HOME} />;
  }

  if (roles && !roles.some(r => user?.userRoles.includes(r))) {
    return <Redirect to={ROUTES.HOME} />;
  }

  return <Route {...routeProps} />;
};

const GlobalLayoutItem = styled("div")`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.euiSizeXXL};
  padding-top: ${({ theme }) => theme.euiSizeL};
  width: 100%;
  max-width: 1400px;
  @media (max-width: ${({ theme }) => theme.euiBreakpoints.s}) {
    padding: ${({ theme }) => theme.euiSize};
    padding-top: ${({ theme }) => theme.euiSizeL};
  }
`;
