import React from "react";
import { EuiTitle } from "@elastic/eui";
import { HTMLMotionProps, motion, Variants } from "framer-motion";
import styled from "styled-components";

export const MotionDiv: React.FC<HTMLMotionProps<"div">> = ({
  children,
  ...props
}) => <motion.div {...props}>{children}</motion.div>;

export const MotionIconWrapper = motion.custom(styled.div<{
  size: number;
  flex?: boolean;
}>`
  font-size: ${({ size }) => size}em;
  height: 1em;
  display: ${({ flex }) => (flex ? "flex" : "block")};
`);

export const MotionEuiText: React.FC<HTMLMotionProps<"div">> = ({
  children,
  ...props
}) => (
  <motion.div {...props} className="euiText euiText--medium">
    {children}
  </motion.div>
);

export const MotionEuiTitle: React.FC<HTMLMotionProps<"h1">> = ({
  children,
  ...props
}) => (
  <EuiTitle size="l">
    <motion.h1 {...props}>{children}</motion.h1>
  </EuiTitle>
);

const variantsBase = {
  vertical: (offset: number, zoom: number) => ({
    initial: { opacity: 0, scale: 1 - zoom / 100, y: offset },
    animate: { opacity: 1, scale: 1, y: 0 },
    exit: { opacity: 0, scale: 1 - zoom / 100, y: offset }
  }),
  horizontal: (offset: number, zoom: number) => ({
    initial: { opacity: 0, scale: 1 - zoom / 100, x: offset },
    animate: { opacity: 1, scale: 1, x: -0 },
    exit: { opacity: 0, scale: 1 - zoom / 100, x: offset }
  }),
  zoom: (zoomAmount: number) => ({
    initial: { opacity: 0, scale: 1 - zoomAmount / 100 },
    animate: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 1 - zoomAmount / 100 }
  })
};

type zoomVariantCreator = (zoomAmount?: number) => Variants;
type sideZoomVariantCreator = (offset?: number, zoom?: number) => Variants;
type commonVariantsType = {
  [key: string]: zoomVariantCreator | sideZoomVariantCreator;
};

export const commonVariants: commonVariantsType = {
  fromTopZoomIn: (offset = -10, zoom = 5) =>
    variantsBase.vertical(offset, zoom),
  fromBottomZoomIn: (offset = 10, zoom = 5) =>
    variantsBase.vertical(offset, zoom),
  fromRightZoomIn: (offset = 10, zoom = 5) =>
    variantsBase.horizontal(offset, zoom),
  fromLeftZoomIn: (offset = -10, zoom = 5) =>
    variantsBase.horizontal(offset, zoom),
  zoomIn: (zoomAmount = 5) => variantsBase.zoom(zoomAmount),
  zoomOut: (zoomAmount = -5) => variantsBase.zoom(zoomAmount)
};
