import React, { FC, useState } from "react";
import {
  EuiBasicTable,
  EuiCard,
  EuiComboBox,
  EuiComboBoxOptionOption,
  EuiEmptyPrompt,
  EuiIcon,
  EuiLink,
  EuiLoadingSpinner,
  EuiSkeletonRectangle
} from "@elastic/eui";
import { useUIContext } from "../../../contexts/ui";
import { useQuery } from "react-query";
import {
  fetchBestTimesForVehicle,
  fetchDrivenTracks,
  fetchDrivenVehicleModels as fetchDrivenVehicleClasses
} from "../../../api/profile";
import { useTablePagination } from "../../../hooks/useTablePagination";
import { BestTimeForVehicle } from "../../../types/profile";
import { useTableSort } from "../../../hooks/useTableSort";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { format } from "date-fns";

interface Props {
  driverId: string;
}

export const BestLapsStats: FC<Props> = props => {
  const { emitErrorToast } = useUIContext();

  const [vehiclesQueryInitialized, setVehiclesQueryInitialized] =
    useState(false);
  const [selectedVehicle, setSelectedVehicle] =
    useState<EuiComboBoxOptionOption<number>>();
  const [selectedTrack, setSelectedTrack] =
    useState<EuiComboBoxOptionOption<number>>();

  const vehiclesQuery = useQuery(
    ["drivenVehicleClasses", props.driverId],
    fetchDrivenVehicleClasses,
    {
      refetchOnWindowFocus: false,
      onSuccess: data => {
        setSelectedVehicle({
          value: data[0]?.vehicleClassId,
          label: data[0]?.vehicleClassName
        });
        setVehiclesQueryInitialized(true);
      },
      onError: () =>
        emitErrorToast({ title: "We couldn't get driven vehicle classes" })
    }
  );
  const drivenTracksQuery = useQuery(
    ["drivenTracks", props.driverId],
    fetchDrivenTracks,
    {
      refetchOnWindowFocus: false,
      onError: () => emitErrorToast({ title: "We couldn't get driven tracks" })
    }
  );
  const bestTimes = useQuery(
    [
      "bestTimesForVehicle",
      props.driverId,
      selectedVehicle?.value,
      selectedTrack?.value
    ],
    fetchBestTimesForVehicle,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      // hack to not fetch all vehicles by default :|
      enabled: vehiclesQueryInitialized,
      onError: () =>
        emitErrorToast({
          title: "We couldn't get best times for given vehicle class"
        })
    }
  );

  const { pagination, pageChangeHandler, getPage } =
    useTablePagination<BestTimeForVehicle>({
      initialPageSize: 5,
      controlledTotalCount: bestTimes.data?.length
    });
  const { sortField, sortDirection, sortChangeHandler, sortData } =
    useTableSort<BestTimeForVehicle>({
      initialSortField: "trackName",
      initialSortDirection: "desc"
    });

  if (
    vehiclesQuery.isFetching ||
    drivenTracksQuery.isFetching ||
    bestTimes.isLoading
  ) {
    return (
      <EuiSkeletonRectangle
        isLoading={true}
        width="100%"
        height="200px"
        borderRadius="s"
      />
    );
  }

  if (
    !vehiclesQuery.data ||
    vehiclesQuery.data.length < 1 ||
    !drivenTracksQuery.data ||
    drivenTracksQuery.data.length < 1
  ) {
    return (
      <EuiCard title={<span>Best laps</span>} layout={"horizontal"}>
        <EuiEmptyPrompt
          iconType="folderExclamation"
          title={<h2>No best laps stats</h2>}
          body={
            <p>Looks like there you have not vehicles or driven tracks yet.</p>
          }
        />
      </EuiCard>
    );
  }

  return (
    <EuiCard
      title={
        <div
          style={{
            display: "flex",
            gap: "20px",
            alignItems: "center",
            flexWrap: "wrap"
          }}
        >
          <span>Best laps</span>
          <EuiComboBox<number>
            placeholder="Select a vehicle class"
            singleSelection={{ asPlainText: true }}
            options={vehiclesQuery.data.map(v => ({
              value: v.vehicleClassId,
              label: v.vehicleClassName
            }))}
            selectedOptions={selectedVehicle ? [selectedVehicle] : []}
            onChange={value => {
              setSelectedVehicle(value[0]);
            }}
          />
          <EuiComboBox<number>
            placeholder="Select a track"
            singleSelection={{ asPlainText: true }}
            options={drivenTracksQuery.data.map(v => ({
              value: v.trackId,
              label: v.trackName
            }))}
            selectedOptions={selectedTrack ? [selectedTrack] : []}
            onChange={value => {
              setSelectedTrack(value[0]);
            }}
          />
        </div>
      }
      layout={"horizontal"}
    >
      <div
        style={{
          position: "relative",
          opacity: bestTimes.isFetching ? 0.5 : 1
        }}
      >
        {bestTimes.isFetching && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, 0)"
            }}
          >
            <EuiLoadingSpinner size="xl" />
          </div>
        )}

        {(!bestTimes.data || bestTimes.data.length < 1) && (
          <EuiEmptyPrompt
            iconType="folderExclamation"
            title={<h2>No stats for selected criteria</h2>}
            body={<p>Looks like there is no stats to display</p>}
          />
        )}

        {bestTimes.data && bestTimes.data.length > 0 && (
          <EuiBasicTable<BestTimeForVehicle>
            style={{ maxHeight: "400px", overflow: "auto" }}
            items={getPage(sortData(bestTimes.data))}
            rowHeader="name"
            columns={[
              {
                field: "trackName",
                name: "Track name"
              },
              {
                field: "bestTime",
                name: "Best time"
              },
              {
                field: "lapDate",
                name: "Lap date",
                render: (item: BestTimeForVehicle["lapDate"]) => {
                  return (
                    <span>{format(new Date(item), "dd.MM.YYY HH:mm")}</span>
                  );
                }
              },
              {
                field: "vehicleModel",
                name: "Vehicle model"
              },
              {
                name: "Race details",
                render: (record: BestTimeForVehicle) => (
                  <EuiLink>
                    <Link
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px"
                      }}
                      to={`${ROUTES.STATS}/${record.driverId}/race-history/${record.splitId}`}
                    >
                      <span>Show</span>
                      <EuiIcon size={"s"} type="arrowRight" />
                    </Link>
                  </EuiLink>
                )
              }
            ]}
            pagination={pagination}
            onChange={({ page, sort }) => {
              if (page) {
                pageChangeHandler(page);
              }
              if (sort) {
                sortChangeHandler(sort);
              }
            }}
            tableLayout="auto"
            sorting={{
              enableAllColumns: true,
              sort: {
                field: sortField,
                direction: sortDirection
              }
            }}
          />
        )}
      </div>
    </EuiCard>
  );
};
