import { useEffect, useState } from "react";
import { differenceInSeconds, isPast } from "date-fns";

export const useTimer = (date: string | Date) => {
  const [refreshedDate, setRefreshedDate] = useState(new Date(date));

  useEffect(() => {
    const timeout =
      differenceInSeconds(refreshedDate, new Date()) < 70 ? 1000 : 5000;

    const timeoutId = setTimeout(() => {
      setRefreshedDate(new Date(refreshedDate));
    }, timeout);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [refreshedDate]);

  return {
    date: refreshedDate,
    isInPast: isPast(refreshedDate)
  };
};
