import React, { FC } from "react";
import {
  EuiBadge,
  EuiBasicTable,
  EuiButton,
  EuiCard,
  EuiText
} from "@elastic/eui";
import {
  ExistingTelemetryAnalyse,
  TelemetrySessionLap
} from "../../types/telemetrySessionDetailsDto";
import { SimpleTimeWithClockIcon } from "./SimpleTimeWithClockIcon";
import { AddToAnalysisModal } from "./AddToAnalysisModal";
import { colors } from "../../theme/eui_overrides";

interface Props {
  laps: TelemetrySessionLap[];
  existingAnalyses: ExistingTelemetryAnalyse[];
}

export const TelemetrySessionTypCard: FC<Props> = ({
  laps,
  existingAnalyses
}) => {
  if (laps.length < 1) {
    return null;
  }

  const sortedLapsByFastest = [...laps].sort((a, b) =>
    a.lapTime.localeCompare(b.lapTime, undefined, { numeric: true })
  );
  const fastestLaps = sortedLapsByFastest
    .filter(l => l.lapTime === sortedLapsByFastest[0].lapTime)
    .map(l => l.lapId);

  return (
    <>
      <EuiCard
        titleElement={"span"}
        display={"plain"}
        hasBorder={true}
        title={
          <div
            style={{
              display: "flex",
              gap: "20px",
              alignItems: "center",
              flexWrap: "wrap"
            }}
          >
            <span style={{ textTransform: "capitalize" }}>
              {laps[0].sessionType}
            </span>
          </div>
        }
        layout={"horizontal"}
      >
        <EuiBasicTable<TelemetrySessionLap>
          style={{ overflow: "auto" }}
          items={laps}
          rowHeader="name"
          columns={[
            {
              name: "Lap",
              render: (record: TelemetrySessionLap) => {
                return <span>{record.lapNumber}</span>;
              }
            },
            {
              name: "Lap time",
              render: (item: TelemetrySessionLap) => {
                return (
                  <EuiBadge
                    color={
                      fastestLaps.includes(item.lapId)
                        ? colors.winGreen
                        : "default"
                    }
                  >
                    <SimpleTimeWithClockIcon time={item.lapTime.slice(3, -4)} />
                  </EuiBadge>
                );
              }
            },
            {
              name: "Track temperature",
              render: (item: TelemetrySessionLap) => {
                return (
                  <SimpleTemperatureComponent
                    temperature={item.weather.trackTemperature}
                  />
                );
              }
            },
            {
              name: "Air temperature",
              render: (item: TelemetrySessionLap) => {
                return (
                  <SimpleTemperatureComponent
                    temperature={item.weather.airTemperature}
                  />
                );
              }
            },
            {
              name: "",
              render: (record: TelemetrySessionLap) => {
                return (
                  <AddToAnalysisModal
                    lap={record}
                    existingAnalyses={existingAnalyses}
                    renderTrigger={setOpened => (
                      <EuiButton onClick={() => setOpened(true)}>
                        Analyze
                      </EuiButton>
                    )}
                  />
                );
              }
            }
          ]}
          tableLayout="auto"
        />
      </EuiCard>
    </>
  );
};

const SimpleTemperatureComponent: FC<{ temperature: number }> = ({
  temperature
}) => {
  return (
    <div style={{ display: "flex", gap: "5px" }}>
      <EuiText size={"relative"}>{temperature}(&deg;C)</EuiText>
      <EuiText size={"relative"} color={"subdued"}>
        {((temperature * 9) / 5 + 32).toFixed(2)}
        (&deg;F)
      </EuiText>
    </div>
  );
};
