import React, { FC, useState } from "react";
import f1Car from "../../assets/icons/wired-gradient-890-f-1-car.json";
import Lottie from "react-lottie-player";

interface Props {}

export const AnimatedF1Icon: FC<Props> = props => {
  const [play, setPlay] = useState(true);
  return (
    <Lottie
      animationData={f1Car}
      rendererSettings={{ preserveAspectRatio: "xMidYMid meet" }}
      play={play}
      onMouseEnter={() => setPlay(true)}
      onComplete={() => setPlay(false)}
      loop={false}
      style={{
        width: 64,
        height: 64
      }}
    />
  );
};
