import countryCodes from "country-code-lookup";
import { countryCodeEmoji } from "country-code-emoji";

export const INTERNATIONAL_CC = "International";
export const INTERNATIONAL_COMBO_BOX_OPTION = {
  value: INTERNATIONAL_CC,
  label: `${"\u{1f30d}"} ${INTERNATIONAL_CC}`
};
export const countryCodesToComboBoxOptions = () => [
  INTERNATIONAL_COMBO_BOX_OPTION,
  ...countryCodes.countries.map(({ iso2, country }) => ({
    value: iso2,
    label: `${countryCodeEmoji(iso2)} ${country}`
  }))
];
export const getComboBoxOptionFromCountryCode = (countryCode = "") =>
  countryCode === INTERNATIONAL_CC
    ? INTERNATIONAL_COMBO_BOX_OPTION
    : {
        value: countryCode,
        label: countryCode
          ? `${countryCodeEmoji(countryCode)} ${
              countryCodes.byIso(countryCode)?.country || ""
            }`
          : ""
      };
