import {
  EuiButton,
  EuiFlexGroup,
  EuiLink,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSpacer,
  EuiText
} from "@elastic/eui";
import { loginUser, registerUser } from "api/auth";
import { Logo } from "components/shared/Logo";
import { useUIContext } from "contexts/ui";
import { useUserContext } from "contexts/user";
import React from "react";
import { SubmitHandler } from "react-hook-form";
import styled from "styled-components";
import { LoginFormFields, RegisterFormFields } from "types/auth";
import { LoginForm } from "./LoginForm/LoginForm";
import { RegisterForm } from "./RegisterForm/RegisterForm";
import { AnimatedF1Icon } from "./AnimatedF1Icon";

export const AuthenticationPage: React.FC = () => {
  const { loginModalMode, setLoginModalMode } = useUserContext();
  const { emitToast, emitErrorToast, setGlobalLoading } = useUIContext();
  const { setUserWithHeaders } = useUserContext();
  const registerHandler: SubmitHandler<RegisterFormFields> = async registerFormData => {
    const loaderTimestamp = setGlobalLoading({ state: true, offset: false });
    try {
      const registerResponse = await registerUser(registerFormData);
      if (registerResponse.status === 200) {
        setLoginModalMode("confirmation");
      }
    } catch (thrownError) {
      const error: any = thrownError;
      const errorMessage = error?.response?.data || error.message;
      emitErrorToast({ text: errorMessage });
    } finally {
      setGlobalLoading({ state: false, timestamp: loaderTimestamp });
    }
  };
  const loginHandler: SubmitHandler<LoginFormFields> = async LoginFormData => {
    const loaderTimestamp = setGlobalLoading({ state: true, offset: false });
    try {
      const authenticatedUser = await loginUser(LoginFormData);
      if (!authenticatedUser.token) {
        throw new Error("Network error");
      }
      emitToast({
        title: "You have been logged in successfully.",
        color: "success",
        iconType: "check"
      });
      setUserWithHeaders(authenticatedUser);
      setLoginModalMode(null);
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error?.message ||
        error?.message ||
        "We couldn't log you in at the moment.";
      emitErrorToast({
        text: <div dangerouslySetInnerHTML={{ __html: errorMessage }} />,
        toastLifeTimeMs: 10_000
      });
    } finally {
      setGlobalLoading({ state: false, timestamp: loaderTimestamp });
    }
  };

  if (!loginModalMode) {
    return null;
  }

  if (loginModalMode === "confirmation") {
    return (
      <EuiModal
        onClose={() => {
          setLoginModalMode(null);
        }}
      >
        <EuiModalHeader>
          <EuiModalHeaderTitle
            style={{ display: "flex", gap: "10px", alignItems: "center" }}
          >
            There is one more lap to go
            <AnimatedF1Icon />
          </EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody>
          <EuiText>
            Confirmation message has been sent to your email address. Please
            click on provided link to finish the registration process.
          </EuiText>
          <EuiSpacer />
          <EuiText>
            Unconfirmed accounts are deleted after 24h. After that you'll be able
            to create new RCO account again.
          </EuiText>
        </EuiModalBody>

        <EuiModalFooter>
          <EuiButton
            onClick={() => {
              setLoginModalMode(null);
            }}
            fill
          >
            Close
          </EuiButton>
        </EuiModalFooter>
      </EuiModal>
    );
  }

  if (loginModalMode === "login") {
    return (
      <EuiModal onClose={() => setLoginModalMode(null)}>
        <EuiModalBody>
          <RootFlexGroup
            direction="column"
            alignItems="center"
            gutterSize="s"
            responsive={false}
          >
            <EuiText textAlign="center">
              <Logo />
            </EuiText>
            <EuiSpacer size="xl" />
            <EuiText>
              <h3 data-testid="formTitle">Sign in</h3>
            </EuiText>
            <EuiSpacer size="m" />
            <LoginForm submitHandler={loginHandler} />
            <EuiSpacer size="xl" />
            <EuiText color="subdued">
              <p>
                Don't have an account yet?{" "}
                <EuiLink
                  data-testid="registerSwitchButton"
                  onClick={() => setLoginModalMode("register")}
                >
                  Sign up now.
                </EuiLink>
              </p>
            </EuiText>
          </RootFlexGroup>
        </EuiModalBody>
      </EuiModal>
    );
  }

  return (
    <EuiModal onClose={() => setLoginModalMode(null)}>
      <EuiModalBody>
        <RootFlexGroup
          direction="column"
          alignItems="center"
          gutterSize="s"
          responsive={false}
        >
          <EuiText textAlign="center">
            <Logo />
          </EuiText>
          <EuiSpacer size="xl" />
          <EuiText>
            <h3 data-testid="formTitle">Sign up</h3>
          </EuiText>
          <EuiSpacer size="m" />
          <RegisterForm submitHandler={registerHandler} />
          <EuiSpacer size="xl" />
          <EuiText color="subdued">
            <p>
              Already registered user?{" "}
              <EuiLink
                data-testid="loginSwitchButton"
                onClick={() => setLoginModalMode("login")}
              >
                Sign in now.
              </EuiLink>
            </p>
          </EuiText>
        </RootFlexGroup>
      </EuiModalBody>
    </EuiModal>
  );
};

const RootFlexGroup = styled(EuiFlexGroup)`
  margin: ${({ theme }) => theme.paddingSizes.m};
`;
