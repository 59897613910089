import React from "react";
import { EuiIcon, EuiText, EuiTextColor } from "@elastic/eui";
import { RatingChange } from "./Ratings";
import styled from "styled-components";

const RatingChangeRow = styled.div`
  display: flex;
  align-items: center;
  div {
    margin-left: ${({ theme }) => theme.paddingSizes.xs};
  }
`;

const TrendArrowIcon = styled(EuiIcon)<{ difference: number }>`
  transform: rotate(
    ${({ difference }) => (difference > 0 ? -45 : difference < 0 ? 45 : 0)}deg
  ) !important;
`;

export const RatingChangeToastRow = ({
  value,
  difference,
  name
}: RatingChange & { name: string }) => (
  <RatingChangeRow>
    <TrendArrowIcon
      type="sortRight"
      difference={difference}
      color={difference > 0 ? "success" : difference < 0 ? "danger" : "default"}
    />
    <EuiText>
      {name}: {value}{" "}
      {difference ? (
        <>
          (
          <EuiTextColor
            color={
              difference > 0 ? "success" : difference < 0 ? "danger" : "default"
            }
          >
            {difference > 0 && "+"}
            {difference}
          </EuiTextColor>
          )
        </>
      ) : null}
    </EuiText>
  </RatingChangeRow>
);
