import { EuiBetaBadge, EuiFlexGroup, EuiTextColor } from "@elastic/eui";
import {
  MotionEuiText,
  MotionEuiTitle
} from "components/shared/MotionComponents";
import { motion } from "framer-motion";
import React, { FC, useContext, useState } from "react";
import Lottie from "react-lottie-player";
import styled, { ThemeContext } from "styled-components";
import { useEuiThemeTyped } from "../../theme/eui_overrides";

const SubtitleContainer = styled.div`
  margin-top: ${({ theme }) => theme.paddingSizes.s};
`;

const TitlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const titleVariants = {
  initial: {
    opacity: 0,
    scale: 0.9,
    width: "fit-content",
    transition: { opacity: { delay: 0.1 } }
  },
  animate: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 0.9, transition: { scale: { delay: 0.1 } } }
};

type AnimatedIconHeaderProps = {
  lightLottieIcon: any;
  darkLottieIcon: any;
  title?: React.ReactNode;
  subtitle?: JSX.Element | string;
  badge?: {
    label: string;
    title: string;
    tooltip: string;
  };
};

export const AnimatedIconHeader: React.FC<AnimatedIconHeaderProps> = ({
  lightLottieIcon,
  darkLottieIcon,
  title,
  subtitle,
  badge
}) => {
  const [hover, setHover] = useState(true);
  const { colorMode } = useEuiThemeTyped();
  const {
    euiTitles: {
      l: { "font-size": titleSize }
    }
  } = useContext(ThemeContext);

  return (
    <EuiFlexGroup
      direction="row"
      justifyContent={"flexStart"}
      onMouseEnter={() => setHover(true)}
    >
      <EuiFlexGroup gutterSize="none" alignItems={"center"}>
        <motion.div
          variants={titleVariants}
          animate="animate"
          initial="initial"
          exit="exit"
        >
          <Lottie
            animationData={
              colorMode === "DARK" ? lightLottieIcon : darkLottieIcon
            }
            rendererSettings={{ preserveAspectRatio: "xMidYMid meet" }}
            play={hover}
            loop={false}
            onComplete={() => setHover(false)}
            data-testid="headerTitleIcon"
            style={{
              width: parseInt(titleSize) * 2.2,
              height: parseInt(titleSize) * 2.2
            }}
          />
        </motion.div>
        {title && (
          <TitlesContainer>
            <MotionEuiTitle
              style={{ alignSelf: "center", lineHeight: 1 }}
              variants={titleVariants}
              animate="animate"
              initial="initial"
              exit="exit"
            >
              {title}&nbsp;
              {badge ? (
                <EuiBetaBadge
                  label={badge.label}
                  title={badge.title}
                  tooltipContent={badge.tooltip}
                />
              ) : null}
            </MotionEuiTitle>
            {subtitle ? (
              <SubtitleContainer>
                {typeof subtitle === "string" ? (
                  <MotionEuiText
                    variants={titleVariants}
                    animate="animate"
                    initial="initial"
                    exit="exit"
                  >
                    <p>
                      <EuiTextColor color="subdued">{subtitle}</EuiTextColor>
                    </p>
                  </MotionEuiText>
                ) : (
                  subtitle
                )}
              </SubtitleContainer>
            ) : null}
          </TitlesContainer>
        )}
      </EuiFlexGroup>
    </EuiFlexGroup>
  );
};

export const AnimatedHeaderWithIcon: FC<AnimatedIconHeaderProps> = props => {
  const [hover, setHover] = useState(true);
  const { colorMode } = useEuiThemeTyped();

  return (
    <div
      style={{
        display: "flex",
        flexFlow: "column",
        gap: "5px",
        marginBottom: "10px",
        alignItems: "flex-start"
      }}
    >
      <div
        style={{ display: "flex", gap: "10px", alignItems: "center" }}
        onMouseEnter={() => setHover(true)}
      >
        <motion.div
          variants={titleVariants}
          animate="animate"
          initial="initial"
          exit="exit"
        >
          <Lottie
            animationData={
              colorMode === "DARK"
                ? props.darkLottieIcon
                : props.lightLottieIcon
            }
            rendererSettings={{ preserveAspectRatio: "xMidYMid meet" }}
            play={hover}
            loop={false}
            onComplete={() => setHover(false)}
            data-testid="headerTitleIcon"
            style={{
              width: 40,
              height: 40
            }}
          />
        </motion.div>
        {props.title}
      </div>
      <div onMouseEnter={() => setHover(true)}>{props.subtitle}</div>
    </div>
  );
};
