import React, { CSSProperties, FC, useState } from "react";
import {
  EuiButton,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle
} from "@elastic/eui";

interface Props {
  body: React.ReactNode;
  title?: React.ReactNode;
  style?: CSSProperties;
  renderTrigger(setOpened: (opened: boolean) => void): React.ReactNode;
  alternativeAction?: React.ReactNode;
}

export const Modal: FC<Props> = props => {
  const [opened, setOpened] = useState(false);

  return (
    <>
      {props.renderTrigger(setOpened)}

      {opened && (
        <EuiModal onClose={() => setOpened(false)} style={props.style}>
          {props.title && (
            <EuiModalHeader>
              <EuiModalHeaderTitle>{props.title}</EuiModalHeaderTitle>
            </EuiModalHeader>
          )}

          <EuiModalBody>{props.body}</EuiModalBody>

          <EuiModalFooter>
            {props.alternativeAction}
            <EuiButton onClick={() => setOpened(false)} fill>
              Close
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      )}
    </>
  );
};
