import { EuiSkeletonText } from "@elastic/eui";
import React from "react";
import { News } from "types/news";
import { MarkdownFormat } from "../../MarkdownFormat";

export interface NewsDetailProps {
  news: News | undefined;
  isLoading: boolean;
}

export const NewsDetail = (props: NewsDetailProps) => {
  const { news, isLoading } = props;
  return (
    <EuiSkeletonText lines={3} size="m" isLoading={isLoading}>
      {news && news.markdownBody && (
        <MarkdownFormat>{news.markdownBody}</MarkdownFormat>
      )}
    </EuiSkeletonText>
  );
};
