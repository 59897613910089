import React, { FC } from "react";
import { EuiIcon } from "@elastic/eui";

interface Props {
  time: string;
}

export const SimpleTimeWithClockIcon: FC<Props> = props => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "2px",
        flexWrap: "nowrap"
      }}
    >
      <EuiIcon type={"clock"} />
      <div style={{ whiteSpace: "nowrap" }}>{props.time}</div>
    </div>
  );
};
