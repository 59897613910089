import React, { FC, useState } from "react";
import {
  EuiButton,
  EuiButtonIcon,
  EuiHorizontalRule,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiPopover,
  EuiTitle
} from "@elastic/eui";
import { UpcomingRacesList } from "../../shared/UpcomingRaces/UpcomingRacesList";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { RaceSeriesEvent } from "../../../types/raceSeries";

interface Props {
  event: RaceSeriesEvent;
  raceSeriesId: number;
  league: { id: number; name: string };
  filterOutNearestRace: boolean;
  renderTrigger(onClick: () => void): React.ReactChild;
}

export const UpcomingRacesPopover: FC<Props> = props => {
  const [opened, setOpened] = useState(false);
  const { isMobile } = useIsMobile();

  if (isMobile) {
    return (
      <>
        {props.renderTrigger(() => setOpened(!opened))}
        {opened && (
          <EuiModal onClose={() => setOpened(false)}>
            <EuiModalHeader>
              <EuiModalHeaderTitle>Upcoming races</EuiModalHeaderTitle>
            </EuiModalHeader>

            <EuiModalBody>
              <UpcomingRacesList
                event={props.event}
                raceSeriesId={props.raceSeriesId}
                filterOutNearestRace={props.filterOutNearestRace}
                league={props.league}
              />
            </EuiModalBody>

            <EuiModalFooter>
              <EuiButton onClick={() => setOpened(false)} fill>
                Close
              </EuiButton>
            </EuiModalFooter>
          </EuiModal>
        )}
      </>
    );
  }

  return (
    <EuiPopover
      button={props.renderTrigger(() => setOpened(!opened))}
      isOpen={opened}
      closePopover={() => {
        /* dummy func added, because it's always called */
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minWidth: "500px",
          minHeight: "600px",
          maxHeight: "800px",
          overflowY: "auto",
          overflowX: "hidden"
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <EuiTitle size={"s"}>
            <span>Upcoming races</span>
          </EuiTitle>
          <EuiButtonIcon
            color={"text"}
            iconType={"cross"}
            onClick={() => setOpened(false)}
          />
        </div>
        <EuiHorizontalRule margin={"xs"} />
        <UpcomingRacesList
          event={props.event}
          raceSeriesId={props.raceSeriesId}
          filterOutNearestRace={props.filterOutNearestRace}
          league={props.league}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            flex: 1,
            marginTop: "10px",
            // this is here to fix the overflow shown by the button-pressed animation
            overflow: "hidden"
          }}
        >
          <EuiButton color={"text"} onClick={() => setOpened(false)}>
            Close
          </EuiButton>
        </div>
      </div>
    </EuiPopover>
  );
};
