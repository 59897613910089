import React, { FC, useState } from "react";
import { RaceSerie } from "../../types/raceSeries";
import {
  EuiBadge,
  EuiButtonIcon,
  EuiNotificationBadge,
  EuiText,
  EuiTitle,
  EuiToolTip
} from "@elastic/eui";
import {
  AiNumberBadge,
  DriversPracticingNumberBadge,
  PreviousRaceDriversCountBadge,
  RegisteredDriversBadge,
  RegisteredDriversBadgeWithDriversList
} from "./RaceCard/RaceNumbers";
import { RaceSeriesDetailsModal } from "../RaceSeriesDetails/RaceSeriesDetails";
import { R3EConnectMenu } from "../shared/R3eConnectMenu";
import { JoinPracticeButton } from "../shared/JoinPracticeButton";
import { RegisterToRaceButton } from "../shared/RegisterToRaceButton";
import { useUserContext } from "../../contexts/user";
import { UpcomingRacesPopover } from "./RaceCard/UpcomingRacesPopover";
import AnimatedDriversCounter from "./RaceCard/AnimatedDriversCounter";
import WatchIconLight from "../../assets/icons/wired-outline-87-watch_light.json";
import WatchIconDark from "../../assets/icons/wired-outline-87-watch_dark.json";
import { AnimatedHeaderWithIcon } from "../shared/AnimatedIconHeader";
import { RacesList } from "./RacesList";

interface Props {
  races: RaceSerie[];
}

export const HourlyRaces: FC<Props> = props => {
  const { user } = useUserContext();
  const [raceModalDetails, setRaceModalDetails] = useState<{
    raceSeriesId: number;
    eventId: number;
    raceId: number;
  }>();

  const sortedRaces = props.races.sort((a, b) =>
    a.event.race.startAtUtc.localeCompare(b.event.race.startAtUtc)
  );

  const allRegisteredDrivers = props.races.reduce((acc, item) => {
    return acc + item.event.race.registeredDrivers.length;
  }, 0);
  const upcomingRegisteredDrivers = props.races.reduce((acc, item) => {
    return acc + item.event.upcomingRacesDriversCount;
  }, 0);
  const practicingDrivers = props.races.reduce((acc, item) => {
    return acc + item.event.practicingDriversCount;
  }, 0);
  const allPreviouslyRegisteredDrivers = props.races.reduce((acc, item) => {
    return acc + item.previousRace.driversCount;
  }, 0);

  return (
    <div>
      <AnimatedHeaderWithIcon
        lightLottieIcon={WatchIconLight}
        darkLottieIcon={WatchIconDark}
        title={
          <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
            <EuiTitle>
              <EuiText>Hourly races</EuiText>
            </EuiTitle>
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <EuiToolTip content="Drivers registered to nearest races">
                <RegisteredDriversBadge
                  count={allRegisteredDrivers}
                  icon={"users"}
                />
              </EuiToolTip>
              <DriversPracticingNumberBadge count={practicingDrivers} />
              <PreviousRaceDriversCountBadge
                count={allPreviouslyRegisteredDrivers}
              />
            </div>
          </div>
        }
        subtitle={
          <EuiText color={"subdued"} size={"s"}>
            Races around the clock.
          </EuiText>
        }
      />
      <RacesList
        races={sortedRaces}
        renderNumbers={(item: RaceSerie) => {
          return (
            <>
              <RegisteredDriversBadgeWithDriversList
                drivers={item.event.race.registeredDrivers}
              />
              <DriversPracticingNumberBadge
                count={item.event.practicingDriversCount}
              />
              <AiNumberBadge event={item.event} />
            </>
          );
        }}
        renderBadges={(item: RaceSerie) => {
          return (
            <div
              style={{
                display: "flex",
                flexFlow: "wrap",
                width: "200px",
                gap: "5px"
              }}
            >
              <EuiToolTip
                position="bottom"
                content={<p>Race session length.</p>}
              >
                <EuiBadge iconType="flag">
                  {`${item.event.raceSessionDurationInMinutes} min.` || ""}
                </EuiBadge>
              </EuiToolTip>
              <EuiToolTip position="bottom" content={<p>Race frequency.</p>}>
                <EuiBadge iconType="refresh">
                  {`${item.humanReadeableFrequency}` || ""}
                </EuiBadge>
              </EuiToolTip>
              {item.dlc.isNeeded && (
                <EuiToolTip
                  position="bottom"
                  content={
                    <p>DLC required. Check series details for more info.</p>
                  }
                >
                  <EuiBadge iconType="package" color="primary">
                    DLC
                  </EuiBadge>
                </EuiToolTip>
              )}
            </div>
          );
        }}
        renderButtons={(record: RaceSerie) => {
          if (
            record.sim.shortCode === "R3E" &&
            user &&
            !user?.isRaceroomAccountConnected
          ) {
            return <R3EConnectMenu />;
          }

          return (
            <div
              style={{
                display: "flex",
                gap: "5px",
                minWidth: "220px",
                width: "100%",
                justifyContent: "flex-end"
              }}
            >
              <JoinPracticeButton raceSeries={record} />
              <RegisterToRaceButton
                race={record.event.race}
                vehicleClassGroups={
                  record.event.raceSeriesVehicleClassGroups.groups
                }
                league={record.league}
              />
              <UpcomingRacesPopover
                event={record.event}
                raceSeriesId={record.id}
                league={record.league}
                filterOutNearestRace={false}
                renderTrigger={onClick => (
                  <div
                    style={{
                      position: "relative"
                    }}
                  >
                    {record.event.upcomingRacesDriversCount > 0 && (
                      <div
                        style={{
                          position: "absolute",
                          top: -5,
                          right: -5,
                          zIndex: 2
                        }}
                      >
                        <EuiToolTip
                          content={
                            <div>
                              Number of drivers registered to upcoming races:{" "}
                              <b>{record.event.upcomingRacesDriversCount}</b>
                            </div>
                          }
                        >
                          <EuiNotificationBadge color={"accent"}>
                            <AnimatedDriversCounter
                              countValue={
                                record.event.upcomingRacesDriversCount
                              }
                            />
                          </EuiNotificationBadge>
                        </EuiToolTip>
                      </div>
                    )}

                    <EuiToolTip content={"Show upcoming races"}>
                      <EuiButtonIcon
                        display={"base"}
                        color={"text"}
                        size={"m"}
                        iconType={"calendar"}
                        aria-label="More"
                        onClick={onClick}
                      />
                    </EuiToolTip>
                  </div>
                )}
              />
            </div>
          );
        }}
      />
      {raceModalDetails && (
        <RaceSeriesDetailsModal
          {...raceModalDetails}
          setOpened={() => setRaceModalDetails(undefined)}
        />
      )}
    </div>
  );
};
