import React, { FC } from "react";
import { EuiButton } from "@elastic/eui";
import { ReactComponent as xLogo } from "assets/icons/x-twitter.svg";
import { objectToGetParams } from "../../utils/objectToGetParams";

interface Props {
  url?: string;
}

export const TwitterShare: FC<Props> = props => {
  return (
    <EuiButton
      iconType={xLogo}
      title={"Share to X"}
      color={"text"}
      href={`https://twitter.com/share${objectToGetParams({
        url: props.url || window.location.href
      })}`}
      target="_blank"
      rel="noreferrer"
    >
      Twitter
    </EuiButton>
  );
};
