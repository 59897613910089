import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useMutation, useQueries, useQuery, UseQueryResult } from "react-query";
import {
  fetchAnalDetails,
  fetchLapDetails,
  removeLapFromAnal
} from "../../api/profile";
import { Link, useHistory, useParams } from "react-router-dom";
import { useUIContext } from "../../contexts/ui";
import {
  EuiButton,
  EuiButtonIcon,
  EuiEmptyPrompt,
  EuiHorizontalRule,
  EuiPanel,
  EuiRadio,
  EuiSkeletonRectangle,
  EuiStat,
  EuiTitle
} from "@elastic/eui";
import { ROUTES } from "../../constants/routes";
import { TrackImage } from "../TrackImage";
import {
  TelemetryTicksResponseDto,
  TelemetryTickType
} from "../../types/telemetryTicksResponseDto";
import { ChartsData, TelemetryCharts } from "./TelemetryCharts";
import { telemetryColorsPallete } from "./colors";
import { TelemetryMap } from "./TelemetryMap";

interface Props {}

export const TelemetryAnalysis: FC<Props> = props => {
  const { emitErrorToast } = useUIContext();
  const history = useHistory();
  const { analKey } = useParams<{
    analKey: string;
  }>();

  // this little shenanigan is here because i couldn't keep the charts in sync with the data correctly. Calculating uPlot.valToPos was off...
  // and without separate state for charts syncing with map was an issue, it's not that ugly and it works tho :)
  // or maybe i'm stupid
  const [hoveredValue, setHoveredValue] = useState<number | null>(null);
  const [hoveredValueFromCharts, setHoveredValueFromCharts] = useState<
    number | null
  >(null);
  const handleSetHoveredFromCharts = useCallback(v => {
    setHoveredValueFromCharts(v);
    setMapIsActive(false);
  }, []);
  const [mapIsActive, setMapIsActive] = useState<boolean>(false);

  const [selectedOnMap, setSelectedOnMap] = useState<number>();

  const anal = useQuery(["analDetails", analKey], fetchAnalDetails, {
    refetchOnWindowFocus: false,
    onError: () =>
      emitErrorToast({ title: "We couldn't get telemetry session data" })
  });
  useEffect(() => {
    if (!anal.data) {
      return;
    }

    setSelectedOnMap(anal.data.lapIds[0]);
  }, [anal.data]);
  const removeLap = useMutation(removeLapFromAnal);

  const lapsDetails = useQueries(
    anal.data?.lapIds.map(lapId => {
      return {
        queryKey: [
          "lapDetails",
          lapId,
          "speed_kmh,gear,brake,throttle,rpms,speed_mph"
        ],
        queryFn: fetchLapDetails,
        refetchOnWindowFocus: false,
        onError: () =>
          emitErrorToast({
            title: `We couldn't get lap details for lap id ${lapId}`
          })
      };
    }) || []
  ) as UseQueryResult<TelemetryTicksResponseDto>[];

  const carPositions = useQueries(
    anal.data?.lapIds.map(lapId => {
      return {
        queryKey: ["lapDetails", lapId, "car_x,car_z"],
        queryFn: fetchLapDetails,
        refetchOnWindowFocus: false,
        onError: () =>
          emitErrorToast({
            title: `We couldn't get lap details for lap id ${lapId}`
          })
      };
    }) || []
  ) as UseQueryResult<TelemetryTicksResponseDto>[];
  const maps = carPositions.map((cp, index) => {
    return {
      data: cp.data!,
      color: telemetryColorsPallete[index]
    };
  });

  const charts: ChartsData = useMemo(() => {
    const data: ChartsData = lapsDetails
      .filter(d => !!d.data)
      .map(ld => ld.data!)
      .reduce((acc, item, currentIndex) => {
        const newAcc = Object.entries(item.telemetryTicks).reduce(
          (acc2, item2) => {
            return {
              ...acc2,
              [item2[0]]: {
                chartData: [
                  ...(acc[item2[0] as TelemetryTickType]?.chartData || []),
                  item2[1].map(it => it.telemetryTick)
                ],
                details: [
                  ...(acc[item2[0] as TelemetryTickType]?.details || []),
                  {
                    ...item,
                    telemetryTicks: item2[1],
                    color: telemetryColorsPallete[currentIndex]
                  }
                ]
              }
            };
          },
          {} as ChartsData
        );

        return {
          ...acc,
          ...newAcc
        };
      }, {} as ChartsData);

    return Object.entries(data).reduce((acc, [key, value], currentIndex) => {
      const xValues = [...value.details]
        .sort((a, b) => b.telemetryTicks.length - a.telemetryTicks.length)[0]
        .telemetryTicks.map(v => v.trackPositionMeters);

      return {
        ...acc,
        [key]: {
          ...value,
          chartData: [xValues, ...value.chartData]
        }
      };
    }, {} as ChartsData);
  }, [lapsDetails]);

  if (
    anal.isFetching ||
    lapsDetails.some(ld => ld.isFetching) ||
    carPositions.some(cp => cp.isFetching)
  ) {
    return (
      <EuiSkeletonRectangle
        isLoading={true}
        width="100%"
        height="200px"
        borderRadius="s"
      />
    );
  }

  if (!anal.data) {
    return (
      <div>
        <EuiButton>
          <Link to={ROUTES.TELEMETRY}>Go back to telemetry</Link>
        </EuiButton>
        <EuiEmptyPrompt
          iconType="folderExclamation"
          title={<h2>No anal for you my son</h2>}
          body={
            <p>
              Looks like there is no data for this session analysis to display.
              Are you sure the anal key is correct? Ask discord for help if you
              thincc it's a mistake.
            </p>
          }
        />
      </div>
    );
  }

  return (
    <div style={{ display: "flex", gap: "10px" }}>
      <EuiPanel
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          maxWidth: "300px"
        }}
      >
        <div>
          <EuiButton
            iconSide={"left"}
            iconType={"arrowLeft"}
            onClick={() => history.goBack()}
          >
            Go back
          </EuiButton>
        </div>
        <EuiTitle>
          <h4>{anal.data.name}</h4>
        </EuiTitle>
        <div style={{ flex: 0 }}>
          <TrackImage
            backgroundUrl={anal.data.track.trackPhotoUrl}
            imageUrl={anal.data.track.trackMapUrl}
          />
        </div>
        <EuiHorizontalRule />
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          {Object.values(charts)[0].details.map(d => (
            <div
              style={{
                display: "flex",
                gap: "10px",
                justifyContent: "space-between"
              }}
            >
              <EuiRadio
                checked={selectedOnMap === d.lapId}
                onChange={() => setSelectedOnMap(d.lapId)}
              />
              <EuiStat
                title={
                  <div>
                    {d.driver.name} ({d.lapId})
                  </div>
                }
                description={d.lapTime.slice(3, -4)}
                reverse={true}
                titleSize={"xxs"}
                titleColor={d.color}
              />
              <EuiButtonIcon
                iconType={"trash"}
                color={"danger"}
                onClick={() => {
                  removeLap
                    .mutateAsync({
                      AnalysisKey: analKey,
                      Laps: [d.lapId]
                    })
                    .then(() => {
                      anal.refetch();
                    });
                }}
              />
            </div>
          ))}
        </div>
      </EuiPanel>
      <div style={{ flex: 1, height: "80vh", width: "100%" }}>
        <TelemetryMap
          data={maps}
          selected={selectedOnMap}
          hovered={mapIsActive ? hoveredValue : hoveredValueFromCharts}
          setHovered={value => {
            setMapIsActive(!!value);
            setHoveredValue(value);
          }}
        />
      </div>
      <div style={{ flex: 1 }}>
        <TelemetryCharts
          data={charts}
          hoveredValue={mapIsActive ? hoveredValue : null}
          setHovered={handleSetHoveredFromCharts}
        />
      </div>
      <div style={{ maxWidth: "200px" }}>notes</div>
    </div>
  );
};
