import React from "react";
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiAvatar,
  EuiText,
  EuiIcon
} from "@elastic/eui";
import styled from "styled-components";

const ButtonOverlayContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.euiPageBackgroundColor}00;
  backdrop-filter: blur(0px);
  cursor: pointer;
  transition: ${({ theme }) => theme.euiAnimSpeedSlow};
  * {
    transition: ${({ theme }) => theme.euiAnimSpeedSlow};
    opacity: 0;
  }
  &:hover {
    background: ${({ theme }) => theme.euiPageBackgroundColor}E6;
    backdrop-filter: blur(3px);
    * {
      opacity: 1;
    }
  }
`;

const DisplayName = styled.h2`
  margin: 0 !important;
`;

type SteamAccountDetailsProps = {
  steamDetails: {
    steamDisplayName?: string;
    avatarUrl?: string;
    steamId?: number;
  };
  clear: () => void;
};

const SteamAccountDetails = ({
  steamDetails: { steamDisplayName, avatarUrl, steamId },
  clear
}: SteamAccountDetailsProps) => {
  return (
    <EuiFlexGroup
      style={{ position: "relative" }}
      gutterSize="s"
      alignItems="center"
      responsive={false}
    >
      <EuiFlexItem grow={false}>
        <EuiAvatar
          size="l"
          name={steamDisplayName || "Avatar"}
          imageUrl={avatarUrl || ""}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiText grow={false} size="xs">
          <DisplayName>{steamDisplayName}</DisplayName>
        </EuiText>
      </EuiFlexItem>

      <ButtonOverlayContainer onClick={clear}>
        <EuiFlexGroup justifyContent="center" gutterSize="s">
          <EuiFlexItem grow={false}>
            <EuiIcon type="trash" size="xl" />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiText>
              <h2>Clear</h2>
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      </ButtonOverlayContainer>
    </EuiFlexGroup>
  );
};

export default SteamAccountDetails;
