import React, { FC } from "react";
import { YearlyStats } from "./YearlyStats";
import { SeriesStats } from "./SeriesStats";
import { BestLapsStats } from "./BestLapsStats";

interface Props {
  driverId: string;
}

export const Details: FC<Props> = props => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <YearlyStats driverId={props.driverId} />
      <SeriesStats driverId={props.driverId} />
      <BestLapsStats driverId={props.driverId} />
    </div>
  );
};
