export const telemetryColorsPallete = [
  "#144b32",
  "#db7622",
  "#c71a1f",
  "#1e73af",
  "#bf8fa2",
  "#d76672",
  "#7a6d49",
  "#318d43",
  "#d3d4bf",
  "#28b5a3"
];
