export default function getPodiumIcon(finishPosition: number) {
  if (finishPosition === 1) {
    return "🥇 ";
  } else if (finishPosition === 2) {
    return "🥈 ";
  } else if (finishPosition === 3) {
    return "🥉 ";
  }

  return "";
}
