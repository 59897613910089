import { AxiosResponse } from "axios";
import { QueryFunction } from "react-query";
import { News } from "types/news";
import axios from "utils/axios";

export const fetchUnreadNews = async () => {
  const { data } = await axios.get<{ news: News[] }>(
    process.env.REACT_APP_ENDPOINT_DRIVER_PROFILE + "/news/unread"
  );
  return data;
};

export const fetchNewsDetails: QueryFunction<News> = async ({ queryKey }) => {
  const [, newsId] = queryKey;
  const { data } = (await axios.get<News>(
    process.env.REACT_APP_ENDPOINT_DRIVER_PROFILE + `/news/${newsId}`
  )) as AxiosResponse<News>;
  return data;
};

export const fetchReadNews = async () => {
  const { data } = await axios.get<{ news: News[] }>(
    process.env.REACT_APP_ENDPOINT_DRIVER_PROFILE + "/news/read"
  );
  return data;
};

export const fetchAllNews = async () => {
  const { data } = await axios.get<{ news: News[] }>(
    process.env.REACT_APP_ENDPOINT_DRIVER_PROFILE + "/news/all"
  );
  return data;
};

export const fetchUnreadNewsCount = async () => {
  const { data } = await axios.get<{ unreadCount: number }>(
    process.env.REACT_APP_ENDPOINT_DRIVER_PROFILE + "/news/count"
  );
  return data;
};

export const markAsReadNews = async (newsId: number) => {
  return await axios.put(
    process.env.REACT_APP_ENDPOINT_DRIVER_PROFILE +
      `/news/mark-as-read/${newsId}`
  );
};

export const markAllReadNews = async () => {
  return await axios.put(
    process.env.REACT_APP_ENDPOINT_DRIVER_PROFILE + `/news/mark-all-read`
  );
};

export const markAsUnReadNews = async (newsId: number) => {
  return await axios.put(
    process.env.REACT_APP_ENDPOINT_DRIVER_PROFILE +
      `/news/mark-as-unread/${newsId}`
  );
};
