import React, { FC, useState } from "react";
import {
  EuiBasicTable,
  EuiButton,
  EuiFieldText,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSpacer,
  EuiText,
  EuiTitle
} from "@elastic/eui";
import { useMutation } from "react-query";
import { useUIContext } from "../../contexts/ui";
import axios from "../../utils/axios";
import { R3eUserResponseDto } from "../../types/profile";
import { useUserContext } from "../../contexts/user";
import { saveUser } from "../../api/auth";

interface Props {
  triggerText?: React.ReactNode;
}

export const R3EConnectMenu: FC<Props> = ({
  triggerText = "Connect your RaceRoom account"
}) => {
  const { emitErrorToast, emitSuccessToast } = useUIContext();
  const { user, refreshUserToken } = useUserContext();

  const [opened, setOpened] = useState(false);
  const [userName, setUserName] = useState("");
  const [userDetails, setUserDetails] = useState<R3eUserResponseDto>();

  const mutation = useMutation(saveUser, {
    onSuccess: () => {
      emitSuccessToast({
        title: "Your r3e user name was assigned to your rco account"
      });

      setOpened(false);
      refreshUserToken();
    },
    onError: (error: any) => {
      const responseErrorMessage = error?.response?.data?.message;
      const errorMessage =
        responseErrorMessage ||
        error.message ||
        "Something went wrong. Please try again later.";
      emitErrorToast({ text: errorMessage });
    }
  });

  const r3eUserDetailsMutation = useMutation(
    () => {
      return axios.get<R3eUserResponseDto>(
        `${process.env.REACT_APP_ENDPOINT_DRIVER_ACCESS}/user/r3e-user/${userName}`
      );
    },
    {
      onSuccess: res => {
        setUserDetails(res.data);
      },
      onError: (error: any) => {
        const responseErrorMessage = error?.response?.data?.message;
        const errorMessage =
          responseErrorMessage ||
          error.message ||
          "Something went wrong. Please try again later.";
        emitErrorToast({
          text: `${errorMessage}. Please check if the provided userName is correct.`
        });
      }
    }
  );

  return (
    <div>
      {user && (
        <EuiButton onClick={() => setOpened(true)}>{triggerText}</EuiButton>
      )}
      {opened && (
        <EuiModal onClose={() => setOpened(false)} maxWidth={true}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              Connect with your R3E account
            </EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>
            <div>
              {!userDetails && (
                <div>
                  <EuiText size="m">
                    Please provide your Raceroom user name.
                  </EuiText>
                  <EuiText size="m">
                    You can find it in your Raceroom profile page url:
                  </EuiText>
                  <div>
                    <img
                      style={{ maxWidth: "400px" }}
                      src={
                        "https://storage.googleapis.com/rco-images/trivia/rco_r3e.jpg"
                      }
                      alt={"r3e url bar"}
                    />
                  </div>
                  <EuiSpacer size={"l"} />
                  <div style={{ display: "flex", gap: "20px" }}>
                    <EuiFormRow label={"Your R3E user name"}>
                      <EuiFieldText
                        placeholder="Your R3E user name"
                        name="userName"
                        aria-label="Your R3E id"
                        value={userName}
                        onChange={event => setUserName(event.target.value)}
                      />
                    </EuiFormRow>
                    <EuiFormRow hasEmptyLabelSpace={true}>
                      <EuiButton
                        onClick={() => r3eUserDetailsMutation.mutate()}
                        disabled={!userName}
                        isLoading={r3eUserDetailsMutation.isLoading}
                      >
                        Fetch your data
                      </EuiButton>
                    </EuiFormRow>
                  </div>
                </div>
              )}
              {userDetails && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px"
                  }}
                >
                  <EuiTitle size="xs">
                    <h3>Are these details correct?</h3>
                  </EuiTitle>
                  <EuiBasicTable<{
                    prop: string;
                    value: string | number | undefined;
                  }>
                    items={[
                      {
                        prop: "User name",
                        value: userDetails?.raceroomUserName
                      },
                      {
                        prop: "User id",
                        value: userDetails?.raceroomUserId
                      }
                    ]}
                    rowHeader="firstName"
                    columns={[
                      {
                        field: "prop",
                        name: "",
                        mobileOptions: {
                          show: true
                        },
                        render: (prop: string) => <b>{prop}</b>
                      },
                      {
                        field: "value",
                        name: "",
                        mobileOptions: {
                          show: true
                        }
                      }
                    ]}
                    responsive={true}
                    tableLayout="auto"
                  />
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <EuiButton
                      onClick={() => setUserDetails(undefined)}
                      isLoading={mutation.isLoading}
                    >
                      No
                    </EuiButton>
                    <EuiButton
                      onClick={() =>
                        mutation.mutate({
                          raceroomUserId: userDetails?.raceroomUserId
                        })
                      }
                      isLoading={mutation.isLoading}
                    >
                      Yes. Save them.
                    </EuiButton>
                  </div>
                </div>
              )}
            </div>
          </EuiModalBody>

          <EuiModalFooter>
            <EuiButton onClick={() => setOpened(false)} fill>
              Close
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      )}
    </div>
  );
};
