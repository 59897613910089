import React, { FC } from "react";
import { EuiCallOut, EuiCard, EuiSpacer } from "@elastic/eui";
import { R3EConnectMenu } from "../shared/R3eConnectMenu";
import { R3ERemoveIntegration } from "../shared/R3eRemoveIntegration";
import { useUserContext } from "../../contexts/user";

interface Props {}

export const SimIntegrations: FC<Props> = () => {
  const { user } = useUserContext();

  if (!user) {
    return null;
  }

  return (
    <EuiCard title={<span>R3E integration</span>} layout={"horizontal"}>
      <EuiSpacer size={"l"} />
      {user.isRaceroomAccountConnected ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px"
          }}
        >
          <EuiCallOut
            title="You are connected with R3E account!"
            color="success"
          />
          <div
            style={{
              display: "flex",
              gap: "20px",
              flexWrap: "wrap",
              justifyContent: "space-between",
              flex: 1
            }}
          >
            <R3EConnectMenu triggerText={"Edit"} />
            <R3ERemoveIntegration />
          </div>
        </div>
      ) : (
        <R3EConnectMenu />
      )}
    </EuiCard>
  );
};
