import React, { FC, useState } from "react";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { WelcomeModal } from "./WelcomeModal";
import { EuiButton, EuiCheckbox } from "@elastic/eui";
import { ROUTES } from "../../constants/routes";
import { useUserContext } from "../../contexts/user";

interface Props {}

export const AutoTriggeredWelcomeModal: FC<Props> = () => {
  const [locallyClosed, setLocallyClosed] = useState(false);
  const [checked, setChecked] = useState(false);
  const [getWelcomeModalSeen, setWelcomeModalSeen] = useLocalStorage<boolean>(
    `welcome-modal`,
    false
  );
  const { user } = useUserContext();

  const handleClose = () => {
    if (checked) {
      setWelcomeModalSeen(true);
      setLocallyClosed(true);
      return;
    }

    setLocallyClosed(true);
  };

  if (
    getWelcomeModalSeen() ||
    locallyClosed ||
    window.location.pathname === ROUTES.FAQ ||
    !user
  ) {
    return null;
  }

  return (
    <WelcomeModal
      onClose={handleClose}
      footer={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "20px"
          }}
        >
          <EuiCheckbox
            id={"welcome-modal-checkbox"}
            label="Do not show it again"
            checked={checked}
            onChange={() => setChecked(!checked)}
          />
          <EuiButton onClick={handleClose} fill={true}>
            Close
          </EuiButton>
        </div>
      }
    />
  );
};
