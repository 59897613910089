import { ConnectionString } from "types/raceSeries";
import { EuiContextMenuItem } from "@elastic/eui";
import React from "react";

type ScreenTypeMenuItemsProps = {
  onClick: (connectionString: ConnectionString["connectionString"]) => void;
} & ConnectionString;

export const ScreenTypeMenuItem = ({
  connectionString,
  screenType,
  onClick
}: ScreenTypeMenuItemsProps) => (
  <EuiContextMenuItem
    key={screenType}
    icon={screenType === "VR" ? "glasses" : "fullScreen"}
    size="s"
    onClick={() => onClick(connectionString)}
  >
    {screenType === "VR" ? "Join VR" : "Join"}
  </EuiContextMenuItem>
);
