import {
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiFlexGroup,
  EuiFlexItem
} from "@elastic/eui";
import React, { FC } from "react";
import { RaceSerie } from "types/raceSeries";

interface Props {
  raceSeriesDetails: RaceSerie;
}

export const SeriesInfo: FC<Props> = ({ raceSeriesDetails }) => {
  type RaceProp = {
    prop: string;
    value: string;
  };

  const raceProps1: RaceProp[] = [
    {
      prop: "Minimum license",
      value: `${raceSeriesDetails?.event.raceSeriesVehicleClassGroups.groups.map(g => g.vehicleClasses.map(c => c.name)).join(', ')}`
    },
    {
      prop: "Grid size",
      value: `${raceSeriesDetails?.gridSize} / unlimited splits`
    },
    {
      prop: "Race length",
      value: `${raceSeriesDetails?.event.raceSessionDurationInMinutes} min.`
    },
    {
      prop: "In game race start",
      value: `${raceSeriesDetails?.event?.race?.inGameRaceStartDateTime}`
    },
    {
      prop: "Qualy length",
      value: `${raceSeriesDetails?.event?.qualificationSessionDurationInMinutes} min.`
    },
    {
      prop: "In game qualy start",
      value: `${raceSeriesDetails?.event?.race?.inGameQualyStartDateTime}`
    }
  ];
  const raceProps2: RaceProp[] = [
    { prop: "Setup", value: `${raceSeriesDetails?.carSetup}` },
    {
      prop: "Full Course Yellow",
      value: `${raceSeriesDetails?.fullCourseYellow}`
    },
    {
      prop: "Time progression",
      value: `X${raceSeriesDetails?.timeProgression}`
    },
    { prop: "Fuel usage", value: `${raceSeriesDetails?.fuelUsage}` },
    { prop: "Tire wear", value: `${raceSeriesDetails?.tireWear}` },
    {
      prop: "Mandatory pitstop",
      value: `${raceSeriesDetails?.mandatoryPitstops}`
    }
  ];

  const columns: Array<EuiBasicTableColumn<RaceProp>> = [
    {
      field: "prop",
      name: "",
      truncateText: true,
      mobileOptions: {
        show: true
      },
      render: (prop: string) => <b>{prop}</b>
    },
    {
      field: "value",
      name: "",
      truncateText: true,
      mobileOptions: {
        show: true
      }
    }
  ];

  return (
    <EuiFlexGroup>
      <EuiFlexItem>
        <EuiBasicTable
          items={raceProps1}
          rowHeader="prop"
          columns={columns}
          responsive={true}
          tableLayout="auto"
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiBasicTable
          items={raceProps2}
          rowHeader="prop"
          columns={columns}
          responsive={true}
          tableLayout="auto"
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
