import React, { useState } from "react";
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiHeaderSectionItemButton,
  EuiLink,
  EuiPopover,
  EuiSpacer,
  EuiText
} from "@elastic/eui";
import { useUserContext } from "contexts/user";
import { User } from "types/auth";
import { logout } from "api/auth";
import { useUIContext } from "contexts/ui";
import { TOAST_LIFE_TIME } from "components/GlobalToast/GlobalToast";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../../constants/routes";
import { UserAvatar } from "../../../shared/UserAvatar";

export const UserMenu: React.FC = () => {
  const [open, setOpen] = useState(false);
  const { user, setUserWithHeaders } = useUserContext();
  const {
    emailAddress,
    steamDisplayName,
    steamId,
    steamAvatarUrl
  } = user as User;
  const { emitToast, clearToasts, setGlobalLoading } = useUIContext();

  const logOutHandler = async () => {
    try {
      setGlobalLoading({ state: true });
      await logout();
      clearToasts();
      emitToast({
        title: "You have been logged out.",
        toastLifeTimeMs: TOAST_LIFE_TIME.DEFAULT
      });
      setUserWithHeaders(null);
    } catch (error) {
    } finally {
      setGlobalLoading({ state: false });
    }
    clearToasts();
    emitToast({
      title: "You have been logged out.",
      toastLifeTimeMs: TOAST_LIFE_TIME.DEFAULT
    });
    setUserWithHeaders(null);
  };

  const button = (
    <EuiHeaderSectionItemButton
      aria-controls="headerUserMenu"
      aria-expanded={open}
      aria-haspopup="true"
      aria-label="User menu"
      data-testid="userMenuButton"
      onClick={() => setOpen(prevOpenState => !prevOpenState)}
    >
      <UserAvatar imageUrl={steamAvatarUrl} name={steamDisplayName} size="s" />
    </EuiHeaderSectionItemButton>
  );

  return (
    <EuiPopover
      id="headerUserMenu"
      ownFocus
      button={button}
      isOpen={open}
      anchorPosition="downRight"
      closePopover={() => setOpen(false)}
      panelPaddingSize="none"
      repositionOnScroll
    >
      <div style={{ width: 320 }}>
        <EuiFlexGroup
          gutterSize="m"
          data-testid="userMenu"
          className="euiHeaderProfile"
          responsive={false}
        >
          <EuiFlexItem grow={false}>
            <UserAvatar
              name={steamDisplayName}
              imageUrl={steamAvatarUrl}
              size="xl"
            />
          </EuiFlexItem>

          <EuiFlexItem>
            <EuiText data-testid="userEmail">
              <p>{emailAddress}</p>
            </EuiText>
            <EuiText data-testid="steamDisplayName">
              <p>{steamDisplayName}</p>
            </EuiText>

            <EuiSpacer size="m" />

            <EuiLink>
              <Link
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px"
                }}
                to={ROUTES.YOUR_PROFILE}
              >
                Your profile
              </Link>
            </EuiLink>

            <EuiSpacer size="l" />

            <EuiLink data-testid="logOutButton" onClick={logOutHandler}>
              Log out
            </EuiLink>
          </EuiFlexItem>
        </EuiFlexGroup>
      </div>
    </EuiPopover>
  );
};
