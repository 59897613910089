import { ROUTES } from "constants/routes";
import { UIContextProvider } from "contexts/ui";
import { UserContextProvider } from "contexts/user";
import { WebSocketServiceContextProvider } from "contexts/webSocketService/webSocketService";
import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "styles/fonts.css";
import "@elastic/eui/dist/eui_theme_light.css";
import "@elastic/eui/dist/eui_theme_dark.css";
import { getRootPathnameSegment } from "utils/url";
import { Faq } from "./Faq/Faq";
import { GlobalLayout } from "./Layout/GlobalLayout";
import { Loader } from "./Loader/Loader";
import { NotFound } from "./NotFound/NotFound";
import { ResetPassword } from "./ResetPassword/ResetPassword";
import { AppGlobalRoute } from "./Router/AppGlobalRoute";
import { Standings } from "./Standings/Standings";
import { SEloHistory } from "./Stats/SEloHistory/SEloHistory";
import { RaceDetails } from "./Stats/RaceHistory/RaceDetails/RaceDetails";
import { RaceHistory } from "./Stats/RaceHistory/RaceHistory";
import { RatingsHistory } from "./Stats/RatingsHistory/RatingsHistory";
import { Stats } from "./Stats/Stats";
import { NewsListContainer } from "./News/list/NewsListContainer";
import { NewsContextProvider } from "./News/newsContext";
import { RaceSeriesDetails } from "./RaceSeriesDetails/RaceSeriesDetails";
import { SeasonsDashboard } from "./Admin/Seasons/SeasonsDashboard";
import { YourProfile } from "./YourProfile/YourProfile";
import { RaceSeriesContextProvider } from "./RaceSeries/raceSeriesContext";
import { RacesListScreen } from "./RaceSeries/RacesListScreen";
import { GlobalAuthWrapper } from "./GlobalAuthWrapper";
import { MaintenancePage } from "./MaintenancePage";
import { ErrorPage } from "./ErrorPage";
import { Telemetry } from "./Telemetry/Telemetry";
import { TelemetrySession } from "./Telemetry/TelemetrySession";
import { AuthenticationPage } from "./AuthenticationPage/AuthenticationPage";
import { TelemetryAnalysis } from "./Telemetry/TelemetryAnalysis";

const queryClient = new QueryClient();

const App = () => (
  <UIContextProvider>
    <UserContextProvider>
      <QueryClientProvider client={queryClient}>
        <WebSocketServiceContextProvider>
          <Router>
            <Loader />
            <ErrorPage>
              <MaintenancePage>
                <GlobalAuthWrapper>
                  <AuthenticationPage />
                  <NewsContextProvider>
                    <RaceSeriesContextProvider>
                      <Switch>
                        <Route
                          render={({ location }) => (
                            <GlobalLayout>
                              <Switch
                                location={location}
                                key={getRootPathnameSegment(location.pathname)}
                              >
                                <AppGlobalRoute
                                  documentTitle={"rco - reset password"}
                                  path={`${ROUTES.RESET_PASSWORD}/:token`}
                                >
                                  <ResetPassword />
                                </AppGlobalRoute>
                                <AppGlobalRoute
                                  documentTitle={"rco - your profile"}
                                  path={ROUTES.YOUR_PROFILE}
                                  onlyForAuthenticatedUsers={true}
                                >
                                  <YourProfile />
                                </AppGlobalRoute>
                                <AppGlobalRoute
                                  documentTitle={"rco - driver stats"}
                                  path={`${ROUTES.STATS}/:driverId`}
                                >
                                  <Stats />
                                </AppGlobalRoute>
                                <AppGlobalRoute
                                  documentTitle={"rco - s.elo view"}
                                  path={`${ROUTES.SELO_VIEW}`}
                                >
                                  <SEloHistory />
                                </AppGlobalRoute>
                                <AppGlobalRoute
                                  documentTitle={"rco - ratings history"}
                                  path={`${ROUTES.RATINGS_HISTORY}`}
                                >
                                  <RatingsHistory />
                                </AppGlobalRoute>
                                <AppGlobalRoute
                                  documentTitle={"rco - races history"}
                                  path={`${ROUTES.RACE_HISTORY}`}
                                >
                                  <RaceHistory />
                                </AppGlobalRoute>
                                <AppGlobalRoute
                                  documentTitle={"rco - race details"}
                                  path={`${ROUTES.RACE_HISTORY}/:raceId`}
                                >
                                  <RaceDetails />
                                </AppGlobalRoute>
                                <AppGlobalRoute
                                  documentTitle={"rco - standings"}
                                  path={ROUTES.STANDINGS}
                                >
                                  <Standings />
                                </AppGlobalRoute>
                                <AppGlobalRoute
                                  documentTitle={"rco - race series details"}
                                  path={`${ROUTES.RACE_SERIES_DETAILS}/:raceSeriesId`}
                                >
                                  <RaceSeriesDetails />
                                </AppGlobalRoute>
                                <AppGlobalRoute
                                  exact
                                  path={[
                                    ROUTES.HOME,
                                    ROUTES.RACES_LIST,
                                  ]}
                                >
                                  <RacesListScreen />
                                </AppGlobalRoute>
                                <AppGlobalRoute
                                  documentTitle={"rco - faq"}
                                  path={ROUTES.FAQ}
                                >
                                  <Faq />
                                </AppGlobalRoute>
                                <AppGlobalRoute
                                  documentTitle={"rco - news"}
                                  path={ROUTES.NEWS}
                                  onlyForAuthenticatedUsers={true}
                                >
                                  <NewsListContainer />
                                </AppGlobalRoute>
                                <AppGlobalRoute
                                  documentTitle={"rco - telemetry analysis"}
                                  path={ROUTES.TELEMETRY_ANALYSIS}
                                  roles={["Admin"]}
                                  onlyForAuthenticatedUsers={true}
                                  fullWidth={true}
                                >
                                  <TelemetryAnalysis />
                                </AppGlobalRoute>
                                <AppGlobalRoute
                                  documentTitle={"rco - telemetry session"}
                                  path={ROUTES.TELEMETRY_SESSION}
                                  roles={["Admin"]}
                                  onlyForAuthenticatedUsers={true}
                                >
                                  <TelemetrySession />
                                </AppGlobalRoute>
                                <AppGlobalRoute
                                  documentTitle={"rco - telemetry"}
                                  path={ROUTES.TELEMETRY}
                                  roles={["Admin"]}
                                  onlyForAuthenticatedUsers={true}
                                >
                                  <Telemetry />
                                </AppGlobalRoute>
                                <AppGlobalRoute
                                  documentTitle={"admin - seasons"}
                                  roles={["Admin"]}
                                  path={ROUTES.MANAGE_SEASONS}
                                  fullWidth={true}
                                >
                                  <SeasonsDashboard />
                                </AppGlobalRoute>
                                <AppGlobalRoute
                                  documentTitle={"rco - :("}
                                  path="*"
                                >
                                  <NotFound />
                                </AppGlobalRoute>
                              </Switch>
                            </GlobalLayout>
                          )}
                        />
                      </Switch>
                    </RaceSeriesContextProvider>
                  </NewsContextProvider>
                </GlobalAuthWrapper>
              </MaintenancePage>
            </ErrorPage>
          </Router>
        </WebSocketServiceContextProvider>
      </QueryClientProvider>
    </UserContextProvider>
  </UIContextProvider>
);

export default App;
