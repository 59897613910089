import React, { FC } from "react";
import {
  EuiButton,
  EuiButtonIcon,
  EuiComboBox,
  EuiDatePicker,
  EuiFieldNumber,
  EuiForm,
  EuiFormRow,
  EuiHorizontalRule,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle
} from "@elastic/eui";
import {
  RaceLicensesResponseDto,
  VehiclesAndTracksResponseDto,
  WeeklyEvent
} from "../../../types/admin";
import { useMutation } from "react-query";
import { updateWeeklyEvent } from "../../../api/admin";
import { useUIContext } from "../../../contexts/ui";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { updateWeeklyEventValidator } from "./formValidator";
import moment, { Moment } from "moment";

type FormClassGroupType = {
  classGroupRank: number;
  raceLicenseId: { value: number; label: string };
  vehicleClasses: { value: number; label: string }[];
}[];

interface Props {
  opened: boolean;
  raceName: string;
  weeklyEvent: WeeklyEvent;
  raceLicenses: RaceLicensesResponseDto;
  vehicleAndTracks: VehiclesAndTracksResponseDto;
  onClose(): void;
  refetch(): void;
}

export const RaceSeriesEdit: FC<Props> = ({
  opened,
  onClose,
  weeklyEvent,
  raceLicenses,
  vehicleAndTracks,
  raceName,
  refetch
}) => {
  const { emitErrorToast } = useUIContext();

  const defaultTrack = vehicleAndTracks.tracks.find(
    t => t.id === weeklyEvent.track.trackId
  );
  const defaultClassGroups: FormClassGroupType = weeklyEvent.classGroups.map(
    cg => {
      const matchingRaceLicense = raceLicenses.raceLicensesList.find(
        rl => rl.raceLicenseId === cg.raceLicenseId
      )!;

      return {
        classGroupRank: cg.classGroupRank,
        vehicleClasses: cg.vehicleClasses.map(vc => {
          const matchingVehicleClass = vehicleAndTracks.vehicleClasses.find(
            vcList => vcList.id === vc.vehicleClassId
          )!;

          return {
            value: matchingVehicleClass.id,
            label: matchingVehicleClass.name
          };
        }),
        raceLicenseId: {
          value: matchingRaceLicense.raceLicenseId,
          label: matchingRaceLicense.raceLicenseName
        }
      };
    }
  );
  const { handleSubmit, register, errors, control, watch } = useForm<{
    classGroups: FormClassGroupType;
    track: { value: number; label: string };
    raceDurationInMinutes: number;
    qualyDurationInMinutes: number;
    inGameQualyStartDate?: Moment | null;
    inGameRaceStartDate?: Moment | null;
  }>({
    defaultValues: {
      classGroups: defaultClassGroups || undefined,
      track: defaultTrack
        ? { value: defaultTrack.id, label: defaultTrack.name }
        : undefined,
      raceDurationInMinutes: weeklyEvent.raceDurationInMinutes,
      qualyDurationInMinutes: weeklyEvent.qualificationDurationInMinutes,
      inGameQualyStartDate: moment(
        weeklyEvent.inGameQualyStartDate.endsWith("Z")
          ? weeklyEvent.inGameQualyStartDate
          : weeklyEvent.inGameQualyStartDate + "Z"
      ).utc(),
      inGameRaceStartDate: moment(
        weeklyEvent.inGameRaceStartDate.endsWith("Z")
          ? weeklyEvent.inGameRaceStartDate
          : weeklyEvent.inGameRaceStartDate + "Z"
      ).utc()
    },
    mode: "onBlur",
    resolver: zodResolver(updateWeeklyEventValidator)
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "classGroups"
  });

  const updateWeeklyEventMutation = useMutation(updateWeeklyEvent);

  const watchedFormClassGroups = watch("classGroups");
  return (
    <div>
      {opened && (
        <EuiModal onClose={() => onClose()} style={{ width: "800px" }}>
          <EuiForm
            component="form"
            onSubmit={handleSubmit(
              data => {
                updateWeeklyEventMutation
                  .mutateAsync({
                    payload: {
                      TrackId: data.track.value,
                      InGameQualyStartDate: data.inGameQualyStartDate?.format(
                        "YYYY-MM-DDTHH:mm:ss[Z]"
                      )!,
                      InGameRaceStartDate: data.inGameRaceStartDate?.format(
                        "YYYY-MM-DDTHH:mm:ss[Z]"
                      )!,
                      qualyDurationInMinutes: data.qualyDurationInMinutes,
                      raceDurationInMinutes: data.raceDurationInMinutes,
                      classGroups: data.classGroups.map(cg => ({
                        classGroupRank: cg.classGroupRank,
                        raceLicenseId: cg.raceLicenseId.value,
                        vehicleClasses: cg.vehicleClasses.map(vc => ({
                          vehicleClassId: vc.value
                        }))
                      }))
                    },
                    params: {
                      id: weeklyEvent.id
                    }
                  })
                  .then(() => {
                    refetch();
                    onClose();
                  })
                  .catch(error => {
                    emitErrorToast({
                      title: "error error, call an ambulance"
                    });
                  });
              },
              errors => {
                console.log("errror", errors);
              }
            )}
          >
            <EuiModalHeader>
              <EuiModalHeaderTitle>
                You are editing {raceName} week {weeklyEvent.weekNumber}
              </EuiModalHeaderTitle>
            </EuiModalHeader>

            <EuiModalBody>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  height: "500px",
                  overflow: "auto"
                }}
              >
                {fields.map((field, index) => (
                  <div
                    key={field.id}
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center"
                    }}
                  >
                    <EuiFormRow
                      style={{ margin: 0, width: "100px" }}
                      isInvalid={Boolean(
                        errors.classGroups?.[index]?.classGroupRank
                      )}
                      error={
                        errors.classGroups?.[index]?.classGroupRank?.message
                      }
                      label={"Class group rank"}
                    >
                      <EuiFieldNumber
                        inputRef={register}
                        min={1}
                        name={`classGroups.${index}.classGroupRank`}
                        placeholder={"1, 2, 3..."}
                        aria-label="classRank"
                      />
                    </EuiFormRow>
                    <Controller
                      control={control}
                      defaultValue={null}
                      name={`classGroups.${index}.raceLicenseId`}
                      render={({ onChange, onBlur, value }) => (
                        <EuiFormRow
                          style={{ margin: 0, width: "150px" }}
                          isInvalid={Boolean(
                            errors.classGroups?.[index]?.raceLicenseId
                          )}
                          error={
                            // @ts-ignore
                            errors.classGroups?.[index]?.raceLicenseId?.message
                          }
                          label={"Race license"}
                        >
                          <EuiComboBox<number>
                            placeholder="Select race license"
                            singleSelection={{ asPlainText: true }}
                            options={[...raceLicenses.raceLicensesList]
                              .sort((a, b) => {
                                return a.raceLicenseLevel - b.raceLicenseLevel;
                              })
                              .map(v => ({
                                value: v.raceLicenseId,
                                label: v.raceLicenseName
                              }))}
                            selectedOptions={value ? [value] : []}
                            onChange={value => {
                              onChange(value[0]);
                            }}
                            onBlur={onBlur}
                          />
                        </EuiFormRow>
                      )}
                    />
                    <Controller
                      control={control}
                      defaultValue={null}
                      name={`classGroups.${index}.vehicleClasses`}
                      render={({ onChange, onBlur, value }) => (
                        <EuiFormRow
                          style={{ margin: 0, flex: 1 }}
                          isInvalid={Boolean(
                            errors.classGroups?.[index]?.vehicleClasses
                          )}
                          error={
                            // @ts-ignore
                            errors.classGroups?.[index]?.vehicleClasses?.message
                          }
                          label={"Vehicle class"}
                        >
                          <EuiComboBox<number>
                            placeholder="Select vehicle classes"
                            options={[
                              ...vehicleAndTracks.vehicleClasses.filter(
                                vc =>
                                  !watchedFormClassGroups
                                    .flatMap(cg => cg.vehicleClasses)
                                    .filter(_ => _)
                                    .some(
                                      cgVehicleClass =>
                                        cgVehicleClass.value === vc.id
                                    )
                              )
                            ]
                              .sort((a, b) => {
                                return a.name.localeCompare(b.name);
                              })
                              .map(v => ({
                                value: v.id,
                                label: v.name
                              }))}
                            selectedOptions={value || []}
                            onChange={value => {
                              onChange(value);
                            }}
                            onBlur={onBlur}
                          />
                        </EuiFormRow>
                      )}
                    />
                    <EuiButtonIcon
                      iconType={"trash"}
                      color={"danger"}
                      size={"m"}
                      onClick={() => remove(index)}
                    />
                  </div>
                ))}
                <EuiFormRow
                  isInvalid={Boolean(errors.classGroups)}
                  error={
                    // @ts-ignore
                    errors.classGroups?.message
                  }
                >
                  <EuiButton
                    onClick={() =>
                      append({
                        classGroupRank: 1,
                        raceLicenseId: undefined,
                        vehicleClasses: []
                      })
                    }
                  >
                    Add new class group
                  </EuiButton>
                </EuiFormRow>
                <EuiHorizontalRule />
                <Controller
                  control={control}
                  defaultValue={null}
                  name={"track"}
                  render={({ onChange, onBlur, value }) => (
                    <EuiFormRow
                      isInvalid={Boolean(errors.track)}
                      error={errors.track?.value?.message}
                      label={"Track"}
                    >
                      <EuiComboBox<number>
                        placeholder="Select a track"
                        singleSelection={{ asPlainText: true }}
                        options={[...vehicleAndTracks.tracks]
                          .sort((a, b) => {
                            return a.name.localeCompare(b.name);
                          })
                          .map(v => ({
                            value: v.id,
                            label: v.name
                          }))}
                        selectedOptions={value ? [value] : []}
                        onChange={value => {
                          onChange(value[0]);
                        }}
                        onBlur={onBlur}
                      />
                    </EuiFormRow>
                  )}
                />
                <EuiFormRow
                  isInvalid={Boolean(errors.raceDurationInMinutes)}
                  error={errors.raceDurationInMinutes?.message}
                  label={"Race duration in minutes"}
                >
                  <EuiFieldNumber
                    inputRef={register}
                    name={"raceDurationInMinutes"}
                    placeholder="set race duration in minutes"
                    isInvalid={Boolean(errors.raceDurationInMinutes)}
                    aria-label="raceDurationInMinutes"
                  />
                </EuiFormRow>
                <EuiFormRow
                  isInvalid={Boolean(errors.qualyDurationInMinutes)}
                  error={errors.qualyDurationInMinutes?.message}
                  label={"Qualification duration in minutes"}
                >
                  <EuiFieldNumber
                    inputRef={register}
                    name={"qualyDurationInMinutes"}
                    placeholder="Set qualy duration in minutes"
                    isInvalid={Boolean(errors.qualyDurationInMinutes)}
                    aria-label="qualyDurationInMinutes"
                  />
                </EuiFormRow>
                <Controller
                  control={control}
                  name={"inGameQualyStartDate"}
                  render={({ onChange, onBlur, value }) => (
                    <EuiFormRow
                      isInvalid={Boolean(errors.inGameQualyStartDate)}
                      error={errors.inGameQualyStartDate?.message}
                      label={"In game qualy time (in UTC)"}
                    >
                      <EuiDatePicker
                        showTimeSelect={true}
                        placeholder="in game qualy start date"
                        isInvalid={Boolean(errors.inGameQualyStartDate)}
                        selected={value}
                        onSelect={onChange}
                        onBlur={onBlur}
                        dateFormat="DD-MM-YYYY HH:mm"
                        timeFormat="HH:mm"
                      />
                    </EuiFormRow>
                  )}
                />
                <Controller
                  control={control}
                  name={"inGameRaceStartDate"}
                  render={({ onChange, onBlur, value }) => (
                    <EuiFormRow
                      isInvalid={Boolean(errors.inGameRaceStartDate)}
                      error={errors.inGameRaceStartDate?.message}
                      label={"In game race start time (in UTC)"}
                    >
                      <EuiDatePicker
                        showTimeSelect={true}
                        placeholder="in game race start date"
                        isInvalid={Boolean(errors.inGameRaceStartDate)}
                        selected={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        dateFormat="DD-MM-YYYY HH:mm"
                        timeFormat="HH:mm"
                      />
                    </EuiFormRow>
                  )}
                />
              </div>
            </EuiModalBody>

            <EuiModalFooter>
              <EuiButton
                type={"submit"}
                fill
                isLoading={updateWeeklyEventMutation.status === "loading"}
              >
                Submit
              </EuiButton>
              <EuiButton color={"text"} onClick={() => onClose()} fill>
                Close
              </EuiButton>
            </EuiModalFooter>
          </EuiForm>
        </EuiModal>
      )}
    </div>
  );
};
