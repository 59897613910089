import React, { FC, useState } from "react";
import {
  IRaceSeries,
  RaceLicensesResponseDto,
  Season,
  VehiclesAndTracksResponseDto,
  WeeklyEvent,
  WeeklyEventsResponseDto
} from "../../../types/admin";
import {
  EuiBadge,
  EuiBasicTable,
  EuiEmptyPrompt,
  EuiPanel,
  EuiSkeletonRectangle,
  EuiSpacer,
  EuiStat,
  EuiToolTip
} from "@elastic/eui";
import { useQuery } from "react-query";
import {
  fetchRaceLicences,
  fetchVehiclesAndTracks,
  fetchWeeklyEvents
} from "../../../api/admin";
import { useUIContext } from "../../../contexts/ui";
import { useTablePagination } from "../../../hooks/useTablePagination";
import { useTableSort } from "../../../hooks/useTableSort";
import { RaceSeriesEdit } from "./RaceSeriesEdit";
import moment from "moment";
import { formatAsUtc } from "../../../utils/dates";
import { badgePropsMap } from "../../RaceLicenceBadge";

interface Props {
  season: Season;
  raceSeries: IRaceSeries;
}

export const RaceSeries: FC<Props> = ({ raceSeries, season }) => {
  const { emitErrorToast } = useUIContext();

  const [editing, setEditing] = useState<WeeklyEvent | null>(null);

  const weeklyEventsQuery = useQuery<
    unknown,
    ResponseError,
    WeeklyEventsResponseDto
  >(["adminRaceSeries", season.id, raceSeries.id], fetchWeeklyEvents, {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    onError: error => {
      emitErrorToast({
        title: error.response.data || error.message || "Can't fetch seasons"
      });
    }
  });
  const vehiclesAndTracksQuery = useQuery<
    unknown,
    ResponseError,
    VehiclesAndTracksResponseDto
  >(["adminVehicleAndTracks", editing?.sim.id], fetchVehiclesAndTracks, {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    enabled: !!editing,
    onError: error => {
      emitErrorToast({
        title: error.response.data || error.message || "Can't fetch seasons"
      });
    }
  });
  const raceLicensesQuery = useQuery<
    unknown,
    ResponseError,
    RaceLicensesResponseDto
  >(["raceLicenses"], fetchRaceLicences, {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    onError: error => {
      emitErrorToast({
        title:
          error.response.data || error.message || "Can't fetch race licenses"
      });
    }
  });

  const {
    pagination,
    pageChangeHandler,
    getPage
  } = useTablePagination<WeeklyEvent>({
    initialPageSize: 10,
    controlledTotalCount: weeklyEventsQuery.data?.weeklyEventsList.length
  });
  const {
    sortField,
    sortDirection,
    sortChangeHandler,
    sortData
  } = useTableSort<WeeklyEvent>({
    initialSortField: "weekNumber",
    initialSortDirection: "asc"
  });

  if (weeklyEventsQuery.isFetching) {
    return (
      <EuiSkeletonRectangle
        isLoading={true}
        width="100%"
        height="300px"
        borderRadius="s"
      />
    );
  }

  return (
    <EuiPanel color="subdued" paddingSize={"none"}>
      <EuiPanel hasBorder={true} style={{ display: "flex", gap: "30px" }}>
        <EuiStat
          title={raceSeries.category}
          description="Category"
          titleSize={"xxs"}
        />
        <EuiStat title={raceSeries.id} description="Id" titleSize={"xxs"} />
      </EuiPanel>
      <div style={{ marginTop: "10px" }}>Weekly Events</div>
      {(!weeklyEventsQuery.data ||
        weeklyEventsQuery.data.weeklyEventsList.length < 1) && (
        <EuiEmptyPrompt
          iconType="folderExclamation"
          title={<h2>No data</h2>}
          body={<p>Looks like there is no data to display</p>}
        />
      )}
      {weeklyEventsQuery.data &&
        weeklyEventsQuery.data.weeklyEventsList.length > 0 && (
          <EuiBasicTable<WeeklyEvent>
            style={{ maxHeight: "400px", overflow: "auto" }}
            items={getPage(sortData(weeklyEventsQuery.data.weeklyEventsList))}
            rowHeader="name"
            columns={[
              {
                field: "id",
                name: "id",
                style: { minWidth: "55px" }
              },
              {
                field: "weekNumber",
                name: "Week"
              },
              {
                field: "track.name",
                name: "Track name"
              },
              {
                field: "classGroups",
                name: "Class groups",
                render: (item: WeeklyEvent["classGroups"]) => {
                  return (
                    <div
                      style={{ display: "flex", gap: "15px", flexWrap: "wrap" }}
                    >
                      {item.map(classGroup => (
                        <EuiPanel
                          key={classGroup.classGroupRank}
                          paddingSize={"xs"}
                          style={{ position: "relative", minWidth: "60px" }}
                        >
                          <EuiBadge
                            style={{
                              ...badgePropsMap[classGroup.raceLicenseLevel][0],
                              position: "absolute",
                              top: -10,
                              left: 5
                            }}
                          >
                            <EuiToolTip content={classGroup.raceLicenseName}>
                              <span>CG{classGroup.classGroupRank}</span>
                            </EuiToolTip>
                          </EuiBadge>
                          <EuiSpacer size={"xs"} />
                          {classGroup.vehicleClasses.map(vc => (
                            <EuiBadge key={vc.vehicleClassId} color={"warning"}>
                              {vc.name}
                            </EuiBadge>
                          ))}
                        </EuiPanel>
                      ))}
                    </div>
                  );
                }
              },
              {
                field: "year",
                name: "Year",
                style: { minWidth: "55px" }
              },
              {
                field: "inGameQualyStartDate",
                name: "In game qualy start date (in UTC)",
                render: (item: WeeklyEvent["inGameQualyStartDate"]) => {
                  return (
                    <span>
                      {item === "0001-01-01T00:00:00"
                        ? "random"
                        : formatAsUtc(item)}
                    </span>
                  );
                }
              },
              {
                field: "inGameRaceStartDate",
                name: "In game race start date (in UTC)",
                render: (item: WeeklyEvent["inGameRaceStartDate"]) => {
                  return (
                    <span>
                      {item === "0001-01-01T00:00:00"
                        ? "random"
                        : formatAsUtc(item)}
                    </span>
                  );
                }
              },
              {
                name: "Actions",
                actions: [
                  {
                    name: "Edit",
                    description: "Edit this race series",
                    type: "icon",
                    icon: "pencil",
                    enabled: item =>
                      isEditable(
                        item.weekNumber,
                        item.year,
                        item.isCurrentlyActive
                      ),
                    onClick: item => setEditing(item)
                  }
                ]
              }
            ]}
            pagination={pagination}
            onChange={({ page, sort }) => {
              if (page) {
                pageChangeHandler(page);
              }
              if (sort) {
                sortChangeHandler(sort);
              }
            }}
            tableLayout="auto"
            sorting={{
              enableAllColumns: false,
              sort: {
                field: sortField,
                direction: sortDirection
              }
            }}
          />
        )}
      {editing && vehiclesAndTracksQuery.data && raceLicensesQuery.data && (
        <RaceSeriesEdit
          opened={!!editing}
          refetch={weeklyEventsQuery.refetch}
          raceName={raceSeries.name}
          onClose={() => setEditing(null)}
          weeklyEvent={editing}
          vehicleAndTracks={vehiclesAndTracksQuery.data}
          raceLicenses={raceLicensesQuery.data}
        />
      )}
    </EuiPanel>
  );
};

// actually the number of the week is +1 (or sth) to the isoWeek, so i also check isActive /shrug
const isEditable = (week: number, year: number, isActive: boolean) => {
  const getWeekActiveStatus = () => {
    if (isActive) {
      return "current";
    }

    if (year < moment().utc().year()) {
      return "past";
    }

    if (year > moment().utc().year()) {
      return "future";
    }

    if (week < moment().utc().isoWeek()) {
      return "past";
    }

    if (week > moment().utc().isoWeek()) {
      return "future";
    }

    return "current";
  };

  return ["current", "future"].some(s => s === getWeekActiveStatus());
};
