import { TOAST_LIFE_TIME } from "components/GlobalToast/GlobalToast";
import { useUIContext } from "contexts/ui";
import { useUserContext } from "contexts/user";
import { useWebSocketServiceContext } from "contexts/webSocketService/webSocketService";
import { useEffect } from "react";

export const useNewsNotififactionChannel = (params: {
  reloadNews: () => void;
}) => {
  const { reloadNews } = params;
  const { user } = useUserContext();
  const { emitSuccessToast } = useUIContext();
  const webSocket = useWebSocketServiceContext();

  useEffect(() => {
    if (webSocket.connected && user !== null) {
      webSocket.instance?.on("NewsNotification", (data: string) => {
        emitSuccessToast({
          title: `You have received new message. Check the news page.`,
          toastLifeTimeMs: TOAST_LIFE_TIME.INFINITE
        });
        reloadNews();
      });
    }
    return () => {
      if (webSocket.connected) {
        webSocket.instance?.off("NewsNotification");
      }
    };
  }, [
    emitSuccessToast,
    reloadNews,
    user,
    webSocket.connected,
    webSocket.instance
  ]);
};
