import {
  fetchAllNews,
  markAllReadNews,
  markAsReadNews,
  markAsUnReadNews
} from "api/news";
import { useUIContext } from "contexts/ui";
import { useUserContext } from "contexts/user";
import React, { createContext, useContext } from "react";
import { QueryStatus, useQuery } from "react-query";
import { News } from "types/news";
import { useNewsNotififactionChannel } from "./useNewsNotificationChannel";

type NewsContextType = {
  newsState: {
    data: News[];
    status: QueryStatus;
  };
  reloadNews: () => void;
  markUnreadNews: (newsId: number) => void;
  markReadNews: (newsId: number) => void;
  markAllAsRead: () => void;
};

export const NewsContext = createContext<NewsContextType>(
  {} as NewsContextType
);
NewsContext.displayName = "NewsContext";

export const NewsContextProvider: React.FC = ({ children }) => {
  const { emitErrorToast } = useUIContext();
  const { user } = useUserContext();

  const allNewsQuery = useQuery("allNewsQuery", fetchAllNews, {
    enabled: !!user,
    refetchOnWindowFocus: false,
    onError: () => emitErrorToast({ title: "We couldn't get news" })
  });

  const handleMarkUnreadMessage = async (newsId: number) => {
    try {
      await markAsUnReadNews(newsId);
      reloadNews();
    } catch (error) {
      emitErrorToast({ title: "Couldn't mark news as unread" });
    }
  };

  const handleMarkReadMessage = async (newsId: number) => {
    try {
      await markAsReadNews(newsId);
      reloadNews();
    } catch (error) {
      emitErrorToast({ title: "Couldn't mark news as read" });
    }
  };

  const handleMarkAllAsRead = async () => {
    try {
      await markAllReadNews();
      reloadNews();
    } catch (error) {
      emitErrorToast({ title: "Couldn't mark all news as read" });
    }
  };

  const reloadNews = () => {
    allNewsQuery.refetch();
  };

  useNewsNotififactionChannel({ reloadNews });
  return (
    <NewsContext.Provider
      value={{
        newsState: {
          data: allNewsQuery.data?.news ?? [],
          status: allNewsQuery.status
        },
        reloadNews: reloadNews,
        markReadNews: handleMarkReadMessage,
        markUnreadNews: handleMarkUnreadMessage,
        markAllAsRead: handleMarkAllAsRead
      }}
    >
      {children}
    </NewsContext.Provider>
  );
};

export const useNewsContext = () => useContext(NewsContext);
