import React, { useMemo, useState } from "react";
import {
  EuiHeaderSectionItemButton,
  EuiIcon,
  EuiKeyPadMenu,
  EuiKeyPadMenuItem,
  EuiNotificationBadge,
  EuiPopover
} from "@elastic/eui";
import { ReactComponent as DiscordIcon } from "assets/icons/discord-logo.svg";
import { ROUTES } from "constants/routes";
import { useUIContext } from "contexts/ui";
import { useUserContext } from "contexts/user";
import { useHistory } from "react-router-dom";
import { FeedbackModal } from "./FeedbackModal";
import { BiLogoPatreon, BiNews } from "react-icons/bi";
import { SiBuymeacoffee } from "react-icons/si";
import { useNewsContext } from "components/News/newsContext";
import { NotificationItem } from "components/NotificationItem/NotificationItem";

export const AppMenu = () => {
  const [open, setOpen] = useState(false);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const { switchTheme, themeMode } = useUIContext();
  const { newsState } = useNewsContext();
  const { push: pushLocation } = useHistory();
  const { user } = useUserContext();
  const redirect = (location: string) => {
    pushLocation(location);
    setOpen(false);
  };

  const unreadNotification = useMemo(() => {
    if (!user || newsState.data.filter(x => !x.isRead).length === 0) {
      return undefined;
    }
    return `${newsState.data.filter(x => !x.isRead).length}`;
  }, [newsState.data, user]);

  return (
    <>
      <EuiPopover
        button={
          <EuiHeaderSectionItemButton
            aria-controls="keyPadMenu"
            aria-expanded={open}
            aria-haspopup="true"
            aria-label="App menu"
            data-testid="appMenuButton"
            notification={unreadNotification}
            onClick={() => setOpen(prevOpenState => !prevOpenState)}
          >
            <EuiIcon type="apps" size="m" />
          </EuiHeaderSectionItemButton>
        }
        isOpen={open}
        anchorPosition="downLeft"
        closePopover={() => setOpen(false)}
        repositionOnScroll
      >
        <EuiKeyPadMenu id="keyPadMenu">
          <EuiKeyPadMenuItem
            label="Races"
            onClick={() => redirect(ROUTES.HOME)}
          >
            <EuiIcon type="graphApp" size="l" />
          </EuiKeyPadMenuItem>
          {user ? (
            <EuiKeyPadMenuItem
              label="My stats"
              onClick={() => redirect(`${ROUTES.STATS}/${user.id}`)}
            >
              <EuiIcon type="visualizeApp" size="l" />
            </EuiKeyPadMenuItem>
          ) : null}
          <EuiKeyPadMenuItem
            label="Standings"
            onClick={() => redirect(ROUTES.STANDINGS)}
          >
            <EuiIcon type="visTable" size="l" />
          </EuiKeyPadMenuItem>
          <EuiKeyPadMenuItem
            label={`Switch theme (${themeMode})`}
            data-testid="switchThemeButton"
            onClick={switchTheme}
          >
            <EuiIcon type="upgradeAssistantApp" size="l" />
          </EuiKeyPadMenuItem>
          <EuiKeyPadMenuItem
            label="Discord"
            onClick={() =>
              window
                .open(
                  "https://discord.gg/racecraft-online-789131939361062963",
                  "_blank"
                )
                ?.focus()
            }
          >
            <EuiIcon type={DiscordIcon} size="l" />
          </EuiKeyPadMenuItem>
          <EuiKeyPadMenuItem
            label="Send feedback"
            onClick={() => {
              setFeedbackModalOpen(true);
              setOpen(false);
            }}
          >
            <EuiIcon type="discuss" size="l" />
          </EuiKeyPadMenuItem>
          <EuiKeyPadMenuItem label="FAQ" onClick={() => redirect(ROUTES.FAQ)}>
            <EuiIcon type="questionInCircle" size="l" />
          </EuiKeyPadMenuItem>
          <EuiKeyPadMenuItem
            label="Patreon"
            onClick={() =>
              window
                .open("https://www.patreon.com/racecraftonline", "_blank")
                ?.focus()
            }
          >
            <EuiIcon type={BiLogoPatreon} size="l" />
          </EuiKeyPadMenuItem>
          <EuiKeyPadMenuItem
            label="Donations"
            onClick={() =>
              window
                .open("https://www.buymeacoffee.com/racecraftonline", "_blank")
                ?.focus()
            }
          >
            <EuiIcon type={SiBuymeacoffee} size="l" />
          </EuiKeyPadMenuItem>
          {user ? (
            <>
              <NotificationItem
                item={
                  <EuiKeyPadMenuItem
                    label="News"
                    data-testid="newsButton"
                    onClick={() => redirect(ROUTES.NEWS)}
                  >
                    <EuiIcon type={BiNews} size="l" />
                  </EuiKeyPadMenuItem>
                }
                notification={
                  unreadNotification ? (
                    <EuiNotificationBadge>
                      {unreadNotification}
                    </EuiNotificationBadge>
                  ) : undefined
                }
              />
            </>
          ) : null}
          {user?.userRoles.includes("Admin") && (
            <EuiKeyPadMenuItem
              label="Admin"
              onClick={() => redirect(ROUTES.MANAGE_SEASONS)}
            >
              <EuiIcon type="managementApp" size="l" />
            </EuiKeyPadMenuItem>
          )}
        </EuiKeyPadMenu>
      </EuiPopover>
      <FeedbackModal
        open={feedbackModalOpen}
        closeHandler={() => setFeedbackModalOpen(false)}
      />
    </>
  );
};
