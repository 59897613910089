import {
  EuiBadge,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiSpacer,
  EuiToolTip
} from "@elastic/eui";
import React from "react";
import { RaceSerie } from "types/raceSeries";
import { TrackName } from "components/RaceSeries/RaceCard/RaceCardHeader/TrackName";
import { VehicleClass } from "./VehcileClass";
import { DetailsImageWithBackground } from "./DetailsImageWithBackground";
import { RaceEventWeather } from "./RaceEventWeather";
import styled from "styled-components";
import { TrackImage } from "../TrackImage";
import { DetailsVehicleClassesImage } from "./DetailsVehicleClassesImage";

type DetailsProps = {
  raceSeriesDetails: RaceSerie;
};

const StyledSectionTitle = styled("p")`
  font-size: ${({ theme }) => theme.euiFontSizeM};
  font-weight: bold;
}`;

export const Details = ({ raceSeriesDetails }: DetailsProps) => (
  <EuiFlexGroup>
    <EuiFlexItem>
      <StyledSectionTitle>Vehicle class</StyledSectionTitle>
      <EuiSpacer size="s" />
      <DetailsVehicleClassesImage
        vehicleClasses={raceSeriesDetails.event.raceSeriesVehicleClassGroups.groups.flatMap(g => g.vehicleClasses)}
        className="coverImageShadowContainer"
      />
      <EuiSpacer size="m" />
      <EuiFlexGroup wrap responsive={false} gutterSize="xs">
        {
          raceSeriesDetails.event.raceSeriesVehicleClassGroups.groups.map((vehicleClassGroup) =>
            <EuiFlexItem grow={false} key={vehicleClassGroup.groupId}>
              <EuiBadge color="default">
                {vehicleClassGroup.requiredLicense.raceLicenseName}
              </EuiBadge>
            </EuiFlexItem>
          )
        }
      </EuiFlexGroup>
      <EuiSpacer size="m" />
      <VehicleClass vehicleClassName={raceSeriesDetails.event.raceSeriesVehicleClassGroups.groups.map(g => g.vehicleClasses.map(c => c.name)).join(', ')} />
      <EuiHorizontalRule margin="m" />
    </EuiFlexItem>
    <EuiFlexItem>
      <StyledSectionTitle>Circuit</StyledSectionTitle>
      <EuiSpacer size="s" />
      <TrackImage
        backgroundUrl={raceSeriesDetails.coverImageUrl} //"https://storage.googleapis.com/rco-images/track-photos/ams2/spa_francorchamps_2022_rx.png"
        imageUrl={raceSeriesDetails.event.track.trackMapUrl} //"https://storage.googleapis.com/rco-images/track-maps/ams2/spa_francorchamps_2022_rx.png"
        className="coverImageShadowContainer"
        simShortCode={raceSeriesDetails.sim.shortCode}
      />
      <EuiSpacer size="m" />
      <EuiFlexGroup wrap responsive={false} gutterSize="xs">
        <EuiFlexItem grow={false}>
          <EuiBadge color="default">
            {raceSeriesDetails.event.track.type}
          </EuiBadge>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiBadge color="default">
            Grade {raceSeriesDetails.event.track.grade}
          </EuiBadge>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiBadge color="default">
            {raceSeriesDetails.event.track.country}
          </EuiBadge>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiBadge color="default">
            {raceSeriesDetails.event.track.worldRegion}
          </EuiBadge>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="m" />
      <TrackName trackName={raceSeriesDetails.event.track.trackName} />
      <EuiHorizontalRule margin="m" />
      {/* </EuiPanel> */}
    </EuiFlexItem>
    <EuiFlexItem>
      <StyledSectionTitle>Weather forecast</StyledSectionTitle>
      <EuiSpacer size="s" />
      {raceSeriesDetails.event.race.weather && (
        <DetailsImageWithBackground
          backgroundUrl={raceSeriesDetails.event.race.weather.imageUrl} //"https://storage.googleapis.com/rco-images/weather-images/ams2/weather_heavycloud.png"
          imageUrl={raceSeriesDetails.event.race.weather.iconUrl} //"https://storage.googleapis.com/rco-images/weather-icons/ams2/weather_heavycloud.png"
        />
      )}
      <EuiSpacer size="m" />
      <EuiFlexGroup wrap responsive={false} gutterSize="xs">
        <EuiFlexItem grow={false}>
          <EuiToolTip
            position="bottom"
            content={<p>In game qualy session date and time.</p>}
          >
            <EuiBadge iconType="clock">
              {`${raceSeriesDetails.event.race.inGameQualyStartDateTime}` ||
                ""}
            </EuiBadge>
          </EuiToolTip>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiToolTip
            position="bottom"
            content={<p>In game race session start date and time.</p>}
          >
            <EuiBadge iconType="flag">
              {`${raceSeriesDetails.event.race.inGameRaceStartDateTime}` ||
                ""}
            </EuiBadge>
          </EuiToolTip>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer size="m" />
      <RaceEventWeather event={raceSeriesDetails.event} />
      <EuiHorizontalRule margin="m" />
    </EuiFlexItem>
  </EuiFlexGroup>
);
