import React, { FC, useState } from "react";
import {
  EuiButton,
  EuiCard,
  EuiComboBox,
  EuiForm,
  EuiFormRow,
  EuiSpacer,
  EuiSwitch
} from "@elastic/eui";
import { useMutation } from "react-query";
import { saveUser } from "../../api/auth";
import { useUIContext } from "../../contexts/ui";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import {
  countryCodesToComboBoxOptions,
  getComboBoxOptionFromCountryCode,
  INTERNATIONAL_COMBO_BOX_OPTION
} from "../../utils/countries";
import { GetUserProfileResponseDto } from "../../types/auth";

interface Props {
  user: GetUserProfileResponseDto;
  regions: { value: number; label: string }[];
}

export const UserBasicInfo: FC<Props> = props => {
  const { emitSuccessToast, emitErrorToast } = useUIContext();
  const [editMode, setEditMode] = useState(false);

  const mutation = useMutation(saveUser);

  const { handleSubmit, errors, control } = useForm<{
    countryCode: { value: string; label: string };
    prefferedWorldRegionId: { value: number; label: string };
    isMarketingAllowed: boolean;
  }>({
    defaultValues: {
      isMarketingAllowed: props.user.isMarketingAllowed,
      countryCode: getComboBoxOptionFromCountryCode(props.user.countryCode),
      prefferedWorldRegionId: props.regions.find(
        r => r.value === props.user.prefferedWorldRegionId
      ) || { value: 0, label: "" }
    },
    mode: "onBlur",
    resolver: zodResolver(validator)
  });

  return (
    <EuiCard title={<span>Basic Info</span>} layout={"horizontal"}>
      <EuiSpacer size={"l"} />

      <EuiForm
        component="form"
        style={{ display: "flex", flexDirection: "column", gap: "20px" }}
        onSubmit={handleSubmit(
          data => {
            mutation
              .mutateAsync({
                countryCode: data.countryCode.value,
                prefferedWorldRegionId: data.prefferedWorldRegionId.value,
                isMarketingAllowed: data.isMarketingAllowed
              })
              .then(() => {
                emitSuccessToast({
                  title: "Profile updated"
                });

                setEditMode(false);
              })
              .catch((error: any) => {
                const responseErrorMessage = error?.response?.data?.message;
                const errorMessage =
                  responseErrorMessage ||
                  error.message ||
                  "Something went wrong. Please try again later.";
                emitErrorToast({ text: errorMessage });
              });
          },
          errors => {
            console.log("errror", errors);
          }
        )}
      >
        <div>
          <Controller
            control={control}
            defaultValue={null}
            name="countryCode"
            render={({ onChange, onBlur, value }) => (
              <EuiFormRow
                isInvalid={Boolean(errors.countryCode)}
                error={errors.countryCode?.value?.message}
                label={"Country"}
                isDisabled={!editMode}
                helpText={
                  <div>
                    Your country. If you don't want to disclose your nationality
                    select <em>International</em> option.
                  </div>
                }
              >
                <EuiComboBox<string>
                  isDisabled={!editMode || mutation.isLoading}
                  isInvalid={Boolean(errors.countryCode)}
                  isClearable={false}
                  placeholder="Select a country"
                  singleSelection={{ asPlainText: true }}
                  options={countryCodesToComboBoxOptions()}
                  selectedOptions={
                    value ? [value] : [INTERNATIONAL_COMBO_BOX_OPTION]
                  }
                  onChange={value => {
                    onChange(value[0]);
                  }}
                  onBlur={onBlur}
                />
              </EuiFormRow>
            )}
          />

          <Controller
            control={control}
            defaultValue={null}
            name="prefferedWorldRegionId"
            render={({ onChange, onBlur, value }) => (
              <EuiFormRow
                isInvalid={Boolean(errors.prefferedWorldRegionId)}
                error={errors.prefferedWorldRegionId?.value?.message}
                isDisabled={!editMode}
                label={"Preferred world region"}
              >
                <EuiComboBox<number>
                  isDisabled={!editMode || mutation.isLoading}
                  isInvalid={Boolean(errors.prefferedWorldRegionId)}
                  isClearable={false}
                  placeholder="Where do you want to race"
                  singleSelection={{ asPlainText: true }}
                  selectedOptions={value ? [value] : []}
                  options={props.regions}
                  onChange={value => {
                    onChange(value[0]);
                  }}
                  onBlur={onBlur}
                />
              </EuiFormRow>
            )}
          />

          <Controller
            control={control}
            name="isMarketingAllowed"
            render={({ onChange, onBlur, value }) => (
              <EuiFormRow
                label={"Newsletter"}
                helpText={
                  <div>
                    We will not spam you. You'll get info about special events,
                    where you could race with great number of other drivers or
                    cool new features we release. You shouldn't expect more than
                    one mail per month :)
                  </div>
                }
              >
                <EuiSwitch
                  label="I would like to be in touch with rco"
                  disabled={!editMode}
                  checked={value}
                  onChange={event => {
                    onChange(event.target.checked);
                  }}
                  onBlur={onBlur}
                />
              </EuiFormRow>
            )}
          />
        </div>

        <div style={{ display: "flex", gap: "20px" }}>
          {editMode ? (
            <>
              <EuiButton type={"submit"} fill isLoading={mutation.isLoading}>
                Submit
              </EuiButton>
              <EuiButton
                color={"text"}
                onClick={() => {
                  setEditMode(false);
                }}
                isLoading={mutation.isLoading}
              >
                Cancel
              </EuiButton>
            </>
          ) : (
            <EuiButton
              color={"text"}
              type={"button"}
              isLoading={mutation.isLoading}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();
                setEditMode(true);
              }}
            >
              Edit
            </EuiButton>
          )}
        </div>
      </EuiForm>
    </EuiCard>
  );
};

const validator = z.object({
  isMarketingAllowed: z.boolean(),
  countryCode: z.object({
    value: z.string(),
    label: z.string()
  }),
  prefferedWorldRegionId: z.object({
    value: z.number(),
    label: z.string()
  })
});
