// todo this is flawed, if we want to have hook with ls, then it should listen to ls changes
export const useLocalStorage = <T>(key: string, defaultValue = {}): [(() => T), ((value: T) => void)] => {
  const get = () => {
    const storedValue = localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : defaultValue;
  };
  const set = (value: any) => {
    localStorage.setItem(key, JSON.stringify(value));
  };
  return [get, set];
};
