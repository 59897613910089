export const getRootPathnameSegment = (pathname: string) =>
  pathname.split("/").filter(x => x)[0];

export const buildSearchParams = (props: Record<string, string | string[]>) => {
  const searchParams = new URLSearchParams();

  Object.entries(props).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach(v => {
        searchParams.append(key, v);
      });
      return;
    }

    searchParams.append(key, value);
  });

  return searchParams;
};
