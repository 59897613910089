import styled from "styled-components";
import React from "react";

const NamePart = styled.span`
  font-weight: 900;
`;

const DomainPart = styled.span`
  font-weight: 200;
`;

const Container = styled.h1`
  font-family: "Inter", sans-serif;
  color: ${({ theme }) => theme.euiTextColors?.default};
`;

type LogoProps = React.HTMLAttributes<HTMLDivElement | HTMLSpanElement> & {
  containerComponent?: keyof JSX.IntrinsicElements;
};

export const Logo = ({ containerComponent, ...rest }: LogoProps) => (
  <Container as={containerComponent} {...rest}>
    <NamePart>racecraft</NamePart>
    <DomainPart>online</DomainPart>
  </Container>
);
