import React from "react";
import styled from "styled-components";
import { VehicleClass } from "types/raceSeries";

const CoverImageShadowContainer = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  transition: 0.5s filter ease-in-out;
  filter: drop-shadow(1px 2px 3px ${({ theme }) => theme.euiShadowColor});
  background-color: #000;
`;

const ClassLogoContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content:space-between;
  flex-wrap: wrap;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 90%);
  filter: drop-shadow(1px 2px 3px ${({ theme }) => theme.euiShadowColor});
  div {
    flex: 1 1 calc(50%);  
    text-align: center;
  }
  div img {
    max-width: 100%;
    max-height: 100%;
    z-index: 3;
    margin-right: ${({ theme }) => theme.paddingSizes.s};
    margin-left: ${({ theme }) => theme.paddingSizes.s};
  }
`;

const CoverImg = styled.img`
  width: 100%;
  object-fit: cover;
  transition: 0.5s all ease-in-out;
  opacity: 0.9;
`;

type CoverImageProps = {
  vehicleClasses: VehicleClass[]
} & React.PropsWithoutRef<JSX.IntrinsicElements["div"]>;

export const DetailsVehicleClassesImage = ({
  vehicleClasses,
  ...props
}: CoverImageProps) => (
  <CoverImageShadowContainer {...props}>
    <CoverImg src={`https://storage.googleapis.com/rco-images/vehicle-class-logos/black.png`} className="coverImage" />
    <ClassLogoContainer>
      {vehicleClasses.map(vcl => (
        <div style={{ 
          width: `${vehicleClasses.length == 1 ? 200 : 200 / vehicleClasses.length * 2 }px`, 
          height: `${vehicleClasses.length == 1 ? 100 :100 / vehicleClasses.length * 2 }px`
          }}>

        <img key={vcl.id}  src={`${vcl.vehicleClassLogoUrl}`} alt="vehicle-class-logo" />
      </div>
      ))}
    </ClassLogoContainer>
  </CoverImageShadowContainer>
);
