import React, { FC, useState } from "react";
import {
  EuiButtonEmpty,
  EuiHorizontalRule,
  EuiIcon,
  EuiLink,
  EuiPopover,
  EuiText,
  EuiTitle,
  EuiToolTip
} from "@elastic/eui";
import { ReactComponent as SteamLogo } from "assets/icons/steam.svg";
import { isAfter, isBefore, isTuesday } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { useRerender } from "../../../hooks/useRerender";
import { useEuiThemeTyped } from "../../../theme/eui_overrides";

interface Props {}

export const SteamMaintenanceWarning: FC<Props> = () => {
  const [opened, setOpened] = useState(false);
  const { colorMode } = useEuiThemeTyped();

  useRerender(10 * 60_000);

  if (!isWithinTheRange()) {
    return null;
  }

  return (
    <EuiPopover
      ownFocus={false}
      isOpen={opened}
      closePopover={() => setOpened(false)}
      anchorPosition="rightDown"
      button={
        <EuiToolTip
          content={
            opened ? "" : "Steam maintenance might occur. Click for more info."
          }
        >
          <EuiButtonEmpty size={"xs"} onClick={() => setOpened(!opened)}>
            <div style={{ display: "flex", gap: "2px" }}>
              <EuiIcon
                type={SteamLogo}
                color={colorMode === "LIGHT" ? "black" : "white"}
              />
              <EuiIcon type={"warning"} color={"warning"} />
            </div>
          </EuiButtonEmpty>
        </EuiToolTip>
      }
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          justifyContent: "center",
          alignItems: "center",
          maxWidth: "400px"
        }}
      >
        <EuiTitle size={"s"}>
          <span>Steam servers maintenance</span>
        </EuiTitle>
        <EuiText>
          Some connection issues may occur in the steam maintenance window,
          which might be expected to happen around 20:00 CET every Tuesday.
        </EuiText>
        <EuiHorizontalRule margin={"s"} />
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between"
          }}
        >
          <EuiLink href={"https://steamstat.us/"} target={"_blank"}>
            Steam status
          </EuiLink>
          <EuiLink
            href={
              "https://help.steampowered.com/en/faqs/view/7016-8AFD-19F7-F397/"
            }
            target={"_blank"}
          >
            Official site
          </EuiLink>
        </div>
      </div>
    </EuiPopover>
  );
};

const isWithinTheRange = () => {
  const steamOfficeTimeZonedDateCurrent = utcToZonedTime(
    new Date(),
    "US/Pacific"
  );
  const steamOfficeTimeZonedDateMin = utcToZonedTime(new Date(), "US/Pacific");
  steamOfficeTimeZonedDateMin.setHours(6, 0, 0, 0);

  const steamOfficeTimeZonedDateMax = utcToZonedTime(new Date(), "US/Pacific");
  steamOfficeTimeZonedDateMax.setHours(18, 0, 0, 0);

  if (!isTuesday(steamOfficeTimeZonedDateCurrent)) {
    return false;
  }

  if (
    isAfter(steamOfficeTimeZonedDateCurrent, steamOfficeTimeZonedDateMin) &&
    isBefore(steamOfficeTimeZonedDateCurrent, steamOfficeTimeZonedDateMax)
  ) {
    return true;
  }
};
