import React, { ErrorInfo } from "react";
import { EuiButton, EuiCallOut, EuiLink } from "@elastic/eui";

export class ErrorPage extends React.Component<
  { children: React.ReactNode },
  { hasError: boolean }
> {
  state = { hasError: false };

  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {}

  render() {
    if (this.state.hasError) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh"
          }}
        >
          <EuiCallOut color={"warning"}>
            <p>Something happened :|</p>
            <p>Please refresh the page and lets hope it will work again then.</p>
            <p>
              If the problem persists please contact us on{' '}
              <EuiLink
                href={
                  "https://discord.com/channels/789131939361062963/1102886413701218366"
                }
                target={"_blank"}
              >
                discord
              </EuiLink>
            </p>
            <EuiButton
              onClick={() => {
                window.location.reload();
              }}
            >
              Refresh
            </EuiButton>
          </EuiCallOut>
        </div>
      );
    }

    return this.props.children;
  }
}
