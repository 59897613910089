const ROOT_ROUTES = {
  EMAIL_CONFIRMATION: "/email-confirmation",
  HOME: "/",
  RACE_SERIES_DETAILS: "/race-series",
  RACES_LIST: "/races/hourlies",
  RATINGS_HISTORY: "/ratings-history",
  RESET_PASSWORD: "/reset-password",
  STANDINGS: "/standings",
  STATS: "/stats",
  FAQ: "/faq",
  NEWS: "/news",
  MANAGE_SEASONS: "/admin",
  YOUR_PROFILE: "/profile",
  TELEMETRY: "/telemetry"
};

export const ROUTES = {
  ...ROOT_ROUTES,
  RATINGS_HISTORY: `${ROOT_ROUTES.STATS}/:driverId/ratings-history`,
  RACE_HISTORY: `${ROOT_ROUTES.STATS}/:driverId/race-history`,
  SELO_VIEW: `${ROOT_ROUTES.STATS}/:driverId/selo-view`,
  LEADERBOARD: `${ROOT_ROUTES.STATS}/leaderboard`,
  TELEMETRY_SESSION: `${ROOT_ROUTES.TELEMETRY}/:sessionId/:sessionType`,
  TELEMETRY_ANALYSIS: `${ROOT_ROUTES.TELEMETRY}/analysis/:analKey`
};
