import React from "react";
import styled from "styled-components";

const CoverImageShadowContainer = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  transition: 0.5s filter ease-in-out;
  filter: drop-shadow(1px 2px 3px ${({ theme }) => theme.euiShadowColor});
  background-color: #000;
`;

const CoverImg = styled.img`
  width: 100%;
  object-fit: cover;
  transition: 0.5s all ease-in-out;
  opacity: 0.9;
`;

const TrackMapContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(1px 2px 3px ${({ theme }) => theme.euiShadowColor});
  img {
    max-width: 80%;
    max-height: 80%;
    margin: ${({ theme }) => theme.paddingSizes.s};
  }
`;

// show bigger maps for r3e
const R3eTrackMapContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(1px 2px 3px ${({ theme }) => theme.euiShadowColor});
  img {
    max-width: 140%;
    max-height: 140%;
    margin: ${({ theme }) => theme.paddingSizes.s};
  }
`;
type CoverImageProps = {
  imageUrl: string;
  backgroundUrl: string;
  simShortCode?: string;
} & React.PropsWithoutRef<JSX.IntrinsicElements["div"]>;

export const TrackImage = ({
  backgroundUrl,
  imageUrl,
  simShortCode,
  ...props
}: CoverImageProps) => (
  <CoverImageShadowContainer {...props}>
    <CoverImg src={`${backgroundUrl}`} className="coverImage" />
    {simShortCode === "R3E" ?
    <R3eTrackMapContainer>
      <img src={`${imageUrl}`} alt="track map" />
      <div />
    </R3eTrackMapContainer>
    :
    <TrackMapContainer>
      <img src={`${imageUrl}`} alt="track map" />
      <div />
    </TrackMapContainer>
    }
  </CoverImageShadowContainer>
);
