import {
  EuiButtonGroup,
  EuiFlexGroup,
  EuiSkeletonRectangle,
  EuiSpacer
} from "@elastic/eui";
import { fetchRaceSeries } from "api/raceSeries";
import { useUIContext } from "contexts/ui";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { NoRaceSeries } from "./NoRaceSeries/NoRaceSeries";
import { RaceSeriesHeader } from "./RaceSeriesHeader/RaceSeriesHeader";
import { useRaceSeriesContext } from "./raceSeriesContext";
import { TrendingRaces } from "./TrendingRaces";
import { HourlyRaces } from "./HourlyRaces";
import { WeeklyRaces } from "./WeeklyRaces";
import { useIsVisible } from "../../hooks/useIsVisible";

export const RacesListScreen = () => {
  const { raceSeries, setRaceSeries } = useRaceSeriesContext();

  const { emitErrorToast } = useUIContext();

  const raceSeriesQuery = useQuery(["raceSeriesQuery"], fetchRaceSeries, {
    refetchOnWindowFocus: false,
    onError: () => emitErrorToast({ title: "We couldn't get any races" }),
    onSuccess: data => {
      setRaceSeries(data);
    }
  });

  const [selectedButton, setSelectedButton] = useState("trending");

  const [trendingRef, trendingVisibilityRatio] = useIsVisible();
  const [hourlyRef, hourlyVisibilityRatio] = useIsVisible();
  const [weeklyRef, weeklyVisibilityRatio] = useIsVisible();

  const handleScrollToElement = (
    targetRef: React.MutableRefObject<HTMLDivElement | null>
  ) => {
    if (targetRef.current) {
      const elementPosition = targetRef.current.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.scrollY - 90;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  };

  useEffect(() => {
    const ratios = [
      { id: "trending", ratio: trendingVisibilityRatio },
      { id: "hourlies", ratio: hourlyVisibilityRatio },
      { id: "weeklies", ratio: weeklyVisibilityRatio }
    ].sort((a, b) => b.ratio - a.ratio);

    setSelectedButton(ratios[0].id);
  }, [hourlyVisibilityRatio, trendingVisibilityRatio, weeklyVisibilityRatio]);

  return (
    <>
      <EuiFlexGroup
        direction="column"
        gutterSize="none"
        style={{ position: "relative" }}
      >
        <RaceSeriesHeader />
        <EuiSpacer size="l" />
        <EuiButtonGroup
          style={{ position: "sticky", top: 48, left: 10, zIndex: 100 }}
          legend="Races selection"
          options={[
            {
              id: "trending",
              label: "Trending"
            },
            {
              id: "hourlies",
              label: "Hourlies"
            },
            {
              id: "weeklies",
              label: "Weeklies"
            }
          ]}
          idSelected={selectedButton}
          onChange={id => {
            if (id === "trending") {
              handleScrollToElement(trendingRef);
            }

            if (id === "hourlies") {
              handleScrollToElement(hourlyRef);
            }

            if (id === "weeklies") {
              handleScrollToElement(weeklyRef);
            }
          }}
          buttonSize="m"
          isFullWidth
        />
        <EuiSpacer size="l" />
        <div ref={trendingRef}>
          <TrendingRaces />
        </div>
        <EuiSpacer size="xxl" />
        {raceSeriesQuery.isFetching && (
          <EuiSkeletonRectangle
            isLoading={true}
            width={"100%"}
            height="500px"
            borderRadius="s"
          />
        )}
        {!raceSeriesQuery.isFetching && raceSeries.length > 0 && (
          <div ref={hourlyRef}>
            <HourlyRaces
              races={raceSeries.filter(r => r.eventType === "regular")}
            />
          </div>
        )}
        <EuiSpacer size="xxl" />
        {!raceSeriesQuery.isFetching && raceSeries.length > 0 && (
          <div ref={weeklyRef}>
            <WeeklyRaces
              races={raceSeries.filter(r => r.eventType === "featured")}
            />
          </div>
        )}
        {!raceSeriesQuery.isFetching && raceSeries.length < 1 && (
          <NoRaceSeries refetchData={raceSeriesQuery.refetch} />
        )}
      </EuiFlexGroup>
    </>
  );
};
