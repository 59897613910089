import {
  EuiEmptyPrompt,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
} from "@elastic/eui";
import { fetchSEloHistory } from "api/profile";
import { useUIContext } from "contexts/ui";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { DriverSEloHistory } from "types/profile";
import { SEloHistoryChart } from "./SEloHistoryChart";

type SEloHistoryProps = {
  driverId?: string;
}

export const SEloHistory : React.FC<SEloHistoryProps> = ({
  driverId
}) => {
  const { setGlobalLoading, emitErrorToast } = useUIContext();
  const [driverSEloHistory, setDriverSEloHistory] = useState<DriverSEloHistory>();

  const seloHistoryQuery = useQuery(
    ["sEloHistoryQuery", driverId],
    fetchSEloHistory,
    {
      refetchOnWindowFocus: false,
      onError: (error: ResponseError) => {
        setDriverSEloHistory(undefined);
        const errorMessage =
          error.response.data ||
          error.message ||
          "Can't get your S.Elo history.";
        emitErrorToast({ title: errorMessage });
      },
      onSuccess: (data: DriverSEloHistory) => {
        setDriverSEloHistory(data);
      }
    }
  );
  
  useEffect(() => {
    setGlobalLoading({ state: seloHistoryQuery.isFetching });
  }, [seloHistoryQuery.isFetching, setGlobalLoading]);


  return (
    <EuiFlexGroup direction="column" gutterSize="l">
      <EuiSpacer size="s" />
      {driverSEloHistory?.eloHistory.length ? (
        <EuiFlexItem>
          <SEloHistoryChart
            driverSEloHistory={driverSEloHistory}
          />
        </EuiFlexItem>
      ) : (
        <EuiEmptyPrompt
          iconType="folderExclamation"
          title={<h2>No S.Elo history</h2>}
          body={
            <p>
              Looks like there is no S.Elo history data to display so far.
            </p>
          }
        />
      )}
    </EuiFlexGroup>
  );
};