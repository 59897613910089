import React, { FC } from "react";
import { EuiButton, EuiCard, EuiSpacer } from "@elastic/eui";
import { useMutation } from "react-query";
import { refreshSteamData } from "../../api/auth";
import { useUIContext } from "../../contexts/ui";

interface Props {
  refresh(): void;
}

export const SteamIntegration: FC<Props> = props => {
  const { emitSuccessToast, emitErrorToast } = useUIContext();
  const mutation = useMutation(refreshSteamData);

  return (
    <EuiCard title={<span>Steam integration</span>} layout={"horizontal"}>
      <EuiSpacer size={"l"} />

      <EuiButton
        onClick={() =>
          mutation
            .mutateAsync()
            .then(() => {
              emitSuccessToast({
                title: "Steam data updated"
              });

              props.refresh();
            })
            .catch((error: any) => {
              const responseErrorMessage = error?.response?.data?.message;
              const errorMessage =
                responseErrorMessage ||
                error.message ||
                "Something went wrong. Please try again later.";
              emitErrorToast({ text: errorMessage });
            })
        }
        isLoading={mutation.isLoading}
      >
        Refresh steam data
      </EuiButton>
    </EuiCard>
  );
};
