import { EuiToolTip } from "@elastic/eui";
import { fetchUserRatings } from "api/profile";
import { TOAST_LIFE_TIME } from "components/GlobalToast/GlobalToast";
import { ROUTES } from "constants/routes";
import { useUIContext } from "contexts/ui";
import { useUserContext } from "contexts/user";
import { useWebSocketServiceContext } from "contexts/webSocketService/webSocketService";
import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { RatingChangeToastRow } from "./RatingsChangeToastRow";
import { SingleRatingStat } from "./SingleRatingStat";
import { useIsMobile } from "../../../../hooks/useIsMobile";

export type RatingChange = {
  value: number;
  difference: number;
};

const getRatingLoading = (ratingValue?: number) => !(Number(ratingValue) >= 0);

export const Ratings = () => {
  const { emitErrorToast, emitToast } = useUIContext();
  const { userRatings, setUserRatings, user } = useUserContext();
  const webSocket = useWebSocketServiceContext();
  const { isMobile } = useIsMobile();

  const userRatingsQuery = useQuery("userRatingsQuery", fetchUserRatings, {
    refetchOnWindowFocus: false,
    onError: () => emitErrorToast({ title: "Can't get your ratings." }),
    onSuccess: ratings => setUserRatings(ratings)
  });

  useEffect(() => {
    if (webSocket.connected) {
      webSocket?.instance?.on("DriverRatingChanged", (eventMessage: string) => {
        const {
          sEloChange,
          safetyChange,
          consistencyChange
        }: Record<string, RatingChange> = JSON.parse(eventMessage);
        setUserRatings({
          safety: safetyChange.value,
          safetyChange: safetyChange.difference,
          consistency: consistencyChange.value,
          consistencyChange: consistencyChange.difference,
          sElo: sEloChange.value,
          sEloChange: sEloChange.difference
        });
        // fire toast after rating change animation
        setTimeout(
          () =>
            emitToast({
              title: "Your rating has changed",
              text: (
                <div>
                  <RatingChangeToastRow name="Safety" {...safetyChange} />
                  <RatingChangeToastRow
                    name="Consistency"
                    {...consistencyChange}
                  />
                  <RatingChangeToastRow name="S.Elo" {...sEloChange} />
                </div>
              ),
              toastLifeTimeMs: TOAST_LIFE_TIME.INFINITE
            }),
          1000
        );
      });
    }
  }, [webSocket.connected]);

  useEffect(() => {
    const removeOnReconnectListener = webSocket.onReconnect(() => {
      userRatingsQuery.refetch();
    });
    return () => removeOnReconnectListener();
  }, [webSocket.onReconnect]);

  return (
    <div style={{ display: "flex", justifyContent: "flex-end", flex: 1 }}>
      <EuiToolTip position="top" content="Go to your stats">
        <Link
          to={`${ROUTES.STATS}/${user?.id}`}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <SingleRatingStat
            isLoading={getRatingLoading(userRatings.safety)}
            description={isMobile ? "S" : "Safety"}
            title={userRatings.safety}
            change={userRatings.safetyChange}
            titleSize="s"
            descriptionElement="h6"
            textAlign="center"
          />
          <SingleRatingStat
            isLoading={getRatingLoading(userRatings.consistency)}
            description={isMobile ? "C" : "Consistency"}
            title={userRatings.consistency}
            change={userRatings.consistencyChange}
            titleSize="s"
            descriptionElement="h6"
            textAlign="center"
          />
          <SingleRatingStat
            isLoading={getRatingLoading(userRatings.sElo)}
            description="S.Elo"
            title={userRatings.sElo}
            change={userRatings.sEloChange}
            titleSize="s"
            descriptionElement="h6"
            textAlign="center"
          />
        </Link>
      </EuiToolTip>
    </div>
  );
};
