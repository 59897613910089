import React, { FC, useMemo } from "react";
import {
  EuiBadge,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiLink,
  EuiPanel,
  EuiText
} from "@elastic/eui";
import { RaceSummaryResponseDto } from "../../../types/profile";
import { colors } from "../../../theme/eui_overrides";
import { ROUTES } from "../../../constants/routes";

type TableItem = {
  driver: string | number;
  race: string | number;
  all: string | number;
  driversIds?: {
    driverId: number;
    raceDriverId: number;
    allDriverId: number;
  };
  prefix?: string;
  suffix?: string;
};

interface Props {
  lapsDiff: RaceSummaryResponseDto["lapsDiff"];
}

export const FastestLapTimes: FC<Props> = ({ lapsDiff }) => {
  const data = useMemo(() => {
    return {
      columns: [
        {
          field: "driver",
          name: "Driver fastest",
          align: "center",
          width: '33%',
          render: (item: TableItem["driver"], record: TableItem) => {
            if (record.driversIds) {
              return (
                <EuiLink
                  href={`${ROUTES.STATS}/${record.driversIds?.driverId}`}
                >
                  {item}
                </EuiLink>
              );
            }

            return (
              <EuiBadge {...getBadgeProps(item, [record.race, record.all])}>
                {record.prefix}
                <strong>{item}</strong>
                <strong>{record.suffix}</strong>
              </EuiBadge>
            );
          }
        },
        {
          field: "race",
          name: "Race fastest",
          align: "center",
          width: '33%',
          render: (item: TableItem["race"], record: TableItem) => {
            if (record.driversIds) {
              return (
                <EuiLink
                  href={`${ROUTES.STATS}/${record.driversIds?.raceDriverId}`}
                >
                  {item}
                </EuiLink>
              );
            }

            return (
              <EuiBadge {...getBadgeProps(item, [record.driver, record.all])}>
                {record.prefix}
                <strong>{item}</strong>
                <strong>{record.suffix}</strong>
              </EuiBadge>
            );
          }
        },
        {
          field: "all",
          name: "RCO fastest",
          align: "center",
          width: '33%',
          render: (item: TableItem["race"], record: TableItem) => {
            if (record.driversIds) {
              return (
                <EuiLink
                  href={`${ROUTES.STATS}/${record.driversIds?.allDriverId}`}
                >
                  {item}
                </EuiLink>
              );
            }

            return (
              <EuiBadge {...getBadgeProps(item, [record.race, record.driver])}>
                {record.prefix}
                <strong>{item}</strong>
                <strong>{record.suffix}</strong>
              </EuiBadge>
            );
          }
        }
      ] as EuiBasicTableColumn<TableItem>[],
      items: [
        {
          driver: lapsDiff.driverBestLap.driverName,
          driversIds: {
            driverId: lapsDiff.driverBestLap.driverId,
            raceDriverId: lapsDiff.splitBestLap.driverId,
            allDriverId: lapsDiff.allTimeBestLap.driverId
          },
          race: lapsDiff.splitBestLap.driverName,
          all: lapsDiff.allTimeBestLap.driverName
        },
        {
          suffix: "%",
          driver: lapsDiff.driverBestLap.rcoBestLapPercentage,
          race: lapsDiff.splitBestLap.rcoBestLapPercentage,
          all: lapsDiff.allTimeBestLap.rcoBestLapPercentage
        },
        {
          driver: lapsDiff.driverBestLap.time.slice(3, -4),
          race: lapsDiff.splitBestLap.time.slice(3, -4),
          all: lapsDiff.allTimeBestLap.time.slice(3, -4)
        },
        ...lapsDiff.driverBestLap.sectors.map((s, index) => ({
          prefix: `S${index + 1} `,
          driver: lapsDiff.driverBestLap.sectors[index].time.slice(3, -4),
          race: lapsDiff.splitBestLap.sectors[index].time.slice(3, -4),
          all: lapsDiff.allTimeBestLap.sectors[index].time.slice(3, -4)
        }))
      ]
    };
  }, [lapsDiff]);

  return (
    <EuiPanel
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "60px",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          alignItems: "center",
          width: "100%"
        }}
      >
        <EuiText>
          <h3 style={{ textTransform: "capitalize" }}>Fastest lap time</h3>
        </EuiText>
        <EuiBasicTable<TableItem>
          style={{ overflow: "auto", width: "100%" }}
          items={data.items}
          rowHeader="name"
          columns={data.columns}
          tableLayout="auto"
        />
      </div>
    </EuiPanel>
  );
};

const getBadgeProps = (current: string | number, rest: (string | number)[]) => {
  const data = [
    { type: "current", value: current.toString() },
    ...rest.map(item => ({ type: "other", value: item.toString() }))
  ]
    .sort((a, b) =>
      a.value.localeCompare(b.value, undefined, { numeric: true })
    )
    .reduce<{ value: string; type: string; place: number }[]>(
      (acc, item, index) => {
        if (index === 0) {
          return [
            ...acc,
            {
              ...item,
              place: 1
            }
          ];
        }

        return [
          ...acc,
          {
            ...item,
            place:
              item.value === acc[index - 1].value
                ? acc[index - 1].place
                : acc[index - 1].place + 1
          }
        ];
      },
      []
    )
    .find(item => item.type === "current");

  if (!data) {
    return {};
  }

  if (data.place === 1) {
    return {
      color: "darkviolet",
      iconType: "starFilled"
    };
  }

  if (data.place === 2) {
    return {
      color: colors.winGreen
    };
  }

  return {};
};
