import { z } from "zod";

const filterOutUndefined = (val: unknown) => {
  if (!val || typeof val !== "string") {
    return undefined;
  }
  return val === "" ? undefined : val;
};

export const usersFeedbackPayloadValidator = z.object({
  userName: z
    .string()
    .min(3, { message: "Name must be at least 3 characters long." })
    .max(200, {
      message: "Name must be no more than 200 characters long."
    })
    .optional(),
  email: z.preprocess(filterOutUndefined, z.string().email().optional()),
  subject: z.preprocess(
    filterOutUndefined,
    z
      .string()
      .min(3, { message: "Subject must be at least 3 characters long." })
      .max(200, {
        message: "Subject must be no more than 200 characters long."
      })
      .optional()
  ),
  content: z
    .string()
    .min(10, { message: "Message must be at least 10 characters long." })
    .max(1000, {
      message: "Message must be no more than 1000 characters long."
    })
});
export type UsersFeedbackPaylod = z.infer<typeof usersFeedbackPayloadValidator>;
