import { useWebSocketServiceContext } from "contexts/webSocketService/webSocketService";
import { useUIContext } from "contexts/ui";
import { useEffect } from "react";
import { TOAST_LIFE_TIME } from "components/GlobalToast/GlobalToast";
import { GlobalMessageType } from "types/layout";

type GenericNotification = {
  title: string;
  markdownBody: string;
  type: string;
};

export const useGeneralWsEventsHandler = () => {
  const webSocket = useWebSocketServiceContext();
  const {
    emitSuccessToast,
    emitErrorToast,
    emitToast,
    setGlobalMessages
  } = useUIContext();

  useEffect(() => {
    if (webSocket.connected) {
      webSocket?.instance?.on(
        "CleanLapChallengeComplete",
        (eventMessage: string) => {
          const { trackName } = JSON.parse(eventMessage) as {
            trackName: string;
          };
          emitSuccessToast({
            title: `Clean Laps Challenge complete! You can now race on ${trackName}.`,
            toastLifeTimeMs: TOAST_LIFE_TIME.INFINITE
          });
        }
      );
      webSocket?.instance?.on(
        "GenericNotification",
        (eventMessage: GenericNotification) => {
          if (eventMessage.type === "Error") {
            emitErrorToast({
              title: eventMessage.title,
              text: eventMessage.markdownBody,
              toastLifeTimeMs: TOAST_LIFE_TIME.INFINITE
            });
          }
          if (eventMessage.type === "Success") {
            emitSuccessToast({
              title: eventMessage.title,
              text: eventMessage.markdownBody,
              toastLifeTimeMs: TOAST_LIFE_TIME.INFINITE
            });
          }
          if (eventMessage.type === "Info") {
            emitToast({
              title: eventMessage.title,
              text: eventMessage.markdownBody,
              toastLifeTimeMs: TOAST_LIFE_TIME.INFINITE
            });
          }
        }
      );

      webSocket?.instance?.on("NewGlobalMessage", (globalMessage: string) => {
        const msg = JSON.parse(globalMessage) as GlobalMessageType;
        setGlobalMessages(gm => [msg, ...gm]);
      });

      webSocket?.instance?.on(
        "DeleteGlobalMessage",
        (globalMessage: string) => {
          const { id } = JSON.parse(globalMessage) as {
            id: number;
          };

          setGlobalMessages(gm => gm.filter(m => m.id != id));
        }
      );

      webSocket?.instance?.on("DeleteAllGlobalMessages", () => {
        setGlobalMessages([]);
      });
    }
  }, [webSocket.connected]);
  useEffect(
    () => () => {
      if (webSocket.connected) {
        webSocket.instance?.off("DeleteAllGlobalMessages");
        webSocket.instance?.off("NewGlobalMessage");
        webSocket.instance?.off("NewGlobalMessage");
        webSocket.instance?.off("NewGlobalMessage");
      }
    },
    []
  );
};
