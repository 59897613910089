import React from "react";
import { EuiStat } from "@elastic/eui";
import { EuiStatProps } from "@elastic/eui/src/components/stat/stat";
import styled from "styled-components";
import { motion } from "framer-motion";

const StyledStat = styled(EuiStat)`
  display: flex;
  .euiStat__description {
    margin-left: 2ch;
    margin-right: 1ch;
  }
  .euiStat__title {
    color: ${({ theme }) => theme.euiTitleColor};
  }
`;

const ChangeFlyout = styled(motion.div)<{ positive: boolean }>`
  color: ${({ positive, theme }) =>
    positive ? theme.euiColorSuccess : theme.euiColorDanger};
  top: 0;
  right: 0;
  position: absolute;
  font-size: ${({ theme }) => theme.euiFontSizeL};
  font-weight: ${({ theme }) => theme.euiFontWeightBold};
`;

type SingleRatingStatProps = EuiStatProps & {
  change?: number;
};

export const SingleRatingStat = ({
  change,
  ...props
}: SingleRatingStatProps) => {
  return (
    <div style={{ position: "relative" }}>
      <StyledStat
        {...props}
        titleElement={"div"}
        title={<div style={{ whiteSpace: "nowrap" }}>{props.title}</div>}
      />
      {change ? (
        <ChangeFlyout
          key={change}
          positive={!!(change && change > 0)}
          transition={{ type: "spring", stiffness: 10 }}
          initial={{ y: "0%" }}
          animate={{
            opacity: [0, 0.5, 1, 0.5, 0],
            y: "-400%"
          }}
        >
          {change > 0 ? `+${change}` : change}
        </ChangeFlyout>
      ) : null}
    </div>
  );
};
