import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSkeletonText,
  EuiSpacer
} from "@elastic/eui";
import { AnimatedIconHeader } from "components/shared/AnimatedIconHeader";
import { compareDesc } from "date-fns";
import React from "react";
import { News } from "types/news";
import { NewsList } from "./NewsList";
import NewsLightIcon from "assets/icons/news-light.json";
import NewsDarkIcon from "assets/icons/news-dark.json";
import { useNewsContext } from "../newsContext";

export const NewsListContainer = () => {
  const { newsState, markReadNews, markUnreadNews, markAllAsRead } =
    useNewsContext();

  const sortNewsPredicateDesc = (a: News, b: News) => {
    return compareDesc(new Date(a.createdAtUtc), new Date(b.createdAtUtc));
  };

  return (
    <>
      <AnimatedIconHeader
        title={`News ${
          newsState.data.filter(x => !x.isRead).length > 0
            ? `(${newsState.data.filter(x => !x.isRead).length})`
            : ""
        }`}
        darkLottieIcon={NewsDarkIcon}
        lightLottieIcon={NewsLightIcon}
      />
      <EuiSpacer size="xxl" />
      {newsState.data.filter(x => !x.isRead).length > 0 && (
        <EuiFlexGroup justifyContent="flexEnd">
          <EuiFlexItem grow={0}>
            <EuiButton onClick={markAllAsRead}>Mark all as read</EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      )}

      <EuiSkeletonText
        lines={3}
        size="m"
        isLoading={newsState.status === "loading"}
      >
        <NewsList
          data={newsState.data.sort(sortNewsPredicateDesc) ?? []}
          onMarkAsUnread={markUnreadNews}
          onMarkAsRead={markReadNews}
          allowedActions={["NEWS_READ_ACTION"]}
        />
      </EuiSkeletonText>
    </>
  );
};
