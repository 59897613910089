import React, { FC } from "react";
import { useUserContext } from "../../contexts/user";
import { EuiSkeletonRectangle, EuiSpacer, EuiText } from "@elastic/eui";
import { UserAvatar } from "../shared/UserAvatar";
import { UserBasicInfo } from "./UserBasicInfo";
import { SteamIntegration } from "./SteamIntegration";
import { SimIntegrations } from "./SimIntegrations";
import { DangerZone } from "./DangerZone";
import { useQuery } from "react-query";
import { getAvailableRegions, getUser } from "../../api/auth";
import { Region } from "../../types/auth";
import { useUIContext } from "../../contexts/ui";

interface Props {}

export const YourProfile: FC<Props> = () => {
  const { emitErrorToast } = useUIContext();
  const { user, refreshUserToken } = useUserContext();

  const regionsQuery = useQuery("regionsQuery", getAvailableRegions, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (fetchedRegions?: Region[]) =>
      fetchedRegions?.map?.(({ worldRegionId, worldRegionName }) => ({
        value: worldRegionId,
        label: worldRegionName
      }))
  });
  const userQuery = useQuery(["getUser"], getUser, {
    refetchOnWindowFocus: false,
    enabled: regionsQuery.isFetched,
    onError: () =>
      emitErrorToast({ title: "We couldn't get your profile info" })
  });

  if (!user) {
    return null;
  }

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div
        style={{
          display: "flex",
          gap: "20px",
          flexWrap: "wrap",
          maxWidth: "1000px",
          flex: 1
        }}
      >
        <div style={{ maxWidth: "300px" }}>
          <UserAvatar
            name={user.steamDisplayName}
            imageUrl={user.steamAvatarUrl}
            size="xl"
          />

          <EuiSpacer size={"l"} />

          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <EuiText data-testid="steamDisplayName">
              <p style={{ fontWeight: 700 }}>{user.steamDisplayName}</p>
            </EuiText>
            <EuiText data-testid="userEmail">
              <p>{user.emailAddress}</p>
            </EuiText>
          </div>
        </div>

        <div
          style={{
            flex: 1,
            display: "flex",
            gap: "20px",
            flexDirection: "column",
            minWidth: "300px"
          }}
        >
          {!userQuery.data || !regionsQuery.data ? (
            <EuiSkeletonRectangle
              isLoading={true}
              width="100%"
              height="320px"
              borderRadius="s"
            />
          ) : (
            <UserBasicInfo user={userQuery.data} regions={regionsQuery.data} />
          )}

          <SteamIntegration refresh={refreshUserToken} />
          <SimIntegrations />
          <DangerZone />
        </div>
      </div>
    </div>
  );
};
