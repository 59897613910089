import React, { FC } from "react";
import {
  EuiButton,
  EuiCallOut,
  EuiLink,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiPanel,
  EuiSpacer,
  EuiTitle
} from "@elastic/eui";
import { ROUTES } from "../../constants/routes";
import { Link } from "react-router-dom";
import { YouTubeVideoContainer } from "../YouTubeVideoContainer";

interface Props {
  closeButtonText?: string;
  footer?: React.ReactNode;

  onClose(): void;
}

export const WelcomeModal: FC<Props> = props => {
  return (
    <EuiModal
      style={{ minWidth: "50vw", maxWidth: "700px" }}
      onClose={props.onClose}
    >
      <EuiModalHeader
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          gap: "10px"
        }}
      >
        <EuiModalHeaderTitle style={{ textAlign: "center" }}>
          Welcome to racecraft.online!
        </EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <EuiPanel hasShadow={false}>
          <EuiTitle size={"xs"}>
            <div style={{ textAlign: "center" }}>
              Checkout this short video to quickly understand basic concepts
              behind racecraft.online
            </div>
          </EuiTitle>
          <EuiSpacer size={"s"} />
          <YouTubeVideoContainer
            id="2Fqa2HltlJc"
            title="What is racecraft.online?"
          />
        </EuiPanel>
      </EuiModalBody>

      <EuiModalFooter style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <EuiTitle size={"xs"}>
            <div style={{ textAlign: "center" }}>
              Don't forget to check our{" "}
              <EuiLink>
                <Link to={ROUTES.FAQ} target={"_blank"}>
                  FAQ
                </Link>
              </EuiLink>{" "}
              for more{" "}
              <EuiLink>
                <Link to={ROUTES.FAQ + "#communityResources"} target={"_blank"}>
                  videos
                </Link>
              </EuiLink>{" "}
              and other stuff
            </div>
          </EuiTitle>
        </div>
        <EuiCallOut
          title="Be aware that AMS2 multiplayer is not fully stable yet!"
          color="danger"
          iconType="warning"
        >
          <p>
            Our AMS2 drivers are disconnected from 5% of their races on average.
            Learn more in our{" "}
            <EuiLink>
              <Link to={ROUTES.FAQ + "#Ams2Multiplayer"} target={"_blank"}>
                FAQ
              </Link>
            </EuiLink>
          </p>
        </EuiCallOut>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {props.footer || (
            <EuiButton onClick={props.onClose} fill>
              Close
            </EuiButton>
          )}
        </div>
      </EuiModalFooter>
    </EuiModal>
  );
};
