import {
  Criteria,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiButtonIcon,
  EuiEmptyPrompt,
  EuiSkeletonRectangle,
  EuiSwitch,
  EuiToolTip
} from "@elastic/eui";
import { RaceTimeCell } from "components/Stats/RaceHistory/RaceHistoryList/RaceTimeCell";
import { useUIContext } from "contexts/ui";
import { useTablePagination } from "hooks/useTablePagination";
import { useTableSort } from "hooks/useTableSort";
import React, { useEffect, useMemo, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import {
  RaceSeriesEvent,
  RegisteredDriver,
  UpcomingRaceEntries,
  UpcomingRaceEntry
} from "types/raceSeries";
import { fetchUpcomingRaces } from "api/raceSeries";
import { RegisterToRaceButton } from "../RegisterToRaceButton";
import { useWebSocketServiceContext } from "../../../contexts/webSocketService/webSocketService";
import { DriverRegistrationWSPayload } from "../../RaceSeries/useRaceSeriesWsEventsHandlers";
import { parseJsonOrNull, toggleElementByKey } from "../../../utils/utils";
import { UpcomingRacesRangePicker } from "./UpcomingRacesRangePicker";
import moment, { Moment } from "moment";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { ShareButtonsWrapper } from "../../ShareButtons/ShareButtonsWrapper";
import { RegisteredDriversBadgeWithDriversList } from "../../RaceSeries/RaceCard/RaceNumbers";

interface UpcomingRacesProps {
  event: RaceSeriesEvent;
  raceSeriesId: number;
  filterOutNearestRace: boolean;
  league: { id: number; name: string };
}

export const UpcomingRacesList = ({
  event,
  raceSeriesId,
  filterOutNearestRace,
  league
}: UpcomingRacesProps) => {
  const [showOnlyWithRegistered, setShowOnlyWithRegistered] = useState(false);
  const [dates, setDates] = useState<[Moment, Moment]>();

  const upcomingRacesQuery = useQuery(
    ["upcomingRacesQuery", event.eventId],
    fetchUpcomingRaces,
    {
      refetchOnWindowFocus: false,
      onError: (error: ResponseError) => {
        const errorMessage =
          error.response.data ||
          error.message ||
          "Can't get upcoming races data.";
        emitErrorToast({ title: errorMessage });
      }
    }
  );
  const filteredItemsBase = filterOutNearestRace
    ? upcomingRacesQuery.data?.races.slice(1)
    : upcomingRacesQuery.data?.races;
  const filteredItems = filteredItemsBase?.filter(r => {
    if (!dates) {
      return true;
    }

    return (
      moment(r.startTimeUtc).isBetween(dates[0], dates[1], null, "[]") &&
      (showOnlyWithRegistered ? r.registeredDrivers.length > 0 : true)
    );
  });

  const queryClient = useQueryClient();
  // todo in general, rework these websockets, there should be one service/hook that accepts listeners for specific events
  const webSocket = useWebSocketServiceContext();
  useEffect(() => {
    if (!webSocket.connected) {
      return;
    }

    const registeredDriversNumberChanged = (payload: string) => {
      const parsedPayload = parseJsonOrNull<DriverRegistrationWSPayload>(
        payload
      );

      if (!parsedPayload) {
        return;
      }

      queryClient.setQueryData<UpcomingRaceEntries>(
        ["upcomingRacesQuery", event.eventId],
        oldData => {
          if (!oldData) {
            return oldData!; // nasty casting a little chance for race condition when ws event come and there's yet no data from http call, maybe.
          }

          const raceIndex = oldData.races.findIndex(r => {
            return r.raceId === parsedPayload.raceId;
          });

          return {
            races: [
              ...oldData.races.slice(0, raceIndex),
              {
                ...oldData.races[raceIndex],
                registeredDrivers: toggleElementByKey(
                  oldData.races[raceIndex].registeredDrivers,
                  parsedPayload,
                  "driverId"
                )
              },
              ...oldData.races.slice(raceIndex + 1)
            ]
          };
        }
      );
    };

    webSocket.instance?.on("DriverRegistered", registeredDriversNumberChanged);
    webSocket.instance?.on(
      "DriverUnregistered",
      registeredDriversNumberChanged
    );

    return () => {
      webSocket.instance?.off(
        "DriverRegistered",
        registeredDriversNumberChanged
      );
      webSocket.instance?.off(
        "DriverUnregistered",
        registeredDriversNumberChanged
      );
    };
  }, [event.eventId, queryClient, webSocket.connected, webSocket.instance]);

  const { emitErrorToast } = useUIContext();
  const {
    pagination,
    pageChangeHandler,
    getPage
  } = useTablePagination<UpcomingRaceEntry>({
    initialPageSize: 5,
    controlledTotalCount: filteredItems?.length || 0
  });

  const {
    sortField,
    sortDirection,
    sortChangeHandler,
    sortData
  } = useTableSort<UpcomingRaceEntry>({
    initialSortField: "startTimeUtc",
    initialSortDirection: "asc"
  });

  const columns: EuiBasicTableColumn<UpcomingRaceEntry>[] = useMemo(
    () => [
      {
        field: "startTimeUtc",
        name: "Date",
        render: (startTimeUtc: UpcomingRaceEntry["startTimeUtc"]) => (
          <RaceTimeCell raceTimeUtc={startTimeUtc} />
        )
      },
      {
        field: "registeredDrivers",
        name: "Drivers",
        render: (registeredDrivers: RegisteredDriver[]) => (
          <RegisteredDriversBadgeWithDriversList drivers={registeredDrivers} />
        )
      },
      {
        name: "",
        render: (race: UpcomingRaceEntry) => (
          <div style={{ display: "flex", gap: "5px" }}>
            <ShareButtonsWrapper
              url={`${window.location.origin}${ROUTES.RACE_SERIES_DETAILS}/${raceSeriesId}?eventId=${event.eventId}&raceId=${race.raceId}`}
            />
            <EuiToolTip
              position="bottom"
              content="Show series details on separated page"
            >
              <Link
                to={`${ROUTES.RACE_SERIES_DETAILS}/${raceSeriesId}?eventId=${event.eventId}&raceId=${race.raceId}`}
              >
                <EuiButtonIcon
                  display={"base"}
                  color={"text"}
                  size={"m"}
                  iconType="push"
                />
              </Link>
            </EuiToolTip>
          </div>
        )
      },
      {
        name: "",
        render: (race: UpcomingRaceEntry) => (
          <RegisterToRaceButton
            race={race}
            vehicleClassGroups={event.raceSeriesVehicleClassGroups.groups}
            league={league}
          />
        )
      }
    ],
    [
      event.eventId,
      event.raceSeriesVehicleClassGroups.groups,
      league,
      raceSeriesId
    ]
  );

  const tableChangeHandler = ({ page, sort }: Criteria<UpcomingRaceEntry>) => {
    if (page) {
      pageChangeHandler(page);
    }
    if (sort) {
      sortChangeHandler(sort);
    }
  };

  if (upcomingRacesQuery.isFetching) {
    return (
      <EuiSkeletonRectangle
        isLoading={true}
        width="100%"
        height="500px"
        borderRadius="s"
      />
    );
  }

  if (!upcomingRacesQuery.data?.races.length) {
    return (
      <EuiEmptyPrompt
        iconType="folderExclamation"
        title={<h2>No upcoming races</h2>}
        body={<p>Looks like the is no upcoming races for this event.</p>}
      />
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        marginTop: "10px"
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <EuiSwitch
          label="Show only races with registered drivers"
          checked={showOnlyWithRegistered}
          onChange={() => setShowOnlyWithRegistered(!showOnlyWithRegistered)}
        />
        <UpcomingRacesRangePicker
          startTime={upcomingRacesQuery.data?.races[0].startTimeUtc}
          endTime={
            upcomingRacesQuery.data?.races[
              upcomingRacesQuery.data.races.length - 1
            ].startTimeUtc
          }
          onChange={setDates}
        />
      </div>
      {filteredItems?.length ? (
        <EuiBasicTable<UpcomingRaceEntry>
          items={getPage(sortData(filteredItems))}
          rowHeader="name"
          columns={columns}
          pagination={pagination}
          onChange={tableChangeHandler}
          tableLayout="auto"
          sorting={{
            enableAllColumns: true,
            sort: {
              field: sortField,
              direction: sortDirection
            }
          }}
        />
      ) : (
        <EuiEmptyPrompt
          iconType="folderExclamation"
          title={<h2>No races for selected filters</h2>}
          body={
            <p>Looks like there's no races matching search criteria {":<"}</p>
          }
        />
      )}
    </div>
  );
};
