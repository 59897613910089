import React, { FC } from "react";
import { useTablePagination } from "../../../hooks/useTablePagination";
import { useTableSort } from "../../../hooks/useTableSort";
import { EuiBasicTable, EuiCard, EuiEmptyPrompt, EuiLink } from "@elastic/eui";
import { RaceSerie } from "../../../types/raceSeries";
import { Link } from "react-router-dom";

type SingleDlc = RaceSerie["dlc"]["dlcs"][0];
interface Props {
  dlc?: RaceSerie["dlc"];
}

export const Dlcs: FC<Props> = props => {
  const {
    pagination,
    pageChangeHandler,
    getPage
  } = useTablePagination<SingleDlc>({
    initialPageSize: 5,
    initialTotalItemsCount: props.dlc?.dlcs.length
  });
  const {
    sortField,
    sortDirection,
    sortChangeHandler,
    sortData
  } = useTableSort<SingleDlc>({
    initialSortField: "dlcName",
    initialSortDirection: "desc"
  });

  if (!props.dlc?.isNeeded) {
    return (
      <EuiCard title={"Required DLCs"} layout={"horizontal"}>
        <EuiEmptyPrompt
          iconType="folderExclamation"
          title={<h2>No dlc needed</h2>}
          body={<p>This race does not require any extra dlcs :)</p>}
        />
      </EuiCard>
    );
  }

  return (
    <EuiCard title={"Required DLCs"} layout={"horizontal"}>
      <EuiBasicTable<SingleDlc>
        style={{ maxHeight: "400px", overflow: "auto" }}
        items={getPage(sortData(props.dlc.dlcs))}
        rowHeader="name"
        columns={[
          {
            field: "dlcName",
            name: "Name"
          },
          {
            field: "dlcUrl",
            name: "Dlc url",
            render: (item, record) => (
              <EuiLink>
                <a target="_blank" href={record.dlcUrl}>{item}</a>
              </EuiLink>
            )
          },
          {
            field: "description",
            name: "Description"
          }
        ]}
        pagination={pagination}
        onChange={({ page, sort }) => {
          if (page) {
            pageChangeHandler(page);
          }
          if (sort) {
            sortChangeHandler(sort);
          }
        }}
        tableLayout="auto"
        sorting={{
          enableAllColumns: true,
          sort: {
            field: sortField,
            direction: sortDirection
          }
        }}
      />
    </EuiCard>
  );
};
