import React, { CSSProperties, FC, useEffect, useRef } from "react";
import { animate } from "framer-motion";

interface Props {
  from: number;
  to: number;
  style?: CSSProperties;
}

export const AnimatedCounter: FC<Props> = ({ from, to, style }) => {
  const nodeRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const node = nodeRef.current;

    if (!node) {
      return;
    }

    const controls = animate(from, to, {
      duration: 1,
      onUpdate(value) {
        node.textContent = value.toFixed(2);
      }
    });

    return () => controls.stop();
  }, [from, to]);

  return <div ref={nodeRef} style={style} />;
};
