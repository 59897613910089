import { EuiHorizontalRule } from "@elastic/eui";
import React from "react";
import { RaceSerie } from "types/raceSeries";
import { JoinPracticeButton } from "components/shared/JoinPracticeButton";
import { RegisterToRaceButton } from "components/shared/RegisterToRaceButton";
import { useUserContext } from "../../../contexts/user";
import { R3EConnectMenu } from "../../shared/R3eConnectMenu";

type RaceCardFooterProps = {
  raceSeries: RaceSerie;
};

export const RaceCardFooter: React.FC<RaceCardFooterProps> = React.memo(
  ({ raceSeries }) => {
    const { user } = useUserContext();

    return (
      <div
        style={{ padding: "0 16px 16px 16px", position: "relative", zIndex: 9 }}
      >
        <EuiHorizontalRule margin="m" />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "nowrap"
          }}
        >
          {raceSeries.sim.shortCode === "R3E" &&
          user &&
          !user?.isRaceroomAccountConnected ? (
            <R3EConnectMenu />
          ) : (
            <>
              <JoinPracticeButton raceSeries={raceSeries} />
              <RegisterToRaceButton
                race={raceSeries.event.race}
                vehicleClassGroups={
                  raceSeries.event.raceSeriesVehicleClassGroups.groups
                }
                league={raceSeries.league}
              />
            </>
          )}
        </div>
      </div>
    );
  }
);
