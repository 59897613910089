import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiSkeletonText,
  EuiSpacer
} from "@elastic/eui";
import {
  fetchEventStandings,
  fetchSeasonStandings,
  fetchSeriesStandings
} from "api/standings";
import { commonVariants } from "components/shared/MotionComponents";
import { StandingSelector } from "components/shared/StandingSelector";
import { StandingsTable } from "components/shared/StandingsTable";
import { useUIContext } from "contexts/ui";
import { motion } from "framer-motion";
import React, { useState } from "react";
import { useQuery } from "react-query";
import {
  EventsStandingsSelection,
  SeasonStandingsSelection,
  SeriesStandingsSelection
} from "types/standings";

interface SeriesStatsProps {
  raceSeriesId: number;
}

export const Standings = (props: SeriesStatsProps) => {
  const { raceSeriesId } = props;
  const { emitErrorToast } = useUIContext();

  const [selectedSeason, setSelectedSeason] =
    useState<SeasonStandingsSelection>();
  const [selectedSeries, setSelectedSeries] =
    useState<SeriesStandingsSelection>();
  const [selectedEvent, setSelectedEvent] =
    useState<EventsStandingsSelection>();

  const seasonStandingsQuery = useQuery(
    ["seasonStandinsQuery", selectedSeason?.value],
    fetchSeasonStandings,
    {
      enabled: Boolean(selectedSeason),
      cacheTime: 0,
      refetchOnWindowFocus: false,
      onError: () => {
        setSelectedSeason(undefined);
        emitErrorToast({
          title: "We couldn't get standings data for the selected season."
        });
      }
    }
  );

  const seriesStandingsQuery = useQuery(
    ["seriesStandinsQuery", selectedSeason?.value, selectedSeries?.value],
    fetchSeriesStandings,
    {
      enabled: Boolean(selectedSeason && selectedSeries),
      cacheTime: 0,
      refetchOnWindowFocus: false,
      onError: () => {
        setSelectedSeries(undefined);
        emitErrorToast({
          title:
            "We couldn't get leaderboard data for the selected race series."
        });
      }
    }
  );

  const eventStandingsQuery = useQuery(
    ["eventStandinsQuery", selectedEvent?.value],
    fetchEventStandings,
    {
      enabled: Boolean(selectedSeason && selectedSeries && selectedEvent),
      cacheTime: 0,
      refetchOnWindowFocus: false,
      onError: () => {
        setSelectedEvent(undefined);
        emitErrorToast({
          title:
            "We couldn't get leaderboard data for the selected race series event."
        });
      }
    }
  );
  return (
    <EuiFlexGroup direction="column">
      <EuiFlexItem>
        <motion.div {...commonVariants.zoomOut()}>
          <StandingSelector
            selectedSeason={selectedSeason}
            setSelectedSeason={setSelectedSeason}
            selectedSeries={selectedSeries}
            setSelectedSeries={setSelectedSeries}
            selectedEvent={selectedEvent}
            setSelectedEvent={setSelectedEvent}
            enableSeries={false}
            enableSeasons={false}
            enableEvents
            defaultSeriesId={raceSeriesId}
          />
        </motion.div>
        <EuiSpacer size="xxl" />
        <EuiSkeletonText
          lines={5}
          size="m"
          isLoading={
            seasonStandingsQuery.isLoading ||
            seriesStandingsQuery.isLoading ||
            eventStandingsQuery.isLoading
          }
        >
          <StandingsTable
            seasonStandingsData={seasonStandingsQuery.data?.drivers}
            seriesStandingsData={seriesStandingsQuery.data?.drivers}
            eventStandingsData={eventStandingsQuery.data?.drivers}
            setSelectedSeries={setSelectedSeries}
            defaultPageSize={5}
          />
        </EuiSkeletonText>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
